import { quat, vec3 } from "gl-matrix";
import Node from "nanogl-node";

const QA = quat.create()
const V3A = vec3.create()
const V3B = vec3.create()

function distance(a: vec3, b: vec3) {
  return distance3(a, b[0], b[1], b[2])
}

function distance3(a: vec3, x: number, y: number, z: number) {
  const dx = x - a[0]
  const dy = y - a[1]
  const dz = z - a[2]
  return Math.sqrt(dx * dx + dy * dy + dz * dz)
}

function quatToDir(rot: quat, v: vec3) {
  vec3.set(v, 0, 0, -1);
  vec3.transformQuat(v, v, rot);
}

function interpolate(p0: number, p1: number, p2: number, p3: number, t: number) {
  const v0 = (p2 - p0) * 0.5
  const v1 = (p3 - p1) * 0.5
  const t2 = t * t
  const t3 = t * t2
  return (2 * p1 - 2 * p2 + v0 + v1) * t3 + (-3 * p1 + 3 * p2 - 2 * v0 - v1) * t2 + v0 * t + p1
}

function splinePoints(points: Node[], options: { closed: boolean, segmentLength: number, fromLookat: boolean } = { closed: false, segmentLength: 0.1, fromLookat: false }) {
  const isClosedPath = options && options.closed
  const segmentLength = (options && options.segmentLength) ? options.segmentLength : 0

  const subpoints = []
  const lookats = []
  const isAnchor = []
  const numPoints = isClosedPath ? points.length : points.length - 1
  for (let i = 0; i < numPoints; i++) {
    let c0, c1, c2, c3
    if (isClosedPath) {
      c0 = (i - 1 + points.length) % points.length
      c1 = i % points.length
      c2 = (i + 1) % points.length
      c3 = (i + 2) % points.length
    } else {
      c0 = i === 0 ? i : i - 1
      c1 = i
      c2 = i > points.length - 2 ? i : i + 1
      c3 = i > points.length - 3 ? i : i + 2
    }
    let numSteps = 3
    if (segmentLength) {
      const dist = distance(points[c1].position, points[c2].position)
      numSteps = Math.max(1, dist / segmentLength)
    }
    if (options.fromLookat) {

      quatToDir(points[c1].rotation, V3A)
      vec3.scale(V3A, V3A, 3)
      vec3.add(V3A, V3A, points[c1].position)

      quatToDir(points[c2].rotation, V3B)
      vec3.scale(V3B, V3B, 3)
      vec3.add(V3B, V3B, points[c2].position)

      const dist = distance(V3A, V3B)
      numSteps = Math.max(1, dist / segmentLength)
    }

    if (segmentLength) {
      numSteps *= 100 // generate 10x more points than necessary
    }
    const step = 1 / numSteps



    for (let t = 0; t < 1; t += step) {
      const x = interpolate(points[c0].position[0],
        points[c1].position[0],
        points[c2].position[0],
        points[c3].position[0], t)
      const y = interpolate(points[c0].position[1],
        points[c1].position[1],
        points[c2].position[1],
        points[c3].position[1], t)
      const z = interpolate(points[c0].position[2],
        points[c1].position[2],
        points[c2].position[2],
        points[c3].position[2], t)
      const v = vec3.fromValues(x, y, z)
      subpoints.push(v)

      // tgt
      quat.slerp(QA, points[c1].rotation, points[c2].rotation, t)
      quatToDir(QA, V3A)
      vec3.scale(V3A, V3A, 10)
      vec3.add(V3A, V3A, v)
      const vl = vec3.create()
      lookats.push(vec3.set(vl, V3A[0], V3A[1], V3A[2]))
      isAnchor.push(t === 0)
    }
  }

  isAnchor[isAnchor.length - 1] = true

  const finalPoints = []
  const finalLookAts = []
  const anchorsLoc = []
  let travelledDist = 0
  let prevPoint = points[0].position
  let prevLookat = lookats[0]
  finalPoints.push(prevPoint)
  finalLookAts.push(lookats[0])
  let count = 0
  for (let i = 0; i < subpoints.length; i++) {
    const p = subpoints[i]
    const l = lookats[i]
    if (options.fromLookat) {
      vec3.copy(V3A, prevLookat)
      vec3.copy(V3B, l)
      vec3.sub(V3A, V3A, prevPoint)
      vec3.sub(V3B, V3B, p)
      vec3.scale(V3A, V3A, 0.1)
      vec3.scale(V3B, V3B, 0.1)
      vec3.add(V3A, V3A, prevPoint)
      vec3.add(V3B, V3B, p)
      travelledDist += distance(V3A, V3B)
    }
    else travelledDist += distance(prevPoint, p)
    if (travelledDist >= segmentLength || isAnchor[i]) {
      finalPoints.push(p)
      finalLookAts.push(l)
      if (isAnchor[i]) anchorsLoc.push(count)
      count++
      travelledDist -= segmentLength
    }
    prevPoint = p
    prevLookat = l
  }

  if (distance(finalLookAts[finalLookAts.length - 1], lookats[lookats.length - 1]) > segmentLength / 2) {
    finalPoints.push(subpoints[subpoints.length - 1])
    finalLookAts.push(lookats[lookats.length - 1])
  }
  return { points: finalPoints, lookats: finalLookAts, anchors: anchorsLoc }
}

export default splinePoints