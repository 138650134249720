<template>
  <div
    ref="$root"
    class="quote prevent-select"
    :class="{
      'quote--init': isSplitInit,
      'quote--intro': isIntro,
    }"
    :style="{
      transform: `translate(${quoteRect.rect[0] + widthDiff / 2}px, ${
        quoteRect.rect[1] + heightDiff / 2
      }px) scale(${scaleX}, ${scaleY})`,
      width: `${baseWidth}px`,
      height: `${baseHeight}px`,
      visibility: `${quoteRect.visible ? `visible` : `hidden`}`,
      '--fontSize': `${Viewport.isDesktop ? desktopFontSize : mobileFontSize}px`,
    }"
  >
    <!-- <div class="inner">
      <div class="base">
        <span class="text" v-html="text"></span>
        <span v-if="!isIntro" class="author" v-html="author"></span>
      </div>
      <div class="alt">
        <span class="text" v-html="text"></span>
        <span v-if="!isIntro" class="author" v-html="author"></span>
      </div>
    </div> -->
    <span class="text" v-html="text"></span>
    <span v-if="!isIntro" class="author" v-html="author"></span>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, PropType, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import gsap from "gsap";
import SplitText from "gsap/dist/SplitText";
import CustomEase from "gsap/dist/CustomEase";

import { QuoteReactive } from "@webgl/scene";

import Viewport from "@/store/modules/Viewport";
import hud from "@/store/modules/Hud";

gsap.registerPlugin(SplitText, CustomEase);

const props = defineProps({
  quoteRect: {
    type: Object as PropType<QuoteReactive>,
    default: {} as QuoteReactive,
  },
});

const { t } = useI18n();

const $root = ref<HTMLElement>();
let splitTexts: SplitText[] = [];
const isSplitInit = ref<boolean>(false);
let animation: gsap.core.Tween;

const isIntro = computed(() => props.quoteRect.id === "title");

const text = computed(() =>
  isIntro.value ? t(`experience.intro.text`) : t(`quote.${props.quoteRect.id}.text`),
);
const author = computed(() => t(`quote.${props.quoteRect.id}.author`));

const baseWidth = ref(props.quoteRect.rect[2]);
const baseHeight = ref(props.quoteRect.rect[3]);

const scaleX = computed(() => props.quoteRect.rect[2] / baseWidth.value);
const scaleY = computed(() => props.quoteRect.rect[3] / baseHeight.value);
const widthDiff = computed(() => props.quoteRect.rect[2] - baseWidth.value);
const heightDiff = computed(() => props.quoteRect.rect[3] - baseHeight.value);

const desktopFontSize = computed(() => {
  return ((isIntro.value ? 23 / 647 : 24 / 340) * baseWidth.value).toFixed(3);
});
const mobileFontSize = computed(() => {
  return ((isIntro.value ? 14 / 371 : 24 / 238) * baseWidth.value).toFixed(3);
});

watch(
  () => props.quoteRect,
  () => {
    if (baseWidth.value > 0) return;
    baseWidth.value = props.quoteRect.rect[2];
    baseHeight.value = props.quoteRect.rect[3];
  },
  {
    deep: true,
  },
);

// const init = () => {
//   splitTexts.forEach((splitText) => {
//     splitText.revert();
//   });
//   splitTexts = [];

//   const split = new SplitText($root.value.querySelectorAll(".text, .author"), {
//     type: "words,chars",
//     wordsClass: "Word",
//     // linesClass: "Line",
//     charsClass: "Char",
//   });

//   splitTexts.push(split);
//   isSplitInit.value = true;
// };

// onMounted(() => {
//   document.fonts.ready.then(() => {
//     init();
//   });
// });

// watch(
//   () => [props.quoteRect.visible, props.quoteRect.fullyOnScreen, isSplitInit.value, hud.showLoader],
//   () => {
//     if (hud.showLoader) return;
//     if (props.quoteRect.visible && props.quoteRect.fullyOnScreen && isSplitInit.value) {
//       animation = gsap.to($root.value.querySelectorAll(".alt .Char"), {
//         opacity: isIntro.value ? 0.7 : 1,
//         duration: 0.7,
//         ease: "power1.out",
//         stagger: {
//           amount: 1.5,
//         },
//         delay: isIntro.value ? 0.15 : 0.5,
//       });
//     }
//     // else if (!props.quoteRect.visible) {
//     //   animation?.kill();
//     //   gsap.set($root.value.querySelectorAll(".Line"), {
//     //     opacity: 0.2,
//     //   });
//     // }
//   },
// );
</script>

<style lang="stylus" scoped>
@import "@/styles/typography";
.quote
  @extend .legend1
  // background rgba($grey, 0.2)
  position absolute
  top 0
  left 0
  pointer-events none
  display flex
  flex-direction column
  justify-content center
  gap rem(14)
  transform-origin center
  font-size var(--fontSize)
  // opacity 0
  // &--init
  //   opacity 1
  &--intro
    @extend .legend2
    text-align center
    text-transform unset
    line-height 1.1
    :deep(.Word)
      text-shadow rem(-3) rem(3) rem(12) rgba($black, 0.35)

  .text
    opacity 0.9
  .author
    color rgba($red, 1)
    font-size calc(var(--fontSize) * 0.83)
    display block
    opacity 0.8
    +desktop()
      font-size calc(var(--fontSize) * 0.66)

  .base
    position relative
    display flex
    flex-direction column
    justify-content center
    gap rem(14)
    // opacity 0.15
  .alt
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    display flex
    flex-direction column
    justify-content center
    gap rem(14)
    // :deep(.Char)
    //   opacity 0
  .inner
    position relative
</style>
