
import Program from 'nanogl/program'

import debugFboVs from '@/webgl/glsl/debug_fbo.vert';
import debugFboFs from '@/webgl/glsl/debug_fbo.frag';

import { GLContext } from 'nanogl/types';
import { TypeScene } from '@/webgl/scene';

/////////////
/////////////////////
/////////
Program.debug = false;
//////////


/**
 * checkHighP
 * @param {WebGLRenderingContext} gl 
 */
function checkHighP(gl: GLContext) {

  const hv = gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT);
  const hf = gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT);
  return hf.precision > 0 && hv.precision > 0;

}



export default class Programs {

  hasHighp: boolean;

  programs: Program[];

  debugFbo: Program;
  blit: Program;
  sharpen: Program;

  /**
   * 
   * @param {import('scene').default} scene 
   */
  constructor(scene: TypeScene) {

    const gl = scene.gl;

    // TODO: test if available
    gl.getExtension('OES_standard_derivatives');

    this.hasHighp = checkHighP(gl);

    this.programs = [
      this.debugFbo = new Program(gl),
    ];

    this.compile();
  }



  precision() {
    return this.hasHighp ? 'highp' : 'mediump';
  }


  compile() {

    var defs = '\n';

    defs += 'precision ' + this.precision() + ' float;\n';


    this.debugFbo.compile(
      debugFboVs(),
      debugFboFs(),
      defs
    );


    this.process();

  }


  process() {
    for (var prg of this.programs) {
      prg.use();
    }
  }


  dispose() {

    for (var prg of this.programs) {
      prg.dispose();
    }
    this.programs = null;

  }

}


/////////////


////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////
////

//////////


