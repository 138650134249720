import { addLoaded, addToLoad } from "@/store/modules/WebglLoading";
import { loadBytes } from "@webgl/assets/Net";
import BasisDecoder from "@webgl/resources/basis/BasisDecoder";
import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Input, { Uniform } from "nanogl-pbr/Input";
import TexCoord from "nanogl-pbr/TexCoord";
import Texture2D from "nanogl/texture-2d";
import { GLContext } from "nanogl/types";

export default class SlideTexture extends Chunk {

  slideTex: Input;
  gCol1: Input;
  gCol2: Input;
  slideV: Input;
  slideVU: Uniform;

  tex: Texture2D

  slideValue = 0

  constructor(gl: GLContext) {
    super(true, false)

    this.tex = new Texture2D(gl, gl.RGB)

    // add 2 inputs for the effect parameters
    // as child of this chunk
    this.addChild(this.slideTex = new Input('slideTex', 4))
    this.addChild(this.gCol1 = new Input('gCol1', 3))
    this.addChild(this.gCol2 = new Input('gCol2', 3))

    this.addChild(this.slideV = new Input('slideV', 1))

    this.gCol1.attachConstant([179 / 255, 202 / 255, 202 / 255])
    this.gCol2.attachConstant([251 / 255, 247 / 255, 240 / 255])

    const tc = TexCoord.create('aTexCoord0')

    this.slideTex.attachSampler('slideTex', tc).set(this.tex)

    // initialize these input with constant values
    this.slideVU = this.slideV.attachUniform('uSlideTex', 1)
    this.slideVU.set(this.slideValue)

  }

  async load(src: string, gl: GLContext) {
    addToLoad()
    const buffer = await loadBytes(src)
    const res = await BasisDecoder.getInstance().decode(gl, buffer)
    addLoaded()
    BasisDecoder.setupTexture(res, this.tex)
    this.tex.bind()
    this.tex.setFilter(true, false, true)
  }

  preRender() {
    this.slideValue += 0.000015
    this.slideVU.set(this.slideValue)
  }

  protected _genCode(slots: ChunksSlots): void {

    const slideCode = `
    vec2 uv = vec2(1. - slideTex_texCoord().x, slideTex_texCoord().y * 2.) * 0.8;
    uv.x += uSlideTex * 0.6 + 0.3;
    uv.x = fract(uv.x);
    vec4 slTex = texture2D(slideTex, uv);

    uv = vec2(1. - slideTex_texCoord().x, slideTex_texCoord().y * 2.);
    uv.x += uSlideTex * 0.3 + 0.2;
    uv.y += 0.4;
    uv.x = fract(uv.x);
    vec4 slideTex1 = texture2D(slideTex, uv);

    uv = vec2(slideTex_texCoord().x, slideTex_texCoord().y * 2.) * 0.75;
    uv.x += uSlideTex;
    uv.y -= 0.15;
    uv.x = fract(uv.x);
    vec4 slideTex2 = texture2D(slideTex, uv);
    
    vec3 gradient = mix(
      gCol1(), 
      gCol2(), smoothstep(0.3, 0.6, slideTex_texCoord().y));
    
    vec4 col = mix(vec4(gradient, 1.), slTex, slTex.a);
    col = mix(col, slideTex2, slideTex2.a);
    FragColor = mix(col, slideTex1, slideTex1.a);
    
    `

    slots.add('postf', slideCode);
  }
}