export type AudioIds = "osage-oil-boom" | "wind-cricket" | "wind-cricket-filtered";
export const AudioIds: AudioIds[] = ["osage-oil-boom", "wind-cricket", "wind-cricket-filtered"];

export type AudioId = AudioIds[number];

type AudioOpt = {
  volume?: number;
  inObject?: boolean; // false by default
  restartAtFadeIn?: boolean; // true by default
};

export const DEFAULT_VOLUME = 0.5;

export const AudioData = {
  "osage-oil-boom": {
    volume: 0.3,
  },
  "wind-cricket-filtered": {
    volume: 0.55,
    restartAtFadeIn: false,
  },
  "wind-cricket": {
    volume: 0.55,
    restartAtFadeIn: false,
  },
} as Record<AudioId, AudioOpt>;
