<template>
  <div class="Quote" ref="$root">
    <div class="Quote__Icon__Wrapper">
      <svg-icon
        class="Quote__Icon col-start-3 xl:col-start-2 col-span-6 xl:col-span-4"
        name="big-quote"
      />
    </div>
    <div class="Quote__Inner">
      <p class="Quote__Text col-start-2 xl:col-start-2 col-span-8 xl:col-span-4">{{ text }}</p>
      <h3 class="Quote__Author paragraph2 col-start-2 xl:col-start-2 col-span-8 xl:col-span-4">
        {{ author }}
      </h3>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import gsap from "gsap";
import SplitText from "gsap/dist/SplitText";
import hud from "@/store/modules/Hud";

const route = useRoute();
const { t, te } = useI18n();
const $root = ref<HTMLElement>();
let splitText: SplitText | null = null;

const detailId = computed(() => route.params.detailId);
const hotspotId = computed(() => route.params.hotspotId);

const text = ref("");
const author = ref("");

const splitTextAnimation = () => {
  const texts = $root.value.querySelectorAll(".Quote__Text, .Quote__Author");
  splitText = new SplitText(texts, {
    type: "words",
    wordsClass: "Word",
  });

  gsap.from($root.value.querySelectorAll(".Word"), {
    opacity: 0,
    duration: 0.7,
    ease: "power1.out",
    stagger: {
      amount: 1.3,
    },
    delay: 0.5,
  });
};

watch(
  [detailId, hotspotId],
  () => {
    if (t(`detail.${detailId.value}.${hotspotId.value}.media.type`) !== "quote") return;
    hud.uiThemeLightQuote = true;
    const tl = gsap.timeline();
    if (splitText) {
      const texts = $root.value.querySelectorAll(".Word");
      tl.to(texts, {
        opacity: 0,
        duration: 0.3,
        ease: "power1.in",
        onComplete: () => {
          text.value = t(`detail.${detailId.value}.${hotspotId.value}.media.source`);
          author.value = te(`detail.${detailId.value}.${hotspotId.value}.media.author`)
            ? t(`detail.${detailId.value}.${hotspotId.value}.media.author`)
            : "";
          nextTick(splitTextAnimation);
        },
        clearProps: "all",
      });
    } else {
      text.value = t(`detail.${detailId.value}.${hotspotId.value}.media.source`);
      author.value = te(`detail.${detailId.value}.${hotspotId.value}.media.author`)
        ? t(`detail.${detailId.value}.${hotspotId.value}.media.author`)
        : "";
      nextTick(splitTextAnimation);
    }
  },
  { immediate: true },
);

const emit = defineEmits(["load"]);

onMounted(() => {
  setTimeout(() => {
    emit("load");
  }, 100);
});

onUnmounted(() => {
  hud.uiThemeLightQuote = false;
});
</script>
<style lang="stylus" scoped>
.Quote
  display flex
  background rgba($black, 1)
  color rgba($white, 1)
  justify-content center
  align-items center
  height 100%

  &__Inner
    position relative
    display grid
    grid-template-columns repeat(10, 1fr)
    gap rem(16) rem(16)
    padding 0 rem(16)
    width 100%
    text-align center

    +desktop()
      grid-template-columns repeat(6, 1fr)
      padding 0 rem(24) 0 rem(12)
      gap rem(16) rem(24)


  &__Icon
    width 100%
    height auto
    opacity 0.03

    &__Wrapper
      position absolute
      top 0
      left 0
      bottom 0
      margin auto
      width 100%
      height 100%
      display grid
      grid-template-columns repeat(10, 1fr)
      align-items center
      gap rem(16) rem(16)
      padding 0 rem(16)
      text-align center

      +desktop()
        grid-template-columns repeat(6, 1fr)
        padding 0 rem(24) 0 rem(12)
        gap rem(16) rem(24)

  &__Text
    font-size rem(24)
    font-weight 600
    line-height 1
    text-transform uppercase

  &__Author
    opacity 0.7
</style>
