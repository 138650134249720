<template>
  <round-button
    :class="[
      'Hotspot',
      state.isDragging || state.isInDetailsTransition ? 'is-dragging' : '',
      state.firstFrameLoaded && appeared ? 'is-loaded' : '',
      state.isHotspotDisabled ? 'is-disabled' : '',
    ]"
    :icon="hotspot.type"
    :to="`/detail/${detailId}/${hotspot.slug}`"
    :isClickForced="false"
    :active="hotspotId === hotspot.slug"
    :style="{
      top: `${hotspot.y * 100}%`,
      transform: `translate(-50%, -50%) translateX(${getPosition(x, parentWidth)}px)`,
    }"
  />
</template>

<script lang="ts" setup>
import { PropType, defineProps, inject, onMounted, onUnmounted, ref } from "vue";
import gsap from "gsap";

import useDetails, { DetailState, Hotspot, PIXEL_PER_FRAME } from "@/composables/useDetails";
import eventBus from "@/utils/EventBus";
import pageTransition from "@/store/modules/PageTransition";

const props = defineProps({
  hotspot: {
    type: Object as PropType<Hotspot>,
    required: true,
  },
  parentWidth: {
    type: Number,
    default: 0,
  },
  parentHeight: {
    type: Number,
    default: 0,
  },
});

const { nbFrames360, detailId, hotspotId } = useDetails();

const appeared = ref<boolean>(false);
const x = ref<number>(0);

const state = inject<DetailState>("state");

const radius = props.hotspot.radius * 100;
const offset = (100 - radius) / 2;

const tick = () => {
  const initialX = (props.hotspot.x * 100 - offset) / 2;
  let positionX =
    (Math.round(
      (Math.floor((state.x / PIXEL_PER_FRAME) % nbFrames360.value) / nbFrames360.value) *
        radius *
        1000,
    ) /
      1000 +
      initialX) %
    radius;
  if (positionX < 0) {
    positionX = radius + positionX;
  }
  positionX = positionX * 2;
  if (positionX > radius) {
    positionX = radius - (positionX - radius);
  }

  x.value = positionX + offset;
  console.groupEnd();
};

const getPosition = (x: number, maxX: number): number => {
  return (x * maxX) / 100;
};

onMounted(() => {
  gsap.ticker.add(tick);
  if (pageTransition.from.name === "Detail") {
    setTimeout(() => {
      appeared.value = true;
    }, 1000);
  } else {
    eventBus.pageEnter.once(({ timeline }) => {
      timeline.add(() => (appeared.value = true), pageTransition.isFirst ? 1.3 : 1.7);
    });
  }
});
onUnmounted(() => {
  gsap.ticker.remove(tick);
});
</script>

<style lang="stylus" scoped>
.Hotspot
  position absolute
  top 0
  left 0
  transform translate(-50%, -50%)
  font-size rem(24)

  opacity 0
  pointer-events none

  transition opacity 1s $easeQuartInOut

  &.is-loaded
    opacity 1
    pointer-events auto
  &.is-dragging
    opacity 0
    transition opacity 0.3s $easeQuartOut
  &.is-disabled
    pointer-events none
</style>
