<template>
  <footer class="FooterMenu padded" :class="[`FooterMenu--${theme}`]">
    <div :class="['Background', `Background--${theme}`, showBackground ? '' : 'hidden']" />
    <MenuLinks
      :class="['Content__nav', showMenu ? '' : 'hidden']"
      :items="menuLinks"
      :active-item="activeItem"
      alignement="start"
      :is-color-variation="true"
      :theme="theme"
    />
    <nav :class="['FooterMenu__inner SubMenu', showSubmenu ? '' : 'hidden', `SubMenu--${theme}`]">
      <ul class="FooterMenu__list">
        <li class="FooterMenu__link legend1" v-for="(link, index) in footerNavItems" :key="index">
          <base-link is="a" :href="link.href" target="_blank">
            {{ link.label }}
          </base-link>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script lang="ts" setup>
import { PropType, onBeforeMount, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

import eventBus from "@/utils/EventBus";
import MenuLinks, { LinkItem } from "@/components/blocks/Menu/MenuLinks.vue";
import pageTransition from "@/store/modules/PageTransition";

const { t } = useI18n();

defineProps({
  activeItem: {
    type: String,
    default: "objects",
  },
  theme: {
    type: String as PropType<"light" | "dark">,
    default: "light",
  },
});

const showMenu = ref<boolean>(true);
const showSubmenu = ref<boolean>(false);
const showBackground = ref<boolean>(false);

const menuLinks = reactive<LinkItem[]>([
  {
    id: "objects",
    label: t("menu.nav-label.listPage") || "objects",
    link: "/list",
    appear: false,
  },
  {
    id: "about",
    label: t("menu.nav-label.aboutPage") || "about",
    link: "/about",
    appear: false,
  },
]);

const footerNavItems = [
  {
    label: t("legals.title"),
    to: t("legals.url"),
    href: t("legals.href"),
  },
  {
    label: t("credits.title"),
    to: t("credits.url"),
    href: t("credits.href"),
  },
];

const isFromMenu = ref<boolean>(false);
onBeforeMount(() => {
  isFromMenu.value = pageTransition.from?.meta?.menuOpen || false;

  if (isFromMenu.value) {
    showMenu.value = false;
    for (let i = 0; i < menuLinks.length; i++) {
      menuLinks[i].appear = true;
    }
  }
});

onMounted(() => {
  eventBus.pageEnter.once(({ timeline }) => {
    if (isFromMenu.value) {
      timeline.add(() => {
        showSubmenu.value = true;
      }, 0);
      timeline.add(() => {
        showMenu.value = true;
      }, 0.5);
      timeline.add(() => {
        showBackground.value = true;
      }, 1);
    } else {
      const amount = 0.3;
      timeline.addLabel("menu", 1.5);
      timeline.add(() => {
        showBackground.value = true;
      }, "menu");

      for (let i = 0; i < menuLinks.length; i++) {
        timeline.add(
          () => {
            menuLinks[i].appear = true;
          },
          `menu+=${i * (amount / menuLinks.length)}`,
        );
      }
      timeline.add(
        () => {
          showSubmenu.value = true;
        },
        `menu+=${amount + 0.1}`,
      );
    }
  });
});
</script>

<style lang="stylus" scoped>
.FooterMenu
  position fixed
  bottom 0
  left 0
  width 100%
  padding-bottom rem(20)
  padding-top rem(20)
  z-index $z-index-hud
  display flex
  justify-content space-between
  &--light
    color rgba($white, 1)
  &--dark
    color rgba($black, 1)
  &__list
    text-align right
  &__inner
    transform translateY(5px)

  .Content__nav, .FooterMenu__inner
    z-index 2

  .Content__nav
    font-size rem(48)
    transition opacity 1s $easeQuartOut
    &.hidden
      opacity 0
      pointer-events none

  +desktop()
    display none

  .MenuLinks
    align-self flex-end

.SubMenu
  transition opacity 1s $easeQuartOut
  align-self center
  margin-bottom 5px
  +desktop
    align-self unset
    margin-bottom 0
  &.hidden
    opacity 0
    pointer-events none

  &--light
    color rgba($black, 1)
  &--dark
    color rgba($white, 1)

.Background
  position absolute
  top 0
  left 0
  full()

  transition opacity 1s $easeQuartOut
  &.hidden
    opacity 0
    pointer-events none
  &--light
    background rgba($grey, 1)
  &--dark
    background rgba($black, 1)

:deep(.MenuLinks--mobileHorizontal.MenuLinks--normal .MenuLinks__list)
  padding-left 0
  padding-right 0
</style>
