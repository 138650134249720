<template>
  <loop-line
    :class="['Loop display1', isInit ? 'is-init' : '']"
    :autoPlay="true"
    :autoPlaySpeed="autoPlaySpeed * 0.4"
    :gap="80"
    :gapSmall="48"
    :x="targetX"
    :listenScroll="false"
    :speed="0.1"
    @update="onLoopLineUpdate"
  >
    <div ref="base" class="Loop__Base">{{ sanitizeHtml($t(`detail.${detailId}.backtitle`)) }}</div>
  </loop-line>
</template>
<script lang="ts" setup>
import { computed, inject, nextTick, onMounted, ref, watch } from "vue";
import gsap from "gsap";
import SplitText from "gsap/dist/SplitText";

import eventBus from "@/utils/EventBus";
import sanitizeHtml from "@/utils/sanitizeHtml";
import Viewport from "@/store/modules/Viewport";
import pageTransition from "@/store/modules/PageTransition";

import useDetails, { DetailState } from "@/composables/useDetails";

gsap.registerPlugin(SplitText);

const { detailId, isHotspotOpen } = useDetails();
const state = inject<DetailState>("state");

const openX = ref<number>(0);
const isInit = ref<boolean>(false);
const autoPlaySpeed = ref<number>(1);

watch(
  () => isHotspotOpen.value,
  (value) => {
    if (value) {
      gsap.to(openX, {
        value: -Viewport.windowWidth * 10,
        duration: 1,
        ease: "power1.inOut",
      });
    } else {
      gsap.to(openX, {
        value: 0,
        duration: 1,
        ease: "power1.inOut",
        delay: 0.3,
      });
    }
  },
);

watch(
  () => [state.backTitleIntroComplete, state.dragDirection, state.isDragging],
  () => {
    if (state.backTitleIntroComplete) {
      if (state.isDragging) {
        autoPlaySpeed.value = 0;
      } else {
        autoPlaySpeed.value = state.dragDirection;
      }
    }
  },
);

const targetX = computed((): number => {
  return state.isInDetailsTransition ? targetX.value : state.x + openX.value;
});

let splitText: SplitText;
let hasPlayIntro = false;
let needToPlayIntro = false;
let splitted = false;
const onLoopLineUpdate = (loop: HTMLDivElement) => {
  nextTick(() => {
    if (splitText) {
      splitText.revert();
    }
    splitText = new SplitText(loop.querySelectorAll(".Loop__Base"), {
      type: "chars",
      charsClass: "Char",
    });

    splitted = true;
    if (state.showBackTitle) playTransitionIn();
  });
};

let tl: gsap.core.Timeline | null = null;
const playTransitionIn = () => {
  if (!hasPlayIntro && needToPlayIntro && splitted) {
    isInit.value = true;
    const visibleChars = splitText.chars.filter((char) => {
      const { left } = char.getBoundingClientRect();
      return left < Viewport.windowWidth;
    });

    if (tl) {
      tl.kill();
    }

    tl = gsap
      .timeline({
        delay: pageTransition.isFirst ? 1 : 0.7,
        onComplete: () => {
          state.backTitleIntroComplete = true;
          hasPlayIntro = true;
        },
      })
      .fromTo(
        visibleChars,
        {
          yPercent: 100,
          opacity: 0,
        },
        {
          yPercent: 0,
          opacity: 1,
          duration: 1,
          ease: "quart.out",
          stagger: {
            amount: 0.3,
          },
        },
      );
  }
};

const playTransitionOut = () => {
  if (splitted) {
    const visibleChars = splitText.chars.filter((char) => {
      const { left } = char.getBoundingClientRect();
      return left < Viewport.windowWidth;
    });

    if (tl) {
      tl.kill();
    }

    tl = gsap.timeline().fromTo(
      visibleChars,
      {
        yPercent: 0,
        opacity: 1,
      },
      {
        yPercent: -100,
        opacity: 0,
        duration: 1,
        ease: "quart.out",
        stagger: {
          amount: 0.3,
        },
      },
    );
  }
};

watch(
  () => state.showBackTitle,
  (value) => {
    value ? playTransitionIn() : playTransitionOut();
  },
);

onMounted(() => {
  if (pageTransition.from.name === "Detail") {
    needToPlayIntro = true;
    if (state.showBackTitle) playTransitionIn();
  } else {
    eventBus.pageEnter.once(() => {
      needToPlayIntro = true;
      playTransitionIn();
    });
  }
});
</script>
<style lang="stylus" scoped>
.Loop
  position absolute
  top 50%
  left 0
  transform translate(0, -50%)
  opacity 0
  pointer-events none
  user-select none
  white-space nowrap

  &.is-init
    opacity 0.05
</style>
