import { assign, createMachine, interpret } from "xstate";
import { AppEvent, OpenContentPanel } from "./AppEvents";
import AppService from "../AppService";
import { getIdFromPermalink } from "./HotSpots";
import Scene from "@webgl/scene";

type XpContext = {
  loaded: boolean;
  isPlayground: boolean;
  isLandscapeParallax: boolean;
};

function CreateContext(): XpContext {
  return {
    loaded: false,
    isPlayground: false,
    isLandscapeParallax: false,
  };
}

export type AppStateContext = ReturnType<typeof CreateContext>;

export type AppStateType = {
  value: string;
  context: AppStateContext;
};
/// #endif

function CreateAppStateMachine() {
  return createMachine<AppStateContext, AppEvent, AppStateType>(
    {
      predictableActionArguments: true,

      id: "app",

      initial: "initial",

      context: CreateContext(),

      on: {
        GO_TO_PLAYGROUND: {
          actions: ["setIsPlayground"],
        },
        GO_TO_LANDSCAPE_PARALLAX: {
          actions: ["setIsLandscapeParallax"],
        },
        SET_CONTENT_PANEL: {
          target: "#app.panel",
          actions: ["setOpenHotSpot"],
        },
        START_XP: {
          target: "xp",
        },
      },

      states: {
        // STATE INIT
        initial: {
          on: {
            INIT: {
              target: "initializing",
            },
          },
        },

        initializing: {},

        xp: {
          initial: "checkloading",
          states: {
            checkloading: {
              always: [
                { target: "#app.xp.running", cond: "isLoaded" },
                { target: "#app.xp.loading" },
              ],
            },
            loading: {
              initial: "load_game_resources",
              states: {
                load_game_resources: {
                  invoke: [
                    {
                      id: "load-activites-start",
                      src: "loadActivitesStart",
                      onDone: {
                        target: "#app.xp.finish_loading",
                      },
                    },
                  ],
                },
              },
            },
            finish_loading: {
              entry: [(ctx) => (ctx.loaded = true)],
              always: [
                { target: "#app.xp.running.playground", cond: "isPlayground" },
                { target: "#app.xp.running.landscaperParallax", cond: "isLandscapeParallax" },
                { target: "#app.xp.running" },
              ],
            },
            running: {
              initial: "default",
              states: {
                default: {
                  entry: ["startWebglXp"],
                },
                playground: {
                  entry: ["startWebglPlayground"],
                },
                landscaperParallax: {
                  entry: ["startWebglPlayground"],
                },
              },
            },
          },
        },
        panel: {},
      },
    },

    {
      actions: {
        startWebglXp: () => {
          AppService.glapp.glview.play();
        },
        startWebglPlayground: () => {
          AppService.glapp.glview.play();
        },
        setOpenHotSpot: (ctx, event) => {
          const hp = getIdFromPermalink((event as OpenContentPanel).permalink);
          (AppService.Scene as Scene).setHotSpot(hp);
        },
        setIsPlayground: assign<XpContext, any>({
          isPlayground: () => true,
        }),
        setIsLandscapeParallax: assign<XpContext, any>({
          isLandscapeParallax: () => true,
        }),
      },

      services: {
        async loadActivitesStart() {
          const toLoad = [];
          toLoad.push(AppService.Scene.load());
          await Promise.all(toLoad);
        },
      },

      guards: {
        isPlayground: (ctx) => {
          return ctx.isPlayground;
        },
        isLandscapeParallax: (ctx) => {
          return ctx.isLandscapeParallax;
        },
        isLoaded: (ctx) => {
          return ctx.loaded;
        },
      },
    },
  );
}

export function CreateAppStateInterpreter() {
  const res = interpret(CreateAppStateMachine());

  res.onTransition((state, evt) => {
    let currentState = state.value;

    if (typeof currentState === "object") {
      const keys = Object.keys(state.value);
      if (keys.length > 0) {
        currentState = keys[0];
      }
    }

    document.body.className = currentState.toString();
  });

  return res;
}

export type AppStateInterpreter = ReturnType<typeof CreateAppStateInterpreter>;
