<template>
  <figure
    :class="['Image', Viewport.isTouch ? 'Image--Touch' : '']"
    :data-isTouch="Viewport.isTouch"
  >
    <frame-set
      class="FrameSet"
      v-if="(isActive || showFrameSet) && !Viewport.isTouch"
      :src="item.slug"
      :x="x"
      :nbFrames="nbFrames"
      :angle="angle"
      :appeared="true"
      @allFramesLoaded="onLoaded"
    />
    <picture v-if="showPicture || Viewport.isTouch">
      <source
        :srcset="`/assets/images/${item.slug}/mobile/avif/${firstFrame}.avif`"
        type="image/avif"
        media="(max-width: 1050px)"
      />
      <source
        :srcset="`/assets/images/${item.slug}/mobile/webp/${firstFrame}.webp`"
        type="image/webp"
        media="(max-width: 1050px)"
      />
      <source
        :srcset="`/assets/images/${item.slug}/mobile/webp/${firstFrame}.png`"
        type="image/png"
        media="(max-width: 1050px)"
      />
      <source
        :srcset="`/assets/images/${item.slug}/desktop/avif/${firstFrame}.avif`"
        type="image/avif"
      />
      <source
        :srcset="`/assets/images/${item.slug}/desktop/webp/${firstFrame}.webp`"
        type="image/webp"
      />
      <img
        :src="`/assets/images/${item.slug}/desktop/png/${firstFrame}.png`"
        :alt="item.title"
        loading="lazy"
        @load="onImgLoaded"
        ref="$img"
      />
    </picture>
  </figure>
</template>

<script lang="ts" setup>
import { PropType, computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import gsap from "gsap";

import { PIXEL_PER_FRAME } from "@/composables/useDetails";
import Viewport from "@/store/modules/Viewport";

import { GridItem } from "./ListItem.vue";

const SPEED = 0.8;

const props = defineProps({
  item: {
    type: Object as PropType<GridItem>,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: true,
  },
});
const { t } = useI18n();

const $img = ref<HTMLImageElement>();

const nbFrames = computed(() => parseInt(t(`detail.${props.item.slug}.nbFrames`)) || 0);
const angle = computed(() => parseInt(t(`detail.${props.item.slug}.angle`)) || 360);
const isBounds = computed(() => {
  return angle.value !== 360;
});

const firstFrame = computed(() => {
  return (isBounds.value ? Math.round(nbFrames.value / 2) + 1 : 1).toString().padStart(3, "0");
});

const x = ref<number>(0);
const showFrameSet = ref<boolean>(false);
const showPicture = ref<boolean>(true);
let timeline: gsap.core.Timeline | null = null;

const onLoaded = () => {
  showFrameSet.value = true;
  showPicture.value = false;

  const offset = (nbFrames.value / 2) * PIXEL_PER_FRAME;
  const min = isBounds.value ? -offset : 0;
  const max = isBounds.value ? offset : PIXEL_PER_FRAME * nbFrames.value;

  if (isBounds.value) {
    timeline = gsap.timeline({
      onComplete: () => {
        timeline = gsap
          .timeline({
            repeat: -1,
            yoyo: isBounds.value,
          })
          .fromTo(
            x,
            {
              value: max,
            },
            {
              value: min,
              duration: (3 * angle.value) / 360 / SPEED,
              ease: "none",
            },
          );
      },
    });
    timeline.fromTo(
      x,
      {
        value: 0,
      },
      {
        value: max,
        duration: (3 * angle.value) / 360 / 2 / SPEED,
        ease: "none",
      },
    );
  } else {
    // TODO ADD YOYO IF NOT 360
    timeline = gsap
      .timeline({
        repeat: -1,
      })
      .fromTo(
        x,
        {
          value: 0,
        },
        {
          value: PIXEL_PER_FRAME * nbFrames.value,
          duration: 3 / SPEED,
          ease: "none",
        },
      );
  }
};

const onImgLoaded = () => {
  if (Viewport.isDesktop) return;
  gsap.fromTo(
    $img.value,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.5,
      ease: "quad.out",
    },
  );
};

watch(
  () => props.isActive,
  () => {
    if (!props.isActive) {
      if (timeline) {
        timeline.kill();
      }
      timeline = gsap
        .timeline({
          onComplete: () => {
            showPicture.value = true;
            setTimeout(() => (showFrameSet.value = false), 50);
          },
        })
        .to(x, {
          value: 0,
          duration:
            ((Math.abs(x.value) / (PIXEL_PER_FRAME * nbFrames.value)) * 1 * angle.value) /
            360 /
            SPEED,
          ease: "quart.out",
        });
    }
  },
);
</script>

<style lang="stylus" scoped>
.Image
  display block
  overflow hidden
  full()
  padding rem(40)

  &--Touch
    padding rem(44)

  picture,
  img
    display block
    full()

  img
    object-fit contain
    // transition transform 0.5s $easeQuartOut
    // .active &
    //   transform scale(1.1)

.FrameSet
  padding rem(40)
</style>
