
/////////////
///////////////////////////////////////////
//////////////////////////////////////////
/////////////////////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////

//////////////////////////////////////////////////////
//////////////
//////////


import GLState from 'nanogl-state/GLState'
import Node from 'nanogl-node'
import Camera from 'nanogl-camera'
import Rect from 'nanogl-primitives-2d/rect'

import Programs from '@/webgl/gl/programs'
// import IBLManager from '@/webgl/gl/env'
import PostProcess from '@/webgl/gl/post'
import Masks from '@/webgl/gl/masks'
import Inputs from '@/webgl/lib/inputs'
import Raycaster from '@/webgl/lib/raycaster'
import Materials from '@/webgl/assets/materials-lib'
import CamControler from '@/webgl/camera/CameraController'
import MaterialElement from "nanogl-gltf/lib/elements/Material";
import { StandardPass } from "nanogl-pbr/StandardPass";
// import OrbitControler from '@/webgl/camera/XPController'
import MaxControler from '@/webgl/camera/MaxController'
import XpController from '@/webgl/camera/XpController'
import Lens from '@/webgl/camera/Lens'
import SceneInvalidator from '@/webgl/lib/scene-invalidator'
import GLView from '@/webgl/glview'
import CameraControler from '@/webgl/camera/CameraController'
import GalleryLighting from "./gallery/GalleryLighting";
// import XPController from '@/webgl/camera/XPController'

// import { DEG2RAD } from '@/webgl/math'

import { GLContext } from 'nanogl/types'
import PerspectiveLens from 'nanogl-camera/perspective-lens'
import { mat4 } from 'gl-matrix'
import Deferred from '@/utils/Deferred'
// import PunctualLight from 'nanogl-pbr/lighting/PunctualLight'
import ScreenSize from './glsl/ScreenSize'

// import resources from '@/store/modules/resources'
import DevCameraController from './camera/DevCameraController'
import Fbo from 'nanogl/fbo'
import GLConfig from 'nanogl-state/GLConfig'
import { Passes } from './glsl/Passes'
import Gltf from 'nanogl-gltf/lib/Gltf'
import { HotSpot } from '@/services/models/HotSpotModel';
import { Ref, ref } from 'vue';
import GltfNode from "nanogl-gltf/lib/elements/Node";
import { TypeScene } from './scene';
import GLTFResource from './assets/GLTFResource';
import { getAssetPath } from './gallery/Assets';
import MaterialOverrideExtension from 'nanogl-gltf/lib/extensions/MaterialOverrideExtension';
import SurfaceParallax from './glsl/surface-parallax/SurfaceParallax';
import { Sampler } from 'nanogl-pbr/Input';
// import { reactive } from "vue";
// import SchemeBlender from './camera/SchemeBlender'

export type HPReactive = {
  value: HotSpot[]
}

export default class ParallaxScene implements TypeScene {

  dt: number
  time: number
  ratio: number
  ilayer: Element
  loaddefer: Deferred
  glview: GLView
  gl: GLContext
  mainCamera: Camera<PerspectiveLens>
  devCamera: Camera<PerspectiveLens>
  camera: Camera<PerspectiveLens>
  sroot: Node
  root: Node
  glstate: GLState
  glconfig: GLConfig
  quad: Rect
  inputs: Inputs
  invalidator: SceneInvalidator
  post: PostProcess
  matlib: Materials

  lighting: GalleryLighting;

  programs: Programs
  camCtrl: CameraControler
  // xpcam: XPController
  devCtrl: DevCameraController
  maxcam: MaxControler


  camGltf: Gltf

  resource: GLTFResource
  gltf: Gltf
  parallax: SurfaceParallax


  spotList: GltfNode[] = []
  nbCam: number = 0


  raycaster: any
  enableDebugDraw: boolean
  forceFps: any

  debugCam: () => void
  mainCam: () => void
  logDebugCam: () => void
  logMainCam: () => void
  mipmapUsage: () => void
  ghostload: () => void

  isLoaded = false


///////////////
//////////////////////
////////////

  isPlayground = false

  hotspotList: Ref<HotSpot[]> = ref([])

  constructor() {

    this.dt = 0;
    this.time = 0;
    this.ratio = 1.0;
    this.ilayer = null;

  }

  /**
   *
   * @param {import('glview').default} glview
   */
  init(glview: GLView) {

    this.loaddefer = new Deferred()

    this.glview = glview
    this.gl = this.glview.gl


    // CAMERA
    // ======
    this.mainCamera = this.makeCamera()
    this.devCamera = this.makeCamera()
    this.camera = this.mainCamera


    this.sroot = new Node();
    this.root = new Node();

    this.glstate = new GLState(this.gl);
    this.glconfig = this.glstate.config()
      .depthMask(false)
      .enableDepthTest(true)
    this.quad = new Rect(this.gl);
    this.inputs = new Inputs(this.ilayer);
    this.invalidator = new SceneInvalidator();


    this.post = new PostProcess(this);
    this.matlib = new Materials(this);
    // this.iblMngr     = new IBLManager ( this );
    this.raycaster = new Raycaster(this);
    this.programs = new Programs(this);


/////////////////
/////////////////////////////////////////
////////////////////////////////
//////////////

    // CONTROLERS
    // ======
    this.camCtrl = new CamControler(this)
    this.maxcam = new MaxControler(this.glview.canvas)
    this.invalidator.watchNode(this.mainCamera);
    this.invalidator.watchNode(this.devCamera);

    // GRAPH
    // ======
    this.root.add(this.mainCamera)
    this.root.add(this.devCamera)
    this.sroot.add(this.root)
    // this.root .add( this.tree      .node );

    // this.camCtrl.setControler(this.maxcam);



    this.inputs.start();

    this.lighting = new GalleryLighting(this, this.gl, "lighting");

    const overrides = new MaterialOverrideExtension();

    overrides.overridePass('Material.001', (ctx, material) => {
      this.parallax = new SurfaceParallax()
      material.getPass('color').pass.inputs.add(this.parallax);
      // tex._tex.bind()
      return null
    })

    this.resource = new GLTFResource(
      "testParallax.glb",
      this,
      {
        extensions: [overrides],
      }
    );


/////////////////
////////////////////////////////////
///////////////
///////////////////////////////////////////////
//////////////////////////
//////////////////
/////////////////////////////////
///////////////////
///////
//////
////////////////////////////////////////////////////////////////

///////////////////////////
///////////////////////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////
/////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
//////////////////////////////
//////////////////////////////
///////////////////////////////////////////////
////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////
/////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////



//////////////

  }

  setup() {

/////////////////
/////////////////////////////////////
//////////////
  }

  invalidate() {
    this.invalidator.invalidate();
    this.post.resize()
  }

  handleResize() {
    this.invalidate();
  }



  render(dt: number) {

/////////////////
//////////////////////////////////
////////////
/////
/////////////////////////
//////////////
    this.dt = dt;
    this.time += dt;


    this.drawScene(this.camera);

  }



  drawScene(camera: Camera, fbo: Fbo = null, force = false) {
    if (!this.isLoaded) return

    const gl = this.gl;
    const w = fbo ? fbo.width : this.glview.width;
    const h = fbo ? fbo.height : this.glview.height;

    this.ratio = w / h;
    ScreenSize.setSize(w, h)



    // preRender
    // =============
    this.camCtrl.preRender();
    this.parallax.preRender(0, this.dt)
    // this.iblMngr.preRender();


    // upadate graph
    // =================

    // this.root.rotation.set([0, 0, 0, 1])
    // this.root.rotateY(this.envRotation)
    this.sroot.updateWorldMatrix()


    camera.updateViewProjectionMatrix(w, h);


    // if (!force && !this.invalidator.needRender()) {
    //   return;
    // }


    // RTT
    // ==========
    this.lighting.lightSetup.prepare(this.gl);

    // this.iblMngr.lights.setup.prepare(gl);
    // this.renderLightmaps()


    // RENDER
    // ========
    this.glstate.apply()
    gl.clearColor(1, 1, 1, 1);
    // gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    this.post.post.preRender(w, h);
    this.post.post.bindColor()

    if (this.gltf) {
      this.renderGltf(camera, Masks.OPAQUE, Passes.COLOR)
      this.renderGltf(camera, Masks.BLENDED, Passes.COLOR)

    }

    this.post.post.render()


/////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////////
///////////////////////////////
////////////////////////////
/////////////////////////////
/////
///////////////////////////
//////////////


/////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////////

  }


  renderGltf(camera: Camera, mask: Masks, passId: Passes = Passes.DEFAULT, cfg?: GLConfig) {
    for (const renderable of this.gltf.renderables) {

      renderable.render(this.gl, camera, mask, passId, cfg)
    }
  }



  async load() {
    await Promise.all([this.post.load(), this.lighting.load(), this.resource.load()])
    // await this.preCompileMats()
    this.onLoaded()
  }




  onLoaded = () => {
    this.isLoaded = true
    this.post.onLoaded()
    this.lighting.onLoaded()

    this.gltf = this.resource.value


    for (const material of this.gltf.materials) {
      const mat = (material as MaterialElement)
      const pass = mat.materialPass;
      if (pass instanceof StandardPass) {
        this.lighting.setupGltfMaterial(mat)
      }
    }

    this.lighting.exposure = 1
    this.lighting.gamma = 2.2
    this.lighting.ibl.intensity = 0.5

    this.root.add(this.gltf.root)
    this.gltf.root.updateWorldMatrix()
    this.camera.lookAt(this.gltf.root.position)
    this.camera.invalidate()
    this.camera.updateWorldMatrix()
    this.gltf.root.lookAt(this.camera.position)
    this.gltf.root.invalidate()
    this.gltf.root.updateWorldMatrix()
  }




  makeCamera() {
    const camera = new Camera(new Lens());
    camera.lens.setVerticalFov(0.2467391304)
    camera.lens.near = .1
    camera.lens.far = 100

    camera.setMatrix(<mat4>new Float32Array(
      // [0.9995434284210205, -1.396704476519517e-7, -0.03021577186882496, 0, -0.0026346975937485695, 0.9961907863616943, -0.08716089278459549, 0, 0.030100684612989426, 0.08720070123672485, 0.9957358837127686, 0, 0.45953, 6.27598, 25.63325, 1]
      [0.9262577891349792, -2.0060909378116776e-7, 0.37688902020454407, 0, 0.00859061535447836, 0.9997400641441345, -0.021112119778990746, 0, -0.3767911195755005, 0.022792980074882507, 0.9260172843933105, 0, 0.6404088735580444, 3.5489344596862793, 29.736970901489258, 1]
    ))

    return camera
  }

}




