<template>
  <div class="VideoSlider__Wrapper grid padded">
    <div class="VideoSlider" ref="slider">
      <div class="VideoSlider__Inner" ref="sliderInner">
        <AboutVideo
          v-for="index in nbVideo"
          :key="index"
          :data-index="index"
          :index="index"
          :activeIndex="activeIndex"
          :active="index === activeIndex"
          :class="['VideoSlider__Item', index === activeIndex ? 'active' : '']"
          @mouseenterNext="() => (isNextActive = true)"
          @mouseleaveNext="() => (isNextActive = false)"
          @mouseenterPrevious="() => (isPreviousActive = true)"
          @mouseleavePrevious="() => (isPreviousActive = false)"
          @clickNext="onClickNext"
          @clickPrevious="onClickPrevious"
        />
      </div>
    </div>

    <cursor :label="t(`about.video.previous`)" :isActive="isPreviousActive" theme="light" />
    <cursor :label="t(`about.video.next`)" :isActive="isNextActive" theme="light" />

    <AboutVideoFullscreen />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, onUnmounted, provide, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

import Viewport from "@/store/modules/Viewport";
import eventBus from "@/utils/EventBus";
import AboutVideoFullscreen from "./AboutVideoFullscreen.vue";

import gsap from "gsap";
import Draggable from "gsap/dist/Draggable";
import InertiaPlugin from "gsap/dist/InertiaPlugin";

gsap.registerPlugin(Draggable, InertiaPlugin);

const { t } = useI18n();

const isNextActive = ref<boolean>(false);
const isPreviousActive = ref<boolean>(false);

const nbVideo = computed(() => {
  let count = 0;
  let key = `about.video-${count + 1}.source`;
  let value = t(key);

  while (value !== key) {
    count++;
    key = `about.video-${count + 1}.source`;
    value = t(key);
  }

  return count;
});

const slider = ref<HTMLElement>();
const sliderInner = ref<HTMLElement>();
const itemWidthCss = ref<string>("0px");
const activeIndex = ref<number>(1);

const draggable = ref<Draggable | null>(null);

export type VideoSliderState = {
  activeVideoIndex: number | null;
};
const state = reactive<VideoSliderState>({
  activeVideoIndex: null,
});
provide("state", state);

const clearDraggable = () => {
  draggable.value?.kill();
  draggable.value = null;
};
const onResize = () => {
  itemWidthCss.value = `${slider.value.clientWidth}px`;
  clearDraggable();
  if (Viewport.isDesktop) {
    draggable.value = Draggable.create(sliderInner.value, {
      type: "x",
      bounds: slider.value,
      inertia: true,
      dragResistance: 0.4,
      edgeResistance: 0.9,
      throwResistance: 2000,
      maxDuration: 0.5,
      zIndexBoost: false,
      snap: (val) => {
        const itemWidth = slider.value.clientWidth + 24;
        const target = Math.max(draggable.value.minX, Math.min(0, val));
        const index = Math.round(target / itemWidth);
        activeIndex.value = Math.max(0, Math.min(nbVideo.value, Math.abs(index) + 1));

        return index * itemWidth;
      },
      onDragStart: () => {
        isNextActive.value = false;
        isPreviousActive.value = false;
      },
    })[0];
  }
};

const onClickNext = () => {
  goto(activeIndex.value + 1);
};
const onClickPrevious = () => {
  goto(activeIndex.value - 1);
};
const goto = (index: number) => {
  if (Viewport.isDesktop) {
    const dest = Math.max(0, Math.min(nbVideo.value, index));
    const itemWidth = slider.value.clientWidth + 24;
    const x = itemWidth * (dest - 1) * -1;

    isNextActive.value = false;
    isPreviousActive.value = false;

    gsap.timeline().to(sliderInner.value, {
      x,
      duration: 1,
      ease: "quart.out",
    });
    activeIndex.value = dest;
  }
};

onMounted(() => {
  eventBus.resize.on(onResize);
  onResize();
});
onUnmounted(() => {
  eventBus.resize.off(onResize);
  clearDraggable();
});
</script>

<style lang="stylus" scoped>
$itemWidth = v-bind(itemWidthCss)
.VideoSlider
  grid-column 1 / span 10
  padding-bottom rem(175)
  +desktop()
    grid-column 3 / span 6
    margin-top rem(32)
    padding-bottom 24vh
    padding-bottom 24dvh

  &__Wrapper
    width 100%
    overflow hidden

  &__Inner
    display flex
    flex-direction column
    gap rem(16)

    +desktop()
      display inline-flex
      flex-direction row
      gap rem(24)

  &__Item
    +desktop()
      flex 0 0 auto
      width $itemWidth
      opacity 0.4
      transition opacity 0.7s $easeQuartOut
      // pointer-events none

      &.active
        opacity 1
        // pointer-events auto
</style>
