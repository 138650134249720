<template>
  <MenuLinks
    ref="menu"
    :class="[`MainMenuLinks`, show ? '' : 'hidden']"
    :items="items"
    :activeItem="activeItem"
    :theme="theme"
    :isColorVariation="isColorVariation"
    :isSmall="isSmall"
    :alignement="alignement"
    :isHorizontalOnMobile="isHorizontalOnMobile"
  />
</template>

<script lang="ts" setup>
import { PropType, onMounted, ref } from "vue";

import eventBus from "@/utils/EventBus";
import pageTransition from "@/store/modules/PageTransition";

import MenuLinks, { LinkItem } from "@/components/blocks/Menu/MenuLinks.vue";

defineProps({
  items: {
    type: Object as PropType<LinkItem[]>,
    required: true,
  },
  activeItem: {
    type: String,
    required: true,
  },
  theme: {
    type: String as PropType<"light" | "dark">,
    default: "light",
  },
  isColorVariation: {
    type: Boolean,
    default: false,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
  alignement: {
    type: String as PropType<"start" | "end">,
    default: "end",
  },
  isHorizontalOnMobile: {
    type: Boolean,
    default: true,
  },
});

const show = ref<boolean>(false);
const menu = ref();

onMounted(() => {
  eventBus.pageEnter.once(({ timeline }) => {
    if (!menu.value) return;
    const { root: current } = menu.value;
    const { previousMainMenu: previous } = pageTransition;
    if (previous && current) {
      const { top: previousTop } = previous.getBoundingClientRect();
      if (previousTop < 0) {
        timeline.addLabel("MenuLinks", 0.7);
        timeline.fromTo(
          current,
          {
            y: previousTop,
          },
          {
            y: 0,
            duration: 0.7,
            ease: "quad.inOut",
          },
          "MenuLinks",
        );
        timeline.fromTo(
          previous,
          {
            y: 0,
          },
          {
            y: -previousTop,
            duration: 0.7,
            ease: "quad.inOut",
          },
          "MenuLinks",
        );
      }
    }

    timeline.add(() => {
      show.value = true;
    }, 0.5);
  });

  eventBus.pageEnterCompleted.once(() => {
    if (menu.value) {
      pageTransition.previousMainMenu = menu.value.root;
    }
  });
});
</script>

<style lang="stylus" scoped>
.MainMenuLinks
  transition opacity 1s $easeQuartOut
  &.hidden
    opacity 0
    pointer-events none
</style>
