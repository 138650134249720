<template>
  <loop-line
    :class="['Loop display1', isInit ? 'is-init' : '']"
    :autoPlay="true"
    :autoPlaySpeed="0.4"
    :gap="80"
    :gapSmall="48"
    :listenScroll="false"
    :speed="0.1"
    @update="onLoopLineUpdate"
  >
    <div ref="base" class="Loop__Base">{{ sanitizeHtml($t(`rotate.backtitle`)) }}</div>
  </loop-line>
</template>
<script lang="ts" setup>
import { nextTick, onMounted, ref } from "vue";
import gsap from "gsap";
import SplitText from "gsap/dist/SplitText";

import sanitizeHtml from "@/utils/sanitizeHtml";
import Viewport from "@/store/modules/Viewport";
import pageTransition from "@/store/modules/PageTransition";

gsap.registerPlugin(SplitText);

const isInit = ref<boolean>(false);

let splitText: SplitText;
let hasPlayIntro = false;
let needToPlayIntro = false;
let splitted = false;
const onLoopLineUpdate = (loop: HTMLDivElement) => {
  nextTick(() => {
    if (splitText) {
      splitText.revert();
    }
    splitText = new SplitText(loop.querySelectorAll(".Loop__Base"), {
      type: "chars",
      charsClass: "Char",
    });

    splitted = true;
    playTransitionIn();
  });
};

let tl: gsap.core.Timeline | null = null;
const playTransitionIn = () => {
  if (!hasPlayIntro && needToPlayIntro && splitted) {
    isInit.value = true;
    const visibleChars = splitText.chars.filter((char) => {
      const { left } = char.getBoundingClientRect();
      return left < Viewport.windowWidth;
    });

    if (tl) {
      tl.kill();
    }

    tl = gsap
      .timeline({
        delay: 0.7,
        onComplete: () => {
          // state.backTitleIntroComplete = true;
          hasPlayIntro = true;
        },
      })
      .fromTo(
        visibleChars,
        {
          yPercent: 100,
          opacity: 0,
        },
        {
          yPercent: 0,
          opacity: 1,
          duration: 1,
          ease: "quart.out",
          stagger: {
            amount: 0.3,
          },
        },
      );
  }
};

onMounted(() => {
  needToPlayIntro = true;
  playTransitionIn();
});
</script>
<style lang="stylus" scoped>
.Loop
  position absolute
  top 50%
  left 0
  transform translate(0, -50%)
  opacity 0
  pointer-events none
  user-select none
  white-space nowrap
  color rgba($white, 1)
  padding rem(8) 0 0

  font-size rem(198)

  &.is-init
    opacity 0.1
</style>
