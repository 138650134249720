<template>
  <transition-page
    class="MenuPage"
    :variant="isFromMenu ? 'fadeIn' : 'vertical'"
    :basicIn="!isFromMenu"
    :fullscreen="false"
  >
    <div class="MenuPage__inner xl:padded grid">
      <div class="MenuPage__filtersWrapper hideScrollbar xl:col-start-1 xl:col-end-3">
        <MenuLinks
          class="MenuPage__filters"
          :items="filterItems"
          :active-item="activeCategory"
          alignement="start"
          :is-small="true"
          :is-horizontal-on-mobile="true"
        />
      </div>
      <div class="MenuPage__list xl:col-start-3 xl:col-end-9">
        <ListGrid :active-category="activeCategory" />
      </div>
      <div class="MenuPage__navWrapper xl:col-start-9 xl:col-end-12">
        <MainMenuLinks
          class="MenuPage__nav"
          :items="menuItems"
          active-item="objects"
          alignement="end"
          :is-color-variation="true"
        />
      </div>
      <FooterMenu active-item="objects" theme="light" />
    </div>
  </transition-page>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

import eventBus from "@/utils/EventBus";
import pageTransition from "@/store/modules/PageTransition";

import ListGrid from "@/components/blocks/List/ListGrid.vue";
import MainMenuLinks from "@/components/blocks/Menu/MainMenuLinks.vue";
import MenuLinks, { LinkItem } from "@/components/blocks/Menu/MenuLinks.vue";
import FooterMenu from "@/components/blocks/Layout/FooterMenu.vue";
import { setHudTheme } from "@/store/modules/Hud";

const { t } = useI18n();
const route = useRoute();

const categories = computed(() => {
  const categories = t("list.categories").split(",");
  return categories.map((category: string) => category.trim()) || [];
});

const activeCategory = computed(() => {
  return (route.query.category as string) || "all";
});

const filterItems = reactive<LinkItem[]>([
  {
    id: "all",
    label: "all",
    link: "/list",
    appear: false,
  },
]);
const menuItems = reactive<LinkItem[]>([
  {
    id: "objects",
    label: t("menu.nav-label.listPage") || "objects",
    link: "/list",
    appear: false,
  },
  {
    id: "about",
    label: t("menu.nav-label.aboutPage") || "about",
    link: "/about",
    appear: false,
  },
]);

const isFromMenu = ref<boolean>(false);
const isFromHome = ref<boolean>(false);

onBeforeMount(() => {
  isFromMenu.value = pageTransition.from?.meta?.menuOpen || false;
  isFromHome.value = pageTransition.from?.name === "Home";

  if (isFromMenu.value) {
    for (let i = 0; i < menuItems.length; i++) {
      menuItems[i].appear = true;
    }
  }
});

onMounted(() => {
  categories.value.forEach((category) => {
    filterItems.push({
      id: category.toLowerCase(),
      label: category,
      link: `/list?category=${category.toLowerCase()}`,
      appear: false,
    });
  });

  eventBus.pageEnter.once(({ timeline }) => {
    const amount = 0.3;
    timeline.addLabel("menu", 1.5);
    timeline.addLabel("filter", 1.5);
    console.log(isFromMenu.value, isFromHome.value);

    timeline.add(() => setHudTheme("dark"), isFromMenu.value ? 0.8 : 0.5);
    for (let i = 0; i < menuItems.length; i++) {
      timeline.add(
        () => {
          menuItems[i].appear = true;
        },
        `menu+=${i * (amount / menuItems.length)}`,
      );
    }

    for (let i = 0; i < filterItems.length; i++) {
      timeline.add(
        () => (filterItems[i].appear = true),
        `filter+=${i * (amount / filterItems.length)}`,
      );
    }
    timeline.add(
      () => {
        pageTransition.isInTransition = false;
      },
      isFromHome.value ? "+=0" : "-=1",
    );
  });
});
</script>

<style lang="stylus" scoped>
.MenuPage
  &__inner
    width 100%
    display flex
    flex-direction column
    padding-top 27vh
    padding-top 27dvh
    +desktop()
      padding-top 0
      display grid
  &__filtersWrapper
    max-width 100%
    overflow-x auto
    padding-bottom rem(10)
    padding-top rem(32)

    +desktop()
      padding 0
  &__filters
    +desktop()
      position fixed
      top 17.111vh
  &__navWrapper
    display inline-flex
    justify-content flex-end
  &__nav
    display none
    justify-self end
    position fixed
    padding-top 17.111vh
    +desktop()
      display block
</style>
