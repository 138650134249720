<template>
  <div class="GLPlayground">
    <GlPlaygroundView />
    <div
      v-if="!showParallax"
      ref="dropZone"
      id="drop_zone"
      :class="isUpload ? 'show' : ''"
      @drop="dropHandler"
      @dragover="dragOverHandler"
    >
      <p>Drag a glb file to replace section {{ toReplace }} in this <i>drop zone</i>.</p>
    </div>
    <div v-if="stats !== null" class="glb-stats">
      <div v-html="statsString" class="stats-wrapper dark-mode"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AppService from "@/services/AppService";
import { useAppState } from "@/services/Composition";
import { onMounted, onUnmounted, ref, defineProps, PropType } from "vue";
import GlPlaygroundView from "@/components/PlayGround/PlaygroundGLView.vue";
import { prettyPrintJson } from "pretty-print-json";
/// #if DEBUG
import gui from "@webgl/dev/gui";
import { NUM_SECTIONS } from "@webgl/gallery";
import { computed } from "vue";
/// #endif

const { state } = useAppState();
const dropZone = ref<HTMLDivElement | null>(null);
const isUpload = ref<boolean>(false);
const toReplace = ref<number | null>(null);
const stats = ref<string | null>(null);

const statsString = computed(() => {
  if (stats.value === null) return;
  return prettyPrintJson.toHtml(stats.value, { lineNumbers: true });
});

const showParallax = computed(() => state.value.context.isLandscapeParallax);

onMounted(() => {
  if (!showParallax.value) {
    /// #if DEBUG
    const fol = gui.folder("gallery sections management");
    for (let i = 0; i < NUM_SECTIONS; i++) {
      fol.btn(`replace gallery section - ${i}`, () => {
        isUpload.value = !isUpload.value;
        toReplace.value = i;
      });
      fol.btn(`get stats on gallery section - ${i}`, () => {
        stats.value = AppService.glapp.glview.scene.gallery.sections[i].getStats();
      });
      fol.open();
    }
    /// #endif
  }
});

onUnmounted(() => {
  /// #if DEBUG
  gui.clearFolder("gallery sections management");

  /// #endif
});

const dropHandler = (ev: DragEvent) => {
  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();

  if (ev.dataTransfer?.items) {
    // Use DataTransferItemList interface to access the file(s)
    [...ev.dataTransfer.items].forEach((item, i) => {
      // If dropped items aren't files, reject them
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file) {
          switch (file.type) {
            case "model/gltf+json":
              handleGlb(file);
          }
          console.log(`… file[${i}].type = ${file.type}`);
        }
      }
    });
  } else if (ev.dataTransfer?.files) {
    // Use DataTransfer interface to access the file(s)
    [...ev.dataTransfer.files].forEach((file, i) => {
      console.log(`… file[${i}].type = ${file.type}`);
    });
  }
  if (dropZone.value) dropZone.value.classList.remove("dropping");
  isUpload.value = false;
};

const handleGlb = (file: File) => {
  const reader = new FileReader();
  reader.addEventListener(
    "load",
    async (event: ProgressEvent) => {
      const contents = reader.result;

      AppService.glapp.glview.scene.gallery.sections[toReplace.value!].replaceContent(
        contents as ArrayBuffer,
      );
    },
    false,
  );
  reader.readAsArrayBuffer(file);
};

const dragOverHandler = (ev: DragEvent) => {
  if (dropZone.value) dropZone.value.classList.add("dropping");

  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();
};
</script>

<style lang="stylus">
.GLPlayground
  position fixed
  top 0px
  left: 0
  width: 100vw
  height: 100vw
  .glb-stats
    position fixed
    top 0px
    left: 0
    width 50vw
    background: rgba(0, 0, 0, 0.5)
    pointer-events: none
    color: black
    display: flex
    .stats-wrapper
      width 100%
    /* Layout */
    .json-container           { font-family: menlo, consolas, monospace; font-style: normal; font-weight: bold; line-height: 1.4em; font-size: 0.9rem; transition: background-color 400ms; }
    a.json-link               { text-decoration: none; border-bottom: 1px solid; outline: none; }
    a.json-link:hover         { background-color: transparent; outline: none; }
    ol.json-lines             { white-space: normal; padding-inline-start: 3em; margin: 0px; }
    ol.json-lines >li         { white-space: pre; text-indent: 0.7em; line-height: 1.5em; padding: 0px; }
    ol.json-lines >li::marker { font-family: system-ui, sans-serif; font-weight: normal; }
    .json-key, .json-string, .json-number, .json-boolean, .json-null, .json-mark, a.json-link, ol.json-lines >li { transition: all 400ms; }

    /* Colors */
    .json-container                   { background-color: white; }
    .json-key                         { color: brown; }
    .json-string                      { color: olive; }
    .json-number                      { color: navy; }
    .json-boolean                     { color: teal; }
    .json-null                        { color: dimgray; }
    .json-mark                        { color: black; }
    a.json-link                       { color: purple; }
    a.json-link:visited               { color: slategray; }
    a.json-link:hover                 { color: blueviolet; }
    a.json-link:active                { color: slategray; }
    ol.json-lines >li::marker         { color: dimgray; }
    ol.json-lines >li:nth-child(odd)  { background-color: gainsboro; }
    ol.json-lines >li:nth-child(even) { background-color: whitesmoke; }
    ol.json-lines >li:hover           { background-color: lemonchiffon; }

    /* Dark Mode */
    .dark-mode .json-container                   { background-color: black; }
    .dark-mode .json-key                         { color: indianred; }
    .dark-mode .json-string                      { color: khaki; }
    .dark-mode .json-number                      { color: deepskyblue; }
    .dark-mode .json-boolean                     { color: mediumseagreen; }
    .dark-mode .json-null                        { color: darkorange; }
    .dark-mode .json-mark                        { color: silver; }
    .dark-mode a.json-link                       { color: mediumorchid; }
    .dark-mode a.json-link:visited               { color: slategray; }
    .dark-mode a.json-link:hover                 { color: violet; }
    .dark-mode a.json-link:active                { color: slategray; }
    .dark-mode ol.json-lines >li::marker         { color: silver; }
    .dark-mode ol.json-lines >li:nth-child(odd)  { background-color: #222222; }
    .dark-mode ol.json-lines >li:nth-child(even) { background-color: #161616; }
    .dark-mode ol.json-lines >li:hover           { background-color: dimgray; }
    p
      text-wrap: wrap

  #drop_zone
    position fixed
    top 0px
    left: 0
    width: 100vw
    height: 100vh
    visibility: hidden
    background: rgba(0, 0, 0, 0.5)
    display flex
    justify-content center
    align-items center
    &.show
      visibility: visible
    &.dropping {
      border: 5px solid blue;
      color: black
    }
</style>
