<template>
  <frame-set
    class="FrameSet"
    :src="src"
    :nbFrames="nbFrames"
    :angle="angle"
    :x="state.x"
    :appeared="appeared"
    :hasIntro="!state.isInDetailsTransition"
    @firstFrameLoaded="state.firstFrameLoaded = true"
    @progressLoaded="onProgress"
  />
</template>
<script lang="ts" setup>
import { inject, onMounted, ref } from "vue";

import useDetails, { DetailState } from "@/composables/useDetails";
import eventBus from "@/utils/EventBus";
import pageTransition from "@/store/modules/PageTransition";

defineProps({
  src: {
    type: String,
    default: "",
  },
});

const state = inject<DetailState>("state");
const { nbFrames, angle } = useDetails();
const appeared = ref<boolean>(false);

const onProgress = (progress: number) => {
  state.progressLoaded = progress;
};

onMounted(() => {
  if (pageTransition.from.name === "Detail") {
    appeared.value = true;
  } else {
    eventBus.pageEnter.once(({ timeline }) => {
      timeline.add(() => (appeared.value = true), pageTransition.isFirst ? 0 : 1);
    });
  }
});
</script>

<style lang="stylus" scoped>
.FrameSet
  transition opacity 1s $easeQuartOut
</style>
