<template>
  <Teleport to="body">
    <div class="VideoFullscreen" :class="[active ? 'active' : '']" ref="$root">
      <Transition :css="false" @enter="transitionIn" @leave="transitionOut">
        <div class="VideoFullscreen__Inner" v-if="imageSrc">
          <div class="VideoFullscreen__Background" />
          <div class="VideoFullscreen__Cover">
            <image-set
              :src="`about/cover/${imageSrc}`"
              :alt="sanitizeHtml($t(`about.video-${state.activeVideoIndex}.title`))"
              :has2x="true"
              :hasWebp="true"
              :hasAvif="true"
            />
          </div>
          <div class="VideoFullscreen__Player">
            <div class="VideoFullscreen__Player__Inner">
              <iframe
                class="VideoFullscreen__Player__Iframe"
                :src="`https://player.vimeo.com/video/${source}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=false&amp;transparent=0&amp;gesture=media&amp;autoplay=true`"
                allowfullscreen
                allowtransparency
                allow="autoplay"
              />
            </div>

            <close-button class="VideoFullscreen__Close" @click="onClose" />
          </div>
        </div>
      </Transition>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { computed, inject, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import Plyr from "plyr";
import "plyr/dist/plyr.css";

import gsap from "gsap";

import sanitizeHtml from "@/utils/sanitizeHtml";
import Viewport from "@/store/modules/Viewport";

import { VideoSliderState } from "./AboutVideoSlider.vue";
import AudioSettings from "@/store/modules/Audio";

const { t } = useI18n();
const state: VideoSliderState = inject("state");

const active = ref<boolean>(false);
const $root = ref<HTMLElement | null>(null);

let player: Plyr | null = null;

onMounted(() => {
  document.addEventListener("keydown", onkeydown);
  // document.addEventListener("fullscreenchange", onFullscreenChanged);
});
onBeforeUnmount(() => {
  if (player) {
    player.destroy();
  }
  document.removeEventListener("keydown", onkeydown);
  // document.removeEventListener("fullscreenchange", onFullscreenChanged);
});

const source = computed(() => {
  if (state.activeVideoIndex === null) return null;
  const key = `about.video-${state.activeVideoIndex}.source`;
  const src = t(key);
  if (key === src) return null;
  return src;
});
const imageSrc = computed(() => {
  if (state.activeVideoIndex === null) return null;
  const key = `about.video-${state.activeVideoIndex}.cover`;
  const src = t(key);
  if (key === src) return "default.jpg";
  return src;
});

const onkeydown = (e: KeyboardEvent) => {
  if (e.key === "Escape") {
    onClose();
  }
};
const onClose = () => {
  if (state.activeVideoIndex !== null) {
    state.activeVideoIndex = null;

    // if (document.exitFullscreen && document.fullscreenElement) {
    //   try {
    //     document.exitFullscreen();
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }
  }
};

// const onFullscreenChanged = () => {
//   if (!document.fullscreenElement) {
//     onClose();
//   }
// };

let timeline: gsap.core.Timeline | null = null;
const transitionIn = (el: Element, done: () => void) => {
  if (timeline) {
    timeline.kill();
  }

  const sliderItem = document.querySelector(
    `.VideoSlider__Item[data-index="${state.activeVideoIndex}"]`,
  );
  if (sliderItem) {
    const { top, left, width, height } = sliderItem.getBoundingClientRect();
    active.value = true;

    const tl = gsap.timeline({
      pause: true,
      onComplete: () => {
        // ON COMPLETE PLAY VIDEO
        done();
      },
    });

    // BACKGROUND
    const background = el.querySelector(".VideoFullscreen__Background");
    if (background) {
      tl.addLabel("background", 0);
      tl.fromTo(
        background,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: "power3.out",
        },
        "background",
      );
    }

    // COVER
    const $picture = el.querySelector(".VideoFullscreen__Cover picture");
    const $image = el.querySelector(".VideoFullscreen__Cover img");
    if ($picture && $image) {
      tl.addLabel("cover", 0);

      const tooHigh = Viewport.windowWidth / Viewport.windowHeight > width / height;
      const ratio = 400 / 225;
      const scale = !tooHigh ? width / Viewport.windowWidth : height / Viewport.windowHeight;
      const offsetX = !tooHigh ? 0 : (Viewport.windowWidth - Viewport.windowHeight * ratio) / 2;
      const offsetY = !tooHigh ? (Viewport.windowHeight - Viewport.windowWidth / ratio) / 2 : 0;
      const x = Math.round((left - offsetX) * 1000) / 1000;
      const y = Math.round((top - offsetY) * 1000) / 1000;

      tl.fromTo(
        $picture,
        {
          x,
          y,
          scale,
          transformOrigin: "0 0",
        },
        {
          x: 0,
          y: 0,
          scale: 1,
          duration: 1,
          ease: "power3.inOut",
        },
        "cover",
      );
      if (Viewport.isDesktop) {
        tl.fromTo(
          $image,
          {
            scale: 1.1,
          },
          {
            scale: 1,
            duration: 1,
            ease: "power3.inOut",
          },
          "cover",
        );
      }
      // tl.pause();

      timeline = tl;
    }
  }

  const $playerInner = el.querySelector(".VideoFullscreen__Player__Inner") as HTMLElement;
  if ($playerInner) {
    const coverTl = gsap.timeline({ paused: true, delay: 1 });
    const $player = el.querySelector(".VideoFullscreen__Player");
    if ($player) {
      coverTl.addLabel("player", 0);
      coverTl.fromTo(
        $player,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: "power3.out",
        },
        "player",
      );
    }
    player = new Plyr($playerInner, {
      controls: ["play", "progress", "mute", "volume"],
      invertTime: true,
      autoplay: true,
    });
    player.once("playing", () => {
      coverTl.play();
    });

    player.once("ready", () => {
      if (timeline) {
        timeline.play();
      }
    });
  }

  // if (Viewport.isMobile) {
  //   if (document.documentElement.requestFullscreen) {
  //     try {
  //       document.documentElement.requestFullscreen();
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   }
  // }
};

const transitionOut = (el: Element, done: () => void) => {
  if (timeline) {
    timeline.kill();
    timeline = null;
  }
  if (active.value) {
    active.value = false;
    if (player) {
      player.destroy();
    }
    done();
  }
};

watch(
  () => state.activeVideoIndex,
  () => {
    AudioSettings.isVideoPlaying = state.activeVideoIndex !== null;
  },
);
</script>

<style lang="stylus" scoped>
.VideoFullscreen
  --plyr-color-main rgba($white, 1)
  --plyr-control-toggle-checked-background transparent
  --plyr-video-control-background-hover transparent

  position fixed
  top 0
  left 0
  width 100%
  height 100%

  pointer-events none
  z-index 10001

  &.active
    pointer-events auto

  &__Background
    position absolute
    top 0
    left 0
    full()
    background rgba($black, 1)

  &__Cover
    position absolute
    top 0
    left 0
    full()
    display flex
    align-items center
    justify-content center

    :deep(picture)
      display block
      position relative
      aspect-ratio 400/225
      width auto
      height 100%
      overflow hidden
      @media (max-aspect-ratio: 400/225)
        width 100%
        height auto

    :deep(img)
      object-fit cover
      object-position center
      display block
      full()
      +desktop()
        transform scale(1.1)


  &__Inner
    full()

  &__Player
    full()
    display flex
    align-items center
    justify-content center
    opacity 0
    overflow hidden

  &__Player__Iframe,
  :deep(.plyr)
    border none
    width auto
    height 100%

    @media (max-aspect-ratio: 400/225)
      width 100%
      height auto

  :deep(.plyr--video)
    overflow visible
  :deep(.plyr--video .plyr__controls)
    left rem(16)
    width calc(100% - 2rem)
    bottom rem(32)
    padding rem(8) rem(16)
    border-radius rem(8)
    background rgba($white, 0.1)
    backdrop-filter blur(8px)
    display flex
    gap rem(16)

    +desktop()
      width 80vw
      left 10vw

  &__Close
    position absolute
    top rem(16)
    right rem(16)

    transition opacity 0.7s $easeQuartOut

    +desktop()
      top rem(24)
      right rem(24)

    .plyr--hide-controls + &:not(:hover)
      opacity 0
</style>
