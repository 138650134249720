<template>
  <Teleport to="body">
    <div
      class="DetailMore"
      :class="{
        open: isHotspotOpen,
        'fully-open': isHotspotFullyOpen,
        'video-extended': videoState.extended,
      }"
    >
      <div class="DetailMore__Inner">
        <Transition
          name="more"
          :duration="hotspotId ? { enter: 1500, leave: 0 } : { enter: 1500, leave: 700 }"
          appear
          @afterEnter="hasNoDelay = true"
          @beforeLeave="
            () => {
              hasNoDelay = false;
            }
          "
        >
          <aside
            v-if="isHotspotOpen && state.isHotspotOpenable && delayedTypeMedia !== 'quote'"
            class="Content"
            :class="[hotspotId ? 'hasLeaveTransition' : '']"
          >
            <div class="Content__Inner">
              <split-text
                is="h2"
                class="Content__Title h4 col-start-2 xl:col-start-2 col-span-8 xl:col-span-4"
                :state="isHotspotOpen && state.isHotspotOpenable && showTitle ? 'active' : 'before'"
                v-html="$t(`detail.${detailId}.more.title`)"
              />
              <p
                class="paragraph2 Content__Text col-start-2 xl:col-start-2 col-span-8 xl:col-span-4"
                v-html="$t(`detail.${detailId}.more.description`)"
              />
            </div>
          </aside>
        </Transition>

        <Transition
          mode="in-out"
          name="more"
          :duration="hotspotId ? { enter: 1500, leave: 0 } : { enter: 1500, leave: 700 }"
          appear
          @enter="
            (enterEl) => {
              quoteZBoosted = enterEl.classList.contains('quote') && !!previousTypeMedia;
              state.isHotspotDisabled = true && !!previousTypeMedia;
              if (typeMedia !== 'quote' || !!previousTypeMedia === false)
                delayedTypeMedia = typeMedia;
              hud.showFooter = false;
            }
          "
          @after-enter="
            () => {
              isHotspotFullyOpen = true;
              if (typeMedia !== delayedTypeMedia) delayedTypeMedia = typeMedia;
              previousTypeMedia = typeMedia;
            }
          "
          @after-leave="
            () => {
              previousTypeMedia = typeMedia;
              quoteZBoosted = false;
              state.isHotspotDisabled = false;
              if (route.name === 'Detail') {
                hud.showFooter = true;
              }
            }
          "
          @before-leave="
            () => {
              isHotspotFullyOpen = false;
            }
          "
        >
          <aside
            v-if="isHotspotOpen && state.isHotspotOpenable"
            :class="[
              'Media__Wrapper',
              hasNoDelay || (typeMedia === 'quote' && !Viewport.isDesktop) ? 'no-delay' : '',
              typeMedia,
              quoteZBoosted ? 'quote--z-boosted' : '',
              videoState.extended ? 'video-extended' : '',
              hotspotId ? 'hasLeaveTransition' : '',
            ]"
            :key="`${transitionKey}`"
          >
            <div :class="['Media__Container', isMediaLoaded ? '' : 'Media__Container--Loading']">
              <MediaComponent class="Media" @load="onMediaLoad" />
            </div>
          </aside>
        </Transition>
        <Transition mode="in-out" name="more" appear>
          <div
            v-if="isHotspotOpen && state.isHotspotOpenable"
            class="Close__Wrapper"
            :class="[
              AudioSettings.isVideoPlaying ? 'video-playing' : '',
              typeMedia === 'quote' ? 'bottom' : '',
            ]"
          >
            <round-button
              class="Close"
              :class="[AudioSettings.isVideoPlaying ? 'video-playing' : '']"
              icon="close"
              :to="`/detail/${detailId}`"
              :small-icon="true"
              :disable-magnet="true"
            />
          </div>
        </Transition>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, onUnmounted, provide, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import gsap from "gsap";

import useDetails, { DetailState } from "@/composables/useDetails";

import hud from "@/store/modules/Hud";
import pageTransition from "@/store/modules/PageTransition";
import AudioSettings from "@/store/modules/Audio";

import DetailMoreGallery from "./DetailMoreGallery.vue";
import DetailMoreImage from "./DetailMoreImage.vue";
import DetailMoreVideo from "./DetailMoreVideo.vue";
import DetailMoreQuote from "./DetailMoreQuote.vue";
import Viewport from "@/store/modules/Viewport";
import { useRoute } from "vue-router";

const { t } = useI18n();

const quoteZBoosted = ref<boolean>(false);
const videoState = reactive<{ extended: boolean }>({ extended: false });
provide("videoState", videoState);

const { detailId, hotspotId, isHotspotOpen } = useDetails();
const isHotspotFullyOpen = ref<boolean>(false);
const state = inject<DetailState>("state");
const showTitle = ref<boolean>(false);
const isMediaLoaded = ref<boolean>(false);
const hasNoDelay = ref<boolean>(false);
const route = useRoute();

const typeMedia = computed(() => {
  return hotspotId.value ? t(`detail.${detailId.value}.${hotspotId.value}.media.type`) : "";
});
const delayedTypeMedia = ref(typeMedia.value);
const previousTypeMedia = ref<string>("");

const mediaType = computed(() => t(`detail.${detailId.value}.${hotspotId.value}.media.type`));
const MediaComponent = computed(() => {
  if (mediaType.value === "gallery") return DetailMoreGallery;
  if (mediaType.value === "image") return DetailMoreImage;
  if (mediaType.value === "video") return DetailMoreVideo;
  if (mediaType.value === "quote") return DetailMoreQuote;
  return null;
});
const transitionKey = computed(() => (mediaType.value === "quote" ? "quote" : hotspotId.value));

let timeline: gsap.core.Timeline | null = null;
onMounted(() => {
  checkIsHotspotOpen();
  gsap.timeline().add(() => (state.isHotspotOpenable = true), pageTransition.isFirst ? 1 : 0.8);
});
onUnmounted(() => {
  if (timeline) {
    timeline.kill();
  }
});
const checkIsHotspotOpen = () => {
  if (isHotspotOpen.value) {
    showTitle.value = false;
    timeline = gsap
      .timeline()
      .add(() => (showTitle.value = true), pageTransition.isFirst ? 1.7 : 1);
  } else {
    showTitle.value = false;
  }
};
watch(isHotspotOpen, checkIsHotspotOpen);

const onMediaLoad = () => {
  isMediaLoaded.value = true;
};

watch(
  () => transitionKey.value,
  () => {
    isMediaLoaded.value = false;
  },
);
</script>

<style lang="stylus" scoped>
.DetailMore
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  overflow hidden
  overflow-y auto
  z-index 3
  pointer-events none
  &.open
    pointer-events auto
  &.video-extended
    z-index 10001

  &::before
    content ''
    position fixed
    top 0
    left 0
    width 100%
    height 100%
    background-color rgba($white, 1)
    z-index 0
    display none
  &.fully-open::before
    display block

  +desktop()
    position static
    height auto
    overflow auto
    z-index auto

  &__Inner
    position relative
    display flex
    flex-direction column
    justify-content space-between
    min-height 100%


.Close
  position absolute
  top auto
  top 0
  left 50%
  color rgba($white, 1)
  pointer-events auto
  +desktop()
    bottom auto
    top 50%
    left 0

  transition opacity 0.7s $easeQuartOut 0.3s
  &.video-playing
    opacity 0
    transition opacity 0.3s $easeQuartOut

  &__Wrapper
    position absolute
    bottom 73.333vw
    bottom 73.333vw
    width 100%
    z-index 5

    &.bottom
      bottom rem(40)
      +desktop()
        bottom auto

    +desktop()
      position fixed
      top 0
      right 0
      bottom auto

      display flex
      height 100%
      width 50vw
      flex-direction column
      pointer-events none

    &.video-playing
      z-index 10002

    &.more-enter-active,
    &.more-leave-active
      transition opacity 0.7s $easeQuartOut

    &.more-enter-active
      transition-delay 1.2s

    &.more-enter-from,
    &.more-leave-to
      opacity 0

.Content
  position relative
  padding rem(90) 0 rem(48)
  // min-height 60vh
  // min-height 60dvh
  min-height calc(100vh - 73.333vw)
  min-height calc(100dvh - 73.333vw)

  top 0
  right 0
  text-align center
  display flex
  align-items center
  z-index 4
  width 100vw
  // height 60vh
  // height 60dvh

  +desktop()
    position fixed
    width 50vw
    height 50vh
    height 50dvh

  &::before,
  &::after
    content ''
    position absolute
    top 0
    left 0
    width 100%
    height 100%

  &::before
    background-color rgba($red, 1)
  &::after
    background-color rgba($white, 1)

  &__Inner
    position relative
    z-index 2
    display grid
    grid-template-columns repeat(10, 1fr)
    gap 0 rem(16)
    padding 0 rem(16)
    width 100%
    +desktop()
      grid-template-columns repeat(6, 1fr)
      padding 0 rem(24) 0 rem(12)
      gap 0 rem(24)

  &__Title
    margin 0 0 rem(16)
    user-select none
    pointer-events none
  &__Text
    user-select none
    pointer-events none
    +desktop()
      padding 0 rem(60)

  &.more-enter-active::after
    transition transform 0.7s $easeSineOut
  &.more-leave-active.hasLeaveTransition::before
    transition transform 0.7s $easeSineOut

  &.more-enter-active::before
    transition transform 0.7s $easeQuartOut
  &.more-leave-active.hasLeaveTransition::after
    transition transform 0.7s $easeQuartOut

  &.more-enter-active::after,
  &.more-enter-active::before
    transition-delay 0.5s


  &.more-enter-from::before,
  &.more-leave-to::before
  &.more-enter-from::after,
  &.more-leave-to::after
    transform translateY(-100%) translateY(rem(-20))

  &.more-enter-active .Content__Inner
    transition opacity 0.7s $easeQuartOut 0.8s
  &.more-leave-active.hasLeaveTransition .Content__Inner
    transition opacity 0.3s $easeQuartOut
  &.more-enter-from .Content__Inner,
  &.more-leave-to .Content__Inner
    opacity 0

.Media
  position relative
  height 100%

  &__Container
    position relative
    height 100%
    clip-path inset(0 0 0% 0)
    &.no-clip
      clip-path none

  &__Wrapper
    position relative
    bottom 0
    right 0
    overflow hidden
    z-index 3
    width 100vw
    height 73.333vw

    &.video-extended
      z-index 10001
      .Media__Container
        clip-path none
    +desktop()
      position fixed
      width 50vw
      height 50vh
      height 50dvh
    // opacity 0.2

    &.quote
      z-index 3
      height 100vh
      height 100dvh
      &--z-boosted
        z-index 4

    &::before
      content ''
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      background-color rgba($black, 1)

    &.more-leave-active.hasLeaveTransition::before
      transition transform 0.7s $easeSineOut
    &.more-enter-active::before
      transition transform 0.7s $easeQuartOut 0.8s
    &.more-enter-from::before,
    &.more-leave-to::before
      transform translateY(-100%) translateY(rem(-20))

    .Media__Container
      transition clip-path 0.7s $easeQuartOut
    .Media__Container--Loading,
    &.more-enter-active .Media__Container
      transition clip-path 0.7s $easeSineOut 0.8s

    &.no-delay::before,
    &.no-delay .Media__Container
      transition-delay 0s

    .Media__Container--Loading,
    &.more-leave-to .Media__Container
      clip-path inset(0 0 99.999% 0)
      opacity 0
</style>
