var fn = function( obj ){
var __t,__p='';
__p+='\n#pragma SLOT definitions\n#pragma SLOT precision\n\n\n#if __VERSION__ == 300\n  #define IN in\n#else\n  #define IN varying\n#endif\n\n#pragma SLOT pf\n\nIN vec3 vWorldPos;\n\nuniform vec3 uCamPos;\nuniform float uDistScale;\n\nvoid main(void){\n\n  #pragma SLOT f\n  float camDist = length( uCamPos - vWorldPos );\n  float dist = camDist / ( 1.0 + (uCamPos.y/vWorldPos.y ) );\n  gl_FragColor = vec4( 0.0, 0.0, 0.0, vWorldPos.y*uDistScale );\n  // gl_FragColor = gl_FragColor*0.0001 + vec4( 0.0, 0.0, 0.0, vWorldPos.y*uDistScale );\n\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
