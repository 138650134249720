import Materials from "./materials-lib";
import BaseMaterial from "nanogl-pbr/Material";


export default class MaterialProvider {

  uid: string;
  lib: Materials;
  matlist: BaseMaterial[];
  materials: Record<string, BaseMaterial>;



  constructor(lib: Materials, uid: string) {
    this.uid = uid;
    this.lib = lib;
    this.matlist = [];
    this.materials = {};
  }


  registerMaterial(mat: BaseMaterial, name: string) {
    if (this.materials[name] === undefined) {
      this.materials[name] = mat;
      this.matlist.push(mat);
      this.lib.registerMaterial(mat, this.uid + '/' + name);
    }
  }


  get(name: string): BaseMaterial {
    return this.materials[name] || this.lib.get(name);
  }


  dispose() {
    // TODO : implement
  }


}
