<template>
  <button
    :class="{
      PlayButton: true,
      'PlayButton--Hover': isHover || isHoverForced,
      hover: isHover || isHoverForced,
      playing: !isPaused,
    }"
  >
    <span
      class="Magnet"
      @mouseenter="onMouseEnter"
      @mousemove="onMouseMove"
      @mouseleave="onMouseLeave"
    />

    <span class="Inner" :style="{ transform: `translate3d(${current.x}px, ${current.y}px, 0px)` }">
      <svg-icon class="Icon" v-if="isPaused || !Viewport.isDesktop" name="play" />
      <svg-icon class="Icon" v-else name="pause" />
    </span>
  </button>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import gsap from "gsap";
import lerp from "@/utils/Lerp";
import Viewport from "@/store/modules/Viewport";

defineProps({
  isPaused: {
    type: Boolean,
    required: true,
  },
});

const power = 0.5;
const ease = 0.05;
let position = { x: 0, y: 0 };
let target = { x: 0, y: 0 };
let magnetSize = {
  width: 0,
  height: 0,
};
const current = ref({ x: 0, y: 0 });
const isHover = ref<boolean>(false);
const isHoverForced = ref<boolean>(false);
const labelState = ref("before");
const labelIsRewind = ref<boolean>(false);

onMounted(() => {
  gsap.ticker.add(tick);
});
onUnmounted(() => {
  gsap.ticker.remove(tick);
});

watch(
  () => isHover.value,
  (value) => {
    if (value) {
      labelIsRewind.value = false;
      setTimeout(() => {
        labelState.value = "active";
      }, 200);
    } else {
      labelIsRewind.value = true;
      labelState.value = "before";
    }
  },
);

const tick = () => {
  current.value.x = Math.round(lerp(current.value.x, target.x, ease) * 100) / 100;
  current.value.y = Math.round(lerp(current.value.y, target.y, ease) * 100) / 100;
};

const onMouseEnter = (e: MouseEvent) => {
  isHover.value = true;
  if (e.target) {
    const { x, y, width, height } = (e.target as HTMLElement).getBoundingClientRect();
    position = { x, y };
    magnetSize.width = width;
    magnetSize.height = height;
  }
};
const onMouseMove = (e: MouseEvent) => {
  target.x = Math.round((e.clientX - position.x - magnetSize.width / 2) * power * 100) / 100;
  target.y = Math.round((e.clientY - position.y - magnetSize.height / 2) * power * 100) / 100;
};
const onMouseLeave = () => {
  isHover.value = false;
  target.x = 0;
  target.y = 0;
};
</script>

<style lang="stylus" scoped>

.PlayButton
  position absolute
  top 0
  left 0
  full()
  transition opacity 0.5s $easeQuartOut

  .Inner
    width rem(64)
    height rem(64)
    position absolute
    top 50%
    left 50%
    margin rem(-32) 0 0 rem(-32)
    border-radius 50%
    background transparent
    display flex
    justify-content center
    align-items center
    color rgba($white, 1)
    font-size rem(24)
    pointer-events none
  &--SmallIcon
    .Inner
      font-size rem(16)

  .Magnet
    display block
    position absolute
    top 0
    left 0
    full()
    border-radius inherit

  .Icon
    position relative
    z-index 1
    font-size rem(40)
    transition transform 0.5s $easeQuartOut

  .Inner
    &::before
      content ''
      position absolute
      top rem(-4)
      left rem(-4)
      right rem(-4)
      bottom rem(-4)
      border 2px solid rgba($white, 1)
      border-radius inherit
      transition transform 0.5s $easeQuartOut
      transform scale(1)

  &:hover,
  &.hover
    .Icon
      transform scale(0.9)
    .Inner::before
      transform scale(1.1)

  &.playing
    opacity 0
  +desktop()
    &.playing:hover,
    &.playing.hover
      opacity 1
</style>
