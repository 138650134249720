
/////////////
///////////////////////////////////////////
//////////////////////////////////////////
/////////////////////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////

//////////////////////////////////////////////////////
//////////////
//////////


import GLState from 'nanogl-state/GLState'
import Node from 'nanogl-node'
import Camera from 'nanogl-camera'
import Rect from 'nanogl-primitives-2d/rect'

import Programs from '@/webgl/gl/programs'
// import IBLManager from '@/webgl/gl/env'
import PostProcess from '@/webgl/gl/post'
import Masks from '@/webgl/gl/masks'
import Inputs from '@/webgl/lib/inputs'
import Raycaster from '@/webgl/lib/raycaster'
import Materials from '@/webgl/assets/materials-lib'
import CamControler from '@/webgl/camera/CameraController'
// import OrbitControler from '@/webgl/camera/XPController'
import MaxControler from '@/webgl/camera/MaxController'
import XpController from '@/webgl/camera/XpController'
import Lens from '@/webgl/camera/Lens'
import Reflect from '@/webgl/gl/Reflect'
import SceneInvalidator from '@/webgl/lib/scene-invalidator'
import GLView from '@/webgl/glview'
import CameraControler from '@/webgl/camera/CameraController'
// import XPController from '@/webgl/camera/XPController'

// import { DEG2RAD } from '@/webgl/math'

import { GLContext } from 'nanogl/types'
import PerspectiveLens from 'nanogl-camera/perspective-lens'
import { mat4 } from 'gl-matrix'
import Deferred from '@/utils/Deferred'
// import PunctualLight from 'nanogl-pbr/lighting/PunctualLight'
import ScreenSize from './glsl/ScreenSize'
import Gallery from './gallery'

// import resources from '@/store/modules/resources'
import DevCameraController from './camera/DevCameraController'
import Fbo from 'nanogl/fbo'
import GLConfig from 'nanogl-state/GLConfig'
import ReflectDistPass from '@/webgl/glsl/reflect_dist';
import { Passes } from './glsl/Passes'
import Gltf from 'nanogl-gltf/lib/Gltf'
import { HotSpot } from '@/services/models/HotSpotModel';
import { Ref, ref } from 'vue';
import GltfNode from "nanogl-gltf/lib/elements/Node";
import Viewport from '@/store/modules/Viewport';
// import { reactive } from "vue";
// import SchemeBlender from './camera/SchemeBlender'

export type HPReactive = {
  value: HotSpot[]
}

export type QuoteReactive = {
  rect: number[],
  visible: boolean,
  fullyOnScreen?: boolean,
  id: string
}

export interface TypeScene {
  glview: GLView
  gl: GLContext
  glstate: GLState
  camCtrl: CameraControler
  camera: Camera<PerspectiveLens>
  isLoaded: boolean
  post: PostProcess
  quad: Rect
  dt: number
  programs: Programs
  ilayer: Element
  mainCamera: Camera<PerspectiveLens>
  devCamera: Camera<PerspectiveLens>
  inputs: Inputs
  render: (dt: number, force?: boolean) => void
  drawScene: (camera: Camera, fbo: Fbo, force?: boolean) => void
  init: (glview: GLView) => void
  setup: () => void
  handleResize: () => void
  load: () => void
  onLoaded: () => void
}

export default class Scene implements TypeScene {

  dt: number
  time: number
  ratio: number
  ilayer: Element
  loaddefer: Deferred
  glview: GLView
  gl: GLContext
  mainCamera: Camera<PerspectiveLens>
  mainCameraParent: Node
  devCamera: Camera<PerspectiveLens>
  camera: Camera<PerspectiveLens>
  sroot: Node
  root: Node
  glstate: GLState
  glconfig: GLConfig
  quad: Rect
  inputs: Inputs
  invalidator: SceneInvalidator
  post: PostProcess
  matlib: Materials
  // iblMngr     : IBLManager
  reflect: Reflect
  reflectDistPass: ReflectDistPass;
  isReflect = true

  programs: Programs
  camCtrl: CameraControler
  // xpcam: XPController
  devCtrl: DevCameraController
  maxcam: MaxControler
  xpcam: XpController
  gallery: Gallery


  camGltf: Gltf


  spotList: GltfNode[] = []
  nbCam = 0
  currCamId = 0


  raycaster: any
  enableDebugDraw: boolean
  envRotation: number
  forceFps: any

  debugCam: () => void
  mainCam: () => void
  logDebugCam: () => void
  logMainCam: () => void
  mipmapUsage: () => void
  ghostload: () => void

  isLoaded = false
  force = false


///////////////
//////////////////////
////////////

  isPlayground = false

  hotspotList: Ref<HotSpot[]> = ref([])

  quoteTitleRect: Ref<QuoteReactive> = ref({
    rect: [0, 0, 0, 0, 0, 0],
    visible: false,
    fullyOnScreen: false,
    id: "title"
  })

  quoteScorceseRect: Ref<QuoteReactive> = ref({
    rect: [0, 0, 0, 0],
    visible: false,
    fullyOnScreen: false,
    id: "martin-scorsese"
  })

  quoteStandingBearRect: Ref<QuoteReactive> = ref({
    rect: [0, 0, 0, 0],
    visible: false,
    fullyOnScreen: false,
    id: "standing-bear"
  })

  hasStartedScrolling: Ref<boolean> = ref(false)

  constructor() {

    this.dt = 0;
    this.time = 0;
    this.ratio = 1.0;
    this.ilayer = null;

  }

  /**
   *
   * @param {import('glview').default} glview
   */
  init(glview: GLView) {

    this.loaddefer = new Deferred()

    this.glview = glview
    this.gl = this.glview.gl


    // CAMERA
    // ======
    this.mainCamera = this.makeCamera(false)
    this.devCamera = this.makeCamera()
    this.camera = this.mainCamera


    this.sroot = new Node();
    this.root = new Node();

    this.glstate = new GLState(this.gl);
    this.glconfig = this.glstate.config()
      .depthMask(false)
      .enableDepthTest(true)
    this.quad = new Rect(this.gl);
    this.inputs = new Inputs(this.ilayer);
    this.invalidator = new SceneInvalidator();


    this.post = new PostProcess(this);
    this.matlib = new Materials(this);
    // this.iblMngr     = new IBLManager ( this );
    this.raycaster = new Raycaster(this);
    this.programs = new Programs(this);

    this.isReflect = !Viewport.isMobile

    if (this.isReflect) {
      this.reflect = new Reflect(this);
      this.reflectDistPass = new ReflectDistPass();
      this.reflectDistPass.mask = Masks.REFLECTED;

    }

    this.gallery = new Gallery(this);


/////////////////
/////////////////////////////////////////
////////////////////////////////
//////////////



    this.envRotation = 0

    // CONTROLERS
    // ======
    this.camCtrl = new CamControler(this)
    this.maxcam = new MaxControler(this.glview.canvas)
    this.xpcam = new XpController(this, `cam.glb`, this.glview.canvas)
    this.invalidator.watchNode(this.mainCamera);
    this.invalidator.watchNode(this.devCamera);

    // GRAPH
    // ======
    this.mainCameraParent = new Node()
    this.mainCameraParent.add(this.mainCamera)
    this.root.add(this.mainCameraParent)
    this.root.add(this.devCamera)
    this.sroot.add(this.root)
    // this.root .add( this.tree      .node );

    // this.camCtrl.setControler(this.maxcam);

    this.inputs.start();


/////////////////
////////////////////////////////////
///////////////
///////////////////////////////////////////////
//////////////////////////
//////////////////
/////////////////////////////////
///////////////////
///////
//////
////////////////////////////////////////////////////////////////

///////////////////////////
///////////////////////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////
/////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
//////////////////////////////
//////////////////////////////
///////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////
/////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////


/////////////////////////////////////////////////////



//////////////

  }

  setup() {

    this.camCtrl.setControler(this.isPlayground ? this.maxcam : this.xpcam)
    if (this.gallery?.birds) {
      this.gallery.birds.force = true
      this.gallery.birds2.force = true
    }

/////////////////
/////////////////////////////////////
//////////////
  }

  setHotSpot(openedHotSpot: string | null) {
    this.xpcam.setup(openedHotSpot)
  }

  invalidate() {
    this.invalidator.invalidate();
    this.post.resize()
  }

  handleResize() {
    this.invalidate();
  }



  render(dt: number, force = false) {

/////////////////
////////////////////////////////////////////
////////////
/////
/////////////////////////
//////////////
    this.dt = dt;
    this.time += dt;


    this.drawScene(this.camera, null, force);

  }



  drawScene(camera: Camera, fbo: Fbo = null, force = false) {
    if (!this.isLoaded && !force) return

    const gl = this.gl;
    const w = fbo ? fbo.width : this.glview.width;
    const h = fbo ? fbo.height : this.glview.height;

    this.ratio = w / h;
    ScreenSize.setSize(w, h)



    // preRender
    // =============
    this.camCtrl.preRender();
    // this.iblMngr.preRender();

    const lerpCam = force ? 0 : this.currCamId


    // upadate graph
    // =================

    // this.root.rotation.set([0, 0, 0, 1])
    // this.root.rotateY(this.envRotation)
    this.sroot.updateWorldMatrix()


    camera.updateViewProjectionMatrix(w, h);

    this.camCtrl.render();

    this.gallery.preRender(lerpCam, this.dt);


    // if (!force && !this.invalidator.needRender()) {
    //   return;
    // }


    // RTT
    // ==========

    // this.iblMngr.lights.setup.prepare(gl);
    // this.renderLightmaps()

    this.gallery.rttPass(lerpCam)

    // REFLECT
    // =======

    if (this.isReflect) {

      this.reflect.bindAndClear();

      this.reflect.processCamera(camera);

      this.gallery.render(camera, Masks.REFLECTED, Passes.REFLECT_DEPTH, lerpCam, this.reflect.globalCfg, force)

      this.reflect.blitRenderBuffer();


      this.reflect.restoreCamera(camera);


      this.reflect.processOutput();
    }


    // RENDER
    // ========
    this.glstate.apply()
    gl.clearColor(1, 1, 1, 1);
    // gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    this.post.post.preRender(w, h);
    this.post.post.bindColor()

    // gl.bindFramebuffer(gl.FRAMEBUFFER, fbo ? fbo.fbo : null);
    // gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    // gl.viewport(0, 0, w, h);

    //render stuffs
    this.gallery.render(camera, Masks.OPAQUE, Passes.COLOR, lerpCam, null, force)
    this.gallery.render(camera, Masks.BLENDED, Passes.COLOR, lerpCam, null, force)
    // this.gallery.postRender()

    this.post.preRender();
    this.post.post.render()


/////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////////
///////////////////////////////
////////////////////////////
/////////////////////////////
/////
///////////////////////////
//////////////


/////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////////

  }



  async load() {
    await Promise.all([this.gallery.load(), this.xpcam.load(), this.post.load()])
    // await this.preCompileMats()
    this.onLoaded()
  }




  onLoaded = () => {
    this.gallery.onLoaded()
    this.post.onLoaded()
    this.isLoaded = true
  }




  makeCamera(setDefaultMatrix = true) {
    const camera = new Camera(new Lens());
    camera.lens.setVerticalFov(0.2467391304)
    camera.lens.near = 1
    camera.lens.far = 350

    if (setDefaultMatrix)
      camera.setMatrix(<mat4>new Float32Array(
        // [0.9995434284210205, -1.396704476519517e-7, -0.03021577186882496, 0, -0.0026346975937485695, 0.9961907863616943, -0.08716089278459549, 0, 0.030100684612989426, 0.08720070123672485, 0.9957358837127686, 0, 0.45953, 6.27598, 25.63325, 1]
        [0.9262577891349792, -2.0060909378116776e-7, 0.37688902020454407, 0, 0.00859061535447836, 0.9997400641441345, -0.021112119778990746, 0, -0.3767911195755005, 0.022792980074882507, 0.9260172843933105, 0, 0.6404088735580444, 3.5489344596862793, 29.736970901489258, 1]
      ))

    return camera
  }

}




