/**
 * Linear interpolation between two numbers.
 * @param start 
 * @param end 
 * @param t 
 * @returns 
 */
export default function lerp(start: number, end: number, t: number): number {
  return start * (1 - t) + end * t
}
