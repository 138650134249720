import { App } from "vue";

import SvgIcon from "@/components/blocks/Icon/SvgIcon.vue";
import ImageSet from "@/components/blocks/Image/ImageSet.vue";
import FrameSet from "@/components/blocks/Image/FrameSet.vue";

import RoundButton from "@/components/blocks/Button/RoundButton.vue";
import PlayButton from "@/components/blocks/Button/PlayButton.vue";
import ExtendButton from "@/components/blocks/Button/ExtendButton.vue";
import PrimaryButton from "@/components/blocks/Button/PrimaryButton.vue";
import BackButton from "@/components/blocks/Button/BackButton.vue";
import CloseButton from "@/components/blocks/Button/CloseButton.vue";

import LoopLine from "@/components/blocks/LoopLine/LoopLine.vue";

import Cursor from "@/components/blocks/Cursor/Cursor.vue";
import CtaCursor from "@/components/blocks/Cursor/CtaCursor.vue";

import BaseLink from "@/components/blocks/Links/BaseLink.vue";

import AboutHeadline from "@/components/blocks/About/AboutHeadline.vue";
import AboutSubline from "@/components/blocks/About/AboutSubline.vue";
import AboutParagraph from "@/components/blocks/About/AboutParagraph.vue";
import AboutImage from "@/components/blocks/About/AboutImage.vue";
import AboutGallery from "@/components/blocks/About/AboutGallery.vue";
import AboutVideo from "@/components/blocks/About/AboutVideo.vue";

import IncrementalLoader from "@/components/blocks/Loader/IncrementalLoader.vue";
import SplitText from "@/components/blocks/Split/SplitText.vue";

import TransitionPage from "@/components/blocks/Transition/TransitionPage.vue";

const register = (app: App<Element>): void => {
  app.component("SvgIcon", SvgIcon);
  app.component("ImageSet", ImageSet);
  app.component("FrameSet", FrameSet);
  app.component("PrimaryButton", PrimaryButton);
  app.component("RoundButton", RoundButton);
  app.component("PlayButton", PlayButton);
  app.component("ExtendButton", ExtendButton);
  app.component("BackButton", BackButton);
  app.component("CloseButton", CloseButton);
  app.component("LoopLine", LoopLine);
  app.component("Cursor", Cursor);
  app.component("BaseLink", BaseLink);
  app.component("AboutHeadline", AboutHeadline);
  app.component("AboutSubline", AboutSubline);
  app.component("AboutParagraph", AboutParagraph);
  app.component("AboutImage", AboutImage);
  app.component("AboutGallery", AboutGallery);
  app.component("AboutVideo", AboutVideo);
  app.component("CtaCursor", CtaCursor);
  app.component("IncrementalLoader", IncrementalLoader);
  app.component("SplitText", SplitText);
  app.component("TransitionPage", TransitionPage);
};

export default { register };
