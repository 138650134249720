<template>
  <article class="Page PageError">
    <image-set
      v-if="Viewport.isDesktop"
      ref="image"
      class="Image"
      :src="`error-page/background.jpg`"
      :has2x="true"
      :hasWebp="true"
      :hasAvif="true"
      :alt="sanitizeHtml($t('error.backtitle'))"
    />

    <loop-line
      v-if="Viewport.isDesktop"
      :class="['Loop display1', isInit ? 'is-init' : '']"
      :autoPlay="true"
      :autoPlaySpeed="speed + 0.4"
      :gap="80"
      :gapSmall="48"
      :listenScroll="false"
      :speed="0.1"
      @update="onLoopLineUpdate"
    >
      <div ref="base" class="Loop__Base">{{ sanitizeHtml($t("error.backtitle")) }}</div>
    </loop-line>

    <div :class="['Container', isInit ? 'is-init' : '']">
      <SplitTextVue
        class="Title"
        v-if="!Viewport.isDesktop"
        :state="titleState"
        v-html="$t('error.backtitle')"
      />
      <primary-button
        tag="a"
        href="/"
        :label="$t('error.cta')"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
      />
    </div>
  </article>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from "vue";
import gsap from "gsap";
import SplitText from "gsap/dist/SplitText";
import sanitizeHtml from "@/utils/sanitizeHtml";

import pageTransition from "@/store/modules/PageTransition";
import Viewport from "@/store/modules/Viewport";
import eventBus from "@/utils/EventBus";

import SplitTextVue from "@/components/blocks/Split/SplitText.vue";

gsap.registerPlugin(SplitText);

const speed = ref<number>(0);
const isInit = ref<boolean>(false);
const showTitle = ref<boolean>(false);
const image = ref();
const titleState = ref<"before" | "active" | "after">("before");

onMounted(() => {
  eventBus.pageEnter.once(({ timeline }) => {
    timeline.add(() => (showTitle.value = true), pageTransition.isFirst ? 3 : 1);
    timeline.add(() => {
      pageTransition.isInTransition = false;
    });

    if (Viewport.isDesktop) {
      needToPlayIntro = true;
      playTransitionIn();
    } else {
      timeline.add(
        () => {
          titleState.value = "active";
          isInit.value = true;
        },
        pageTransition.isFirst ? 3 : 1,
      );
    }
  });
});

let splitText: SplitText;
let hasPlayIntro = false;
let needToPlayIntro = false;
let splitted = false;
const onLoopLineUpdate = (loop: HTMLDivElement) => {
  nextTick(() => {
    if (splitText) {
      splitText.revert();
    }
    splitText = new SplitText(loop.querySelectorAll(".Loop__Base"), {
      type: "chars",
      charsClass: "Char",
    });

    splitted = true;
    playTransitionIn();
  });
};

let tl: gsap.core.Timeline | null = null;
const playTransitionIn = () => {
  if (!hasPlayIntro && needToPlayIntro && splitted) {
    isInit.value = true;
    const visibleChars = splitText.chars.filter((char) => {
      const { left } = char.getBoundingClientRect();
      return left < Viewport.windowWidth;
    });

    if (tl) {
      tl.kill();
    }

    tl = gsap
      .timeline({
        delay: pageTransition.isFirst ? 1 : 0.7,
        // onComplete: () => {
        //   state.backTitleIntroComplete = true;
        //   hasPlayIntro = true;
        // },
      })
      .fromTo(
        visibleChars,
        {
          yPercent: 100,
          opacity: 0,
        },
        {
          yPercent: 0,
          opacity: 1,
          duration: 1,
          ease: "quart.out",
          stagger: {
            amount: 0.3,
          },
        },
      );
  }
};

const onMouseEnter = () => {
  const tl = gsap.timeline().to(speed, { value: 30, duration: 1, ease: "quart.out" }, 0);

  if (image.value) {
    tl.to(image.value.img, { opacity: 1, duration: 1, ease: "quart.out" }, 0);
  }
};
const onMouseLeave = () => {
  const tl = gsap.timeline().to(speed, { value: 0, duration: 1, ease: "quart.inOut" }, 0);

  if (image.value) {
    tl.to(image.value.img, { opacity: 0, duration: 1, ease: "quart.out" }, 0);
  }
};
</script>

<style lang="stylus" scoped>
.PageError
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  height 100dvh
  background rgba($grey, 1)
  color rgba($black, 1)
  visibility visible
  z-index 1
  display flex
  align-items center
  justify-content center

.Loop
  position absolute
  top 50%
  left 0
  transform translate(0, -50%)
  opacity 0
  pointer-events none
  user-select none
  white-space nowrap
  color rgba($red, 1)

  &.is-init
    opacity 1

.Container
  position relative
  text-align center
  transition opacity 0.7s $easeQuartOut
  opacity 0
  display flex
  flex-direction column
  gap rem(42)
  align-items center
  &.is-init
      opacity 1

  +desktop()
    transition-delay 1.5s
    margin-top rem(320)


.Image
  :deep(img)
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    object-fit cover
    object-position center
    opacity 0

.Title
  font-family var(--primary-font)
  font-size rem(64)
  line-height 0.65
  font-weight 600
  text-transform uppercase
  color rgba($red, 1)
  :deep(.Char)
    // padding 0.0825em 0 0 0
    line-height 0.825
</style>
