<template>
  <div
    ref="$root"
    class="intro prevent-select"
    :class="{
      'intro--init': isSplitInit,
    }"
    :style="{
      transform: `translate(${bounds.rect[0] + widthDiff / 2}px, ${
        bounds.rect[1] + heightDiff / 2
      }px) scale(${scaleX}, ${scaleY})`,
      width: `${props.bounds.rect[4]}px`,
      height: `${props.bounds.rect[5]}px`,
      visibility: `${bounds.visible ? `visible` : `hidden`}`,
    }"
  >
    <svg-icon name="logo-apple" />
    <svg-icon name="logo-koftm" />
    <div class="Subtitle uppercase secondary-font font-semibold">
      {{ t("homepage.subtitle") }}
    </div>

    <span class="Paragraph paragraph2 text" v-html="text"></span>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, PropType } from "vue";
import { useI18n } from "vue-i18n";
import gsap from "gsap";
import SplitText from "gsap/dist/SplitText";
import CustomEase from "gsap/dist/CustomEase";

import { QuoteReactive } from "@webgl/scene";

import Viewport from "@/store/modules/Viewport";

gsap.registerPlugin(SplitText, CustomEase);

const props = defineProps({
  bounds: {
    type: Object as PropType<QuoteReactive>,
    default: {} as QuoteReactive,
  },
});

const { t } = useI18n();

const $root = ref<HTMLElement>();
const isSplitInit = ref<boolean>(false);

const text = computed(() => t(`experience.intro.text`));

const scaleX = computed(() => props.bounds.rect[2] / props.bounds.rect[4]);
const scaleY = computed(() => props.bounds.rect[3] / props.bounds.rect[5]);
const widthDiff = computed(() => props.bounds.rect[2] - props.bounds.rect[4]);
const heightDiff = computed(() => props.bounds.rect[3] - props.bounds.rect[5]);

const fontSizeCss = computed(() => {
  return `${((16 / (Viewport.isMobile ? 371 : 572)) * props.bounds.rect[4]).toFixed(3)}px`;
});
const paddingCss = computed(() => {
  return `${((52 / (Viewport.isMobile ? 371 : 572)) * props.bounds.rect[4]).toFixed(3)}px`;
});
</script>

<style lang="stylus" scoped>
$fontSize = v-bind(fontSizeCss)
$padding = v-bind(paddingCss)

@import "@/styles/typography";
.intro
  position absolute
  top 0
  left 0
  pointer-events none
  display flex
  flex-direction column
  justify-content center
  align-items center
  gap 0
  transform-origin center
  // background rgba($white, 0.8)

  padding 0 $padding
  +desktop()
     padding 0 rem(40)

  .base
    position relative
    display flex
    flex-direction column
    justify-content center
    gap rem(14)
  .alt
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    display flex
    flex-direction column
    justify-content center
    gap rem(14)

  .inner
    position relative


  .LogoApple
    width 11.2vw
    height auto
    margin-bottom rem(14)
    +desktop()
      width 6.512vw
      margin-bottom rem(28)
  .LogoKoftm
    width 79.5%
    height auto
    margin-bottom rem(-14)
    +desktop()
      margin-bottom rem(-20)
    &:deep(.LogoKoftm__Subtitle)
      display none

  .Subtitle
    font-size rem(14)
    line-height 1.2
    letter-spacing 0.05em
    color rgba($red, 1)
    +desktop()
      font-size rem(26)

  .Paragraph
    font-size $fontSize
    text-align center
    margin-top rem(50)
    opacity 0.7

    +desktop()
      padding 0 rem(10)
</style>
