SPLIT TEXT

<template>
  <component :is="is" :class="['SplitText', isInit ? 'SplitText--Init' : '']" ref="root">
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { PropType, onMounted, onUnmounted, ref, watch } from "vue";
import gsap from "gsap";
import SplitText from "gsap/dist/SplitText";

import eventBus from "@/utils/EventBus";

gsap.registerPlugin(SplitText);

export type SplitTextState = "before" | "active" | "after";
const props = defineProps({
  state: {
    type: String as PropType<SplitTextState>,
    default: "before",
  },
  isRewind: {
    type: Boolean,
    default: false,
  },
  is: {
    type: String as PropType<string>,
    default: "span",
  },
});

defineExpose({
  hardChangeState: (newState: SplitTextState) => {
    forceStateUpdate(newState);
  },
});

const currentState = ref(props.state);
const root = ref<HTMLSpanElement>();
const isInit = ref<boolean>(false);
let splitTexts: SplitText[] = [];
let animation: gsap.core.Tween;

const onResize = () => {
  splitTexts.forEach((splitText) => {
    splitText.revert();
  });
  splitTexts = [];

  const split = new SplitText(root.value, {
    type: "words,chars",
    linesClass: "Line",
    wordsClass: "Word",
    charsClass: "Char",
  });
  splitTexts.push(split);
  updateState(true);
  isInit.value = true;
};

const forceStateUpdate = (state: SplitTextState) => {
  if (state === currentState.value) return;
  currentState.value = state;
  animation?.kill();
  updateState(true);
};

const updateState = (instant = false) => {
  if (!root.value) return;
  const yPercent =
    currentState.value === "active" ? 0 : currentState.value === "before" ? 100 : -100;
  const chars = root.value.querySelectorAll(".Char");
  const icon = root.value.querySelector(".icon");
  let elements;
  if (icon) elements = [...chars, icon];
  else elements = [...chars];

  if (props.isRewind && currentState.value !== "active") animation?.kill();
  animation = gsap.to(elements, {
    yPercent,
    duration: instant ? 0 : 0.5,
    ease: "quart.out",
    stagger: instant
      ? 0
      : {
          from: currentState.value === "active" ? "start" : props.isRewind ? "end" : "start",
          amount: 0.2,
        },
  });
};

onMounted(() => {
  document.fonts.ready.then(() => {
    eventBus.resize.on(onResize);
    onResize();
    updateState(true);
  });
});
onUnmounted(() => {
  eventBus.resize.off(onResize);
});

watch(
  () => props.state,
  () => {
    currentState.value = props.state;
    updateState();
  },
);

// const playEnter = () => {
//   console.log('SplitText playEnter', { root })
// }
// defineExpose({
//   playEnter,
// });
</script>

<style lang="stylus" scoped>
.SplitText
  opacity 0
  &--Init
    opacity 1

:deep(.Word)
  overflow hidden
</style>
