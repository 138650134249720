import Program from "nanogl/program";
import Gltf from "nanogl-gltf/lib/Gltf";
import KHR_texture_transform from "nanogl-gltf/lib/extensions/KHR_texture_transform";
import KHR_lights_punctual from "nanogl-gltf/lib/extensions/KHR_lights_punctual";
// import KHR_lights_punctual from "nanogl-gltf/lib/extensions/KHR_lights_punctual";
// import KHR_materials_pbrSpecularGlossiness from "nanogl-gltf/lib/extensions/KHR_materials_pbrSpecularGlossiness";
import KHR_materials_unlit from "nanogl-gltf/lib/extensions/KHR_materials_unlit";
import KHR_texture_basisu from "nanogl-gltf/lib/extensions/KHR_texture_basisu";

import { TextureCodecs } from './assets/TextureCodec'
import { TextureCodecWebp } from './assets/TextureCodecStd'

import BasisDecoder from "./resources/basis/BasisDecoder";
import TextureCodecBasis from './assets/TextureCodecBasis'


//==============================
// code here is called before GLApp is defined
//==============================


/////////////
/////////////////////
/////////
Program.debug = false;
//////////

TextureCodecs.registerCodec(new TextureCodecWebp());
TextureCodecs.registerCodec(new TextureCodecBasis());


// Gltf.addExtension(new KHR_lights_punctual());
// Gltf.addExtension(new KHR_materials_unlit());
// Gltf.addExtension(new KHR_materials_pbrSpecularGlossiness());
Gltf.addExtension(new KHR_texture_basisu(BasisDecoder.getInstance()));
Gltf.addExtension(new KHR_texture_transform());
Gltf.addExtension(new KHR_lights_punctual());
Gltf.addExtension(new KHR_materials_unlit());
