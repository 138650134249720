<template>
  <div ref="root" class="root-gl" />
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import AppService from "@/services/AppService";
import { useAppState } from "@/services/Composition";
// import Viewport from "@/store/modules/Viewport";
// import GLApp from "@webgl/main";

const { state } = useAppState();

const isRunning = computed(() => state.value.matches("xp.running.default"));
watch(isRunning, (running) => {
  if (running) {
    AppService.glapp.glview.play();
  } else {
    AppService.glapp.glview.stop();
  }
});

const root = ref(null);

onMounted(() => {
  root.value.appendChild(AppService.glapp.glview.canvas);
});
onBeforeUnmount(() => {
  root.value.removeChild(AppService.glapp.glview.canvas);
});
</script>

<style lang="stylus">

.root-gl

.glview, .root-gl
  position absolute
  top 0
  left 0
  touch-action none
  box-sizing border-box
  width 100vw
  height 100vh
  height: calc(var(--vh, 1vh) * 100);


  canvas
    width 100vw
    height 100vh
    height: calc(var(--vh, 1vh) * 100);
    position absolute
    top 0
    left 0
    cursor grab
    &.dragging
      cursor grabbing

  /**
   * windowed - test canvas not fullscreen
   */
  &.windowed
    margin 200px 0
    padding 40px
    width 100%
    height 100%

    canvas
      position initial
      width 100%
      height 100%
</style>
