<template>
  <Transition name="font" :duration="1000">
    <div v-if="!isFontLoaded" class="FontVeil" />
  </Transition>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";

const isFontLoaded = ref<boolean>(false);

onMounted(() => {
  const minimum = new Promise((resolve) => setTimeout(resolve, 500));

  Promise.all([document.fonts.ready, minimum]).then(() => {
    isFontLoaded.value = true;
  });
});
</script>

<style lang="stylus" scoped>
.FontVeil
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color rgba($grey, 1)
  z-index $z-index-veil
  user-select none

  &.font-leave-active
    transition opacity 1s $easeQuartOut
  &.font-leave-to
    opacity 0
</style>
