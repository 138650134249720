var fn = function( obj ){
var __t,__p='';
__p+='\n\n{\n\n  #define CS_SAMPLES 8\n  #define CS_P .068\n  vec2 tc = texCoordVP - vec2(.5);\n\n  for( int i = 0; i < CS_SAMPLES; i++ ){\n    float shift = float(i)/float(CS_SAMPLES-1);\n\n    vec2 ftc = tc * (1.0 + uAmount*(shift - .5));\n    c += texture2D( tInput, ftc + .5 ).xyz * texture2D( tChromaShiftTex, vec2( shift * (1.0-2.0*CS_P) + CS_P , .5 ) ).rgb;\n  }\n\n\n  c /= (float( CS_SAMPLES ) *.35);\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
