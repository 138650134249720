<template>
  <component
    :is="tag"
    :to="to"
    :href="href"
    class="PrimaryButton paragraph1"
    :class="[
      `${theme}`,
      `${isLight ? 'light' : 'dark'}`,
      `PrimaryButton--${isHover ? 'hover' : ''}`,
    ]"
    @click="onBtnClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <split-text class="PrimaryButton__inner" :state="textState">
      <span>{{ label }}</span>
      <svg-icon v-if="icon" class="icon" :name="icon" />
    </split-text>
    <split-text class="PrimaryButton__subInner" :state="hoverTextState">
      <span>{{ label }}</span>
      <svg-icon v-if="icon" class="icon" :name="icon" />
    </split-text>
  </component>
</template>

<script lang="ts" setup>
import { defineProps, PropType, ref } from "vue";
import { SplitTextState } from "../Split/SplitText.vue";
const props = defineProps({
  tag: {
    type: String,
    default: "button",
  },
  to: {
    type: String,
    required: false,
  },
  href: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: true,
  },
  icon: {
    required: false,
  },
  theme: {
    type: String as PropType<"fill" | "outline">,
    default: "fill",
  },
  isLight: {
    type: Boolean,
    required: false,
    default: false,
  },
  onClick: {
    type: Function,
    required: false,
    default: () => {},
  },
  freezeOnClick: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const textState = ref<SplitTextState>("active");
const hoverTextState = ref<SplitTextState>("before");
const isHover = ref(false);
const isFreeze = ref(false);

const onBtnClick = () => {
  props.onClick?.();
  if (props.freezeOnClick) {
    isFreeze.value = true;
  }
};

const onMouseEnter = () => {
  if (isFreeze.value) return;
  textState.value = "after";
  hoverTextState.value = "active";
  isHover.value = true;
};
const onMouseLeave = () => {
  if (isFreeze.value) return;
  textState.value = "active";
  hoverTextState.value = "before";
  isHover.value = false;
};
</script>

<style lang="stylus" scoped>
.PrimaryButton
  --bgColor: transparent
  --borderColor: transparent
  &.dark
    --fontColor: rgba($black, 1)
    --borderColor: rgba($black, 1)
    &.fill
      --fontColor: rgba($white, 1)
      --bgColor: rgba($black, 1)
  &.light
    --fontColor: rgba($white, 1)
    --borderColor: rgba($white, 1)
    &.fill
      --fontColor: rgba($black, 1)
      --bgColor: rgba($white, 1)

  &--hover.light, &--hover.light.fill, &--hover.dark, &--hover.dark.fill
    --bgColor: rgba($red, 1)
    --fontColor: rgba($white, 1)
    --borderColor: rgba($red, 1)

:deep(.SplitText > span)
  line-height 1

.PrimaryButton
  position relative
  border-radius: rem(28)
  background var(--bgColor)
  border 1px solid var(--borderColor)
  color var(--fontColor)
  overflow hidden
  transition background 0.3s $easeQuartOut, border 0.3s $easeQuartOut, color 0.3s $easeQuartOut

  &__inner, &__subInner
    display: flex
    gap rem(8)
    align-items: center
    padding: rem(12) rem(24) rem(10) rem(24)

  &--hover
    .PrimaryButton__inner
      opacity 0
    .PrimaryButton__subInner
      opacity 1
  &__inner
    opacity 1
    transition opacity 0.5s $easeQuartOut

  &__subInner
    position absolute
    top 0
    left 0
    bottom 0
    right 0
    opacity 0
    transition opacity 0.5s $easeQuartOut
</style>
