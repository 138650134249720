

import GLView from './glview'
import Scene, { TypeScene } from './scene'
import Quality from './lib/quality'
import ParallaxScene from './ParallaxScene'

// import {gui} from './dev'
// console.log( gui );



export default class App {

  private _loadPromise: Promise<void> = null

  private static _instance: App = null
  static getInstance(): App {
    if (this._instance === null) {
      this._instance = new App()
    }
    return this._instance
  }


  glview: GLView
  scene: TypeScene

  readonly canvas: HTMLCanvasElement

  constructor() {

    this.canvas = document.createElement('canvas')

    this.glview = new GLView(this.canvas)

  }

  init(isParallaxScene = false) {
    if (isParallaxScene) {
      this.scene = new ParallaxScene()

    } else {
      this.scene = new Scene()
    }

    this.scene.ilayer = this.canvas

    Quality.setMode('high')

    this.glview.scene = this.scene
    this.scene.init(this.glview)
  }



  /**
    start loading assets
    @return a (when.js) promise 
  **/
  // load(): Promise<void> {
  //   if (this._loadPromise === null) {
  //     this._loadPromise = this._doLoad()
  //   }
  //   return this._loadPromise
  // }

  // async _doLoad() {
  //   await this.scene.load();
  // }

  start() {
    this.glview.play();
  }

  stop() {
    this.glview.stop();
  }



  setCurrentArtwork(currentArtwork: number) {
    // this.scene.gallery.povs.activateFrame(currentArtwork)
  }



}
