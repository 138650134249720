<template>
  <!-- <div class="Loader" :class="{ hide: !showLoader }"> -->
  <div ref="$loader" class="Loader prevent-select" v-show="hud.showLoader">
    <div class="Loader__Background"></div>
    <div class="Loader__Inner">
      <div class="Text__Wrapper">
        <split-text ref="$splitText1" class="Text Text--1" :state="textState1">LOADING</split-text>
        <split-text ref="$splitText2" class="Text Text--2" :state="textState2">LOADING</split-text>
      </div>
      <IncrementalLoader
        v-if="transitionStarted"
        class="Progress"
        :forceZeroStart="true"
        :progress="percLoading"
        @complete="onLoadingComplete"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch, ref } from "vue";
import gsap from "gsap";

import Delay from "@/core/Delay";
import { useAppState } from "@/services/Composition";
import hud from "@/store/modules/Hud";
import WebglLoading from "@/store/modules/WebglLoading";

const { state } = useAppState();

const $loader = ref<HTMLElement>();

const textState1 = ref("before");
const $splitText1 = ref<SplitText>();
const textState2 = ref("before");
const $splitText2 = ref<SplitText>();
const textStates = computed(() => [
  { state: textState1, ref: $splitText1 },
  { state: textState2, ref: $splitText2 },
]);
const activeStateIndex = ref(0);
let stateInterval: number | null = null;
let stateResetTimeout: number | null = null;

// const toLoad = ref(100);
// const loaded = ref(0);

const loadingStarted = ref(false);
const transitionStarted = ref(false);

const isFirstPageHome = computed(
  () => hud.firstPageVisited === null || hud.firstPageVisited === "Home",
);

watch(
  () => hud.showHomepage,
  () => {
    if (!hud.showHomepage && isFirstPageHome.value && loadingStarted.value) {
      // console.log("start timer from homepage");
      startTimer();
    }
  },
);

watch(
  () => [hud.showLoader, loadingStarted.value],
  () => {
    if (loadingStarted.value && hud.showLoader && !isFirstPageHome.value) {
      // console.log("start timer from showLoader");
      startTimer();
    }
  },
);

watch(
  state,
  () => {
    const hasStartedLoading = state.value.matches("xp.loading");
    // console.log(hasStartedLoading, hud.showHomepage);

    if (hasStartedLoading) {
      // console.log("state machine started loading");
      loadingStarted.value = true;
    }
  },
  { deep: true },
);

const startTimer = async () => {
  // console.log("start timer");
  await Delay(500);
  transitionStarted.value = true;
  // setInterval(() => {
  //   loaded.value = Math.min(loaded.value + 1, 100);
  // }, 3000);
};

const updateLoadingTextState = (isFirst?: boolean, isLast?: boolean) => {
  const idx = activeStateIndex.value;
  const activeState = textStates.value[idx].state;
  const inactiveState = textStates.value[1 - idx].state;
  if (isFirst) {
    activeState.value = "active";
  } else if (isLast) {
    activeState.value = "after";
  } else {
    activeState.value = "after";
    inactiveState.value = "active";
    stateResetTimeout = setTimeout(() => {
      textStates.value[idx].ref.value?.hardChangeState("before");
    }, 700);
    activeStateIndex.value = (activeStateIndex.value + 1) % 2;
  }
  // isFirstState.value = false;
};

watch(transitionStarted, () => {
  if (transitionStarted.value && !stateInterval) {
    updateLoadingTextState(true);
    stateInterval = setInterval(() => {
      updateLoadingTextState();
    }, 1500);
  }
});

const onLoadingComplete = () => {
  clearInterval(stateInterval);
  clearTimeout(stateResetTimeout);
  updateLoadingTextState(false, true);

  const $ = gsap.utils.selector($loader.value);
  const tl = gsap.timeline({
    onComplete: () => {
      hud.showLoader = false;
    },
    delay: 0.15,
  });
  tl.to(
    $loader.value,
    {
      xPercent: 100,
      duration: 1,
      ease: "power3.inOut",
    },
    0,
  );
  const subTl = gsap
    .timeline()
    .to($(".Loader__Background"), {
      xPercent: -20,
      duration: 0.33,
      ease: "power3.inOut",
    })
    .to($(".Loader__Background"), {
      xPercent: 0,
      duration: 0.5,
      ease: "power3.inOut",
    });
  tl.add(subTl, 0);
};

const percLoading = computed(() => {
  return Math.round((WebglLoading.loaded / WebglLoading.toLoad) * 100);
});
</script>

<style lang="stylus" scoped>
.Loader
  width 100vw
  height 100vh
  height: calc(var(--vh, 1vh) * 100);
  position absolute
  top 0
  left 0
  z-index 2
  visibility: visible;
  &__Inner,
  &__Background
    position absolute
    top 0
    left 0
    width 100%
    height 100%
  &__Inner
    position relative
    background rgba($grey, 1)
    margin-bottom rem(-10)
    text-transform uppercase
    color rgba($black, 1)
    .Text
      position absolute
      font-size rem(20)
      top rem(25)
      left rem(25)
      +desktop()
        top rem(50)
        left rem(50)

    .Progress
      position absolute
      right rem(25)
      bottom rem(25)
      font-size rem(100)
      +desktop()
        right rem(50)
        bottom rem(50)
        font-size rem(200)
      :deep(.Numbers)
        justify-content end
        margin-bottom -0.07em
        line-height 0.85
  &__Background
    background rgb($red, 1)
  &.debug
    background rgba($red, 1)
  &.hide
    visibility: hidden
  h1
    font-size 2rem
    color black
</style>
