import { useRoute } from "vue-router";
import { watch } from "vue";
import AudioLib, { DEFAULT_FADE_DURATION } from "@/services/AudioLib";
import { AudioData, AudioId } from "@/services/models/AudioModel";
import AudioSettings from "@/store/modules/Audio";
import hud from "@/store/modules/Hud";

const getObjectsMusic = (): AudioId[] => {
  const allowedMusic = [];
  for (const id in AudioData) {
    if (AudioData[id].inObject) {
      allowedMusic.push(id);
    }
  }
  return allowedMusic;
};

const PAGE_MUSIC_ALLOWED = ["/detail/martin-book", "/detail/guitar", "/"];

export type AudioMethods = {
  playObjectSound: () => void;
  startGalleryMusic: () => void;
  startGalleryAmbient: () => void;
};

export const useAudio = () => {
  AudioLib.instance;

  const route = useRoute();
  let currentMode: "music" | "ambient" | "gallery-ambient" | "none" = "none";

  const startGalleryMusic = () => {
    // console.log("startGalleryMusic");
    AudioLib.instance.fadeIn("osage-oil-boom", { duration: 2500 });
    currentMode = "music";
  };

  const startGalleryAmbient = () => {
    // console.log("startGalleryAmbient", currentMode);
    if (currentMode === "music") {
      AudioLib.instance.fadeOut("osage-oil-boom", {
        duration: currentMode === "music" ? 2500 : DEFAULT_FADE_DURATION,
      });
    }
    if (currentMode === "ambient") {
      AudioLib.instance.fadeOut("wind-cricket-filtered");
    }

    AudioLib.instance.fadeIn("wind-cricket", {
      duration: currentMode === "music" ? 2500 : DEFAULT_FADE_DURATION,
    });
    currentMode = "gallery-ambient";
  };

  const startGlobalAmbient = () => {
    // console.log("startGlobalAmbient", currentMode);
    if (currentMode === "music") {
      AudioLib.instance.fadeOut("osage-oil-boom");
    }
    if (currentMode === "gallery-ambient") {
      AudioLib.instance.fadeOut("wind-cricket");
    }
    if (currentMode !== "ambient") {
      AudioLib.instance.fadeIn("wind-cricket-filtered");
    }
    currentMode = "ambient";
  };

  const stopAmbient = () => {
    // console.log("stop ambient");

    AudioLib.instance.fadeOut("wind-cricket-filtered");
    currentMode = "none";
  };

  watch(
    () => AudioSettings.canAudioStart,
    () => {
      // console.log("canAudioStart", AudioSettings, route);
      AudioLib.instance.loadAudio();

      if (
        route.fullPath === "/" &&
        hud.firstPageVisited === "Home" &&
        AudioSettings.hasAmbientStarted === false
      ) {
        startGalleryMusic();
      } else if (route.fullPath === "/") {
        AudioSettings.hasAmbientStarted = true;
        startGalleryAmbient();
      } else {
        AudioSettings.hasAmbientStarted = true;
        startGlobalAmbient();
      }
    },
  );

  watch(
    () => AudioSettings.isVideoPlaying,
    () => {
      if (AudioSettings.isVideoPlaying) {
        stopAmbient();
      } else {
        startGlobalAmbient();
      }
    },
  );

  watch(
    () => AudioSettings.isMuted,
    () => {
      AudioLib.instance.setMuted(AudioSettings.isMuted);
    },
  );

  watch(route, () => {
    if (!AudioSettings.canAudioStart) return;

    if (PAGE_MUSIC_ALLOWED.includes(route.fullPath) && AudioSettings.hasAmbientStarted === false) {
      return;
    }
    AudioSettings.hasAmbientStarted = true;
    if (route.fullPath === "/") {
      startGalleryAmbient();
    } else {
      startGlobalAmbient();
    }
  });

  const playObjectSound = () => {
    const allowedMusic = getObjectsMusic();
    const randomIndex = Math.floor(Math.random() * allowedMusic.length);
    const randomId = allowedMusic[randomIndex];
    AudioLib.instance.fadeIn(randomId);
  };

  return {
    playObjectSound,
    startGalleryMusic,
    startGalleryAmbient,
  } as AudioMethods;
};
