<template>
  <footer class="Footer padded" :class="[`Footer--${hud.uiTheme}`]">
    <nav class="Footer__inner">
      <ul class="Footer__list">
        <li class="Footer__link legend1" v-for="link in footerNavItems" :key="link.label">
          <base-link is="a" :href="link.href" target="_blank">
            {{ link.label }}
          </base-link>
        </li>
        <li class="Footer__soundBtn">
          <SoundButton />
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import hud from "@/store/modules/Hud";
import SoundButton from "@/components/blocks/Button/SoundButton.vue";

const { t } = useI18n();

const footerNavItems = [
  {
    label: t("legals.title"),
    to: t("legals.url"),
    href: t("legals.href"),
  },
  {
    label: t("credits.title"),
    to: t("credits.url"),
    href: t("credits.href"),
  },
];
</script>

<style lang="stylus" scoped>
.Footer
  position fixed
  bottom 0
  left 0
  width 100vw
  padding-bottom rem(24)
  z-index $z-index-hud
  display none
  justify-content end
  pointer-events none
  margin-bottom -12px

  transition color 0.7s $easeQuartOut
  &--light
    color rgba($white, 1)
  &--dark
    color rgba($black, 1)

  &__list
    display flex
    align-items center
    pointer-events auto
  &__link
    display contents
    &:nth-of-type(1) :deep(a)
      margin-right rem(12)
    :deep(a)
      margin-top rem(2.2)

  +desktop()
    display flex
</style>
