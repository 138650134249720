import { createRouter, createWebHistory, RouteLocation, NavigationGuardNext } from "vue-router";
import { computed } from "vue";
import i18n from "@/core/i18n";
import hud, { setHud, setCloseMenuLink } from "@/store/modules/Hud";
import { useAppState } from "@/services/Composition";

import HomePage from "@/components/pages/HomePage.vue";
import DetailPage from "@/components/pages/DetailPage.vue";
// import LegalsCreditsPage from "@/components/pages/LegalsCreditsPage.vue";
import ErrorPage from "@/components/pages/ErrorPage.vue";
import AboutPage from "@/components/pages/AboutPage.vue";
import ListPage from "@/components/pages/ListPage.vue";
import eventBus from "@/utils/EventBus";
import pageTransition from "@/store/modules/PageTransition";

const { state } = useAppState();

const isLoaded = computed(() => state.value.context.loaded);

const checkDetail = (to: RouteLocation, from: RouteLocation) => {
  const { t } = i18n.global;
  const detailId = to?.params?.detailId;
  const key = `detail.${detailId}.title`;
  const title = t(key);
  if (title === key) {
    const error = new Error(`Detail ${detailId} not found`);
    console.warn("Slug Error", error);
    hud.uiTheme = "dark";
    return { name: "Error", params: { pathMatch: "404" }, replace: false };
  } else {
    return true;
  }
};

const checkDetailMore = (to: RouteLocation, from: RouteLocation) => {
  const { t } = i18n.global;
  const detailId = to?.params?.detailId;
  const hotspotId = to?.params?.hotspotId;
  const key = `detail.${detailId}.hotspots`;
  const hotspotsString = t(key);
  if (hotspotsString === key) {
    return { name: "Detail", params: { detailId }, replace: true };
  } else {
    const hotspots = hotspotsString.split(",").map((slug: string) => slug.trim());
    if (!hotspots.includes(hotspotId as string)) {
      return { name: "Detail", params: { detailId }, replace: true };
    } else {
      return true;
    }
  }
};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "Home",
      path: "/",
      component: HomePage,
      meta: {
        showHeader: false,
        showFooter: true,
        menuOpen: false,
        fullscreen: true,
      },
    },
    {
      name: "Detail",
      path: "/detail/:detailId",
      component: DetailPage,
      meta: {
        showHeader: true,
        showFooter: true,
        menuOpen: false,
        fullscreen: true,
      },
      beforeEnter: checkDetail,
      children: [
        {
          name: "DetailHotspot",
          path: "/detail/:detailId/:hotspotId",
          component: DetailPage,
          meta: {
            showHeader: true,
            showFooter: false,
            menuOpen: false,
            fullscreen: true,
          },
          beforeEnter: checkDetailMore,
        },
      ],
    },
    /* {
      name: "Legals",
      path: "/legals",
      component: LegalsCreditsPage,
      meta: {
        showHeader: true,
        showFooter: true,
        showBackBtn: true,
        menuOpen: false,
        fullscreen: false,
      },
    },
    {
      name: "Credits",
      path: "/credits",
      component: LegalsCreditsPage,
      meta: {
        showHeader: true,
        showFooter: true,
        showBackBtn: true,
        menuOpen: false,
        fullscreen: false,
      },
    }, */
    {
      name: "About",
      path: "/about",
      component: AboutPage,
      meta: {
        showHeader: true,
        showFooter: true,
        menuOpen: true,
        fullscreen: false,
      },
    },
    {
      name: "List",
      path: "/list",
      component: ListPage,
      meta: {
        showHeader: true,
        showFooter: true,
        menuOpen: true,
        fullscreen: false,
      },
    },
    {
      name: "Error",
      path: "/:pathMatch(.*)*",
      component: ErrorPage,
      meta: {
        showHeader: true,
        showFooter: true,
        menuOpen: false,
        fullscreen: true,
      },
    },
  ],
});

router.beforeEach((to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) => {
  if (to.meta?.menuOpen && !from.meta?.menuOpen) {
    let link;
    if (from.path.includes("/detail")) {
      link = `/detail/${from.params.detailId}`;
    } else {
      link = from.path;
    }
    setCloseMenuLink(link);
  }

  if (to.meta) {
    eventBus.pageUpdateHUD.once(() => {
      const {
        showHeader,
        showFooter,
        showBackBtn,
        menuOpen,
        // fullscreen,
      } = to.meta;
      setHud({
        showHomepage: (from.name === undefined) as boolean,
        showLoader: (to.name === "Home" && !isLoaded.value) as boolean,
        showHeader: showHeader as boolean,
        showFooter: showFooter as boolean,
        showCookies: !localStorage.cookiesAccepted as boolean,
        showBackBtn: showBackBtn as boolean,
        menuOpen: menuOpen as boolean,
        // fullscreen: fullscreen as boolean,
        firstPageVisited: to.name as string,
      });
    });
    eventBus.pageEnterCompleted.on(() => {
      hud.fullscreen = to.meta.fullscreen as boolean;
    });
  }

  pageTransition.from = from;
  next();
});

export default router;
