<template>
  <article
    :class="[
      'Page',
      'PageHome',
      hud.showHomepage && (hud.firstPageVisited === undefined || hud.firstPageVisited === 'Home')
        ? ''
        : 'hide',
    ]"
  >
    <image-set
      class="PageHome__Bg"
      src="homepage/background.jpg"
      :hasWebp="true"
      :hasAvif="true"
      :hasMobile="true"
      :has2x="true"
      :hasMobile2x="true"
    />
    <div class="PageHome__Content">
      <svg-icon name="logo-apple" />
      <svg-icon name="logo-koftm" />
      <div class="Subtitle uppercase secondary-font font-semibold">
        {{ t("homepage.subtitle") }}
      </div>
    </div>

    <primary-button
      class="PageHome__Cta"
      :label="t('homepage.cta')"
      @click="onStart"
    ></primary-button>

    <div class="PageHome__SoundOn">
      <svg-icon name="sound" class="Icon" /><span class="paragraph1">{{
        t("homepage.volume-on")
      }}</span>
    </div>
  </article>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted } from "vue";
import AppService from "@/services/AppService";
import hud, { setHudTheme } from "@/store/modules/Hud";
import eventBus from "@/utils/EventBus";
import pageTransition from "@/store/modules/PageTransition";

const { t } = useI18n();

const onStart = () => {
  hud.showHomepage = false;
  hud.showHeader = true;
};

onMounted(() => {
  eventBus.pageEnter.once(() => {
    setHudTheme("light");
  });
  if (!pageTransition.isFirst) {
    eventBus.pageLeave.once(() => {
      // console.log("pageEnter");
      AppService.state.send({ type: "START_XP" });
    });
  } else {
    AppService.state.send({ type: "START_XP" });
  }
  eventBus.pageLeaveCompleted.once(() => {
    pageTransition.isInTransition = false;
  });
});
</script>

<style lang="stylus" scoped>
.PageHome
  display flex
  justify-content center
  align-items center
  flex-direction column
  color black
  transition opacity 0.3s $easeQuartOut
  background rgba($grey, 1)
  padding-bottom rem(32)
  +tablet()
    padding-bottom 12vh
    padding-bottom 12dvh
  +desktop()
    padding-bottom 0

  &.hide
    opacity 0
    // display none
    pointer-events none

  &__Bg
    position fixed
    top 0
    left 0
    width 100vw
    height 100%
    &:deep(img)
      width 100%
      height 100%
      pointer-events none
      object-fit cover
      full()

  &__Content
    display flex
    flex-direction column
    align-items center
    z-index 5

    .LogoApple
      width 25vw
      height auto
      margin-bottom rem(38)
      +desktop()
        width 7vw
        margin-bottom rem(28)

    .LogoKoftm
      width 65vw
      height auto
      margin-bottom rem(-14)
      +desktop()
        width 30vw
        margin-bottom rem(-20)
      &:deep(.LogoKoftm__Subtitle)
        display none

    .Subtitle
      font-size rem(14)
      line-height 1.2
      letter-spacing 0.05em
      color rgba($red, 1)
      +desktop()
        font-size rem(26)

  &__Cta
    margin-top rem(40)
    +desktop()
      margin-top rem(60)

  &__SoundOn
    position absolute
    bottom rem(19)
    left 50%
    transform translateX(-50%)
    color rgba($white, 1)
    display flex
    gap rem(8)
    align-items center
    .Icon
      font-size rem(12)
      margin-top -1px
</style>
