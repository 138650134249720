import { reactive } from "vue";
import { RouteLocation } from "vue-router";

type PageTransition = {
  isFirst: boolean;
  from: RouteLocation | null;
  isInTransition: boolean;

  previousMainMenu: HTMLElement | null;
};

const pageTransition = reactive<PageTransition>({
  isFirst: true,
  from: null,
  isInTransition: false,

  previousMainMenu: null,
}) as PageTransition;
export default pageTransition;
