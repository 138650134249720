<template>
  <Teleport to="body">
    <div
      v-if="!Viewport.isTouch"
      :class="['Cursor legend1', isActive ? 'Active' : '', `Cursor--${theme}`]"
      :style="{ transform: `translate3d(${mouse.x}px, ${mouse.y}px, 0px)` }"
    >
      <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle ref="circle" opacity="0.3" cx="48" cy="48" r="47.5" stroke="currentColor" />
      </svg>
      <div class="Text">
        <split-text class="CtaCursor__label" :state="textState" :isRewind="true">
          <span v-html="label"></span>
        </split-text>
      </div>
    </div>
  </Teleport>
</template>
<script lang="ts" setup>
import { defineProps, onMounted, ref, onUnmounted, watch, PropType } from "vue";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/src/DrawSVGPlugin";
import Viewport from "@/store/modules/Viewport";
import { SplitTextState } from "../Split/SplitText.vue";

gsap.registerPlugin(DrawSVGPlugin);

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String as PropType<"light" | "dark">,
    default: "dark",
  },
});

const circle = ref<SVGCircleElement>();
const mouse = ref({
  x: 0,
  y: 0,
});
const textState = ref<SplitTextState>("before");

const onMouseMove = (event: MouseEvent) => {
  mouse.value = {
    x: event.clientX,
    y: event.clientY,
  };
};
onMounted(() => {
  if (!Viewport.isTouch) {
    document.addEventListener("mousemove", onMouseMove);

    gsap.set(circle.value, {
      drawSVG: props.isActive ? "0% 100%" : "0% 0%",
    });
  }
});
onUnmounted(() => {
  document.removeEventListener("mousemove", onMouseMove);
});

watch(
  () => props.isActive,
  () => {
    if (!Viewport.isTouch) {
      gsap
        .timeline()
        .to(circle.value, {
          drawSVG: props.isActive ? "0% 100%" : "100% 100%",
          duration: 0.7,
          ease: "quart.inOut",
        })
        .set(circle.value, {
          drawSVG: props.isActive ? "0% 100%" : "0% 0%",
        })
        .add(
          () => (textState.value = props.isActive ? "active" : "before"),
          props.isActive ? 0.2 : 0,
        );
    }
  },
);
</script>
<style lang="stylus" scoped>
.Cursor
  position fixed
  z-index $z-index-cursor
  top 0
  left 0
  width rem(96)
  height rem(96)
  text-align center
  display flex
  align-items center
  justify-content center
  flex-direction column
  padding rem(8)
  pointer-events none
  user-select none

  &--light
    color rgba($white, 1)

  svg
    position absolute
    top 0
    left 0
    right 0
    bottom 0


.Line
  transform translateY(100%) rotate(9deg)
  transform-origin 0 0
  transition transform 0.7s $easeQuartOut

  &__Wrapper
    overflow hidden

    .Active &:nth-child(2) .Line
      transition-delay 0.2s
    .Active &:nth-child(3) .Line
      transition-delay 0.4s
    .Active &:nth-child(4) .Line
      transition-delay 0.6s


  .Active &
    transform translateY(0) rotate(0)
</style>
