var fn = function( obj ){
var __t,__p='';
__p+='#if enableFReflect\n{\n  vec2 screenUV = gl_FragCoord.xy / ScreenSize();\n  vec3 ref = texture2D( tFloorReflect, screenUV ).rgb;\n  ref = ReflectionStrength() * ref; //pow( ref, vec3(2.2) );\n  surface.specular = ref;\n}\n#endif';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
