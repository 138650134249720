import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Input, { Uniform } from "nanogl-pbr/Input";
import FragCode from "./SurfaceParallax.frag";
import { LAYERS } from "./ParallaxModel";

export default class SurfaceParallax extends Chunk {
  inputpY: Input;
  pY: Uniform;
  time: Input;
  t: Uniform;
  shiftFactor: Input;
  uShiftFactor: Uniform;
  parallaxValues: string;
  y: number = 0
  time_d: number = 0
  constructor() {
    super(true, false);
    this.addChild((this.inputpY = new Input("SurfaceParallaxY", 1)));
    this.pY = this.inputpY.attachUniform("u_surfaceParallaxY", 1);
    this.pY.set(0);

    this.addChild((this.time = new Input("TimeFactor", 1)));
    this.t = this.time.attachUniform("u_timeFactor", 1);
    this.t.set(0);

    // this.addChild((this.shiftFactor = new Input("ShiftFactor", 1)));
    // this.uShiftFactor = this.shiftFactor.attachUniform("u_shiftFactor", 1);
    // this.uShiftFactor.set(0);

    this.writeParallaxValues();
  }

  writeParallaxValues() {
    this.parallaxValues = `#define LAYERS ${LAYERS.length}\n
    const vec4 parallaxValues[${LAYERS.length}] = vec4[] (\n`;

    LAYERS.forEach((layer, i) => {
      this.parallaxValues += `vec4(${layer.xp}, ${layer.yp}, ${layer.speed}, ${layer.y})`;
      if (i < LAYERS.length - 1) this.parallaxValues += ",\n";
      else this.parallaxValues += "\n";
    });

    this.parallaxValues += `);\n`;
  }

  protected _genCode(slots: ChunksSlots): void {
    slots.add("pf", this.parallaxValues);
    slots.add("pbrsurface", FragCode());
  }

  preRender(y: number, dt: number, perc: number = 0) {
    this.y = perc;
    this.pY.set(this.y);
    this.time_d += dt
    this.t.set(this.time_d);
  }
}
