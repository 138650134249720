<template>
  <transition-page
    class="Page PageAbout"
    :variant="isFromMenu ? 'fadeIn' : 'vertical'"
    :basicIn="!isFromMenu"
    :fullscreen="false"
    theme="dark"
  >
    <HeaderFooterMask theme="dark" />
    <div class="PageAbout__Inner" ref="$rootAbout">
      <div class="Content grid padded">
        <div class="Content__Blocks">
          <div
            :class="[
              'Block__Wrapper',
              `Block__Wrapper--${getType(index)}`,
              isRightAligned(index) ? '--right' : '',
            ]"
            v-for="index in nbBlocks"
            :key="index"
            :data-index="index"
          >
            <component
              :is="getType(index)"
              class="Block"
              :index="index"
              :content="t(`about.block-${index}.content`)"
            />
          </div>
        </div>
        <div class="Content__nav__Wrapper xl:col-start-9 xl:col-end-12">
          <MainMenuLinks
            class="Content__nav desktop"
            :items="menuLinks"
            active-item="about"
            alignement="end"
            :is-color-variation="true"
            theme="dark"
          />
        </div>
      </div>
      <AboutVideoSlider />
      <FooterMenu active-item="about" theme="dark" />
    </div>
  </transition-page>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, onUnmounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import SplitText from "gsap/dist/SplitText";

import eventBus from "@/utils/EventBus";
import pageTransition from "@/store/modules/PageTransition";
import Viewport from "@/store/modules/Viewport";

import MainMenuLinks from "@/components/blocks/Menu/MainMenuLinks.vue";
import { LinkItem } from "@/components/blocks/Menu/MenuLinks.vue";
import HeaderFooterMask from "@/components/blocks/Layout/HeaderFooterMask.vue";
import FooterMenu from "@/components/blocks/Layout/FooterMenu.vue";
import AboutVideoSlider from "@/components/blocks/About/AboutVideoSlider.vue";
import { setHudTheme } from "@/store/modules/Hud";

gsap.registerPlugin(ScrollTrigger, SplitText);

const { t } = useI18n();

const menuLinks = reactive<LinkItem[]>([
  {
    id: "objects",
    label: t("menu.nav-label.listPage") || "objects",
    link: "/list",
    appear: false,
  },
  {
    id: "about",
    label: t("menu.nav-label.aboutPage") || "about",
    link: "/about",
    appear: false,
  },
]);

const $rootAbout = ref<HTMLElement>();

function isRightAligned(index: number) {
  return t(`about.block-${index}.toRight`) === "1";
}

function getType(index: number) {
  switch (t(`about.block-${index}.type`)) {
    case "title":
      return "about-headline";
    case "subtitle":
      return "about-subline";
    case "text":
      return "about-paragraph";
    case "image":
      return "about-image";
    case "video":
      return "about-video";
  }
}

const nbBlocks = computed(() => {
  let count = 0;
  let key = `about.block-${count + 1}.type`;
  let value = t(key);

  while (value !== key) {
    count++;
    key = `about.block-${count + 1}.type`;
    value = t(key);
  }

  return count;
});

const isFromMenu = ref<boolean>(false);

onBeforeMount(() => {
  isFromMenu.value = pageTransition.from?.meta?.menuOpen || false;

  if (isFromMenu.value) {
    for (let i = 0; i < menuLinks.length; i++) {
      menuLinks[i].appear = true;
    }
  }
});

let scrollTimelines: gsap.core.Timeline[] = [];
onUnmounted(() => {
  scrollTimelines.forEach((tl) => {
    tl.scrollTrigger.kill(false);
    tl.kill();
  });
});

onMounted(() => {
  eventBus.pageEnter.once(({ el, timeline }) => {
    const blocks = el.querySelectorAll(".Block, .AboutHeadline--line");

    const aboutHeadline = new SplitText($rootAbout.value.querySelector(".AboutHeadline"), {
      type: "lines",
      linesClass: "AboutHeadline--line ++",
    });

    const linesRightAligned = t("about.block-1.toRight").split(",");

    aboutHeadline.lines.forEach((line) => {
      if ([...line.classList].filter((c) => linesRightAligned.includes(c)).length > 0)
        line.classList.add("--right");
    });

    // gsap.from(aboutHeadline.lines, { opacity: 0, duration: 1, stagger: 0.3 });

    timeline.set(blocks, { opacity: 0 }, 0);

    timeline.add(
      () => {
        setHudTheme("light");

        const visibleBlocks: HTMLElement[] = [];
        const hiddenBlocks: HTMLElement[] = [];

        const subTimeline = gsap.timeline();
        blocks.forEach((block) => {
          const { top } = block.getBoundingClientRect();
          if (top < Viewport.windowHeight) {
            visibleBlocks.push(block as HTMLElement);
          } else {
            hiddenBlocks.push(block as HTMLElement);
          }
        });

        const firstTimeDelay = pageTransition.isFirst ? 0.5 : 0;
        subTimeline.addLabel("start", (Viewport.isDesktop ? 0 : 0.5) + firstTimeDelay);
        subTimeline.from(
          aboutHeadline.lines,
          {
            opacity: 0,
            y: 30,
            duration: 1,
            ease: "ease.out",
            stagger: {
              each: 0.15,
            },
          },
          "start",
        );
        subTimeline.fromTo(
          visibleBlocks,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 1,
            ease: "quart.in",
            stagger: {
              amount: 0.3,
            },
          },
          "start",
        );
        subTimeline.fromTo(
          visibleBlocks,
          {
            y: 60,
          },
          {
            y: 0,
            duration: 1,
            ease: "quad.inOut",
            stagger: {
              amount: 0.3,
            },
          },
          "start",
        );

        eventBus.pageEnterCompleted.once(() => {
          hiddenBlocks.forEach((hiddenBlock) => {
            const tl = gsap.timeline({
              scrollTrigger: {
                trigger: hiddenBlock.parentElement,
                start: "top bottom",
                end: "bottom top",
                // markers: true,
              },
            });
            tl.fromTo(
              hiddenBlock,
              {
                opacity: 0,
              },
              {
                opacity: 1,
                duration: 0.7,
                ease: "quart.in",
              },
              0,
            );
            tl.fromTo(
              hiddenBlock,
              {
                y: 60,
              },
              {
                y: 0,
                duration: 1,
                ease: "quad.inOut",
              },
              0,
            );

            scrollTimelines.push(tl);
          });
        });

        pageTransition.isInTransition = false;
      },
      pageTransition.isFirst ? 0.3 : 0.6,
    );

    if (!isFromMenu.value) {
      const amount = 0.3;
      timeline.addLabel("menu", 1.5);

      for (let i = 0; i < menuLinks.length; i++) {
        timeline.add(
          () => (menuLinks[i].appear = true),
          `menu+=${i * (amount / menuLinks.length)}`,
        );
      }
    }
  });
});

const menuGridRowCss = computed(() => {
  return `1 / span ${nbBlocks.value}`;
});
</script>

<style lang="stylus">
$menuGridRow = v-bind(menuGridRowCss)

.PageAbout
  &__Inner
    position relative
    width 100%
    height 100%
    padding 0
    z-index 1

  .Content
    overflow hidden
    +desktop()
      overflow visible
    &__Blocks
      display contents
      .Block__Wrapper + .Block__Wrapper
        margin-top rem(24)
        +desktop()
          margin-top rem(40)

      .Block__Wrapper:last-child
        margin-bottom rem(33)
        +desktop()
          margin-bottom rem(73)

    &__nav
      &.desktop
        display none
        position sticky
        top 0
        width 100%
        padding-top 17.111vh

        // top 17vh
        +desktop()
          margin-top 0
          display block

      &__Wrapper
        grid-row $menuGridRow


.Block
  &__Wrapper

    &--about-headline
      grid-column 1 / span 10
      +tablet()
        grid-column 3 / span 8
      +desktop()
        grid-column 2 / span 7
        padding-left rem(24)
      .AboutHeadline--line.--right
          text-align end !important
    &--about-subline
      grid-column 1 / span 10
      +desktop()
        grid-column 3 / span 6
    &--about-paragraph
      grid-column 1 / span 10
      +tablet()
        grid-column 1 / span 5
        &.--right
          grid-column 5 / span 5
      +desktop()
        grid-column 3 / span 3
        &.--right
          grid-column 6 / span 3
</style>
