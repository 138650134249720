export enum CacheId {
  DEFAULT = 'hvmtl',
  BOARD = 'hvmtl-board',
}

class ResourceCache {

  _available: boolean = false;
  _currentCache: Cache | undefined = undefined;
  _requestedPaths: string[] = [];

  async open(name: CacheId) {
    if (typeof caches === "undefined") {
      console.warn('Cache not available');
      return;
    }
    this._currentCache = await caches.open(name);
    this._requestedPaths = [];
  }

  async close() {

    if (!this._currentCache) return;

    const cacheKeys = await this._currentCache.keys();
    const deleted = [];
    // Check cache diff and remove orphan cached items
    for (const key of cacheKeys) {
      if (!this._requestedPaths.includes(key.url)) {
        await this._currentCache?.delete(key);
        deleted.push(key.url);
      }
    }

/////////////////
/////////////////////////////
///////////////////////////////////////////////////
/////
//////////////

    this._currentCache = undefined;
    this._requestedPaths = [];

  }

  async request(url: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response> {

    let path = url.toString();
    if (path.indexOf('http://') === -1 && path.indexOf('https://') === -1) {
      path = window.location.origin + url;
    }
    this._requestedPaths.push(path);

    if (this._currentCache === undefined) return fetch(url, init);

    let response = await this._currentCache.match(url);
    if (response) return response;

    response = await fetch(url, init);
    if (this._currentCache === undefined) return response;

    this._currentCache.put(url, response.clone());

    return response;

  }

  delete(name: string) {
    caches.delete(name);
  }

}

export type ResourceCacheType = ResourceCache;

export default new ResourceCache();