<template>
  <div :class="['HeaderMask', show ? '' : 'hidden']" />
</template>

<script setup lang="ts">
import eventBus from "@/utils/EventBus";
import { computed, defineProps, onMounted, PropType, ref } from "vue";

const props = defineProps({
  theme: {
    type: String as PropType<"light" | "dark">,
    default: "light",
  },
  isFooter: {
    type: Boolean,
    default: false,
  },
});

const maskColor = computed(() =>
  props.theme === "light" ? "rgba(var(--grey), 1)" : "rgba(var(--black), 1)",
);
const maskPositionTop = computed(() => (props.isFooter ? "unset" : "0"));
const maskPositionBottom = computed(() => (props.isFooter ? "0" : "unset"));
const maskHeight = computed(() =>
  props.isFooter ? "calc((75 / 16) * 1rem)" : "calc((105 / 16) * 1rem)",
);

const show = ref<boolean>(false);
onMounted(() => {
  eventBus.pageEnter.once(({ timeline }) => {
    timeline.add(() => {
      show.value = true;
    }, 1.5);
  });
});
</script>

<style lang="stylus" scoped>
$maskColor = v-bind(maskColor)
$maskPositionTop = v-bind(maskPositionTop)
$maskPositionBottom = v-bind(maskPositionBottom)
$maskHeight = v-bind(maskHeight)
.HeaderMask
  position fixed
  top $maskPositionTop
  bottom $maskPositionBottom
  left 0
  background $maskColor
  width 100%
  height $maskHeight
  z-index 2
  transform-origin top center

  transition opacity 1s $easeQuartOut
  &.hidden
    opacity 0
    pointer-events none

  +desktop()
    display none
</style>
