<template>
  <div
    ref="trigger"
    class="Draggable__Trigger"
    @mousemove="state.isHovered = true"
    @mouseleave="state.isHovered = false"
  />
  <div class="Draggable">
    <DetailTitleLoop class="Loop" />
    <DetailVisual class="Visual" />
    <DetailCursorLoader />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, inject } from "vue";
import gsap from "gsap";
import Draggable from "gsap/dist/Draggable";
import InertiaPlugin from "gsap/dist/InertiaPlugin";

import useDetails, { DetailState, PIXEL_PER_FRAME } from "@/composables/useDetails";
import DetailVisual from "@/components/blocks/Detail/DetailVisual.vue";
import DetailTitleLoop from "@/components/blocks/Detail/DetailTitleLoop.vue";
import DetailCursorLoader from "@/components/blocks/Detail/DetailCursorLoader.vue";
import Mouse from "@/store/modules/Mouse";

gsap.registerPlugin(Draggable, InertiaPlugin);

const state = inject<DetailState>("state");
const { nbFrames, angle, isHotspotOpen } = useDetails();

const trigger = ref<HTMLDivElement>();
const draggable = ref<Draggable>();

let proxy: HTMLDivElement;

onMounted(() => {
  proxy = document.createElement("div");

  const hasBounds = angle.value !== 360;

  const offset = (nbFrames.value / 2) * PIXEL_PER_FRAME;
  const bounds = hasBounds ? { minX: -offset, maxX: offset, minY: 0, maxY: 0 } : null;

  draggable.value = Draggable.create(proxy, {
    trigger: trigger.value,
    bounds,
    edgeResistance: 0.85,
    inertia: true,
    onMove: () => {
      state.x = draggable.value.x * -1;
    },
    onThrowUpdate: () => {
      state.x = draggable.value.x * -1;
    },
    onThrowComplete: () => {
      state.isThrowing = false;
    },
    onDrag: (event: MouseEvent) => {
      if (Mouse.value.x > event.clientX) {
        state.dragDirection = 1;
      } else if (Mouse.value.x < event.clientX) {
        state.dragDirection = -1;
      }

      Mouse.value = {
        x: event.clientX,
        y: event.clientY,
      };
    },
    onDragStart: () => {
      state.isDragging = true;
      state.isThrowing = true;
      onDragStart();
    },
    onDragEnd: () => {
      state.isDragging = false;
      onDragEnd();
    },
  })[0];

  draggable.value.disable();
});

watch(isHotspotOpen, () => {
  if (isHotspotOpen.value) {
    resetRotation();
  }
});

const resetRotation = () => {
  const pixelPerTurn = nbFrames.value * PIXEL_PER_FRAME;
  const currentTurnX = draggable.value.x % pixelPerTurn;
  const endTurn = Math.abs(currentTurnX) > pixelPerTurn / 2;
  const targetX = endTurn
    ? draggable.value.x + (pixelPerTurn - Math.abs(currentTurnX)) * Math.sign(currentTurnX)
    : draggable.value.x - currentTurnX;

  gsap.to(proxy, {
    x: targetX,
    duration: 0.7,
    ease: "power1.inOut",
    onUpdate: () => {
      draggable.value.update();
    },
    onStart: () => {
      draggable.value.disable();
    },
    onComplete: () => {
      draggable.value.enable();
    },
  });

  gsap.to(state, {
    x: targetX * -1,
    duration: 0.7,
    ease: "power1.inOut",
  });
};

watch(
  () => [state.allFrameLoaded, state.backTitleIntroComplete, draggable.value],
  () => {
    if (!draggable.value || !state) return;
    if (state.allFrameLoaded && state.backTitleIntroComplete) {
      draggable.value.enable();
    } else {
      draggable.value.disable();
    }
  },
  { immediate: true },
);

const onDragStart = () => {
  state.hasSwipe = true;
};
const onDragEnd = () => {
  setTimeout(() => {
    state.hasSwipe = false;
  }, 1500);
};
</script>

<style lang="stylus" scoped>
.Draggable
  position relative
  flex 1 1 auto
  display flex
  justify-content center
  height 100%

  &__Trigger
    position absolute
    left 0
    top 0
    width 100%
    height 100%
    z-index 1
    +desktop()
      left 50%
      width 70%
      transform translateX(-50%)

    // background rgba($red, 0.3)

  +desktop()
    padding-left var(--container-margin)
    padding-right var(--container-margin)
</style>
