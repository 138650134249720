const disableAvif = process.env.VUE_APP_AVIF === "false";
const disableWebp = process.env.VUE_APP_WEBP === "false";

/**
 * Check if format is
 * @param format
 * @param dataUri
 * @returns
 */
export const isFormatSupported = (format: string, dataUri: string, disabled: boolean) =>
  new Promise<boolean>((resolve, reject) => {
    if (disabled) {
      resolve(false)
    }
    const image = new Image();

    image.src = `data:image/${format};base64,${dataUri}`;

    image.onload = () => {
      resolve(true);
    };

    image.onerror = () => {
      reject(format.toUpperCase() + " format not supported");
    };
  });


export const isAvifSupported = () =>
  isFormatSupported(
    "avif",
    "AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=",
    disableAvif,
  );

export const isWebpAlphaSupported = () =>
  isFormatSupported(
    "webp",
    "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
    disableWebp
  );

export const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      resolve(image);
    };

    image.src = src;

    image.onerror = () => {
      reject(`Image with name '...${src.slice(-20)}' was not found.`);
    };
  });
};


export type ImageFormat = 'avif' | 'webp' | 'png' | 'jpg';
export const getImageFormat = async (fallback: ImageFormat = 'png'): Promise<ImageFormat> => {
  // check for best image format supported
  if ((await isAvifSupported().catch(console.warn)) || false) {
    return 'avif';
  }
  if ((await isWebpAlphaSupported().catch(console.warn)) || false) {
    return 'webp';
  }

  return fallback;
};
