import Scene from "@webgl/scene";
import { vec3 } from "gl-matrix";
import Gltf from "nanogl-gltf/lib/Gltf";
import Bird from "./Bird";
import Node from "nanogl-node";
import Camera from "nanogl-camera";
import GLArrayBuffer from "nanogl/arraybuffer";
import Masks from "@webgl/gl/masks";
import Material from 'nanogl-pbr/Material'
import { Passes } from "@webgl/glsl/Passes";
import GLConfig from "nanogl-state/GLConfig";
import Viewport from "@/store/modules/Viewport";
import { InstancingImpl } from "@webgl/resources/Instancing";
import Capabilities from "@webgl/resources/Capabilities";
import BirdChunk from "./BirdChunk";
import Program from "nanogl/program";
import Primitive from "nanogl-gltf/lib/elements/Primitive";
import GLState from "nanogl-state/GLState";

const BIRDS_NB = 45
const BIRDS_NB_MOBILE = 30

const LOOKAT_V = vec3.create()
const V3_A = vec3.create()

export default class Birds {
  root: Node
  materials: Material[] = [];

  flockDestination: vec3
  flockDestinationLerp = vec3.create()
  angleFlock: number

  birdList: Bird[]
  instanceData: Float32Array;
  instanceBuffer: GLArrayBuffer;

  instanceAttributeStride: number;

  force = false



  instanceChunk: BirdChunk;


  instancing: InstancingImpl;


  constructor(private birdGltf: Gltf,
    private scene: Scene) {
    this.root = new Node()

    this.instancing = Capabilities(scene.gl).instancing;

    this.birdList = [...Array(Viewport.isMobile ? BIRDS_NB_MOBILE : BIRDS_NB)].map((x, i) => {
      const b = new Bird(
        this.scene.gl,
        this.birdGltf,
        i,
        this.flockDestination
      )
      this.root.add(b.root)
      return b
    })

    this.instanceAttributeStride = 4 * 4 + 1;


    this.instanceBuffer = new GLArrayBuffer(this.scene.gl, null, this.scene.gl.DYNAMIC_DRAW);

    this.instanceBuffer
      .attrib("aInstanceMatrix1", 4, this.scene.gl.FLOAT)
      .attrib("aInstanceMatrix2", 4, this.scene.gl.FLOAT)
      .attrib("aInstanceMatrix3", 4, this.scene.gl.FLOAT)
      .attrib("aInstanceMatrix4", 4, this.scene.gl.FLOAT)
      .attrib("aFlap", 1, this.scene.gl.FLOAT)

    this.instanceData = new Float32Array(this.birdList.length * this.instanceAttributeStride);
    this.instanceBuffer.data(this.instanceData)


    this.instanceChunk = new BirdChunk()

    this.scene.root.add(this.root)

    this.setupMaterial()

  }

  setupMaterial() {

    for (const primitive of this.birdGltf.primitives) {
      const material = primitive.material.createMaterialForPrimitive(this.birdGltf, null, primitive);
      material.inputs.add(this.instanceChunk);
      this.materials.push(material);
    }

  }

  async changeDestination() {

    if (!this.flockDestination || this.force) {
      this.flockDestination = vec3.create()

      this.force = false

      this.changeDestination()
      // const dirX = Math.random() > 0.5 ? 1 : -1

      vec3.copy(this.flockDestinationLerp, this.flockDestination)

      for (const bird of this.birdList) {
        bird.startPosition(this.flockDestination)
      }
    }

    this.scene.camera.updateWorldMatrix();
    vec3.set(LOOKAT_V, 0, 0, -1);
    vec3.transformQuat(LOOKAT_V, LOOKAT_V, this.scene.mainCameraParent.rotation);

    vec3.scale(LOOKAT_V, LOOKAT_V, 70)

    vec3.add(LOOKAT_V, this.scene.camera._wposition, LOOKAT_V);
    vec3.set(V3_A, (-1 + Math.random() * 2) * 30, -7.5 + Math.random() * 20, (-1 + Math.random() * 2) * 30)
    vec3.add(LOOKAT_V, LOOKAT_V, V3_A)

    vec3.copy(this.flockDestination, LOOKAT_V)
  }

  preRender(dir: vec3) {
    this.changeDestination()

    vec3.copy(V3_A, dir)
    vec3.scale(V3_A, V3_A, 70)

    vec3.add(V3_A, this.scene.camera._wposition, V3_A);

    // console.log(Math.round(V3_A[0]), Math.round(V3_A[1]), Math.round(V3_A[2]), Math.round(this.flockDestination[0]), Math.round(this.flockDestination[1]), Math.round(this.flockDestination[2]))

    vec3.lerp(this.flockDestinationLerp, this.flockDestinationLerp, this.flockDestination, 0.04)
    for (const bird of this.birdList) {
      bird.preRender(this.flockDestinationLerp, V3_A, this.flockDestination)
    }
  }

  render(camera: Camera, mask: Masks, passId: Passes = Passes.DEFAULT, cfg?: GLConfig) {
    let count = 0
    for (const bird of this.birdList) {
      const birdMat = bird.root._matrix
      this.instanceData.set([birdMat[0], birdMat[1], birdMat[2], birdMat[3]], count * this.instanceAttributeStride + 0)
      this.instanceData.set([birdMat[4], birdMat[5], birdMat[6], birdMat[7]], count * this.instanceAttributeStride + 4)
      this.instanceData.set([birdMat[8], birdMat[9], birdMat[10], birdMat[11]], count * this.instanceAttributeStride + 8)
      this.instanceData.set([birdMat[12], birdMat[13], birdMat[14], birdMat[15]], count * this.instanceAttributeStride + 12)
      this.instanceData.set([bird.flap], count * this.instanceAttributeStride + 16)
      count++
    }
    this.instanceBuffer.data(this.instanceData)


    const gl = this.scene.gl;
    const primitives = this.birdGltf.primitives;
    const glstate = GLState.get(gl)

    for (let i = 0; i < primitives.length; i++) {

      const primitive = primitives[i];

      const mat: Material = this.materials[i];

      if (!mat.hasPass(passId) || (mat.mask & mask) === 0) continue;

      const passInstance = mat.getPass(passId);

      if ((passInstance.pass.mask & mask) === 0) continue;

      passInstance.prepare(this.root, camera)

      glstate.push(passInstance.pass.glconfig);
      mat.glconfig && glstate.push(mat.glconfig);
      cfg && glstate.push(cfg);

      glstate.apply()

      // render
      // ----------
      this.drawCall(camera, passInstance.getProgram(), primitive);

      glstate.pop();
      mat.glconfig && glstate.pop();
      cfg && glstate.pop();
    }


  }



  drawCall(camera: Camera, prg: Program, sub: Primitive) {

    sub.bindVao(prg);

    this.instanceBuffer.attribPointer(prg);
    this.instancing.vertexAttribDivisor(prg.aInstanceMatrix1(), 1);
    this.instancing.vertexAttribDivisor(prg.aInstanceMatrix2(), 1);
    this.instancing.vertexAttribDivisor(prg.aInstanceMatrix3(), 1);
    this.instancing.vertexAttribDivisor(prg.aInstanceMatrix4(), 1);
    this.instancing.vertexAttribDivisor(prg.aFlap(), 1);

    this.instancing.drawElementsInstanced(
      this.scene.gl.TRIANGLES,
      sub.indices.count,
      sub.indexBuffer.type,
      0,
      this.birdList.length
    );

    sub.unbindVao();

  }
}