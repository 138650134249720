<template>
  <div class="DetailCursors" :class="{ Active: state.allFrameLoaded }" :key="prev">
    <div @click="emit('prev')">
      <div
        v-if="!Viewport.isTouch"
        ref="triggerPrev"
        class="Trigger Trigger__Prev"
        @mouseenter="() => (isPrevActive = true)"
        @mouseleave="() => (isPrevActive = false)"
        @click="() => (isPrevActive = false)"
      ></div>
      <span
        :class="[
          'MobileCursor MobileCursor__Prev legend1',
          Viewport.isTouch ? 'MobileCursor__Touch' : '',
        ]"
        v-html="$t(`detail.gallery-btn.back`)"
      ></span>
    </div>
    <div @click="emit('next')">
      <div
        v-if="!Viewport.isTouch"
        ref="triggerNext"
        class="Trigger Trigger__Next"
        @mouseenter="() => (isNextActive = true)"
        @mouseleave="() => (isNextActive = false)"
        @click="() => (isNextActive = false)"
      ></div>
      <span
        :class="[
          'MobileCursor MobileCursor__Next legend1',
          Viewport.isTouch ? 'MobileCursor__Touch' : '',
        ]"
        v-html="$t(`detail.gallery-btn.next`)"
      ></span>
    </div>

    <div
      v-if="!Viewport.isTouch"
      :class="['DetailCursor legend1', isPrevActive || isNextActive ? 'Active' : '']"
      :style="{ transform: `translate3d(${mouse.x}px, ${mouse.y}px, 0px)` }"
    >
      <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle ref="circle" opacity="0.3" cx="48" cy="48" r="47.5" stroke="currentColor" />
      </svg>
      <div class="DetailCursor__Text">
        <split-text class="label" :state="textStatePrev" :isRewind="true">
          <span
            v-html="
              te(`detail.${prev}.shortTitle`)
                ? t(`detail.${prev}.shortTitle`)
                : t(`detail.${prev}.backtitle`)
            "
          ></span>
        </split-text>
        <split-text class="label" :state="textStateNext" :isRewind="true">
          <span
            v-html="
              te(`detail.${next}.shortTitle`)
                ? t(`detail.${next}.shortTitle`)
                : t(`detail.${next}.backtitle`)
            "
          ></span>
        </split-text>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, onMounted, ref, onUnmounted, watch, inject, computed } from "vue";
import { useI18n } from "vue-i18n";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/src/DrawSVGPlugin";

import eventBus from "@/utils/EventBus";
import Viewport from "@/store/modules/Viewport";
import { DetailState } from "@/composables/useDetails";
import { SplitTextState } from "../Split/SplitText.vue";

gsap.registerPlugin(DrawSVGPlugin);

defineProps({
  prev: {
    type: String,
    required: true,
  },
  next: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["prev", "next"]);

const state = inject<DetailState>("state");
const { t, te } = useI18n();

const circle = ref<SVGCircleElement>();
const mouse = ref({
  x: 0,
  y: 0,
});
const textStatePrev = ref<SplitTextState>("before");
const textStateNext = ref<SplitTextState>("before");

const isPrevActive = ref<boolean>(false);
const isNextActive = ref<boolean>(false);

const onMouseMove = (event: MouseEvent) => {
  mouse.value = {
    x: event.clientX,
    y: event.clientY,
  };
};
onMounted(() => {
  if (!Viewport.isTouch) {
    document.addEventListener("mousemove", onMouseMove);
    gsap.set(circle.value, {
      drawSVG: isPrevActive.value || isNextActive.value ? "0% 100%" : "0% 0%",
    });
  } else {
    eventBus.pageEnter.once(({ el, timeline }) => {
      const mobileBtn = el.querySelectorAll(".MobileCursor");
      timeline.fromTo(
        mobileBtn,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.5,
          ease: "power1.out",
        },
      );
    });
  }
});

onUnmounted(() => {
  document.removeEventListener("mousemove", onMouseMove);
});

const isActive = computed(
  () =>
    (isPrevActive.value || isNextActive.value) &&
    !state.isInDetailsTransition &&
    state.allFrameLoaded &&
    (mouse.value.x > 0 || mouse.value.y > 0),
);

watch(isActive, () => {
  if (!Viewport.isTouch) {
    gsap
      .timeline()
      .to(circle.value, {
        drawSVG: isActive.value ? "0% 100%" : "100% 100%",
        duration: 0.7,
        ease: "quart.inOut",
      })
      .set(circle.value, {
        drawSVG: isActive.value ? "0% 100%" : "0% 0%",
      })
      .add(
        () => {
          textStatePrev.value = isPrevActive.value && isActive.value ? "active" : "before";
          textStateNext.value = isNextActive.value && isActive.value ? "active" : "before";
        },
        isActive.value ? 0.2 : 0,
      );
  }
});
</script>
<style lang="stylus" scoped>
.DetailCursors
  position fixed
  width 100%
  left 0
  right 0
  bottom rem(24)
  z-index 3
  pointer-events none
  &.Active
    pointer-events all


  +desktop()
    height 100%
    top 0
    z-index unset
  .Trigger
    display none
    +desktop()
      display block
      position absolute
      width 10%
      height 70%
      top 0
      bottom 0
      margin auto
      cursor pointer

      &__Prev
        left 0
      &__Next
        right 0

  .MobileCursor
    position fixed
    bottom rem(21)

    +desktop()
      display none

    &__Touch
      +desktop()
        display block

    &__Prev
      left rem(16)
      +desktop()
        left rem(24)
    &__Next
      right rem(16)
      +desktop()
        right unset
        left rem(76)

.DetailCursor
  position fixed
  z-index $z-index-cursor
  top 0
  left 0
  width rem(96)
  height rem(96)
  text-align center
  display none
  align-items center
  justify-content center
  flex-direction column
  padding rem(8)
  pointer-events none

  +desktop()
    display flex

  svg
    position absolute
    top 0
    left 0
    right 0
    bottom 0

  &__Text
    position relative
    .label
      position fixed
      top 10%
      bottom 0
      left 0
      right 0
      margin auto
      width 70%
      height fit-content
</style>
