import { GLContext } from "nanogl/types"
import TexCoord from "nanogl-pbr/TexCoord"
import Chunk from "nanogl-pbr/Chunk"
import Input, { ShaderType, Uniform } from "nanogl-pbr/Input"
import ChunksSlots from "nanogl-pbr/ChunksSlots"
import { StandardPass } from "nanogl-pbr/StandardPass"
import { TypeScene } from "../scene"
import { loadBytes } from "../assets/Net"
import BasisDecoder from "../resources/basis/BasisDecoder"
import Texture2D from "nanogl/texture-2d"
import { getAssetPath } from "./Assets"
import { addLoaded, addToLoad } from "@/store/modules/WebglLoading"
import Viewport from "@/store/modules/Viewport"
import RoomLighting from "./GalleryLighting"

class LightmapChunk extends Chunk {

  readonly lightmapInput: Input
  readonly lightmapExposure: Input
  readonly iblCutoffMult: Input
  readonly iblCutoffOffset: Input
  readonly diffuseMix: Input

  constructor() {
    super(true, false);
    this.lightmapInput = new Input('lightmapInput', 4, ShaderType.FRAGMENT)
    this.lightmapExposure = new Input('lightmapExpo', 1, ShaderType.FRAGMENT)
    this.iblCutoffMult = new Input('lmIblCutoffMult', 1, ShaderType.FRAGMENT)
    this.iblCutoffOffset = new Input('lmIblCutoffOffset', 1, ShaderType.FRAGMENT)
    this.diffuseMix = new Input('diffuseMix', 1, ShaderType.FRAGMENT)

    this.addChild(this.lightmapInput)
    this.addChild(this.lightmapExposure)
    this.addChild(this.iblCutoffMult)
    this.addChild(this.iblCutoffOffset)
    this.addChild(this.diffuseMix)

  }


  protected _genCode(slots: ChunksSlots): void {
    const code = `
    vec4 rgbm = lightmapInput();
    vec3 lmvalue = ( rgbm.rgb * 8.0 * rgbm.a );
    float occlu = saturate( dot( lmvalue*lmIblCutoffMult(), vec3(1.0) )-lmIblCutoffOffset() );
    lightingData.lightingColor *= occlu;
    lightingData.lightingColor += mix(vec3(1.0), brdfData.diffuse, diffuseMix()) * lmvalue * lightmapExpo() ;
    //lightingData.lightingColor = lightingData.lightingColor * 0.0001 + occlu;
    `
    // slots.add('lightsf', code)
    slots.add('lm', code)
  }
}

const EXPO = 0.1;
const CUTOFF_MULT = 1.4;
const CUTOFF_OFFSET = 0.4;
const DIFFUSE_MIX = 0.3;

export default class RoomLightmap {

  lightmapChunk: LightmapChunk;

  diffuseUniform: Uniform = null;
  expoUniform: Uniform = null;
  cutoffMultUniform: Uniform = null;
  cutoffOffsetUniform: Uniform = null;
  tex: Texture2D

  _exposure = EXPO;
  _diffuseMix = DIFFUSE_MIX;

  public get diffuseMix(): number {
    return this._diffuseMix
  }
  public set diffuseMix(value: number) {
    this._diffuseMix = value
    if (this.diffuseUniform === null) {
      this.diffuseUniform = this.lightmapChunk.diffuseMix.attachUniform('diffuseMix')
    }
    this.diffuseUniform.set(this._diffuseMix)
  }

  public get exposure(): number {
    return this._exposure
  }
  public set exposure(value: number) {
    this._exposure = value
    if (this.expoUniform === null) {
      this.expoUniform = this.lightmapChunk.lightmapExposure.attachUniform('lmExpo')
    }
    this.expoUniform.set(this._exposure)
  }

  _cutoffMult = CUTOFF_MULT;
  public get cutoffMult(): number {
    return this._cutoffMult
  }
  public set cutoffMult(value: number) {
    this._cutoffMult = value
    if (this.cutoffMultUniform === null) {
      this.cutoffMultUniform = this.lightmapChunk.iblCutoffMult.attachUniform('lmCutoffMult')
    }
    this.cutoffMultUniform.set(this._cutoffMult)
  }

  _cutoffOffset = CUTOFF_OFFSET;
  public get cutoffOffset(): number {
    return this._cutoffOffset
  }
  public set cutoffOffset(value: number) {
    this._cutoffOffset = value
    if (this.cutoffOffsetUniform === null) {
      this.cutoffOffsetUniform = this.lightmapChunk.iblCutoffOffset.attachUniform('lmCutoffOffset')
    }
    this.cutoffOffsetUniform.set(this._cutoffOffset)
  }

  constructor(
    public roomId: string,
    private lighting: RoomLighting,
    private gl: GLContext,
    private scene: TypeScene,
    readonly startExposure: number = EXPO,
    diffuseMix: number = DIFFUSE_MIX
  ) {
    this._exposure = startExposure

    this.tex = new Texture2D(gl)

    this.lightmapChunk = new LightmapChunk()
    this.lightmapChunk.lightmapExposure.attachConstant(this.exposure)
    this.lightmapChunk.iblCutoffMult.attachConstant(this.cutoffMult)
    this.lightmapChunk.iblCutoffOffset.attachConstant(this.cutoffOffset)
    this.diffuseMix = diffuseMix

/////////////////
////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
///////////////////////////////////////
///////////////////////////////////////
//////////////




  }

  async load() {
    const tc = TexCoord.create('aTexCoord0')
    this.lightmapChunk.lightmapInput.attachSampler('basecolor', tc).set(this.tex)
    // if (this.roomId.includes("LM_Wall_Part")) {
    //   const url = getAssetPath(`${this.roomId}${Viewport.isMobile ? "_mobile" : ""}.png`)
    //   addToLoad()
    //   const img = new Image()
    //   img.src = url
    //   await img.decode()
    //   console.log(img)
    //   this.tex.fromImage(img)
    //   addLoaded()

    // } else {
    const url = getAssetPath(`${this.roomId}${Viewport.isMobile ? "_mobile" : ""}.ktx2`)
    addToLoad()
    const buffer = await loadBytes(url)
    const res = await BasisDecoder.getInstance().decode(this.gl, buffer)
    addLoaded()
    BasisDecoder.setupTexture(res, this.tex)

    // }
  }

  setupStandardPass(standardPass: StandardPass) {
    standardPass.inputs.add(this.lightmapChunk);

  }

}