<template>
  <Teleport to="body">
    <div
      v-if="!Viewport.isTouch"
      :class="['CtaCursor paragraph1']"
      :style="{ transform: `translate3d(${mouse.x}px, ${mouse.y}px, 0px)` }"
    >
      <div class="CtaCursor__wrapper" ref="$wrapper">
        <split-text class="CtaCursor__label" :state="textState" :isRewind="true">{{ label }}</split-text>
      </div>
    </div>
  </Teleport>
</template>
<script lang="ts" setup>
import { defineProps, onMounted, ref, onUnmounted, watch } from "vue";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/src/DrawSVGPlugin";
import Viewport from "@/store/modules/Viewport";
import { SplitTextState } from "../Split/SplitText.vue";

gsap.registerPlugin(DrawSVGPlugin);

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const mouse = ref({
  x: 0,
  y: 0,
});
const $wrapper = ref<HTMLDivElement | null>(null);
const textState = ref<SplitTextState>("before");

const onMouseMove = (event: MouseEvent) => {
  mouse.value = {
    x: event.clientX,
    y: event.clientY,
  };
};
onMounted(() => {
  if (!Viewport.isTouch) {
    document.addEventListener("mousemove", onMouseMove);
  }
});
onUnmounted(() => {
  document.removeEventListener("mousemove", onMouseMove);
});

watch(
  () => props.isActive,
  () => {
    if (!Viewport.isTouch && $wrapper.value) {

      const { width, height } = $wrapper.value.getBoundingClientRect();

      const from = `inset(${height/2}px ${width - height/2}px ${height/2}px ${height/2}px round ${height/2}px)`;
      const to = `inset(0px 0px 0px 0px round ${height/2}px)`;

      // const from = `circle(0% at ${height/2}px ${height/2}px)`;
      // const to = `circle(101% at ${height/2}px ${height/2}px)`;

      gsap.timeline()
        .fromTo(
          $wrapper.value,
          {
            clipPath: props.isActive ? from : to,
          },
          {
            clipPath: props.isActive ? to : from,
            duration: 0.5,
            delay: props.isActive ? 0 : 0.1,
            ease: "quart.inOut",
          },
        )
        .add(() => textState.value = props.isActive ? "active" : "before", props.isActive ? 0.2 : 0)
    }
  },
);
</script>
<style lang="stylus" scoped>
.CtaCursor
  position fixed
  z-index $z-index-cursor
  top 0
  left 0
  pointer-events none
  
  &__wrapper
    text-align center
    display flex
    align-items center
    justify-content center
    color rgba($white, 1)
    background rgba($red, 1)
    padding rem(10) rem(24) rem(10)
    border-radius rem(28)
    clip-path inset(0% 100% 0% 0% round 0 rem(28) rem(28) 0)

  :deep(.SplitText)
    display flex
    align-items center
    justify-content center
    gap 0.2em
</style>
