import Camera from "nanogl-camera";
import GLConfig from "nanogl-state/GLConfig";
import Gallery from ".";
import GLTFResource from "../assets/GLTFResource";
import Masks from "../gl/masks";
import { Passes } from "../glsl/Passes";
import Gltf from "nanogl-gltf/lib/Gltf";
import { StandardPass } from "nanogl-pbr/StandardPass";
import Bounds from "nanogl-pbr/Bounds";
import FloorReflectionChunk from "@webgl/glsl/standard_ssr/FloorReflectionChunk";
import { Uniform } from "nanogl-pbr/Input";
import GltfTypes from "nanogl-gltf/lib/types/GltfTypes";
import GltfLoader from "nanogl-gltf/lib/io/GltfLoader";
import { IO } from "nanogl-gltf/lib/io/web";
import gui from "@webgl/dev/gui";
import Material from "nanogl-gltf/lib/elements/Material";
import MaterialElement from "nanogl-gltf/lib/elements/Material";
import MaterialOverrideExtension from "nanogl-gltf/lib/extensions/MaterialOverrideExtension";
import CutoutShadow from "@webgl/core/lighting/CutOutShadow";
import ReflectDistPass from "@webgl/glsl/reflect_dist";
import { addLoaded, addToLoad } from "@/store/modules/WebglLoading";


/////////////
///////////////////////////////////////////////////////////////////////////
//////////

const FLOORS_MATERIAL_NAME: string[] = ["LM_Ground_Part01", "LM_Ground_Part02", "LM_Ground_Part03"]

const REFLECT_FLOOR_NAME = "Ground_Part01"
const REFLECT_FLOOR_NAME_2 = "Ground_Part02"
const REFLECT_FLOOR_NAME_3 = "Ground_Part03"

export default class GallerySection {


  gltfResource: GLTFResource;

  bounds: Bounds = new Bounds();
  centroid: Float32Array = new Float32Array(3);


  floorAlbedoBoostUniform: Uniform;
  floorReflectivityUniform: Uniform;

  floorReflectivity = .12
  floorAlbedoBoost = 1.5

  gltf: Gltf

  costumeAngle = 0




  constructor(readonly gallery: Gallery, readonly index: number, url: string) {

    const overrides = new MaterialOverrideExtension();

    overrides.overridePass('LM_Display_Part01', this.cutoffShadows)
    overrides.overridePass('LM_Display_Part02', this.cutoffShadows)
    overrides.overridePass('LM_Display_Part03', this.cutoffShadows)

    overrides.overridePass('Base concrete_0.001', (ctx, material) => {
      const pass = material.getPass('color').pass as StandardPass
      const col = { col: { r: 150, g: 144, b: 135 } }

///////////////////
///////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////
////////
////////////////
      pass.emissiveFactor.attachConstant([col.col.r / 255, col.col.g / 255, col.col.b / 255])

      return null
    })

    // overrides.overridePass(FLOORS_MATERIAL_NAME[index - 1], (ctx, material) => {
    //   const pass = material.getPass('color').pass as StandardPass;
    //   pass.inputs.add(this.gallery.blendColor)
    //   return null
    // })

    this.gltfResource = new GLTFResource(
      url,
      gallery.scene,
      {
        extensions: [overrides],
      }
    );


/////////////////
///////////////////////////////////////
/////////////////////////////////////////////////////////////////
////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
/////////
////////
////////////////////////////////////////////////////////////////
////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////
////////

/////

/////////////////////////

////////
//////////////

  }

  cutoffShadows = (ctx: any, material: any): null => {
    const shadowCutout = new CutoutShadow()
    const pass = material.getPass('color').pass as StandardPass;
    shadowCutout.color.attach(pass.alpha.param)
    material.getPass('depth').pass.inputs.add(shadowCutout)
    if (this.gallery.scene.isReflect) {
      const reflectDistPass = new ReflectDistPass();
      reflectDistPass.mask = Masks.REFLECTED;
      const passReflect = material.addPass(reflectDistPass, Passes.REFLECT_DEPTH)
      passReflect.pass.inputs.add(shadowCutout)
    }
    return null
  }

  async replaceContent(content: ArrayBuffer) {
    this.cleanup()
    const loader = new GltfLoader(IO, "", {})
    loader.unpackGlb(content)
    await loader.parseAll()
    await loader.gltf.allocate(this.gallery.scene.gl)
    this.gltf = loader.gltf
    this.onLoaded()

    this.gallery.scene.camera.lookAt(this.gltf.root.position)
    this.gallery.scene.camera.invalidate()
    this.gallery.scene.camera.updateWorldMatrix()
  }


  async load(): Promise<void> {

    addToLoad()
    await this.gltfResource.load().then(() => {
      addLoaded()
      this.gltf = this.gltfResource.value
      this.gallery.scene.root.add(this.gltf.root)
    })
  }

  onLoaded() {
    if (this.gallery.scene.isReflect) {
      for (const renderable of this.gltf.renderables) {
        for (const material of renderable.materials) {
          if (!material.hasPass(Passes.REFLECT_DEPTH)) {
            material.addPass(this.gallery.scene.reflectDistPass, Passes.REFLECT_DEPTH)
          }
        }
      }
    }
    for (const material of this.gltf.materials) {
      const mat = (material as MaterialElement)
      const pass = mat.materialPass;
      if (pass instanceof StandardPass) {
        this.gallery.lighting.setupGltfMaterial(mat)
      }
    }
    // }

    this.computeStaticBounds(this.bounds);
    // this.gallery.lighting.updateIblBounds(this.bounds);

    this.gallery.scene.root.add(this.gallery.lighting.root);


    this.floorAlbedoBoostUniform = new Uniform("albedoMult", 1)
    this.floorReflectivityUniform = new Uniform("fru", 1)
    this.floorAlbedoBoostUniform.set(this.floorAlbedoBoost)
    this.floorReflectivityUniform.set(this.floorReflectivity)
    if (this.gallery.scene.isReflect)
      this.createConcreteFloor(this.gltf.getElementByName(GltfTypes.MATERIAL, FLOORS_MATERIAL_NAME[this.index - 1]) as Material)
  }

///////////////
//////////////
/////////////////////////////////////////////
////////////////
///
////////////

  createConcreteFloor(mat: Material) {
    if (!mat) return
    const pass = mat.materialPass

    pass.glconfig.enableCullface(true)
      .enableDepthTest()
      .depthMask(true)
    pass.mask = Masks.OPAQUE

    const reflChunk = new FloorReflectionChunk()
    reflChunk.reflectionTexture = this.gallery.scene.reflect.getOutput()
    // reflChunk.strength.attachConstant( .9 )
    pass.inputs.add(reflChunk)


    pass.surface.baseColorFactor.attach(this.floorAlbedoBoostUniform)
    reflChunk.strength.attach(this.floorReflectivityUniform)

  }

  computeStaticBounds(out: Bounds) {
    this.gltf.root.updateWorldMatrix();
    const b: Bounds = new Bounds();
    Bounds.transform(out, this.gltf.renderables[0].bounds, this.gltf.renderables[0].node._wmatrix)
    for (const renderable of this.gltf.renderables) {
      Bounds.transform(b, renderable.bounds, renderable.node._wmatrix)
      Bounds.union(out, out, b);
    }
    this.centroid[0] = (out.min[0] + out.max[0]) * 0.5;
    this.centroid[1] = (out.min[1] + out.max[1]) * 0.5;
    this.centroid[2] = (out.min[2] + out.max[2]) * 0.5;
  }


  preRender() {
    // console.log(this.blendColor.camPosition.param)
    // this.gallery.scene.camera._wmatrix[12],
    // this.gallery.scene.camera._wmatrix[13],
    // this.gallery.scene.camera._wmatrix[14]
  }


  render(camera: Camera, mask: Masks, passId: Passes = Passes.DEFAULT, cfg?: GLConfig) {
    for (const renderable of this.gltf.renderables) {
      if (passId === Passes.REFLECT_DEPTH && (renderable.node.name === REFLECT_FLOOR_NAME || renderable.node.name === REFLECT_FLOOR_NAME_2 || renderable.node.name === REFLECT_FLOOR_NAME_3)) {
        continue
      }

      renderable.render(this.gallery.scene.gl, camera, mask, passId, cfg)
    }
  }

  cleanup() {
    this.gallery.scene.root.remove(this.gltf.root)

  }

}
