<template>
  <Teleport to="body">
    <div
      :class="['LoaderCursor Cursor legend1', !state.allFrameLoaded ? 'Active' : '']"
      :style="{ transform: `translate3d(${position.x}px, ${position.y}px, 0px)` }"
    >
      <svg class="SvgCircle" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <mask
            :id="`maskCircle--${uid}`"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="600"
            height="600"
          >
            <circle
              class="maskCircle"
              ref="maskCircle"
              cx="48"
              cy="48"
              r="47.5"
              stroke="white"
              stroke-width="2"
            />
          </mask>
        </defs>
        <g :mask="`url(#maskCircle--${uid})`">
          <circle
            class="baseCircle"
            ref="baseCircle"
            cx="48"
            cy="48"
            r="47.5"
            stroke="currentColor"
          />
        </g>
      </svg>
      <div class="Inner">
        <IncrementalLoader
          class="Loader"
          :progress="state.progressLoaded"
          @progress="updateProgress"
          @complete="onLoadingComplete"
        />
        <split-text class="Text" :state="textState"><span v-html="$t('detail.drag')" /></split-text>
        <div ref="draggingCursor" class="DraggingCursor">
          <svg-icon class="Icon" name="chevron" />
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script lang="ts" setup>
import { onMounted, ref, watch, inject, computed, getCurrentInstance } from "vue";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/src/DrawSVGPlugin";

import Viewport from "@/store/modules/Viewport";
import Mouse from "@/store/modules/Mouse";

import useDetails, { DetailState } from "@/composables/useDetails";
import { SplitTextState } from "../Split/SplitText.vue";

gsap.registerPlugin(DrawSVGPlugin);

let tl: gsap.core.Timeline;
const state = inject<DetailState>("state");
const { isHotspotOpen } = useDetails();
const loadingComplete = ref(false);

const isDragging = computed(() => !Viewport.isTouch && state.isDragging);

const isActive = computed(
  () =>
    (state.isHovered && !state.isDragging) ||
    state.isCursorForced ||
    (Viewport.isTouch && !state.isDragging && !isHotspotOpen.value && !state.isInDetailsTransition),
);

const textState = ref<SplitTextState>("before");
watch(loadingComplete, () => {
  if (loadingComplete.value) {
    textState.value = isActive.value ? "active" : "before";
  }
});

const uid = computed(() => getCurrentInstance().uid);

const baseCircle = ref<SVGCircleElement>();
const maskCircle = ref<SVGCircleElement>();
const draggingCursor = ref<HTMLDivElement>();

const position = computed<{ x: number; y: number }>(() => {
  if (Viewport.isTouch) {
    return {
      x:
        Math.round(((Viewport.windowHeight > 700 ? 44 : 11) / 375) * Viewport.windowWidth * 100) /
        100,
      y:
        Math.round(
          ((Viewport.windowHeight > 700 ? 340 : 314) / 630) * Viewport.windowHeight * 100,
        ) / 100,
    };
  }

  return Mouse.value;
});

const onLoadingComplete = () => {
  state.isCursorForced = false;
  setTimeout(() => {
    state.allFrameLoaded = true;
    loadingComplete.value = true;
  }, 300);
};

const updateProgress = (progress: number) => {
  gsap.to(baseCircle.value, {
    drawSVG: `0% ${progress}%`,
    duration: 0.7,
    ease: "quart.inOut",
  });
};

watch(
  () => state.progressLoaded,
  (progress) => {
    state.canStartTransition = progress === 100;
  },
);

onMounted(() => {
  state.isCursorForced = true;
  gsap.set(baseCircle.value, {
    drawSVG: "0% 0%",
    ease: "quart.inOut",
  });
});

watch(
  () => [isActive.value, isDragging.value],
  ([newIsActive, newIsDragging], [oldIsActive, oldIsDragging]) => {
    const active = newIsActive;
    if ((oldIsActive === newIsActive && oldIsDragging === newIsDragging) || state.isCursorForced)
      return;
    tl?.kill();

    if (newIsDragging && !active) {
      textState.value = "before";
      tl = gsap
        .timeline()
        .fromTo(
          [baseCircle.value, maskCircle.value],
          {
            scale: 1,
          },
          {
            scale: 0.58,
            duration: 0.7,
            ease: "quart.inOut",
          },
          0,
        )
        .to(
          draggingCursor.value,
          {
            opacity: 1,
            duration: 0.3,
            ease: "quart.inOut",
          },
          0.7 / 2,
        );
    } else if (!newIsDragging) {
      tl = gsap
        .timeline()
        .addLabel("start", 0)
        .add(
          () => {
            textState.value = active ? "active" : "before";
          },
          active ? 0.2 : 0,
        )
        .to(
          maskCircle.value,
          {
            drawSVG: active ? "0% 100%" : "0% 0%",
            duration: 0.7,
            ease: "quart.inOut",
          },
          "start",
        )
        .to(
          [maskCircle.value, baseCircle.value],
          {
            scale: 1,
            duration: 0.7,
            ease: "quart.inOut",
          },
          "start",
        )
        .to(
          draggingCursor.value,
          {
            opacity: 0,
            duration: 0.3,
            ease: "quart.inOut",
          },
          "start",
        );
    }
  },
);
</script>
<style lang="stylus" scoped>
.Cursor
  position fixed
  z-index $z-index-cursor
  top 0
  left 0
  width rem(96)
  height rem(96)
  color rgba($black, 1)
  text-align center
  display flex
  align-items center
  justify-content center
  flex-direction column
  padding rem(8)
  pointer-events none
  user-select none

  .SvgCircle
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    width 100%
    height 100%
    transform rotate(90deg)
    opacity 0.3

.Inner
  display grid
  width 100%
  height 100%
  grid-template-columns 100%
  grid-template-rows 100%
  align-items center
  justify-items center

  & > *
    grid-column 1 / span 1
    grid-row 1 / span 1

// .Text
//   opacity 0
.baseCircle, .maskCircle
  transform-origin 25% 25%
.DraggingCursor
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  width rem(56)
  height rem(56)
  background-color rgba($white, 1)
  border-radius 50%
  display flex
  align-items center
  justify-content center
  opacity 0
  .Icon
    font-size rem(24)
    fill none
</style>
