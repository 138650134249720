import { ref } from "vue";
import Viewport from "./Viewport";

const Mouse = ref({
  x: Viewport.windowWidth / 2,
  y: Viewport.windowHeight / 2,
});


const onMouseMove = (event: MouseEvent) => {
  Mouse.value = {
    x: event.clientX,
    y: event.clientY,
  };
};

window.addEventListener("mousemove", onMouseMove);

export default Mouse;

