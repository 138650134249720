<template>
  <div class="ItemTitle__Wrapper">
    <TransitionGroup name="fade">
      <div
        class="ItemTitle prevent-select"
        v-for="hotspot in hotspotsTitle"
        :key="hotspot.id"
        v-show="visibleHotspotsId.includes(hotspot.id)"
      >
        <split-text
          ref="splitTitle"
          class="split"
          :state="visibleHotspotsId.includes(hotspot.id) ? 'active' : 'before'"
        >
          <span v-html="hotspot.title" />
        </split-text>
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import AppService from "@/services/AppService";
import { computed, onMounted, ref, watch } from "vue";
import { getPermalinkFromId } from "@/services/states/HotSpots";
import { useI18n } from "vue-i18n";
import Scene from "@webgl/scene";
import Viewport from "@/store/modules/Viewport";

const { t } = useI18n();

const hotspots = computed(() => (AppService.glapp.scene as Scene).hotspotList.value);
const visibleHotspotsId = computed(() => {
  // if (Viewport.isDesktop) {
  const first = hotspots.value.find((hotspot) => hotspot.visible);
  if (first) {
    return [first.id];
  } else {
    return [];
  }
  // } else {
  //   return hotspots.value.filter((hotspot) => hotspot.visible).map((hotspot) => hotspot.id);
  // }
});

const hotspotsTitle = ref([]);

const setTitles = () => {
  if (hotspotsTitle.value.length > 0) return;
  hotspotsTitle.value = hotspots.value
    .map((hotspot) => {
      const key = `detail.${getPermalinkFromId(hotspot.id)}.list.title`;
      let title = t(key);
      const keyMobile = `detail.${getPermalinkFromId(hotspot.id)}.list.title-mobile`;
      let titleMobile = t(keyMobile);
      if (titleMobile === keyMobile) {
        titleMobile = title;
      }

      if (!Viewport.isDesktop) {
        title = titleMobile;
      }

      if (title === key) {
        return null;
      } else {
        return {
          id: hotspot.id,
          visible: true,
          title,
        };
      }
    })
    .filter((hotspot) => hotspot !== null);
};

onMounted(() => {
  setTitles();
});
watch(hotspots, () => {
  setTitles();
});
</script>

<style lang="stylus" scoped>
@import "@/styles/typography";
.ItemTitle
  @extend .h1
  width 100vw
  font-size rem(28)
  color rgba($white, 1)
  position absolute
  top 6vh
  left 50%
  padding 10vh 5vw
  transform translateX(-50%)
  visibility visible
  z-index 1
  pointer-events none
  // background radial-gradient(ellipse at 50% 50%, rgba(0,0,0,0.1) 30%, rgba(0,0,0,0) 60%)
  text-align center
  :deep(.Word)
    line-height 0.85
    margin-bottom rem(-2)
  +desktop()
    top 0
    width unset
    font-size rem(49)
  &__Wrapper
    position absolute
    top 0
    left 0
    full()
    pointer-events none
</style>
