import { computed, provide, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export const PIXEL_PER_FRAME = 40;

export type Hotspot = {
  slug: string;
  type: string;
  x: number;
  y: number;
  radius: number;
};

export type DetailState = {
  hasSwipe: boolean;
  isHovered: boolean;

  isDragging: boolean;
  isThrowing: boolean;
  dragDirection: 1 | -1;
  isCursorForced: boolean;
  x: number;

  firstFrameLoaded: boolean;
  allFrameLoaded: boolean;
  canStartTransition: boolean;

  backTitleIntroComplete: boolean;
  showBackTitle: boolean;

  progressLoaded: number;

  isHotspotOpenable: boolean;
  isHotspotDisabled: boolean;

  isInDetailsTransition: boolean;
};

export const useInitialDetailState = () => {
  const state = reactive<DetailState>({
    hasSwipe: false,
    isHovered: false,

    isDragging: false,
    isThrowing: false,
    dragDirection: 1,
    isCursorForced: true,
    x: 0,

    firstFrameLoaded: false,
    allFrameLoaded: false,
    canStartTransition: false,
    backTitleIntroComplete: false,
    showBackTitle: true,

    progressLoaded: 0,

    isHotspotOpenable: false,
    isHotspotDisabled: false,

    isInDetailsTransition: false,
  });
  provide("state", state);

  return {
    state,
  };
};

const useDetails = () => {
  const route = useRoute();
  const { t } = useI18n();

  const detailId = computed(() => route.params.detailId as string);
  const hotspotId = computed(() => route.params.hotspotId);

  const nbFrames = computed(() => parseInt(t(`detail.${detailId.value}.nbFrames`)) || 0);
  const angle = computed(() => parseInt(t(`detail.${detailId.value}.angle`)) || 360);
  const nbFrames360 = computed(() => {
    return (nbFrames.value * 360) / angle.value;
  });

  const isHotspotExist = computed(() => {
    const hotspotSlugs = t(`detail.${detailId.value}.hotspots`)
      .split(",")
      .map((slug: string) => slug.trim());
    return hotspotSlugs.includes(hotspotId.value as string);
  });

  const isHotspotOpen = computed(() => {
    return hotspotId.value !== undefined && isHotspotExist.value;
  });

  const hotspots = computed((): Hotspot[] => {
    const translation = t(`detail.${detailId.value}.hotspots`);
    if (translation === `detail.${detailId.value}.hotspots`) return [];

    const hotspotSlugs = translation.split(",").map((slug: string) => slug.trim());
    const nbHotspots = hotspotSlugs.length;

    const hostspots = [];
    for (let i = 0; i < nbHotspots; i++) {
      const slug = hotspotSlugs[i];
      const x = parseFloat(t(`detail.${detailId.value}.${slug}.position.x`)) / 100 || 0;
      const radius = Math.round(Math.abs((x - 0.5) * 2) * 100) / 100;

      const hotspot: Hotspot = {
        slug: slug,
        x,
        y: parseFloat(t(`detail.${detailId.value}.${slug}.position.y`)) / 100 || 0,
        radius,
        type: t(`detail.${detailId.value}.${slug}.media.type`) as string,
      };
      hostspots.push(hotspot);
    }

    // return [hostspots[0]];
    return hostspots;
  });

  return {
    detailId,

    hotspotId,
    isHotspotExist,
    isHotspotOpen,
    hotspots,

    nbFrames,
    nbFrames360,
    angle,
  };
};

export default useDetails;
