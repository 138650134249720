<template>
  <div class="loop__line__wrapper" ref="root">
    <div class="loop__line" ref="line">
      <div class="loop__item loop__item--base" ref="base"><slot /></div>
      <div class="loop__item loop__item--clone" v-for="i in repeat" :key="'logo_' + id + '_' + i">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, onMounted, getCurrentInstance, onUnmounted, computed, watch } from "vue";
import gsap from "gsap";

import eventBus from "@/utils/EventBus";
import useResponsive from "@/composables/useResponsive";

const props = defineProps({
  direction: {
    type: String,
    default: "left",
  },
  gap: {
    type: Number,
    default: 0,
  },
  gapSmall: {
    type: Number,
    default: 0,
  },
  autoPlay: {
    type: Boolean,
    default: false,
  },
  speed: {
    type: Number,
    default: 1,
  },
  autoPlaySpeed: {
    type: Number,
    default: 1,
  },

  listenScroll: {
    type: Boolean,
    default: true,
  },
  x: {
    type: Number,
    default: 0,
  },
});
const emit = defineEmits(['update'])

const { isSmall } = useResponsive();
const id = ref<number>(null);
const root = ref<HTMLElement>();
const line = ref<HTMLElement>();
const base = ref<HTMLElement>();
const repeat = ref<number>(0);
const baseWidth = ref<number>(0);

const gapCssVariable = computed(() => {
  return isSmall.value ? `${props.gapSmall}px` : `${props.gap}px`;
});

// let top = 0

const onResize = () => {
  if (!root.value || !base.value) return;

  baseWidth.value = base.value.offsetWidth;
  if (root.value.offsetWidth > 0 && baseWidth.value > 0) {
    repeat.value = Math.max(
      0,
      Math.ceil((root.value.offsetWidth + baseWidth.value) / base.value.offsetWidth) - 1,
    );
  }

  emit('update', root.value);
};

let scrollTargetX = 0;
let autoPlayTargetX = 0;
let lastAutoPlayTargetX = 0;
let lastX = 0;
let lastScroll = 0;
let scrollDirection = 1;

const getNextX = (x: number) => {
  const gap = isSmall.value ? props.gapSmall : props.gap;
  const loopWidth = baseWidth.value + gap;
  let nextX = Math.round((x % loopWidth) * 100) / 100;
  nextX = nextX < 0 ? nextX + loopWidth : nextX;

  return nextX;
};
const onScroll = (e: any) => {
  if (!isSmall.value) {
    scrollDirection = 1;
    if (baseWidth.value && line.value) {
      scrollTargetX = (e.scroll / 4) * props.speed * (props.direction === "left" ? 1 : -1);
    }
  }
  const nextScroll = Math.round(e.scroll);
  if (nextScroll > lastScroll) {
    scrollDirection = 1;
  } else if (nextScroll < lastScroll) {
    scrollDirection = -1;
  }
  lastScroll = nextScroll;
};

watch(() => props.x, () => {
  scrollDirection = 1
  if (baseWidth.value && line.value) {
    scrollTargetX = props.x/4 * props.speed * (props.direction === 'left' ? 1 : -1)
  }
  const nextScroll = Math.round(props.x)
  if (nextScroll > lastScroll) {
    scrollDirection = 1
  } else if (nextScroll < lastScroll) {
    scrollDirection = -1
  }
  lastScroll = nextScroll
})

const onTick = (time: number, deltaTime: number) => {
  if (line.value) {
    if (isSmall.value || props.autoPlay) {
      autoPlayTargetX =
        lastAutoPlayTargetX +
        deltaTime *
          0.02 *
          props.autoPlaySpeed *
          scrollDirection *
          (props.direction === "left" ? 1 : -1);
      lastAutoPlayTargetX = autoPlayTargetX;
    }
    const targetX = getNextX(scrollTargetX + autoPlayTargetX);
    if (lastX !== targetX) {
      lastX = targetX;
      if (inViewport) {
        line.value.style.transform = `translate3d(${-targetX}px, 0, 0)`;
      }
    }
  }
};

let intersectionObserver: IntersectionObserver | null = null;
let inViewport = false;
const observe = () => {
  intersectionObserver = new IntersectionObserver(
    (entries) => {
      inViewport = entries[0].intersectionRatio > 0;
    },
    { threshold: 0 },
  );

  root.value && intersectionObserver.observe(root.value);
};

onMounted(() => {
  id.value = getCurrentInstance().uid;

  // WAIT FONT LOADED
  eventBus.resize.on(onResize);
  // eventBus.pageEnter.once(onResize);

  if (props.listenScroll) {
    document.addEventListener("scroll", onScroll);
  }

  gsap.ticker.add(onTick);

  document.fonts?.ready &&
    document.fonts.ready.then(() => {
      onResize();
      observe();
    });
});

onUnmounted(() => {
  eventBus.resize.off(onResize);
  // eventBus.pageEnter.off(onResize);
  document.removeEventListener('scroll', onScroll);

  gsap.ticker.remove(onTick);

  intersectionObserver && intersectionObserver.disconnect();
})
</script>

<style lang="stylus" scoped>
$gap = v-bind(gapCssVariable)
.loop__line
  display flex
  gap $gap
  will-change transform

  &__wrapper
    position relative
    width 100%
    overflow hidden
</style>
