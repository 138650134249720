<template>
  <canvas
    ref="canvas"
    :class="['FrameSet', firstFrameLoaded && appeared ? 'FrameSet--Loaded' : '']"
  ></canvas>
</template>
<script lang="ts" setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import gsap from "gsap";

import { loadImage, getImageFormat } from "@/utils/image.util";
import Viewport from "@/store/modules/Viewport";
import { PIXEL_PER_FRAME } from "@/composables/useDetails";
import pageTransition from "@/store/modules/PageTransition";

const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  nbFrames: {
    type: Number,
    default: 0,
  },
  angle: {
    type: Number,
    default: 360,
  },
  x: {
    type: Number,
    default: 0,
  },

  hasIntro: {
    type: Boolean,
    default: false,
  },
  appeared: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["firstFrameLoaded", "allFramesLoaded", "progressLoaded"]);

const firstFrameLoaded = ref<boolean>(false);

const canvas = ref<HTMLCanvasElement | null>(null);

let currentFrame = 0;
let ctx: CanvasRenderingContext2D | null = null;
let size = {
  width: 0,
  height: 0,
};
let scale = 1;
let frames: HTMLImageElement[] = [];

const nbFrames360 = computed(() => {
  return (props.nbFrames * 360) / props.angle;
});
const isBounds = computed(() => {
  return props.angle !== 360;
});

const introAnimation = ref<number>(0);
const initialOffset = computed(() => {
  return isBounds.value ? Math.round(props.nbFrames / 2) : 0;
});

const updateProgress = () => {
  emit("progressLoaded", Math.round((frames.length / props.nbFrames) * 100));
};

const loadFirstFrame = async (): Promise<HTMLImageElement> => {
  const version = Viewport.isDesktop ? "desktop" : "mobile";
  const format = await getImageFormat();

  const frame = initialOffset.value + 1 + introAnimation.value;
  currentFrame = frame - 1;

  const promise = loadImage(
    `/assets/images/${props.src}/${version}/${format}/${frame
      .toString()
      .padStart(3, "0")}.${format}`,
  );
  promise.then((image) => {
    frames[currentFrame] = image;
    updateProgress();
  });

  return promise;
};

const loadOtherFrames = async (): Promise<HTMLImageElement[]> => {
  const format = await getImageFormat();
  const version = Viewport.isDesktop ? "desktop" : "mobile";

  const promises = [];
  for (let i = 0; i < props.nbFrames; i++) {
    const frame = i + 1;
    if (currentFrame !== i) {
      const promise = loadImage(
        `/assets/images/${props.src}/${version}/${format}/${frame
          .toString()
          .padStart(3, "0")}.${format}`,
      );
      promises.push(promise);
      promise.then((image) => {
        frames[i] = image;
        updateProgress();
      });
    }
  }

  return Promise.all(promises);
};

const onResize = () => {
  if (!canvas.value) return;
  size.width = canvas.value.offsetWidth * 2;
  size.height = canvas.value.offsetHeight * 2;
  canvas.value.width = size.width;
  canvas.value.height = size.height;

  if (frames.length === 0) return;

  const first = frames[currentFrame];
  if (first) {
    const ratioCanvas = size.width / size.height;
    const ratioImage = first.width / first.height;

    if (ratioCanvas > ratioImage) {
      scale = size.height / first.height;
    } else {
      scale = size.width / first.width;
    }

    drawImage(true);
  }
};

const drawImage = (forceRepaint = false) => {
  if (size) {
    ctx.clearRect(0, 0, size.width, size.height);

    if (frames.length <= currentFrame && !forceRepaint) return;
    const image = frames[currentFrame];

    if (image) {
      const w = image.width * scale;
      const h = image.height * scale;

      ctx.drawImage(image, size.width / 2 - w / 2, size.height / 2 - h / 2, w, h);
      // } else {
      //   /// #if DEBUG
      //   console.warn('no image', { currentFrame, frames })
      //   /// #endif
    }
  }
};

let allLoaded = false;
const onTick = () => {
  if (!allLoaded) return;
  const x = props.x / PIXEL_PER_FRAME + initialOffset.value + introAnimation.value;
  let nextFrame = isBounds.value
    ? Math.floor(x)
    : Math.floor(x % nbFrames360.value) + (x < 0 ? nbFrames360.value : 0);
  if (x < 0 && Math.floor(x % nbFrames360.value) === 0) nextFrame = 0;

  // console.log('onTick', {
  //   angle: props.angle,
  //   nextFrame, x,
  //   propsX: props.x,
  //   initialOffset: initialOffset.value,
  //   introAnimation: introAnimation.value,
  //   nbFrames360: nbFrames360.value,
  //   nbFrames: props.nbFrames,
  // })
  if (isBounds.value) {
    nextFrame = Math.min(Math.max(nextFrame, 0), props.nbFrames - 1);
  }

  if (currentFrame === nextFrame) return;
  currentFrame = nextFrame;
  drawImage();
};

onMounted(() => {
  init();
});

const init = () => {
  gsap.ticker.add(onTick);
  ctx = canvas.value.getContext("2d");

  introAnimation.value = props.hasIntro
    ? isBounds.value
      ? Math.round((props.nbFrames - 1) / 2) - 1
      : Math.round(props.nbFrames / 5)
    : 0;

  loadFirstFrame().then(() => {
    document.fonts?.ready &&
      document.fonts.ready.then(() => {
        window.addEventListener("resize", onResize);
        onResize();

        firstFrameLoaded.value = true;
        emit("firstFrameLoaded");

        loadOtherFrames().then(() => {
          allLoaded = true;
          emit("allFramesLoaded");
          if (props.hasIntro) {
            gsap.timeline().to(
              introAnimation,
              {
                value: 0,
                duration: 0.7,
                ease: "power1.inOut",
              },
              pageTransition.isFirst ? 1 : 1,
            );
          }
        });
      });
  });
};

const destroy = () => {
  gsap.ticker.remove(onTick);
  window.removeEventListener("resize", onResize);
};

onUnmounted(() => {
  destroy();
});
</script>
<style lang="stylus" scoped>
.FrameSet
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  pointer-events none
  user-select none

  opacity 0

  &--Loaded
    opacity 1
</style>
