<template>
  <button
    class="HeaderMenuBtn paragraph1 prevent-select"
    :class="[
      `HeaderMenuBtn--${hud.uiTheme}`,
      hud.uiThemeLightQuote ? 'HeaderMenuBtn--quote' : '',
      `HeaderMenuBtn--${hud.menuOpen ? 'open' : 'close'}`,
      `HeaderMenuBtn--${direction}`,
    ]"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click="onClick"
    ref="$root"
  >
    <!-- NOT DISPLAYED - ONLY USED FOR CONTENT WIDTH -->
    <split-text
      class="Item Main MenuOpen debug1 hidden"
      style="opacity: 0; color: greenyellow"
      state="active"
    >
      {{ t("global.menu-open") }}
      <div class="icon-wrapper menu">
        <Menu class="icon" />
      </div>
    </split-text>

    <split-text
      ref="displayedRef"
      class="Item Secondary MenuOpen debug1"
      :class="[
        showMenuOpen ? 'hidden' : 'visible',
        displayedState,
        isTransitioning ? 'isTransitioning' : '',
      ]"
      :state="displayedState"
    >
      {{ t("global.menu-open") }}
      <div class="icon-wrapper menu">
        <Menu class="icon" />
      </div>
    </split-text>
    <split-text
      ref="displayedRefClose"
      class="Item Secondary debug1 MenuClose"
      :class="[
        displayedState,
        showMenuOpen ? 'visible' : 'hidden',
        isTransitioning ? 'isTransitioning' : '',
      ]"
      :state="displayedState"
    >
      {{ t("global.menu-close") }}
      <div class="icon-wrapper close">
        <Close class="icon" />
      </div>
    </split-text>

    <split-text
      ref="hoverRef"
      class="Item Secondary debug2 MenuOpen"
      :class="[
        hoverState,
        showMenuOpen ? 'hidden' : 'visible',
        isTransitioning ? 'isTransitioning' : '',
      ]"
      :state="hoverState"
    >
      {{ t("global.menu-open") }}
      <div class="icon-wrapper menu">
        <Menu class="icon" />
      </div>
    </split-text>
    <split-text
      ref="hoverRefClose"
      class="Item Secondary debug2 MenuClose"
      :class="[
        hoverState,
        showMenuOpen ? 'visible' : 'hidden',
        isTransitioning ? 'isTransitioning' : '',
      ]"
      :state="hoverState"
    >
      {{ t("global.menu-close") }}
      <div class="icon-wrapper close">
        <Close class="icon" />
      </div>
    </split-text>

    <split-text
      ref="nextDisplayedRef"
      class="Item Secondary debug3 MenuOpen"
      :class="[
        nextDisplayedState,
        showMenuOpen ? 'hidden' : 'visible',
        isTransitioning ? 'isTransitioning' : '',
      ]"
      :state="nextDisplayedState"
    >
      {{ t("global.menu-open") }}
      <div class="icon-wrapper menu">
        <Menu class="icon" />
      </div>
    </split-text>
    <split-text
      ref="nextDisplayedRefClose"
      class="Item Secondary debug3 MenuClose"
      :class="[
        nextDisplayedState,
        showMenuOpen ? 'visible' : 'hidden',
        isTransitioning ? 'isTransitioning' : '',
      ]"
      :state="nextDisplayedState"
    >
      {{ t("global.menu-close") }}
      <div class="icon-wrapper close">
        <Close class="icon" />
      </div>
    </split-text>
  </button>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch, ComputedRef, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import eventBus from "@/utils/EventBus";
import hud from "@/store/modules/Hud";

import SplitText, { SplitTextState } from "../Split/SplitText.vue";
import { throttle } from "lodash";

// import Close from "@/assets/icons/close.svg";
import Close from "@/assets/icons/menu-close.svg";
import Menu from "@/assets/icons/menu.svg";
import Viewport from "@/store/modules/Viewport";

const router = useRouter();

const { t } = useI18n();

const menuLink = computed(() => {
  return hud.menuOpen ? hud.closeMenuLink : "/list";
});

const $root = ref<HTMLAnchorElement | null>(null);
const isHover = ref<boolean>(false);

const displayedState = ref<SplitTextState>("active");
const hoverState = ref<SplitTextState>("before");
const nextDisplayedState = ref<SplitTextState>("after");

const displayedRef = ref<SplitText>();
const displayedRefClose = ref<SplitText>();
const hoverRef = ref<SplitText>();
const hoverRefClose = ref<SplitText>();
const nextDisplayedRef = ref<SplitText>();
const nextDisplayedRefClose = ref<SplitText>();

const isTransitioning = ref(false);
const cachedMenuOpen = ref(false);
const direction = ref("");

const showMenuOpen = computed(() => {
  if (!isTransitioning.value) {
    return hud.menuOpen;
  }
  return cachedMenuOpen.value;
});

type PositionsObj = {
  top: {
    state: ComputedRef<SplitTextState> | null;
    ref: ComputedRef<SplitText>[] | null;
  };
  bottom: {
    state: ComputedRef<SplitTextState> | null;
    ref: ComputedRef<SplitText>[] | null;
  };
  middle: {
    state: ComputedRef<SplitTextState> | null;
    ref: ComputedRef<SplitText>[] | null;
  };
};

onMounted(() => {
  eventBus.resize.on(onResize);
});

onUnmounted(() => {
  eventBus.resize.off(onResize);
});

const onResize = () => {
  displayedState.value = "active";
  hoverState.value = "before";
  nextDisplayedState.value = "after";
};

const getPositions = () => {
  const positions: PositionsObj = {
    top: null,
    bottom: null,
    middle: null,
  };

  const testPosition = (test: SplitTextState) => {
    return displayedState.value === test
      ? { ref: [displayedRef, displayedRefClose], state: displayedState }
      : hoverState.value === test
        ? { ref: [hoverRef, hoverRefClose], state: hoverState }
        : nextDisplayedState.value === test
          ? { ref: [nextDisplayedRef, nextDisplayedRefClose], state: nextDisplayedState }
          : null;
  };

  positions.top = testPosition("after");
  positions.bottom = testPosition("before");
  positions.middle = testPosition("active");
  return positions;
};

const moveTop = (positions: PositionsObj) => {
  direction.value = "up";
  positions.top.ref.forEach((ref) => {
    ref.value?.hardChangeState("before");
  });
  positions.top.state.value = "before";
  positions.bottom.state.value = "active";
  positions.middle.state.value = "after";
};

const moveBottom = (positions: PositionsObj) => {
  direction.value = "down";
  positions.top.state.value = "active";
  positions.bottom.ref.forEach((ref) => {
    ref.value?.hardChangeState("after");
  });
  positions.bottom.state.value = "after";
  positions.middle.state.value = "before";
};

watch(
  () => [hud.menuOpen, isHover.value],
  ([newMenuOpen, newIsHover], [oldMenuOpen, oldIsHover]) => {
    const hasOpenChanged = oldMenuOpen !== newMenuOpen;
    const hasHoverChanged = oldIsHover !== newIsHover;

    if ((isTransitioning.value && hasHoverChanged) || (!hasHoverChanged && !hasOpenChanged)) return;

    const positions = getPositions();

    if (Viewport.isTouch) {
      if (hasOpenChanged) {
        // console.log("Page changed");
        cachedMenuOpen.value = oldMenuOpen;
        isTransitioning.value = true;
        setTimeout(() => {
          isTransitioning.value = false;
        }, 400);
        moveTop(positions);
      }
      return;
    }

    if (newIsHover && hasOpenChanged) {
      // console.log("UPDATE STATE MENU WHILE HOVERING");
      cachedMenuOpen.value = oldMenuOpen;
      isTransitioning.value = true;
      setTimeout(() => {
        isTransitioning.value = false;
      }, 400);

      moveTop(positions);
    } else if (hasHoverChanged && !newIsHover) {
      // We just changed display
      if (nextDisplayedState.value === "active") {
        // console.log("LEAVE HOVER AFTER DISPLAY CHANGE");
        moveBottom(positions);
      } else {
        // console.log("LEAVE HOVER");
        moveBottom(positions);
      }
    } else if (newIsHover) {
      // console.log("REGULAR HOVER");
      moveTop(positions);
    } else if (hasOpenChanged) {
      // console.log("Page changed");
      cachedMenuOpen.value = oldMenuOpen;
      isTransitioning.value = true;
      setTimeout(() => {
        isTransitioning.value = false;
      }, 400);
      moveTop(positions);
    }
  },
);

const onMouseEnter = () => {
  isHover.value = true;
};
const onMouseLeave = () => {
  isHover.value = false;
};
const onClick = throttle(() => {
  router.push(menuLink.value);
}, 400);
</script>

<style lang="stylus" scoped>
// .debug1
//   color rgba(red, 0.5)
// .debug2
//   color rgba(green, 0.5)
// .debug3
//   color rgba(blue, 0.5)
// .debug4
//   color rgba(purple, 0.5)
.HeaderMenuBtn
  --menuScale: 2.5px
  position relative
  &--dark
    --bgColor: transparent
    --fontColor: rgba($black, 1)
    --borderColor: rgba(0, 0, 0, 0.3)
  &--light,
  &--quote
    --bgColor: transparent
    --fontColor: rgba($white, 1)
    --borderColor: rgba($white, 1)
  &--quote
    +desktop()
      --fontColor: rgba($black, 1)
      --borderColor: rgba(0, 0, 0, 0.3)
  +desktop()
    &:hover
      --bgColor: rgba($red, 1)
      --fontColor: rgba($white, 1)
      --borderColor: rgba($red, 1)

.HeaderMenuBtn
  position relative
  border-radius: rem(28)
  background var(--bgColor)
  border 1px solid var(--borderColor)
  color var(--fontColor)
  height fit-content
  overflow hidden
  pointer-events auto
  transition background 0.3s $easeQuartOut, border 0.3s $easeQuartOut, color 0.3s $easeQuartOut
  padding: rem(9) rem(14)
  cursor pointer

  &--open
    .Item.isTransitioning.active.MenuClose
      opacity  1
    .Item.isTransitioning.active.MenuOpen
      opacity  0

  &--close
    .Item.isTransitioning.active.MenuClose
      opacity 0
    .Item.isTransitioning.active.MenuOpen
      opacity  1

  // &--up
  //   .Item.before
  //     opacity 0

  .icon-wrapper
    overflow hidden
    display inline-block
    height 1em
    &.close
      margin-right var(--menuScale)
    &.menu
      height calc(15px + var(--menuScale) * 2)
      .icon
        padding var(--menuScale)
        width calc(15px + var(--menuScale) * 2)
        height calc(15px + var(--menuScale) * 2)
    .icon
      padding rem(1)
      width 1em
      height 1em
      fill currentColor
      vertical-align unset
.Item
  opacity 0
  transition opacity 0s $easeQuartOut
  &.visible
    opacity 1

.Main
  display flex
  gap rem(10)
.Secondary
  position absolute
  top 0
  left 0
  padding: rem(9) rem(14)
  display flex
  align-items center
  justify-content space-between
  full()
  :deep(.Char)
    padding 0.0825em 0 0 0
    line-height 0.825
  :deep(.Word)
      display flex
      line-height 0.825
      margin-left rem(1.5)
</style>
