
enum RenderPass {
  COLOR = 'color',
  DEPTH = 'depth',
  REFLECT_DEPTH = 'reflect-depth',
  OUTLINE = 'outline',
  
  DEFAULT = COLOR
}

export default RenderPass