<template>
  <div class="Incremental">
    <div class="Numbers Numbers--Current">
      <div
        v-for="(item, index) in currentList"
        :key="index"
        class="Number Number--Current"
        ref="currentNumbers"
      >
        {{ item }}
      </div>
    </div>
    <div class="Numbers Numbers--Next">
      <div
        v-for="(item, index) in nextList"
        :key="index"
        class="Number Number--Next"
        ref="nextNumbers"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, onMounted, ref, watch } from "vue";
import gsap from "gsap";

const props = defineProps({
  progress: {
    type: Number,
    required: true,
    default: 0,
  },
  forceZeroStart: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["progress", "complete"]);

const currentNumbers = ref<HTMLDivElement[]>();
const nextNumbers = ref<HTMLDivElement[]>();
const current = ref<string>("00");
const currentList = computed(() => current.value.split(""));
const next = ref<string | null>("00");
const nextList = computed(() => next.value.split(""));

let isFirst = true;
let isLast = false;
let isAnimating = false;
let last: number | null = null;

const checkNext = (forcedZero?: boolean) => {
  const progress = Math.min(99, Math.max(0, forcedZero ? 0 : props.progress));
  if (progress !== last) {
    if (!isAnimating) {
      if (isFirst) {
        current.value = progress.toString().padStart(2, "0");
        next.value = current.value;
      } else {
        current.value = next.value;
        next.value = progress.toString().padStart(2, "0");
      }
      emit("progress", props.progress);
      animateNext();
      if (isFirst) {
        isFirst = false;
      }
      last = progress;
    }
  } else if (progress === 99 && props.progress === 100 && !isLast) {
    isLast = true;
    current.value = next.value;
    animateNext();
  }
};
const animateNext = () => {
  if (!isAnimating) {
    isAnimating = true;

    const tl = gsap.timeline({
      onComplete: () => {
        isAnimating = false;
        if (!isLast) {
          checkNext();
        }
      },
    });
    if (isFirst) {
      if (currentNumbers.value) {
        tl.fromTo(
          currentNumbers.value,
          {
            yPercent: 100,
            opacity: 1,
          },
          {
            yPercent: 0,
            duration: 0.5,
            ease: "quad.inOut",
            stagger: 0.1,
          },
        );
      }
    } else if (isLast) {
      if (currentNumbers.value) {
        tl.fromTo(
          currentNumbers.value,
          {
            yPercent: 0,
          },
          {
            yPercent: -100,
            duration: 0.5,
            ease: "quad.inOut",
            stagger: 0.1,
          },
          0,
        );
      }
      if (nextNumbers.value) {
        tl.set(
          nextNumbers.value,
          {
            opacity: 0,
          },
          0,
        );
      }
      tl.add(() => emit("complete"), 0.1);
    } else {
      if (currentNumbers.value) {
        tl.fromTo(
          currentNumbers.value,
          {
            yPercent: 0,
          },
          {
            yPercent: -100,
            duration: 0.5,
            ease: "quad.inOut",
            stagger: 0.1,
          },
          0,
        );
      }
      if (nextNumbers.value) {
        tl.fromTo(
          nextNumbers.value,
          {
            yPercent: 100,
            opacity: 1,
          },
          {
            yPercent: 0,
            duration: 0.5,
            ease: "quad.inOut",
            stagger: 0.1,
          },
          0,
        );
      }
    }
  }
};

onMounted(() => {
  if (props.forceZeroStart) checkNext(true);
});

watch(
  () => props.progress,
  () => checkNext(),
);
</script>

<style lang="stylus" scoped>

.Incremental
  position relative
  font-variant-numeric tabular-nums
.Numbers
  display flex
  flex-direction row
  overflow hidden
  width 1em
  justify-content center

  .Number
    position relative
    opacity 0
    width 0.375em
    &:nth-child(1)
      text-align right
    &:nth-child(2)
      text-align left

  &--Next
    position absolute
    top 0
    left 0
    width 100%
    height 100%
</style>
