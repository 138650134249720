<template>
  <transition-page
    :fullscreen="true"
    :class="[
      'Page PageDetail',
      isHotspotOpen && state.isHotspotOpenable ? 'PageDetail--HasMore' : '',
      !state.allFrameLoaded ? 'PageDetail--Loading' : '',
    ]"
  >
    <Transition
      mode="out-in"
      @enter="onTransitionEnter"
      @leave="onFallbackLeave"
      @before-enter="onTransitionBeforeEnter"
    >
      <div class="PageDetail__Inner" :key="detailId" ref="$root">
        <back-button :class="['BackButton', showBackButton ? '' : 'hidden']" />
        <DetailDraggable />
        <DetailContent class="PageDetail__Content" @prev="handlePrev" @next="handleNext" />
      </div>
    </Transition>

    <DetailMore />
  </transition-page>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, ref, watch } from "vue";
import gsap from "gsap";

import AppService from "@/services/AppService";
import useDetails, { useInitialDetailState, PIXEL_PER_FRAME } from "@/composables/useDetails";

import DetailMore from "@/components/blocks/Detail/DetailMore.vue";
import DetailDraggable from "@/components/blocks/Detail/DetailDraggable.vue";
import DetailContent from "@/components/blocks/Detail/DetailContent.vue";
import eventBus from "@/utils/EventBus";
import pageTransition from "@/store/modules/PageTransition";
import { setHudTheme } from "@/store/modules/Hud";
import { useRouter } from "vue-router";

const router = useRouter();

const { detailId, isHotspotOpen, nbFrames, angle } = useDetails();
const { state } = useInitialDetailState();
const showBackButton = ref<boolean>(true);
const $root = ref<HTMLElement>();

const canStartEnterAnimation = ref(false);

onBeforeMount(() => {
  AppService.state.send({
    type: "SET_CONTENT_PANEL",
    permalink: detailId.value as string,
  });
});

onMounted(() => {
  eventBus.pageEnter.once(({ el, timeline }) => {
    const backBtn = el.querySelector(".BackButton");
    timeline.add(() => {
      setHudTheme("dark");
      pageTransition.isInTransition = false;
    });
    timeline.fromTo(
      backBtn,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.5,
        ease: "power1.out",
      },
    );
  });
});

const handleNext = (id: string) => handleTransition(id, true);
const handlePrev = (id: string) => handleTransition(id, false);

let isNext = false;
let hasTransitionOut = false;
let transitionEl: Element;
let doneEnter: CallableFunction;

const handleTransition = (id: string, newIsNext: boolean) => {
  hasTransitionOut = true;
  isNext = newIsNext;
  const isBounds = angle.value !== 360;
  const offset = isBounds
    ? Math.round((nbFrames.value - 1) / 2) - 1
    : Math.round(nbFrames.value / 5);

  const tl = gsap.timeline({
    onComplete: () => {
      state.backTitleIntroComplete = false;
      router.push(`/detail/${id}`);
      state.progressLoaded = 0;
    },
  });
  tl.addLabel("start", 0);
  tl.add(() => (state.isInDetailsTransition = true));
  tl.add(() => (state.showBackTitle = false));
  tl.add(() => (pageTransition.isInTransition = true));
  tl.to(
    state,
    {
      x: state.x + (isNext ? +PIXEL_PER_FRAME : -PIXEL_PER_FRAME) * offset,
      duration: 0.6,
      ease: "power1.out",
    },
    "start",
  );

  gsap.set($root.value.querySelector(".Visual"), {
    transitionDelay: "0s",
    transition: "none",
  });
  tl.to(
    $root.value.querySelector(".Visual"),
    {
      opacity: 0,
      x: `${isNext ? "-" : ""}25vw`,
      duration: 0.6,
      ease: "power1.out",
      clearProps: "transitionDelay, transition",
    },
    "start",
  );
};

const onFallbackLeave = (el: Element, done: CallableFunction) => {
  if (hasTransitionOut) {
    done();
    return;
  }
  isNext = true;
  gsap.to(el, {
    opacity: 0,
    duration: 0.5,
    ease: "power1.out",
    onStart: () => {
      state.isInDetailsTransition = true;
      state.showBackTitle = false;
      pageTransition.isInTransition = true;
    },
    onComplete: () => {
      state.progressLoaded = 0;
      done();
    },
  });
};

const onTransitionBeforeEnter = () => {
  state.allFrameLoaded = false;
  state.firstFrameLoaded = false;
};

const onTransitionEnter = (el: Element, done: CallableFunction) => {
  canStartEnterAnimation.value = true;
  transitionEl = el;
  doneEnter = done;
  gsap.set(el.querySelector(".Visual"), {
    opacity: 0,
  });
  if (state.canStartTransition) enterAnimation();
};

watch(
  () => [state.canStartTransition, canStartEnterAnimation.value],
  () => {
    if (state.canStartTransition && canStartEnterAnimation.value) {
      enterAnimation();
    }
  },
);

const enterAnimation = () => {
  const el = transitionEl;
  const done = doneEnter;
  const isBounds = angle.value !== 360;
  const offset = isBounds
    ? Math.round((nbFrames.value - 1) / 2) - 1
    : Math.round(nbFrames.value / 5);
  state.x = offset * (isNext ? -PIXEL_PER_FRAME : +PIXEL_PER_FRAME);

  gsap.set(el.querySelector(".Visual"), {
    transitionDelay: "0s",
    transition: "none",
    transitionProperty: "none",
  });

  const tl = gsap.timeline({
    onStart: () => {
      hasTransitionOut = false;
    },
    onComplete: () => {
      state.isInDetailsTransition = false;
      pageTransition.isInTransition = false;
      state.canStartTransition = false;
      done();
    },
    delay: 0.1,
  });
  tl.addLabel("start", 0);
  tl.add(() => (state.showBackTitle = true), "start");
  tl.fromTo(
    el.querySelector(".Visual"),
    { opacity: 0, x: hasTransitionOut ? `${isNext ? "" : "-"}25vw` : 0 },

    {
      opacity: 1,
      x: 0,
      duration: 0.6,
      ease: "power1.in",
      onComplete: () => {
        gsap.set(el.querySelector(".Visual"), {
          clearProps: "all",
          delay: 0.05,
        });
      },
    },
    "start",
  );
  tl.to(state, { x: 0, duration: 0.5, ease: "power1.in", delay: 0.1 }, "start");
};
</script>

<style lang="stylus" scoped>
.PageDetail
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  height 100dvh
  color rgba($black, 1)

  &--Loading
    cursor wait

  &__Inner
    width 100%
    height 100%
    display flex
    flex-direction column

    padding-top rem(56)
    padding-bottom rem(52)
    +desktop()
      padding-top rem(62)
      padding-bottom 0


    +desktop()
      :deep(.Visual),
      :deep(.Draggable__Trigger)
        transition transform 1s $easeInOut
        transition-delay 0.3s
      .PageDetail--HasMore & :deep(.Visual)
        transform translateX(-25vw)
        transition-delay 0s

      .PageDetail--HasMore & :deep(.Draggable__Trigger)
        transform translateX(-50%) translateX(-25vw)
        transition-delay 0s

.BackButton
  z-index 3
  transition transform 0.5s $easeQuartOut
  transition-delay 1s
  display flex
  top unset
  bottom rem(20)

  +desktop()
    top rem(24)
    bottom unset

  &.hidden,
  .PageDetail--HasMore &
    +desktop()
      transform translateX(calc(-50% - 1.75rem))
      transition-delay 0s

.PageDetail__Content
  transition opacity 0.5s $easeQuartOut
  transition-delay 1s

  .PageDetail--HasMore &
    opacity 0
    pointer-events none
    transition-delay 0s
</style>
