<template>
  <div class="Video" ref="$placeholder">
    <div class="Video__Player__Background" :class="{ extended: isExtended }" />
    <div class="Video__Player" :class="{ extended: isExtended, visible: isVisible }">
      <div class="Video__Player__Inner">
        <div ref="$player">
          <iframe
            class="Video__Player__Iframe"
            :src="
              hiddenKey
                ? `https://player.vimeo.com/video/${source}?h=${hiddenKey}&amp;loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=false&amp;transparent=0&amp;gesture=media&amp`
                : `https://player.vimeo.com/video/${source}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=false&amp;transparent=0&amp;gesture=media&amp`
            "
            allowfullscreen
            allowtransparency
            allow="autoplay"
          />
        </div>
        <close-button class="Video__Player__Close" @click="onReduce" />
      </div>
    </div>

    <div class="ExtendButton__Wrapper" v-if="isVisible">
      <extend-button class="ExtendButton" @click="onExtend" :isExtended="isExtended" />
    </div>

    <div class="Video__Player__Loader" v-if="isLoading" />
  </div>
</template>
<script lang="ts" setup>
import { computed, inject, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import gsap from "gsap";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

import AudioSettings from "@/store/modules/Audio";
import eventBus from "@/utils/EventBus";
import Viewport from "@/store/modules/Viewport";

const route = useRoute();
const { t } = useI18n();

const detailId = computed(() => route.params.detailId);
const hotspotId = computed(() => route.params.hotspotId);

const videoState = inject<{ extended: boolean }>("videoState");

const source = computed(() => {
  const key = `detail.${detailId.value}.${hotspotId.value}.media.source`;
  const value = t(key);
  if (key === value) return null;

  return value;
});

const hiddenKey = computed(() => {
  const key = `detail.${detailId.value}.${hotspotId.value}.media.key`;
  const value = t(key);
  if (key === value) return null;

  return value;
});

const emit = defineEmits(["load"]);

const isExtended = ref<boolean>(false);
const isVisible = ref<boolean>(false);
const isLoading = ref<boolean>(false);

watch(isExtended, (isExtended) => {
  AudioSettings.isVideoPlaying = isExtended;
});

let player: Plyr | null = null;
const $player = ref<HTMLElement | null>(null);
const $placeholder = ref<HTMLElement | null>(null);
const placeholderSize = ref({ width: 0, height: 0 });

const onResize = () => {
  placeholderSize.value.width = $placeholder.value?.offsetWidth || 0;
  placeholderSize.value.height = $placeholder.value?.offsetHeight || 0;
};

const onkeydown = (e: KeyboardEvent) => {
  if (e.key === "Escape") {
    onReduce();
  }
};

onMounted(() => {
  document.addEventListener("keydown", onkeydown);
  document.addEventListener("fullscreenchange", onFullscreenChanged);

  onResize();
  eventBus.resize.on(onResize);

  player = new Plyr($player.value, {
    controls: ["play", "progress", "mute", "volume"],
    autoplay: Viewport.isIOS ? false : true,
    invertTime: true,
    loop: { active: true },
    // fullscreen: { enabled: true, fallback: false, iosNative: false, container: null },
  });

  // player.play();
  emit("load");
  isLoading.value = true;
  player.once("playing", () => {
    isLoading.value = false;
    player.volume = 0;

    if (!Viewport.isIOS) {
      gsap.timeline().add(() => {
        isVisible.value = true;
      }, 0.7);
    }
  });
  if (Viewport.isIOS) {
    gsap.timeline().add(() => {
      isVisible.value = true;
    }, 0.7);
  }
});
onBeforeUnmount(() => {
  if (player) {
    player.destroy();
  }
  eventBus.resize.off(onResize);
  document.removeEventListener("keydown", onkeydown);
  document.addEventListener("fullscreenchange", onFullscreenChanged);
});

const onExtend = () => {
  player.volume = 1;
  player.loop = false;
  player.play();

  // if (Viewport.isMobile && !Viewport.isIOS) {
  //   player.fullscreen.enter();
  // } else {
  //   isExtended.value = true;
  // }
  isExtended.value = true;
  videoState.extended = true;
  if (Viewport.isMobile) {
    if (document.documentElement.requestFullscreen) {
      try {
        document.documentElement.requestFullscreen();
      } catch (e) {
        console.error(e);
      }
    }
  }
};
const onReduce = () => {
  isExtended.value = false;
  setTimeout(() => (videoState.extended = false), 700);

  if (document.exitFullscreen && document.fullscreenElement) {
    try {
      document.exitFullscreen();
    } catch (e) {
      console.error(e);
    }
  }
  if (player) {
    player.volume = 0;
    player.loop = true;
    player.play();
  }
};

const onFullscreenChanged = () => {
  if (!document.fullscreenElement) {
    onReduce();
  }
};

const reducedTransform = computed(() => {
  const { width, height } = placeholderSize.value;
  if (width === 0 || height === 0) return 1;

  const ratioFullscreen = 810 / 1440;
  const ratioSmall = height / width;
  const tooHigh = ratioSmall > ratioFullscreen;

  const videoHeight = width * ratioFullscreen;
  const videoWidth = height / ratioFullscreen;
  const scale = Math.round((tooHigh ? height / videoHeight : width / videoWidth) * 1000) / 1000;

  const y = Viewport.isDesktop
    ? 0
    : Math.round(
        (Viewport.windowHeight -
          height -
          ((Viewport.windowHeight - videoHeight) / 2 + (videoHeight - height) / 2)) *
          1000,
      ) / 1000;

  return `translateY(${y}px) scale(${scale})`;
});
</script>
<style lang="stylus" scoped>
$reducedTransform = v-bind(reducedTransform)

@keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.Video
  display flex
  background rgba($black, 1)
  color rgba($white, 1)
  justify-content center
  align-items center

  &__Player
    position fixed
    top 0
    left 0
    full()

    --plyr-color-main rgba($white, 1)
    --plyr-control-toggle-checked-background transparent
    --plyr-video-control-background-hover transparent

    z-index 3
    transform scale(1)
    +desktop()
      transform scale(0.5)
    transform-origin 100% 100%
    overflow hidden
    transition transform 0.7s $easeQuartOut
    opacity 0
    &.visible
      opacity 1

    &.extended
      z-index 10001
      transform scale(1)
      transition transform 0.7s $easeQuartOut

    &__Background
      position fixed
      top 0
      left 0
      full()
      background rgba($black, 1)

      opacity 0
      pointer-events none
      z-index 10001
      transition opacity 0.7s $easeQuartOut
      &.extended
        opacity 1
        pointer-events auto

    &__Inner
      position relative
      display flex
      align-items center
      justify-content center
      pointer-events none
      full()
      transform $reducedTransform
      transition transform 0.7s $easeQuartInOut

      .Video__Player:not(.extended) & :deep(.plyr__controls)
        opacity 0
        pointer-events none

      .Video__Player.extended &
        transform scale(1)
        pointer-events auto

    &__Loader
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
      width rem(64)
      height rem(64)
      border 2px solid rgba($white, 0.1)
      border-top-color rgba($white, 1)
      border-radius 50%
      animation spin 1.2s linear infinite

    :deep(.plyr--video)
      overflow visible
    :deep(.plyr--video .plyr__controls)
      left rem(16)
      width calc(100% - 2rem)
      bottom rem(32)
      padding rem(8) rem(16)
      border-radius rem(8)
      background rgba($white, 0.1)
      backdrop-filter blur(8px)
      display flex
      gap rem(16)

      +desktop()
        width 80vw
        left 10vw

    &__Iframe,
    :deep(.plyr)
      border none
      width auto
      height 100%

      @media (max-aspect-ratio: 400/225)
        width 100%
        height auto

    &__Close
      position absolute
      top rem(16)
      right rem(16)

      transition opacity 0.7s $easeQuartOut

      +desktop()
        top rem(24)
        right rem(24)

      .plyr--hide-controls + &:not(:hover)
        opacity 0

.ExtendButton
  z-index 1

  &__Wrapper
    position absolute
    z-index 3
    bottom 0
    left 0
    width 100vw
    height 73.333vw
    +desktop()
      position fixed
      left 50%
      width 50vw
      height 50vh
      height 50dvh
</style>
