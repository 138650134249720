<template>
  <component :is="is" ref="root" class="Text">
    <span class="Text__Base" v-html="text"></span>
    <span class="Text__Alt" v-html="text"></span>
  </component>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import gsap from "gsap";
import SplitText from "gsap/dist/SplitText";

import eventBus from "@/utils/EventBus";

gsap.registerPlugin(SplitText);

const props = defineProps({
  is: {
    type: String,
    default: "h2",
  },
  active: {
    type: Boolean,
    default: false,
  },
  delay: {
    type: Number,
    default: 0,
  },
  percent: {
    type: Number,
    default: 100,
  },
  text: {
    type: String,
    default: "",
  },
});

let splitTexts: SplitText[] = [];
const root = ref<HTMLElement>();
const onResize = () => {
  splitTexts.reverse().forEach((splitText) => {
    splitText.revert();
  });
  splitTexts = [];

  const splitWord = new SplitText(root.value.querySelectorAll(".Text__Base, .Text__Alt"), {
    type: "words",
    wordsClass: "Word__Wrapper",
  });
  splitTexts.push(splitWord);

  const split = new SplitText(root.value.querySelectorAll(".Text__Base, .Text__Alt"), {
    type: "words,chars",
    linesClass: "Line",
    wordsClass: "Word",
    charsClass: "Char",
  });
  splitTexts.push(split);

  gsap.set(root.value.querySelectorAll(".Text__Alt .Word"), {
    yPercent: 100,
  });
};

let timeline: gsap.core.Timeline | null = null;
watch(
  () => props.active,
  () => {
    if (props.active) {
      if (timeline) {
        timeline.kill();
      }

      timeline = gsap.timeline({ delay: props.delay });
      timeline.fromTo(
        root.value.querySelectorAll(".Text__Base .Word"),
        {
          yPercent: 0,
        },
        {
          yPercent: props.percent * -1,
          duration: 0.7,
          stagger: {
            amount: 0.3,
          },
          ease: "quart.out",
        },
        0,
      );
      timeline.fromTo(
        root.value.querySelectorAll(".Text__Alt .Word"),
        {
          yPercent: props.percent,
        },
        {
          yPercent: 0,
          duration: 0.7,
          stagger: {
            amount: 0.3,
          },
          ease: "quart.out",
        },
        0,
      );
    }
  },
);

onMounted(() => {
  onResize();
  eventBus.resize.on(onResize);
});
onUnmounted(() => {
  eventBus.resize.off(onResize);
});
</script>

<style lang="stylus" scoped>
.Text
  position relative
  &__Base
    position relative
  &__Alt
    position absolute
    top 0
    left 0
    full()

  :deep(.Word__Wrapper)
    position relative
    overflow hidden
</style>
