<template>
  <li
    class="Item"
    :class="{
      lastItem: isLast,
      active: isActive,
      init: isInit,
      disabled: !isInit,
    }"
    ref="$root"
  >
    <router-link
      :to="`detail/${item.slug}`"
      class="Item__Link"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <ListItemFigure :item="item" :isActive="isActive" />
      <div class="Label__Wrapper">
        <div class="Label__Inner">
          <split-text
            is="div"
            class="Label Label--Main font-semibold uppercase"
            :state="item.textState"
            ref="$labelMain"
          >
            {{ item.title }}
          </split-text>
          <split-text
            is="div"
            class="Label Label--Secondary font-semibold uppercase"
            :state="item.textStateSecondary"
            >{{ item.title }}</split-text
          >
        </div>
      </div>
    </router-link>
  </li>
</template>

<script lang="ts" setup>
import { PropType, inject, onMounted, ref } from "vue";
import gsap from "gsap";

import { SplitTextState } from "@/components/blocks/Split/SplitText.vue";

import { StateList } from "./ListGrid.vue";
import ListItemFigure from "./ListItemFigure.vue";
import pageTransition from "@/store/modules/PageTransition";
import Viewport from "@/store/modules/Viewport";

export type GridItem = {
  id: string;
  category: string;
  title: string;
  slug: string;
  textState: SplitTextState;
  textStateSecondary: SplitTextState;
};

const props = defineProps({
  item: {
    type: Object as PropType<GridItem>,
    required: true,
  },
  isLast: {
    type: Boolean,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: true,
  },
  inInPageTransition: {
    type: Boolean,
    required: true,
  },
});
const $root = ref<HTMLLIElement>();
const $labelMain = ref();
const state = inject<StateList>("state");
const isInit = ref<boolean>(false);

onMounted(() => {
  if (!props.inInPageTransition) {
    isInit.value = true;
  }
});

const onMouseEnter = () => {
  if (Viewport.isTouch) return;
  props.item.textState = "after";
  props.item.textStateSecondary = "active";

  state.currentItem = props.item;
  state.isHovered = true;
};

const onMouseLeave = () => {
  if (Viewport.isTouch) return;
  props.item.textState = "active";
  props.item.textStateSecondary = "before";

  state.isHovered = false;
  state.currentItem = null;
};

const playEnter = () => {
  const tl = gsap.timeline();

  tl.add(
    () => {
      if (!$root.value) return;
      const { top, left, width, height } = $root.value.getBoundingClientRect();
      const inViewport =
        top >= -width &&
        top < Viewport.windowHeight &&
        left >= -height &&
        left < Viewport.windowWidth;

      if (inViewport) {
        const subtimeline = gsap.timeline({
          onComplete: () => {
            isInit.value = true;
          },
        });
        subtimeline.to(
          $root.value,
          {
            opacity: 1,
            duration: 0.7,
            ease: "quad.inOut",
          },
          0,
        );
        subtimeline.add(() => {
          props.item.textState = "before";
          $labelMain.value?.hardChangeState("before");
        }, 0);
        subtimeline.add(() => {
          props.item.textState = "active";
        }, 0.3);
      } else {
        isInit.value = true;
      }
    },
    pageTransition.isFirst ? 2 : 2,
  );

  return tl;
};
defineExpose({
  playEnter,
});
</script>

<style lang="stylus" scoped>
$gridColor = rgba(202, 199, 190, 1)
.Item
  flex 0 0 auto
  position relative
  width auto
  aspect-ratio 1 / 1
  border 1px solid $gridColor
  margin 0 -1px 0 0
  opacity 0
  width 67.2vw

  &.disabled
    pointer-events none
  &.init
    opacity 1

  +desktop()
    margin 0 -1px -1px 0
    width auto
  &:nth-of-type(1), &:nth-of-type(2)
    +desktop()
      margin -1px -1px -1px 0

  &__Link
    full()


.Label
  display block
  // height rem(20)
  line-height rem(16)
  padding 0
  text-align center

  &__Wrapper
    position absolute
    bottom 0
    left 0
    width 100%
    padding-bottom rem(10)
    font-size rem(20)
    display flex
    align-items center
    justify-content center
    padding-right rem(20)
    padding-left rem(20)


  &__Inner
    position relative

  &--Secondary
    position absolute
    top 0
    left 0


  :deep(.Char)
    padding 0.0825em 0 0 0
    line-height 0.825
  :deep(.Word)
    display flex
    line-height 0.825
</style>
