<template>
  <component
    class="BaseLink"
    :is="is"
    :to="to"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <split-text class="BaseLink__inner" :state="textState">
      <slot />
    </split-text>
    <split-text class="BaseLink__subInner" :state="hoverTextState">
      <slot />
    </split-text>
  </component>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { SplitTextState } from "../Split/SplitText.vue";

defineProps({
  is: {
    type: String,
    required: true,
  },
  to: {
    type: String,
  },
});

const textState = ref<SplitTextState>("active");
const hoverTextState = ref<SplitTextState>("before");

const onMouseEnter = () => {
  textState.value = "after";
  hoverTextState.value = "active";
};
const onMouseLeave = () => {
  textState.value = "active";
  hoverTextState.value = "before";
};
</script>

<style lang="stylus" scoped>
.BaseLink
  overflow hidden
  display inline-block
  position relative
  user-select none

  &__subInner
    position absolute
    top 0
    left 0

  :deep(.Char)
    padding 0.0825em 0 0 0
    line-height 0.825
  :deep(.Word)
    display flex
    line-height 0.825
</style>
