<template>
  <cta-cursor :label="$t('menu.discover')" :isActive="!Viewport.isTouch && state.showCursor" />
  <Transition :css="false" mode="out-in" @enter="onEnter" @leave="onLeave">
    <div ref="$root" class="ListGrid" :key="activeCategory">
      <ul class="ListGrid__inner hideScrollbar">
        <ListItem
          v-for="(item, index) in displayedItems"
          :key="item.id"
          :data-id="item.id"
          :isLast="index === listItems.length - 1"
          :isActive="state.currentItem?.id === item.id"
          :inInPageTransition="inInPageTransition"
          :item="item"
          ref="$items"
        />
      </ul>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { computed, onMounted, provide, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { debounce, shuffle } from "lodash";
import gsap from "gsap";

import pageTransition from "@/store/modules/PageTransition";
import Viewport from "@/store/modules/Viewport";
import { HOTSPOTS, getPermalinkFromId } from "@/services/states/HotSpots";
import eventBus from "@/utils/EventBus";
import sanitizeHtml from "@/utils/sanitizeHtml";

import ListItem, { GridItem } from "./ListItem.vue";

const props = defineProps({
  activeCategory: {
    type: String,
    default: "all",
  },
});

export type StateList = {
  isHovered: boolean;
  currentItem: GridItem | null;

  showCursor: boolean;
};
const state = reactive<StateList>({
  isHovered: false,
  currentItem: null,

  showCursor: false,
});
provide<StateList>("state", state);

const { t } = useI18n();

const $root = ref<HTMLDivElement | null>(null);
const $items = ref([]);
const inInPageTransition = ref<boolean>(true);
// const format = ref("");

const listItems = ref<GridItem[]>([]);

// ----- LIST ITEMS -----
const generateList = (): GridItem[] => {
  const items: GridItem[] = [];
  HOTSPOTS.forEach((hotspot) => {
    try {
      const slug = getPermalinkFromId(hotspot.id);
      items.push({
        id: hotspot.id,
        category: t(`detail.${slug}.category`),
        // image: require(
        //   `/public/assets/images/${slug}/${version}/${format.value}/001.${format.value}`,
        // ),
        slug,
        title: sanitizeHtml(t(`detail.${slug}.title`)),

        textState: "active",
        textStateSecondary: "before",
      });
    } catch (error) {
      /// #if DEBUG
      console.warn(error);
      /// #endif
    }
  });

  return items;
};

onMounted(() => {
  listItems.value = generateList();

  eventBus.pageEnterCompleted.once(() => {
    inInPageTransition.value = false;
  });
});

watch(
  () => state.isHovered,
  () => {
    if (state.showCursor)
      debounce(() => {
        state.showCursor = state.isHovered;
      }, 50)();
    else state.showCursor = state.isHovered;
  },
);

const displayedItems = computed(() => {
  if (props.activeCategory === "all") return listItems.value;
  return listItems.value.filter((item) => item.category === props.activeCategory);
});

// ----- LIST TRANSITION -----

const getAnimationItems = (el: HTMLElement) => {
  const $ = gsap.utils.selector(el);

  const scrollerH = Viewport.isDesktop ? window.innerHeight : $(".ListGrid__inner")[0].clientHeight;
  const scrollerW = Viewport.isDesktop ? window.innerWidth : $(".ListGrid__inner")[0].clientWidth;

  const sizeToVerify = Viewport.isDesktop ? scrollerH : scrollerW;
  const boundToVerify = Viewport.isDesktop ? "top" : "left";

  const items = $(".Item");
  if (!items.length) return false;
  const itemHeight = items[0].clientHeight;
  let itemsCollection: { item: HTMLElement; bounds: DOMRect }[] = [];
  let itemsToAnimate = [];

  items.forEach((item) => {
    let bounds = item.getBoundingClientRect();
    const negativeMargin = 1;
    if (Viewport.isDesktop) {
      bounds = {
        top: bounds.top + negativeMargin,
        ...bounds,
      };
    }

    itemsCollection.push({
      item,
      bounds,
    });
  });

  for (const iterator of itemsCollection) {
    const { item } = iterator;
    const bound = iterator.bounds[boundToVerify];
    if (bound < 0 && bound + itemHeight > 0) {
      itemsToAnimate.push(item);
    } else if (bound >= 0 && bound <= sizeToVerify) {
      itemsToAnimate.push(item);
    }
  }

  return {
    itemsToAnimate,
  };
};

const playTransition = (
  el: HTMLElement,
  itemsToAnimate: HTMLElement[],
  done: CallableFunction,
  isEnter: boolean,
) => {
  const shuffledItems = shuffle(itemsToAnimate);
  const mobileScroller = document.querySelector(".ListGrid__inner");

  const tl = gsap.timeline({
    onStart: () => {
      Viewport.isDesktop
        ? gsap.set(document.documentElement, { overflow: "hidden" })
        : gsap.set(mobileScroller, { overflow: "hidden" });
    },
    onComplete: () => {
      Viewport.isDesktop
        ? gsap.set(document.documentElement, { overflow: "auto" })
        : gsap.set(mobileScroller, { overflow: "auto" });
      if (!isEnter) {
        gsap.set(el, {
          opacity: 0,
        });
        gsap.set(window, {
          scrollTo: {
            y: 0,
            x: 0,
          },
        });
      } else {
        listItems.value.map((item) => (item.textState = "active"));
      }
      done();
    },
  });
  for (let i = 0; i < shuffledItems.length; i++) {
    const item = shuffledItems[i];

    const itemId = item.dataset.id;
    const childTl = gsap.timeline();

    childTl.fromTo(
      item,
      {
        opacity: isEnter ? 0 : 1,
      },
      {
        opacity: isEnter ? 1 : 0,
        delay: (isEnter ? 0.15 : 0.05) * i,
        duration: isEnter ? 1 : 0.5,
        ease: isEnter ? "quad.inOut" : "quart.out",
      },
      0,
    );

    if (isEnter) {
      childTl.add(() => {
        listItems.value.find((it) => it.id === itemId).textState = "active";
      }, 0.5);
    }

    tl.add(childTl, 0);
    if (isEnter) tl.add(() => (pageTransition.isInTransition = false), "-=0.1");
  }
};

const onEnter = (el: Element, done: CallableFunction) => {
  // await nextTick();
  const params = getAnimationItems(el as HTMLElement);
  if (!params) {
    done();
    return;
  }

  const { itemsToAnimate } = params;
  playTransition(el as HTMLElement, itemsToAnimate, done, true);
};

const onLeave = (el: Element, done: CallableFunction) => {
  // //Force text to be in 'before' state to make it appear in the enter transition
  for (const item of listItems.value) {
    item.textState = "before";
    item.textStateSecondary = "before";
  }

  const params = getAnimationItems(el as HTMLElement);
  if (!params) {
    // Breaks without it
    setTimeout(() => {
      done();
    }, 10);
    return;
  }
  const { itemsToAnimate } = params;

  pageTransition.isInTransition = true;
  playTransition(el as HTMLElement, itemsToAnimate, done, false);
};

onMounted(() => {
  eventBus.pageEnter.once(({ timeline }) => {
    $items.value.forEach(($item, index) => {
      timeline.add(() => $item.playEnter(), index * 0.1);
    });
  });
});
</script>

<style lang="stylus" scoped>
.ListGrid
  position relative
  min-height 100%
  width 100vw
  +desktop()
    width 100%

  &__inner
    position relative
    display flex
    max-width 100%
    overflow-x auto
    padding-bottom rem(10)

    +desktop()
      padding 1px
      overflow hidden
      display grid
      grid-template-columns repeat(2, 50%)
      grid-template-rows repeat(auto-fill, 1fr)
</style>
