var fn = function( obj ){
var __t,__p='';
__p+='precision highp float;\n\n\nattribute vec2 aPosition;\nattribute vec2 aTexCoord;\n\nvarying vec2 vTexCoord;\n\n  \nvoid main( void ){\n\n  gl_Position = vec4( aPosition, 0.0, 1.0 );\n  vTexCoord = aTexCoord;\n\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
