
const _files = require.context('@/assets/webgl/', false, /.*/i)

const _map: Record<string, string> = {}

_files.keys().forEach(k => {
  const id = k.substring(2, k.length)
  const filepath = _files(k)
  // console.log(id, filepath );
  _map[id] = filepath
})


export function getAssetPath(filename: string): string {
  const res = _map[filename]
  if (!res) {
    console.error(`con't find asset ${filename}`);
  }
  return (res as any)["default"]
}
