

import Signal   from '@/webgl/lib/signal'
import Ray      from '@/webgl/math/ray'
import glmat    from 'gl-matrix'


const vec3  = glmat.vec3;
const mat4  = glmat.mat4;
const mat3  = glmat.mat3;

const M4    = mat4.create();

const V4A   = new Float32Array(4)
const V4B   = new Float32Array(4)
const ray   = new Ray()




class Pickable {


  constructor( picking ){
    this.touchPos  = new Float32Array(3);
    this.touchWPos = new Float32Array(3);
  
    this.onPicked    = new Signal();
    this.onLost      = new Signal();
    
    this.picking = picking;
  
    this.touch  = null;
    this.active = false;
  }


  _updatePos( pos ){

    this.touchWPos[0] = pos[0];
    this.touchWPos[1] = pos[1];
    this.touchWPos[2] = pos[2];

    mat4.invert( M4, this.picking.node._wmatrix );
    vec3.transformMat4( this.touchPos, this.touchWPos, M4 );

  }
  

  _picked( touch, pos ){

    if( this.touch ){
      this.onTouchEnd();
    }

    this._updatePos( pos );
    this.touch = touch;
    
    this.active = true;
    touch.onEnd.on( this.onTouchEnd );
    this.onPicked.emit()
  }


  onTouchEnd = ()=>{
    this.touch.onEnd.off( this.onTouchEnd );
    this.onLost.emit();
    this.active = false;
    this.touch = null;
  }

}





export default class Raycaster {
    
  
  constructor( scene ){


    this.onTouchAdded   = this._onTouchAdded  .bind( this );
    this.onTouchRemoved = this._onTouchRemoved.bind( this );
    
    this.scene = scene;
  
  
    this.pickables = [];
  
    this.inputs = this.scene.inputs;
  
    this.inputs.onTouchAdded  .on( this.onTouchAdded  );
    this.inputs.onTouchRemoved.on( this.onTouchRemoved );
  
  }


  registerPicking( p ){
    for (var i = 0; i < this.pickables.length; i++) {
      if( this.pickables[i].picking === p ) return this.pickables[i];
    }

    var pickable = new Pickable( p );
    this.pickables.push( pickable );
    return pickable;
  }


  _onTouchAdded( touch ){
    ray.unproject( touch.coords, this.scene.camera );
    V4B.set([0, 0, 0, 10000])
    var pickable = null;
    for (var i = 0; i < this.pickables.length; i++) {
      var res = this.pickables[i].picking.raycast( ray, V4A );
      if( res !== 0 ){
        if( V4B[3] > V4A[3] ){
          pickable = this.pickables[i];
          V4B.set( V4A );
        }
      }
    }

    if( pickable !== null ){
      pickable._picked( touch, V4B );
    }
    
  }


  _onTouchRemoved( touch ){
    //console.log( 'touch removed '+touch.id );
  }


}

