/////////////
/////////////////////////////////
//////////
import AppResources from "@/services/AppResources";
import { CreateAppStateInterpreter, AppStateInterpreter } from "@/services/states/AppStateMachine";
// import GameScene from "@webgl/GameScene";
import GLApp from "@webgl/main";
import { TypeScene } from "@webgl/scene";

export class AppServiceClass {
  private static _instance: AppServiceClass;

  glapp: GLApp;
  state: AppStateInterpreter;

  resources: AppResources;

  static getInstance() {
    if (!AppServiceClass._instance) {
      AppServiceClass._instance = new AppServiceClass();
    }
    return AppServiceClass._instance;
  }

  get Scene(): TypeScene {
    return this.glapp.scene;
  }

  constructor() {
    this.state = CreateAppStateInterpreter();

    this.resources = new AppResources();
    this.glapp = new GLApp();
  }

  start(isPlayground = false, isLandscapeParallax = false) {
    this.state.start();
    this.state.send({ type: "INIT" });

/////////////////
////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
//////////////

    this.glapp.init(isLandscapeParallax);

    AppService.glapp.glview.scene.setup();
  }
}

const AppService = AppServiceClass.getInstance();

export default AppService;
