/////////////
//////////////////////////////////////////////////////////////////////
/////////////////////////////////
//////////
import { GLContext } from "nanogl/types";
import Enum from "nanogl-pbr/Enum";
import { GammaModes } from "nanogl-pbr/GammaModeEnum";
import Input, { Uniform } from "nanogl-pbr/Input";
import { StandardPass } from "nanogl-pbr/StandardPass";
import Material from "nanogl-pbr/Material";
import MaterialElement from "nanogl-gltf/lib/elements/Material";
import RenderPass from "@/webgl/core/RenderPass";
import Node from "nanogl-node";
import LightmapRenderer, { LightmapRenderFunction } from "@/webgl/core/LightmapRenderer";
import Ibl from "nanogl-pbr/lighting/Ibl";
import RoomLightmap from "./RoomLightmap";
import IblResource from "@/webgl/assets/IblResource";
import Bounds from "nanogl-pbr/Bounds";
import Camera from "nanogl-camera";
import LightmappedSetup from "@/webgl/core/lighting/LightmappedSetup";
import Scene, { TypeScene } from "../scene";
/////////////
/////////////////////////////////////////
//////////


const EXPO = 1.57
const GAMMA = 1.04

// const EXPO = 1.0
// const GAMMA = 1.8

export default class RoomLighting {

  root: Node;

  // lightSetup: LightSetup;
  lightSetup: LightmappedSetup;
  ibl: Ibl

  gammaMode: Enum<readonly ["GAMMA_NONE", "GAMMA_STD", "GAMMA_2_2", "GAMMA_TB"]>;

  gammaInput: Input
  exposureInput: Input

  expoUniform: Uniform = null
  gammaUniform: Uniform = null


  lightmaps: RoomLightmap[];
  iblResource: IblResource;

  _exposure: number = EXPO

  lm_diffuseMix = 0.2
///////////////
////////////////////////

////////////

  public get exposure(): number {
    return this._exposure
  }
  public set exposure(value: number) {
    this._exposure = value
    if (this.expoUniform === null) {
      this.expoUniform = this.exposureInput.attachUniform('utmExpo')
    }
    this.expoUniform.set(this._exposure)
  }


  private _gamma: number = GAMMA

  public get gamma(): number {
    return this._gamma
  }
  public set gamma(value: number) {
    this._gamma = value
    if (this.gammaUniform === null) {
      this.gammaUniform = this.gammaInput.attachUniform('_u_gamma')
    }
    this.gammaUniform.set(1 / this._gamma)
  }

  // playerLight: SpotLight;

  constructor(
    readonly scene: TypeScene,
    readonly gl: GLContext,
    readonly id: string,
  ) {
    this.root = new Node();
    this.ibl = new Ibl();
    this.ibl.specularIntensity = 0.07;
    this.ibl.intensity = 1;
    this.ibl.ambiantIntensity = 0;

    this.ibl.enableRotation = true;
    this.ibl.enableBoxProjection = true;


    this.iblResource = new IblResource({
      path: `/assets/webgl/envs/White`,
      useAssetDatabase: false,
      ibl: this.ibl
    }, gl, this.scene);

    // this.lightmap = new RoomLightmap("", this.ibl, this.scene.gl, this.scene);

/////////////////
///////////////////////////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////
///////
//////
/////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////////////
////////////////////////////////////////////////////

////////////////////////////////////////////////////
//////////////////////////////////

//////////////

    this.lightmaps = [
      new RoomLightmap("LM_Ground_Part01", this, this.scene.gl, this.scene, 0.09),
      new RoomLightmap("LM_Ground_Part02", this, this.scene.gl, this.scene, 0.09),
      new RoomLightmap("LM_Ground_Part03", this, this.scene.gl, this.scene, 0.09),
      new RoomLightmap("LM_Wall_Part01", this, this.scene.gl, this.scene, 0.09),
      new RoomLightmap("LM_Wall_Part02", this, this.scene.gl, this.scene, 0.09),
      new RoomLightmap("LM_Wall_Part03", this, this.scene.gl, this.scene, 0.09),
      new RoomLightmap("LM_Display_Part01", this, this.scene.gl, this.scene, 0.07, 0),
      new RoomLightmap("LM_Display_Part02", this, this.scene.gl, this.scene, 0.07, 0),
      new RoomLightmap("LM_Display_Part03", this, this.scene.gl, this.scene, 0.07, 0),
      new RoomLightmap("LM_Props_Part01", this, this.scene.gl, this.scene, 0.09),
      new RoomLightmap("LM_Props_Part02", this, this.scene.gl, this.scene, 0.09),
      new RoomLightmap("LM_Props_Part03", this, this.scene.gl, this.scene, 0.09),
    ]

  }

  load() {
    const loadArr = this.lightmaps.map(lm => lm.load())
    loadArr.push(this.iblResource.load() as unknown as Promise<void>)
    return loadArr
  }

  onLoaded() {
    this.lightSetup = new LightmappedSetup();
    this.lightSetup.add(this.ibl);
    this.lightSetup.bounds.fromMinMax([-1, -1, -1], [1, 1, 1]);
    this.root.add(this.ibl);

    this.gammaMode = new Enum('gammaMode', GammaModes);
    this.gammaInput = new Input('gamma', 1, Input.ALL);
    this.exposureInput = new Input('exposure', 1, Input.ALL);

    this.gammaInput.attachConstant(1 / this.gamma);
    this.exposureInput.attachConstant(this.exposure);
    this.gammaMode.set('GAMMA_STD');

    this.lightSetup.stdModel.shadowFilter.set('PCF4x4');
    this.lightSetup.depthFormat.set("D_RGB");


    // this.lightSetup.stdModel.shadowFilter.set('PCF4x4')
    // this.lightSetup.stdModel.iblShadowing.enable()
    // this.lightSetup.depthFormat.set("D_RGB");

    // const depthPass = new DepthPass(this.scene.gl);
    // depthPass.setLightSetup(this.lightSetup);
  }

  updateIblBounds(bounds: Bounds) {

    this.ibl.boxProjectionSize.set([
      (bounds.max[0] - bounds.min[0]) * 1.05,
      (bounds.max[1] - bounds.min[1]) * 1.05,
      (bounds.max[2] - bounds.min[2]) * 1.05
    ]);

    this.ibl.y = this.ibl.boxProjectionSize[1] / 2;
    this.lightSetup.bounds.copyFrom(bounds);

  }

  renderLightmaps(renderFunction: LightmapRenderFunction): void {
    LightmapRenderer.render(this.gl, this.lightSetup, renderFunction)
  }

  setupMaterial(material: Material): void {
    const pass = material.getPass(RenderPass.COLOR).pass
    if (pass instanceof StandardPass) {
      this.setupStandardPass(pass)
    }
  }

  setupGltfMaterial(material: MaterialElement) {
    this.setupStandardPass(material.materialPass);
    for (const lightmap of this.lightmaps) {
      if (material.name.match(lightmap.roomId)) {
        this.setupLightmap(material.materialPass, lightmap)
      }
    }

    // if (material.name.match("LM_Ground_Part01")) {
    //   this.setupLightmap(material.materialPass, this.lightmaps.find(lm => lm.roomId.match("Ground_Part01_LM")))
    // } else if (material.name.match("LM_Ground_Part02")) {
    //   this.setupLightmap(material.materialPass, this.lightmaps.find(lm => lm.roomId.match("Ground_Part02_LM")))
    // } else if (material.name.match("LM_Ground_Part03")) {
    //   this.setupLightmap(material.materialPass, this.lightmaps.find(lm => lm.roomId.match("Ground_Part03_LM")))
    // }


    // if (material.name.match("Material.005")) {
    //   this.setupLightmap(material.materialPass, this.lightmaps.find(lm => lm.roomId.match("Display_Part02_LM")))
    // }
  }

  setupStandardPass(standardPass: StandardPass): void {
    standardPass.setLightSetup(this.lightSetup)
    standardPass.iGamma.proxy(this.gammaInput)
    standardPass.iExposure.proxy(this.exposureInput)
    standardPass.gammaMode.proxy(this.gammaMode)
  }

  setupLightmap(standardPass: StandardPass, lightmap: RoomLightmap): void {
    lightmap.setupStandardPass(standardPass)
  }

  preRender(camera: Camera): void {
  }

  dispose(): void {

  }


}