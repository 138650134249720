<template>
  <div>
    <span class="ScrollLabelDesktop legend1 prevent-select" v-if="Viewport.isDesktop">
      <span class="Text__Wrapper ScrollLabelDesktop__Inner" :class="{ hidden: !isVisible }">
        <split-text ref="$splitText1" class="Text Text--1" :state="textState1">{{
          $t("footer.scroll")
        }}</split-text>
        <split-text ref="$splitText2" class="Text Text--2" :state="textState2">{{
          $t("footer.scroll")
        }}</split-text>
      </span>
    </span>
    <div class="ScrollLabelMobile" v-else>
      <div class="ScrollLabelMobile__Bg"></div>
      <div class="ScrollLabelMobile__Inner" :class="{ hidden: !isVisible }">
        <svg
          class="ScrollLabelMobile__Icon"
          viewBox="0 0 55 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.11628 15.2324L2 8.11614L9.11628 0.999863"
            stroke="currentColor"
            stroke-width="2.37209"
          />
          <path d="M2.00082 8.11719L18.6055 8.11719" stroke="currentColor" stroke-width="2.37209" />
          <path
            d="M44.7001 15.2324L51.8164 8.11614L44.7001 0.999863"
            stroke="currentColor"
            stroke-width="2.37209"
          />
          <path d="M51.8156 8.11719L35.2109 8.11719" stroke="currentColor" stroke-width="2.37209" />
          <path
            d="M24.5362 37.7673C24.5362 35.802 22.9433 34.2092 20.9781 34.2092C19.0128 34.2092 17.4199 35.802 17.4199 37.7673V49.6278C17.4199 59.453 25.3854 67.4185 35.2106 67.4185C45.0358 67.4185 53.0013 59.453 53.0013 49.6278V40.1394C53.0013 38.1741 51.4085 36.5813 49.4432 36.5813C47.4779 36.5813 45.885 38.1741 45.885 40.1394V37.7673C45.885 35.802 44.2922 34.2092 42.3269 34.2092C40.3616 34.2092 38.7688 35.802 38.7688 37.7673V35.3952C38.7688 33.4299 37.1759 31.8371 35.2106 31.8371C33.2453 31.8371 31.6525 33.4299 31.6525 35.3952V18.7906C31.6525 16.8253 30.0596 15.2324 28.0943 15.2324C26.1291 15.2324 24.5362 16.8253 24.5362 18.7906V46.0696V37.7673Z"
            stroke="white"
            stroke-width="2.37209"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="legend1 Text__Wrapper">
          <split-text ref="$splitText1" class="Text Text--1" :state="textState1">{{
            $t("footer.scrollMobile")
          }}</split-text>
          <split-text ref="$splitText2" class="Text Text--2" :state="textState2">{{
            $t("footer.scrollMobile")
          }}</split-text>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import hud from "@/store/modules/Hud";
import Viewport from "@/store/modules/Viewport";
import { computed, onUnmounted, ref, watch } from "vue";

const isVisible = ref<boolean>(false);

const textState1 = ref("active");
const $splitText1 = ref<SplitText>();
const textState2 = ref("before");
const $splitText2 = ref<SplitText>();
const textStates = computed(() => [
  { state: textState1, ref: $splitText1 },
  { state: textState2, ref: $splitText2 },
]);
const activeStateIndex = ref(0);
let initialTimeout: number | null = null;
let stateInterval: number | null = null;
let stateResetTimeout: number | null = null;

const updateLoadingTextState = () => {
  const idx = activeStateIndex.value;
  const activeState = textStates.value[idx].state;
  const inactiveState = textStates.value[1 - idx].state;

  activeState.value = "after";
  inactiveState.value = "active";
  stateResetTimeout = setTimeout(() => {
    textStates.value[idx].ref.value?.hardChangeState("before");
  }, 700);
  activeStateIndex.value = (activeStateIndex.value + 1) % 2;

  // isFirstState.value = false;
};

watch(
  () => [hud.showLoader, hud.showFooter],
  () => {
    if (hud.showFooter && !hud.showLoader) {
      startTimer();
    }
  },
);

const startTimer = () => {
  isVisible.value = true;
  initialTimeout = setTimeout(() => {
    stateInterval = setInterval(() => {
      updateLoadingTextState();
    }, 1500);
  }, 500);
};

onUnmounted(() => {
  clearTimeout(initialTimeout);
  clearInterval(stateInterval);
  clearTimeout(stateResetTimeout);
});
</script>

<style lang="stylus" scoped>
.ScrollLabelMobile
  color rgba($white, 1)
  visibility visible
  position fixed
  bottom 0
  left 0
  right 0
  pointer-events none


  +desktop()
    display none
  &::before
    content ''
    position absolute
    z-index -1
    top 0
    left 50%
    transform translateX(-50%) translateY(10px)
    filter blur(10px)
    height 100%
    width 370%
    background radial-gradient(ellipse at 50% 100%, rgba(15,15,15,0.8) 10%, rgba(0,0,0,0) 60.7%)
  &__Inner
    display flex
    flex-direction column
    align-items center
    gap rem(11)
    padding-bottom rem(30)
    padding-top rem(50.5)

    opacity 1
    transition opacity 0.3s ease-out
    &.hidden
      opacity 0

  &__Icon
    width rem(41)
.ScrollLabelDesktop
  display none
  +desktop()
    display block

  position fixed
  visibility visible
  pointer-events none
  bottom rem(21)
  left 0
  right 0
  margin auto
  width fit-content
  color rgba($white, 1)

  &__Inner
    opacity 1
    transition opacity 0.3s ease-out
    &.hidden
      opacity 0

  &::before
    content ''
    display block
    border-radius 100%
    opacity 0.5
    // opacity 1
    background rgba($black, 1)
    filter blur(100px)
    width 424px
    height 219px
    position absolute
    top rem(-40)
    left 50%
    transform translateX(-50%)

.Text
  position relative
  &__Wrapper
    position relative
  &--2
    position absolute
    top 0
    left 0
    full()
</style>
