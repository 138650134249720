import Signal from "./Signal";
import gsap from "gsap";

export type PageEvent = {
  el: HTMLElement,
  timeline?: gsap.core.Timeline,
}

const eventBus = {
  // introCompleted: new Signal<void>(),

  pageUpdateHUD: new Signal<void>(),
  pageEnter: new Signal<PageEvent>(),
  pageEnterCompleted: new Signal<PageEvent>(),
  
  pageLeave: new Signal<PageEvent>(),
  pageLeaveCompleted: new Signal<PageEvent>(),

  // scrollStart: new Signal<void>(),
  // scrollStop: new Signal<void>(),

  resize: new Signal<void>()
}

export default eventBus