<template>
  <div class="hotspots">
    <div
      class="Hotspot__Wrapper"
      v-for="(hotspot, index) in hotspots"
      :key="index"
      :style="{
        transform: `translate(${hotspot.position[0] - 20}px, ${hotspot.position[1] - 20}px)`,
      }"
    >
      <Transition name="hotspotTransition">
        <round-button
          :to="`detail/${getPermalink(hotspot.id)}`"
          class="Hotspot"
          v-show="hotspot.visible"
          icon="eye"
          icon-label="plus"
          :label="$t('experience.discover')"
        />
      </Transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch, inject } from "vue";
import AppService from "@/services/AppService";
import { getPermalinkFromId } from "@/services/states/HotSpots";
import { AudioMethods } from "@/composables/useAudio";
import Scene from "@webgl/scene";
import AudioSettings from "@/store/modules/Audio";

const audio = inject<AudioMethods>("audioMethods");

const hotspots = computed(() => (AppService.glapp.scene as Scene).hotspotList.value);
let visibleHotspotId: string | null = null;

const getPermalink = (hotspotId: string) => getPermalinkFromId(hotspotId);

watch(
  hotspots,
  (hotspots) => {
    const visibleHotspot = hotspots.find((hotspot) => hotspot.visible);
    if (visibleHotspot && visibleHotspotId !== visibleHotspot.id) {
      visibleHotspotId = visibleHotspot.id;
      if (!["hp-04", "hp-05"].includes(visibleHotspot.id) && !AudioSettings.hasAmbientStarted) {
        AudioSettings.hasAmbientStarted = true;
        audio.startGalleryAmbient();
      }
    }
  },
  { deep: true },
);
</script>

<style lang="stylus" scoped>
.hotspotTransition-enter-active, .hotspotTransition-leave-active
  transition: all .3s $easeQuartOut
.hotspotTransition-enter-from, .hotspotTransition-leave-to
  opacity 0
  // transform scale(0.5)

.Hotspot
  position absolute
  top 0
  left 0
  transform-origin 50% 50%
  pointer-events auto
  &__Wrapper
    position relative

.hotspots
  width 100vw
  height 100vh
  height: calc(var(--vh, 1vh) * 100);
  position absolute
  top 0
  left 0
  z-index: 1
  visibility: visible
  pointer-events none
</style>
