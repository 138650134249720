export const NUM_HOTSPOTS = 3;

type HotSpotDef = {
  id: string;
  permalink: string;
};

export const getIdFromPermalink = (permalink: string) => {
  return HOTSPOTS.filter((h) => h.permalink === permalink)[0].id;
};

export const getPermalinkFromId = (id: string) => {
  return HOTSPOTS.filter((h) => h.id === id)[0].permalink;
};

export const getIndexFromId = (id: string) => {
  for (let i = 0; i < HOTSPOTS.length; i++) {
    if (HOTSPOTS[i].id === id) return i;
  }
  return -1;
};

export const HOTSPOTS: HotSpotDef[] = [
  {
    id: "hp-04",
    permalink: "martin-book",
  },
  {
    id: "hp-05",
    permalink: "guitar",
  },
  {
    id: "hp-06-01",
    permalink: "reta",
  },
  {
    id: "hp-06-02",
    permalink: "mollie",
  },
  {
    id: "hp-07-01",
    permalink: "anna",
  },
  {
    id: "hp-07-02",
    permalink: "minnie",
  },
  {
    id: "hp-08",
    permalink: "hand-fan",
  },
  {
    id: "hp-09",
    permalink: "mollie-earrings",
  },
  {
    id: "hp-10",
    permalink: "mollie-wedding-outfit",
  },
  {
    id: "hp-11",
    permalink: "ernest-wedding-outfit",
  },
  {
    id: "hp-12",
    permalink: "mollie-wedding-hat",
  },
  {
    id: "hp-13",
    permalink: "officiant-outfit",
  },
  {
    id: "hp-14",
    permalink: "mollie-baby",
  },
  {
    id: "hp-15",
    permalink: "wabonka-pin",
  },
  {
    id: "hp-16",
    permalink: "mollie-prayer",
  },
  {
    id: "hp-17",
    permalink: "ernest-stetson",
  },
  {
    id: "hp-18",
    permalink: "lilly-book",
  },
  {
    id: "hp-19",
    permalink: "hale-suit",
  },
  {
    id: "hp-20",
    permalink: "ernest-pendleton",
  },
  {
    id: "hp-21",
    permalink: "mason-paddle",
  },
  {
    id: "hp-22",
    permalink: "tom-white-stetson",
  },
  {
    id: "hp-23",
    permalink: "osage-male",
  },
  {
    id: "hp-24",
    permalink: "osage-female",
  },
  {
    id: "hp-25",
    permalink: "moccasins",
  },
  {
    id: "hp-26",
    permalink: "osage-drum",
  },
];
