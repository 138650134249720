import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";

export default class BirdChunk extends Chunk {

  constructor() {
    super(true, false);
  }

  protected _genCode(slots: ChunksSlots): void {

    slots.add('pv', /*glsl*/`
    IN vec4 aInstanceMatrix1;
    IN vec4 aInstanceMatrix2;
    IN vec4 aInstanceMatrix3;
    IN vec4 aInstanceMatrix4;
    IN float aFlap;

    mat3 rotation3dX(float angle) {
      float s = sin(angle);
      float c = cos(angle);
    
      return mat3(
        1.0, 0.0, 0.0,
        0.0, c, s,
        0.0, -s, c
      );
    }
    `)
    slots.add('vertex_warp', /*glsl*/`
    mat4 posMat = mat4(
      aInstanceMatrix1,
      aInstanceMatrix2,
      aInstanceMatrix3,
      aInstanceMatrix4 );
      float wing = aColor0.r;
      vec3 flapPosition = aPosition.xyz;
      flapPosition.z += sin(aFlap) * wing * 10.;
      vertex.position.xyz = flapPosition.xyz * rotation3dX((101. + 180.) * 3.14 / 180.);
      vertex.worldMatrix = posMat;
    `)
  }
}