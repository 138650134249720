<template>
  <image-set
    class="Image"
    :src="`more/${imageSrc}`"
    :alt="sanitizeHtml($t(`detail.${detailId}.title`))"
    :has2x="true"
    :hasWebp="true"
    :hasAvif="true"
  />
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import sanitizeHtml from "@/utils/sanitizeHtml";

const route = useRoute();
const { t } = useI18n();

const detailId = computed(() => route.params.detailId);
const hotspotId = computed(() => route.params.hotspotId);

const imageSrc = computed(() => t(`detail.${detailId.value}.${hotspotId.value}.media.source`));
</script>
<style lang="stylus" scoped>
.Image
  display block
  width 100%
  height 100%
  background rgba($white, 1)

  :deep(img)
    display block
    // width 100%
    // height 100%
    object-fit cover

    // position fixed
    width 100%
    height 73.333vw
    bottom 0
    pointer-events none
    +desktop()
      position static
      width 100%
      height 100%
</style>
