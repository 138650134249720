<template>
  <router-link
    @click="onClose"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    to="/"
    :class="['BackButton', pageTransition.isFirst ? '' : 'appear', showHeader ? '' : '']"
  >
    <svg-icon name="arrow-left" />
    <div class="BackButton__Wrapper">
      <split-text class="BackButton__inner" :state="textState">
        <span class="BackButton__Text legend1">{{ $t("detail.back") }}</span>
      </split-text>
      <split-text class="BackButton__subInner" :state="hoverTextState">
        <span class="BackButton__Text legend1">{{ $t("detail.back") }}</span>
      </split-text>
    </div>
  </router-link>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import gsap from "gsap";

import hud from "@/store/modules/Hud";
import eventBus from "@/utils/EventBus";
import pageTransition from "@/store/modules/PageTransition";
import { SplitTextState } from "../Split/SplitText.vue";

const onClose = () => {
  hud.showHomepage = false;
  hud.showHeader = true;
};

const showHeader = ref<boolean>(false);
onMounted(() => {
  eventBus.pageEnter.once(() => {
    if (pageTransition.isFirst) {
      showHeader.value = true;
    } else {
      gsap
        .timeline()
        .addLabel("content", 0.3)
        .add(() => (showHeader.value = true), "content");
    }
  });
});

const textState = ref<SplitTextState>("active");
const hoverTextState = ref<SplitTextState>("before");

const onMouseEnter = () => {
  textState.value = "after";
  hoverTextState.value = "active";
};
const onMouseLeave = () => {
  textState.value = "active";
  hoverTextState.value = "before";
};
</script>

<style lang="stylus" scoped>
.BackButton
  position fixed
  top rem(24)
  left 0
  right 0
  width fit-content
  margin auto
  pointer-events auto
  display flex
  align-items center
  gap rem(7)
  user-select none

  display none

  &::before
    content ''
    position absolute
    top rem(-24)
    left rem(-24)
    right rem(-24)
    bottom rem(-24)

  +desktop()
    display flex

  &__Wrapper
    position relative
    overflow hidden
    display inline-block

    .BackButton__Text
      display block
      padding rem(5) 0 0

    .BackButton__subInner
      position absolute
      top 0
      left 0

  .icon
    font-size rem(14)
    transition transform 0.5s $easeQuartOut
    transform translateX(0)
    margin-top 1px

    +desktop()
      margin-top 2px

  &:hover
    .icon
      transform translateX(rem(-8))

  :deep(.Char)
    padding 0.0825em 0 0 0
    line-height 0.825
  :deep(.Word)
    display flex
    line-height 0.825

.appear
  transition opacity 1s $easeQuartOut
  opacity 1

  &.hidden
    opacity 0
    pointer-events none
</style>
