<template>
  <article
    :class="[
      'Page',
      `Page--${theme}`,
      isReady ? 'Page--ready' : '',
      fullscreen ? 'Page--fullscreen' : '',
    ]"
  >
    <div class="Page__Background">
      <span class="Layer0" />
      <span class="Layer1" />
      <span class="Layer2" />
    </div>
    <div class="Page__Inner">
      <slot />
    </div>
  </article>
</template>

<script lang="ts" setup>
import { PropType, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import gsap from "gsap";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";

import eventBus from "@/utils/EventBus";
import pageTransition from "@/store/modules/PageTransition";

gsap.registerPlugin(ScrollToPlugin);

export type Theme = "light" | "dark";

const props = defineProps({
  theme: {
    type: String as PropType<Theme>,
    default: "light",
  },
  basicIn: {
    type: Boolean,
    default: false,
  },
  fullscreen: {
    type: Boolean,
    default: true,
  },
  variant: {
    type: String as PropType<"fadeIn" | "vertical">,
    default: "vertical",
  },
});

const route = useRoute();
const isReady = ref<boolean>(false);

onMounted(() => {
  /********************
   *
   * TRANSITION IN
   *
   *******************/
  eventBus.pageEnter.once(({ el, timeline }) => {
    const duration = pageTransition.isFirst ? 0 : 0.7;
    const layer1 = el.querySelector(".Page__Background .Layer1");
    const layer2 = el.querySelector(".Page__Background .Layer2");

    if (!pageTransition.isFirst) {
      timeline.to(window, { scrollTo: 0, duration: 0.7, ease: "quart.inOut" }, "background");
    }

    timeline
      .addLabel("background", 0.5)
      .add(() => eventBus.pageUpdateHUD.emit(), "background+=0.1");

    switch (props.variant) {
      case "fadeIn":
        timeline.fromTo(
          el.querySelector(".Page__Background"),
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration,
            ease: "power2.inOut",
          },
          "background",
        );
        break;
      case "vertical":
        timeline
          .fromTo(
            layer1,
            {
              yPercent: -100,
            },
            {
              yPercent: 0,
              duration,
              ease: "power1.inOut",
            },
            "background",
          )
          .fromTo(
            layer2,
            {
              yPercent: -100,
            },
            {
              yPercent: 0,
              duration,
              ease: "power4.inOut",
            },
            "background",
          )
          .fromTo(
            el.querySelector(".Page__Background .Layer0"),
            {
              opacity: 0,
            },
            {
              opacity: 1,
              duration,
              ease: "power1.out",
            },
            "background",
          );

        if (!pageTransition.isFirst) {
          timeline.fromTo(
            [...document.querySelectorAll(".root-gl, .Experience")],
            {
              yPercent: 0,
            },
            {
              yPercent: 12.5,
              clearProps: "yPercent",
              duration: 0.7,
              ease: "power1.inOut",
            },
            "background",
          );
        }
        break;
    }

    if (props.basicIn) {
      timeline.fromTo(
        el.querySelector(".Page__Inner"),
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.7,
          ease: "power2.inOut",
        },
        "background+=0.7",
      );
    }

    isReady.value = true;
  });

  /********************
   *
   * TRANSITION OUT
   *
   *******************/
  eventBus.pageEnterCompleted.once(() => {
    eventBus.pageLeave.once(({ el, timeline }) => {
      if (route.name === "Home") {
        timeline
          .addLabel("background", 0.1)
          .add(() => eventBus.pageUpdateHUD.emit(), "background+=0.6")
          .fromTo(
            el.querySelector(".Page__Background .Layer1"),
            {
              yPercent: 0,
            },
            {
              yPercent: -100,
              duration: 0.5,
              ease: "power4.out",
            },
            "background",
          )
          .fromTo(
            el.querySelector(".Page__Background .Layer2"),
            {
              yPercent: 0,
            },
            {
              yPercent: -100,
              duration: 0.5,
              ease: "power1.out",
            },
            "background",
          )
          .fromTo(
            el.querySelector(".Page__Background .Layer0"),
            {
              opacity: 1,
            },
            {
              opacity: 0,
              duration: 0.5,
              ease: "power4.in",
            },
            "background",
          )
          .fromTo(
            [...document.querySelectorAll(".root-gl, .Experience")],
            {
              yPercent: 12.5,
            },
            {
              yPercent: 0,
              clearProps: "yPercent",
              duration: 0.5,
              ease: "power1.out",
            },
            "background",
          )
          .to(
            el.querySelector(".Page__Inner"),
            {
              opacity: 0,
              duration: 0.5,
              ease: "quart.out",
            },
            0,
          );
      }
    });
  });
});
</script>

<style lang="stylus" scoped>
.Page
  position relative
  top 0
  left 0
  width 100vw
  visibility hidden
  z-index 3

  &--fullscreen
    position fixed
    height 100vh
    height 100dvh

  &--ready
    visibility visible

  &--light
    color rgba($black, 1)
  &--dark
    color rgba($white, 1)

  &__Inner
    position relative
    z-index 1
    width 100%
    .Page--fullscreen &
      height 100%


.Page__Background
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  z-index 0
  pointer-events none

  span
    position absolute
    top 0
    left 0
    width 100%
    height 100%

  .Layer0
    background rgba($black, 0.5)

  .Page--light &
    .Layer1
      background rgba($red, 1)
    .Layer2
      background rgba($grey, 1)

  .Page--dark &
    .Layer1
      background rgba($red, 1)
    .Layer2
      background rgba($black, 1)
</style>
