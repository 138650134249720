<template>
  <div class="quotes">
    <Intro :bounds="quoteTitleRect" />
    <Quote :quoteRect="quoteScorceseRect" />
    <Quote :quoteRect="quoteStandingBearRect" />
  </div>
</template>

<script lang="ts" setup>
import AppService from "@/services/AppService";
import { computed } from "vue";
import Scene from "@webgl/scene";
import Quote from "./ExperienceQuote.vue";
import Intro from "./ExperienceIntro.vue";

const quoteTitleRect = computed(() => (AppService.glapp.scene as Scene).quoteTitleRect.value);
const quoteScorceseRect = computed(() => (AppService.glapp.scene as Scene).quoteScorceseRect.value);
const quoteStandingBearRect = computed(
  () => (AppService.glapp.scene as Scene).quoteStandingBearRect.value,
);
</script>

<style lang="stylus" scoped>
@import "@/styles/typography";

.quotes
  width 100vw
  height 100vh
  height 100dvh
  position absolute
  top 0
  left 0
  z-index 1
</style>
