<template>
  <nav
    ref="root"
    class="MenuLinks"
    :class="[
      `MenuLinks--${isSmall ? 'small' : 'normal'}`,
      `MenuLinks--${isHorizontalOnMobile ? 'mobileHorizontal' : ''}`,
      `MenuLinks--${isColorVariation ? 'colorVariation' : ''}`,
      `MenuLinks--${theme}`,
    ]"
  >
    <ul class="MenuLinks__list">
      <li
        class="MenuLinks__listItem"
        v-for="item in items"
        :key="item.id"
        @mouseenter="() => onMouseEnter(item.id)"
        @mouseleave="onMouseLeave"
      >
        <router-link
          class="MenuLinks__link MenuLinks__link--primary"
          :class="[
            `MenuLinks__link--${activeItem === item.id ? 'active' : ''}`,
            isSmall ? 'h1' : 'h1--big',
          ]"
          :to="item.link"
          ><split-text
            :state="
              item.appear === true
                ? itemHoverId === item.id || activeItem === item.id
                  ? 'after'
                  : 'active'
                : itemHoverId === item.id || activeItem === item.id
                  ? 'after'
                  : 'before'
            "
            >{{ item.label }}</split-text
          ></router-link
        >
        <span
          class="MenuLinks__link MenuLinks__link--secondary"
          :class="[
            `MenuLinks__link--${activeItem === item.id ? 'active' : ''}`,
            isSmall ? 'h1' : 'h1--big',
          ]"
          ><split-text
            :state="
              item.appear === true
                ? itemHoverId === item.id || activeItem === item.id
                  ? 'active'
                  : 'before'
                : 'before'
            "
            >{{ item.label }}
          </split-text></span
        >
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { PropType, computed, ref } from "vue";

export type LinkItem = {
  id: string;
  label: string;
  link: string;

  appear: boolean;
};

const itemHoverId = ref("");
const root = ref<HTMLElement>();

const props = defineProps({
  items: {
    type: Object as PropType<LinkItem[]>,
    required: true,
  },
  activeItem: {
    type: String,
    required: true,
  },
  theme: {
    type: String as PropType<"light" | "dark">,
    default: "light",
  },
  isColorVariation: {
    type: Boolean,
    default: false,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
  alignement: {
    type: String as PropType<"start" | "end">,
    default: "end",
  },
  isHorizontalOnMobile: {
    type: Boolean,
    default: true,
  },
});

const onMouseEnter = (id: string) => {
  itemHoverId.value = id;
};

const onMouseLeave = () => {
  itemHoverId.value = "";
};

const blockAlignement = computed(() => props.alignement);

defineExpose({
  root,
});
</script>

<style lang="stylus" scoped>
@import "@/styles/typography";

$alignment = v-bind(blockAlignement)
.MenuLinks
  line-height 0.7
  height fit-content
  --color rgba($black, 1)
  --colorDisabled rgba($black, 1)
  &--colorVariation
    --color rgba($red, 1)
  &--dark
    --colorDisabled rgba($white, 1)

  color var(--colorDisabled)

  // @extend .h1
  // line-height 0.9
  // font-size rem(32)
  // +desktop()
  //   line-height 1

  +desktop()
    margin-right 0
  &--small
    line-height 0.7
    +desktop()
      line-height 1
    .h1
      font-size rem(24)
      +desktop()
        font-size rem(32)
        line-height 1
  &--normal
    +desktop()
      text-align right
  &__list
    display flex
    flex-direction column
    align-items $alignment
    gap rem(8)
    .MenuLinks--small &
      gap rem(0)
      +desktop()
        gap rem(4)
    .MenuLinks--mobileHorizontal &
      display inline-flex
      align-items start
      flex-direction row
      gap rem(16)
      padding-left var(--container-margin)
      padding-right var(--container-margin)
      +desktop()
        padding-left 0
        padding-right 0
        flex-direction column
        align-items $alignment
    .MenuLinks--mobileHorizontal.MenuLinks--small &
      gap rem(8)
      +desktop()
        gap rem(2)
    .MenuLinks--normal &
      +desktop()
        gap 0
  &__listItem
    position relative
    white-space nowrap
    // .MenuLinks--small &
    //   +desktop()
    //     margin-top rem(-12)
    // .MenuLinks--normal &
    //   +desktop()
    //     margin-top rem(-45)
  &__link
    user-select none
    opacity 0.1
    transition opacity 0.5s $easeQuartOut
    // :deep(.SplitText)
    //   display block
    //   width 110%
    &--secondary
      position absolute
      top 0
      left 0
      opacity 1
      color var(--color)
      pointer-events none

.MenuLinks
  :deep(.Char)
    padding 0.03em 0 0 0
    line-height 0.8
    +desktop()
      line-height 0.8
  :deep(.Word)
    display flex
    line-height 0.8
    +desktop()
      line-height 0.8
</style>
