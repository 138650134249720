import LightmappedModel from "./LightmappedModel"
import Chunk from 'nanogl-pbr/Chunk';
import LightSetup from 'nanogl-pbr/lighting/LightSetup';

class LightmappedSetup extends LightSetup {

  lmModel: LightmappedModel;

  constructor() {
    super();
    this.lmModel = new LightmappedModel();
    this._registerModel('lm', this.lmModel);
  }

  getChunks(modelId: string): Chunk[] {
    const res = this._modelsMap['lm'].getChunks();
    res.unshift(this.depthFormat);
    return res;
  }

}


export default LightmappedSetup;