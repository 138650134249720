import { reactive } from "@vue/runtime-core";

type Hud = {
  menuOpen: boolean;
  showHeader: boolean;
  showFooter: boolean;
  uiTheme: string;
  uiThemeLightQuote: boolean | null;
  closeMenuLink: string;
  showHomepage: boolean;
  showLoader: boolean;
  showCookies: boolean;
  showBackBtn: boolean;
  fullscreen: boolean;
  firstPageVisited?: string;
};

const hud = reactive<Hud>({
  menuOpen: false,
  showHeader: false,
  showFooter: true,
  uiTheme: "dark",
  uiThemeLightQuote: false,
  closeMenuLink: "/",
  showHomepage: true,
  showLoader: false,
  showCookies: false,
  showBackBtn: false,
  fullscreen: true,
}) as Hud;
export default hud;

export function setShowLoader(show: boolean) {
  hud.showLoader = show;
}

export function setHudTheme(theme: string) {
  hud.uiTheme = theme;
}

export function setHud(partialHud: Partial<Hud>): Hud {
  partialHud.firstPageVisited = hud.firstPageVisited || partialHud.firstPageVisited;
  Object.assign(hud, partialHud);
  return hud;
}

export function setCloseMenuLink(link: string): Hud {
  hud.closeMenuLink = link;
  return hud;
}
