<template>
  <figure ref="root" class="Visual">
    <DetailFrameSet :src="`${detailId}`" />
    <DetailHotspot
      v-for="hotspot in hotspots"
      :key="hotspot.slug"
      :hotspot="hotspot"
      :parentWidth="width"
      :parentHeight="height"
      class="Hotspot"
    />
  </figure>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from "vue";

import useDetails from "@/composables/useDetails";

import DetailHotspot from "./DetailHotspot.vue";
import DetailFrameSet from "./DetailFrameSet.vue";
import eventBus from "@/utils/EventBus";

const { detailId, hotspots } = useDetails();

const root = ref<HTMLLinkElement>();
const width = ref<number>(0);
const height = ref<number>(0);

const onResize = () => {
  if (root.value) {
    const { width: w, height: h } = root.value.getBoundingClientRect();
    width.value = w;
    height.value = h;
  }
};

onMounted(() => {
  document.fonts.ready.then(() => {
    onResize();
    eventBus.resize.on(onResize);
  });
});
onUnmounted(() => {
  eventBus.resize.off(onResize);
});
</script>

<style lang="stylus" scoped>
.Visual
  position relative
  display block
  width 100%
  height auto
  z-index 2
  pointer-events none
  // background rgba($black, 0.3)

  +desktop()
    width auto
    height 100%
    aspect-ratio 1 / 1

  :deep(img)
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    object-fit contain
    opacity 0
</style>
