

/////////////
/////////////////////////////////
//////////

import Node from 'nanogl-node';

const STABLE_FRAMES = 3;
const EPSILON = 0.0000001;


class NodeWatcher{
  node: Node;
  _isStable: boolean;
  stableFrames: number;
  lastXform: Float32Array;



  constructor( node:Node ){
    this.node = node;
    this._isStable = false;
    this.stableFrames = 0;
    this.lastXform = new Float32Array( 16 );
  }

  preRender(){

    const m  = this.node._wmatrix;
    const lm = this.lastXform;
    let equal = true;
    
    for (var i = 0; i < 16; i++) {
      if( Math.abs( m[i] - lm[i] ) > EPSILON ) {
        equal = false;
      }
    }

    lm.set( m );

    if( equal ){
      this.stableFrames++;
      
      if( this.stableFrames > STABLE_FRAMES ){
        this._isStable = true;
      }

    } else {
      this.stableFrames = 0;
      this._isStable       = false;
    }


  }

}




export default class SceneInvalidator{

  private _nodeWatchers: NodeWatcher[];
  private _invalid    : boolean;
  private _forceUpdate: boolean;

  constructor(){
    
    this._invalid = true;

    this._nodeWatchers = [];

    this._forceUpdate = false

/////////////////
////////////////////////////////////
//////////////

  }


  watchNode(node:Node){
    const nw = new NodeWatcher( node );
    this._nodeWatchers.push( nw );
  }

  invalidate(){
    this._invalid = true;
  }

  needRender(){

    let res = false;

    let watchersInvalid = false;
    for (const watcher of this._nodeWatchers ) {
      watcher.preRender();
      watchersInvalid = watchersInvalid || ( !watcher._isStable );
    }

    
    if( this._invalid || watchersInvalid ) res = true;
    
    this._invalid = false;
    
    if( this._forceUpdate ) res = true;
    
    return res;
  }

}