import { quat, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import { ICameraController } from "./ICameraController";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import CameraPath from "./CameraPath";
import Scene, { QuoteReactive } from "@webgl/scene";
import lerp from "@/utils/Lerp";
import { InputTouch } from "@webgl/lib/inputs";
import { HotSpot } from "@/services/models/HotSpotModel";
import { reactive } from "vue";
import { HOTSPOTS, getIndexFromId } from "@/services/states/HotSpots";
import Node from "nanogl-node";
import { addLoaded, addToLoad } from "@/store/modules/WebglLoading";
import Viewport from "@/store/modules/Viewport";
import { mod } from "@/utils/math";
import gsap, { Expo, Quart } from "gsap";

const V3A = vec3.create()

const START_HOTSPOT_CAM_ID = 3
const MOBILE_START_HOTSPOT_CAM_ID = 5

const MAX_SCROLL_SPEED_MOBILE = 0.0035
const MAX_SCROLL_SPEED = 0.001

const SCROLL_SPEED_MOBILE = 0.1
const SCROLL_SPEED = 0.025

export default class XpController implements ICameraController {

  mouse: vec3;
  cam: Camera<PerspectiveLens>;
  camPath: CameraPath

  orbitRadius: number;

  _loaded = false

  perc = 0
  startPerc = 0
  percLerp = 0
  dragLerp = 0

  mouseX = 0
  currmouseX = 0

  preventInteraction = false
  isDrag = false
  isWheel = false

  latestSign = 1

  firstRevolution = false

  percTw: gsap.core.Tween | null = null

  loopGalleryTw = false

  scrollTimeout: number
  scrollTimeoutDelay = 1000

  wheelDist = -1000

  currCamId = 0

  openedHotSpot: string | null = null

  xRot = 0
  yRot = 0

  maxScrollSpeed: number = MAX_SCROLL_SPEED

  scrollSpeed: number = SCROLL_SPEED


  constructor(readonly scene: Scene, pathurl: string, readonly el: HTMLCanvasElement) {
    this.camPath = new CameraPath(this.scene, pathurl)
  }

  setup(openedHotSpot: string | null) {
    if (openedHotSpot !== null) {
      this.openedHotSpot = openedHotSpot
    }

    if (this._loaded) this.setOpenedHotSpot()
  }

  async load() {
    addToLoad()
    await this.camPath.loadDatas()
    addLoaded()
    this.onLoaded()
  }

  onLoaded() {
    this.maxScrollSpeed = Viewport.isMobile ? MAX_SCROLL_SPEED_MOBILE : MAX_SCROLL_SPEED
    this.scrollSpeed = Viewport.isMobile ? SCROLL_SPEED_MOBILE : SCROLL_SPEED
    this.camPath.onLoaded()

    this.firstRevolution = false

    let count = 0

    this.camPath.camAttached = []
    this.camPath.hotSpotListOrdered = []
    this.scene.hotspotList.value = []

    for (const [index, hp] of this.camPath.hotSpotList.entries()) {
      if (hp._children.length > 0) {
        for (const hpp of hp._children) {
          this.camPath.camAttached.push(Viewport.isMobile ? count : index)
          this.camPath.hotSpotListOrdered.push(hpp)
          this.scene.hotspotList.value.push(
            reactive<HotSpot>({
              id: HOTSPOTS[count].id,
              position: [-1, -1],
              visible: false
            }))
          count++
        }
      } else {
        this.camPath.camAttached.push(Viewport.isMobile ? count : index)
        this.camPath.hotSpotListOrdered.push(hp)
        this.scene.hotspotList.value.push(
          reactive<HotSpot>({
            id: HOTSPOTS[count].id,
            position: [-1, -1],
            visible: false
          }))
        count++
      }
    }
    this._loaded = true

    this.setOpenedHotSpot()
  }

  setOpenedHotSpot() {
    if (this.openedHotSpot !== null) {
      const id = getIndexFromId(this.openedHotSpot)
      this.openedHotSpot = null


      this.currCamId = this.scene.currCamId = this.camPath.anchors[this.camPath.camAttached[id] + (Viewport.isMobile ? MOBILE_START_HOTSPOT_CAM_ID : START_HOTSPOT_CAM_ID)]

      this.percLerp = this.perc = ((this.currCamId) / (this.camPath.timeline.numFrames - 1))
    }
  }


  start(cam: Camera) {
    this.cam = cam as Camera<PerspectiveLens>;
    this.cam.invalidate()
    this.cam.updateWorldMatrix()

    this.scene.inputs.onTouchAdded.on(this.onTouchAdded)
    this.scene.inputs.onTouchRemoved.on(this.onTouchRemoved)

    document.body.addEventListener("mousewheel", this.onMouseWheel)
    window.addEventListener("DOMMouseScroll", this.onMouseWheel)
  }


  stop() {
    this.cam = null;

    this.scene.inputs.onTouchAdded.off(this.onTouchAdded)
    this.scene.inputs.onTouchRemoved.off(this.onTouchRemoved)

    document.body.removeEventListener("mousewheel", this.onMouseWheel)
    window.removeEventListener("DOMMouseScroll", this.onMouseWheel)
  }

  onMouseWheel = (e: any) => {
    if (this.preventInteraction || this.el.classList.contains('dragging') || this.loopGalleryTw) return
    if (!this.loopGalleryTw) {
      this.percTw?.kill()
      this.percTw = null
    }
    const me = e as WheelEvent
    const scroll = me.deltaY / 1200
    this.isWheel = true
    this.currmouseX = 0
    this.mouseX = Math.max(-this.maxScrollSpeed, Math.min(this.maxScrollSpeed, scroll * this.scrollSpeed))
    this.isDrag = true
    clearTimeout(this.scrollTimeout)
    this.scrollTimeout = setTimeout(this.onScrollTimeout, this.scrollTimeoutDelay)
    this.scene.hasStartedScrolling.value = true
  }

  onScrollTimeout = () => {
    if (!this.loopGalleryTw) {
      this.percTw?.kill()
      this.percTw = null
    }
    this.isWheel = false
    this.startPerc = this.perc
    this.touchRemove()
  }

  onTouchAdded = (t: InputTouch) => {
    if (this.preventInteraction || this.isWheel || this.loopGalleryTw) return
    clearTimeout(this.scrollTimeout)
    if (!this.loopGalleryTw) {
      this.percTw?.kill()
      this.percTw = null
    }
    this.isDrag = true
    this.mouseX = this.currmouseX = t.pcoords[0]
    this.dragLerp = 0
    this.perc = this.startPerc = this.percLerp
    this.el.classList.add('dragging')
    this.scene.hasStartedScrolling.value = true
  }

  onTouchRemoved = () => {
    if (!this.loopGalleryTw) {
      this.percTw?.kill()
      this.percTw = null
    }
    this.isDrag = false
    this.scrollTimeout = setTimeout(this.touchRemove, this.scrollTimeoutDelay)
  }

  touchRemove = (loopGallery = false) => {
    if (this.preventInteraction || this.loopGalleryTw) return
    if (!this.loopGalleryTw) {
      this.percTw?.kill()
      this.percTw = null
    }
    if (this.camPath.cameraList) {
      this.isDrag = false
      const p = Math.round((this.camPath.timeline.numFrames - 1) * this.percLerp)
      const closest = this.camPath.anchors.reduce((prev, curr) => {
        return (Math.abs(curr - p) < Math.abs(prev - p) ? curr : prev);
      });

      this.perc = closest / (this.camPath.timeline.numFrames - 1)


      if (loopGallery) {
        this.firstRevolution = true
        if (this.latestSign > 0) {
          this.perc = this.camPath.anchors[this.camPath.anchors.length - 1] / (this.camPath.timeline.numFrames - 1)
        } else {
          this.perc = this.camPath.anchors[this.camPath.anchors.length - 6] / (this.camPath.timeline.numFrames - 1)
        }
      }
      this.loopGalleryTw = loopGallery
      this.percTw = gsap.to(this,
        {
          percLerp: this.perc,
          duration: loopGallery ? 2 : 2.5,
          ease: loopGallery ? Expo.easeInOut : Quart.easeInOut,
          onComplete: () => {
            this.loopGalleryTw = false
            this.percTw = null
          }
        })
      this.el.classList.remove('dragging')

      this.startPerc = this.perc
    }
  }

  setCameraAt(perc: number) {
    const mat = this.camPath.getPositionAt(perc)
    this.scene.mainCameraParent.setMatrix(mat)
    this.scene.mainCameraParent.invalidate()


    if (!Viewport.isMobile) {

      quat.identity(this.cam.rotation)

      const x = -this.scene.inputs.nmouseCoords[1] * 0.025
      const y = -this.scene.inputs.nmouseCoords[0] * 0.025

      this.xRot = lerp(this.xRot, x, 0.05)
      this.yRot = lerp(this.yRot, y, 0.05)

      quat.rotateY(this.cam.rotation, this.cam.rotation, this.yRot)
      quat.rotateX(this.cam.rotation, this.cam.rotation, this.xRot)

      this.cam.invalidate()
    }

    this.scene.camera.updateWorldMatrix();
  }

  update() {
    if (this._loaded) {
      if (this.percLerp > (Viewport.isMobile ? 0.79 : 0.75) && this.percTw === null && this.percLerp < 0.99 && !this.loopGalleryTw) {
        this.touchRemove(true)
      }
      if (this.isDrag) {
        if (this.isWheel) {
          this.currmouseX = 0
          this.perc += this.mouseX
          this.mouseX = lerp(this.mouseX, this.currmouseX, 0.2)
          this.latestSign = Math.sign(this.mouseX)
        } else {
          this.currmouseX = this.scene.inputs.clientCoords[0]
          this.dragLerp = lerp(this.dragLerp, -(this.currmouseX - this.mouseX), 0.3)
          this.latestSign = Math.sign(-(this.currmouseX - this.mouseX))
          this.perc += Viewport.isMobile ? this.dragLerp * 0.0001 : this.dragLerp / this.el.clientWidth * 0.1
          this.mouseX = this.currmouseX
        }
      }
      if (!this.firstRevolution) {
        this.perc = Math.max(0, this.perc % 1)
      } else this.perc = mod(this.perc, 1)
      if (Math.abs(this.perc - this.percLerp) > 0.8) {
        const diff = this.percLerp - this.perc
        this.percLerp = lerp(this.percLerp, this.percLerp + Math.sign(diff) * (1 - Math.abs(diff)), 0.09)
        this.percLerp = mod(this.percLerp, 1)
      } else if (!this.percTw) this.percLerp = lerp(this.percLerp, this.perc, 0.09)

      this.setCameraAt(this.percLerp)

      const p = Math.round((this.camPath.timeline.numFrames - 1) * this.percLerp)
      const closest = this.camPath.anchors.reduce((prev, curr) => {
        return (Math.abs(curr - p) < Math.abs(prev - p) ? curr : prev);
      });

      this.currCamId = this.scene.currCamId = this.camPath.anchors.findIndex(x => x === closest)
    }

    if (this.scene.force) {
      this.setCameraAt(0.01)
    }
  }

  render() {
    for (const [index, hp] of this.scene.hotspotList.value.entries()) {
      const hpNode = this.camPath.hotSpotListOrdered[index]
      const camAttached = this.camPath.camAttached[index]
      if (!hpNode) continue

      const [x, y] = this.getPointScreenPos(hpNode)
      hp.position = [x, y]
      const anchor = this.camPath.anchors[this.currCamId]
      hp.visible = Math.abs(anchor - this.percLerp * (this.camPath.timeline.numFrames - 1)) < 12 && camAttached === this.currCamId - (Viewport.isMobile ? MOBILE_START_HOTSPOT_CAM_ID : START_HOTSPOT_CAM_ID)
    }

    if ((this.currCamId < (Viewport.isMobile ? 6 : 4) || this.percLerp > 0.9) || this.scene.force) {
      this.setRect(this.scene.quoteTitleRect.value, this.camPath.pointsToWatchTitle)
      this.setRect(this.scene.quoteScorceseRect.value, this.camPath.pointsToWatchScorcese)
      this.setRect(this.scene.quoteStandingBearRect.value, this.camPath.pointsToWatchStandingBear)


    } else if (this.scene.quoteScorceseRect.value.visible ||
      this.scene.quoteStandingBearRect.value.visible ||
      this.scene.quoteTitleRect.value.visible
    ) {
      this.resetQuoteRect()
    }
  }

  resetQuoteRect() {
    this.scene.quoteTitleRect.value.visible = false
    this.scene.quoteTitleRect.value.fullyOnScreen = false
    this.scene.quoteScorceseRect.value.visible = false
    this.scene.quoteScorceseRect.value.fullyOnScreen = false
    this.scene.quoteStandingBearRect.value.visible = false
    this.scene.quoteStandingBearRect.value.fullyOnScreen = false
  }

  setRect(rect: QuoteReactive, pointsToWatch: Node[]) {
    const tl = this.getPointScreenPos(pointsToWatch[0])
    const tr = this.getPointScreenPos(pointsToWatch[1])
    const br = this.getPointScreenPos(pointsToWatch[2])

    rect.rect[0] = tl[0]
    rect.rect[1] = tl[1]
    rect.rect[2] = tr[0] - tl[0]
    rect.rect[3] = br[1] - tr[1]
    if (this.scene.force) {
      rect.rect[4] = tr[0] - tl[0]
      rect.rect[5] = br[1] - tr[1]
    }
    rect.visible = (tr[0] > 0 && tl[0] < this.scene.glview.canvasWidth)
    rect.fullyOnScreen = !this.scene.force && tr[0] > 0 && tr[0] < this.scene.glview.canvasWidth && tl[0] < this.scene.glview.canvasWidth && tl[0] > 0

  }

  getPointScreenPos(p: Node) {
    vec3.set(V3A, p._wmatrix[12], p._wmatrix[13], p._wmatrix[14]);

    vec3.transformMat4(V3A, V3A, this.cam._viewProj);

    const x = ((V3A[0] + 1) / 2) * (this.scene.glview.canvasWidth);
    const y = ((-1 * V3A[1] + 1) / 2) * (this.scene.glview.canvasHeight);

    return [x, y]
  }
}