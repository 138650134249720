<template>
  <image-set class="Image" :src="`about/${imageSrc}`" :has2x="true" :hasWebp="true" />
</template>
<script lang="ts" setup>
import { onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  content: {
    type: String,
    default: "",
  },
});

const imageSrc = computed(() => props.content);
</script>
<style lang="stylus" scoped>
.Image
  display block
  width 100%
  background rgba($white, 1)
  :deep(img)
    display block
    width 100%
    height 100%
    object-fit cover
</style>
