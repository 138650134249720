import BaseMaterial, { PassInstance } from 'nanogl-pbr/Material';
import Deferred from '@/utils/Deferred';

const BATCH = 1;



function expandPasses(mats: BaseMaterial[], passes: PassInstance[]) {
  const l = mats.length;
  for (let i = 0; i < l; i++) {
    passes.push(...mats[i].getAllPasses());
  }
}



function _compileAll(mlist: PassInstance[]): Promise<PassInstance[]> {

  const defer = new Deferred<PassInstance[]>();
  const passes = mlist.concat();

  function nextCompile() {

    var nm = 0;

    while (passes.length > 0 && nm < BATCH) {
      var m = passes.pop();
      m.getProgram();
      nm++;
    }

    if (passes.length === 0) {
      defer.resolve(mlist);
    } else {
      window.requestAnimationFrame(nextCompile);
    }

  }

  nextCompile();

  return defer.promise;

}



function _initAll(mlist: PassInstance[]): Promise<PassInstance[]> {

  const defer = new Deferred<PassInstance[]>();
  const mats = mlist.concat();

  function nextCompile() {

    var nm = 0;

    while (mats.length > 0 && nm < BATCH) {
      var prg = mats.pop().getProgram();
      if (!prg.ready) {
        prg.use()
        nm++;
      }
    }



    if (mats.length === 0) {
      defer.resolve(mlist);
    } else {
      window.requestAnimationFrame(nextCompile);
    }

  }

  nextCompile();

  return defer.promise;

}


export default function compileAsync(mats: BaseMaterial[]) {

  const passes: PassInstance[] = []
  mats = mats.concat();
  expandPasses(mats, passes);

  return Promise.resolve(passes)
    .then(_compileAll)
    .then(_initAll);

}
