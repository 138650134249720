<template>
  <div class="Video__Wrapper" ref="wrapper" :data-index="index">
    <div class="Video" ref="video" :data-activeIndex="activeIndex" :data-index="index">
      <div class="Video__Cover">
        <image-set
          :class="['Video__Cover__Picture', isHover ? 'hover' : '']"
          :src="`about/cover/${imageSrc}`"
          :alt="sanitizeHtml($t(`about.video-${index}.title`))"
          :has2x="true"
          :hasWebp="true"
          :hasAvif="true"
          @load="emit('load')"
        />
        <div class="Video__Inner" :class="[isPlaying ? 'isPlaying' : '']">
          <header :class="['Video__Header', isHover ? 'hover' : '']">
            <AboutVideoSplitText
              class="Video__Title"
              is="h2"
              :text="t(`about.video-${index}.title`)"
              :active="active"
            />
            <AboutVideoSplitText
              class="Video__Subtitle"
              is="h3"
              :text="t(`about.video-${index}.subtitle`)"
              :active="active"
              :delay="0.3"
              :percent="110"
            />
          </header>
          <primary-button
            class="PlayButton"
            :isLight="true"
            :label="t(`about.video.cta`)"
            @click="onPlay"
            :freezeOnClick="false"
            @mouseenter="isHover = true"
            @mouseleave="isHover = false"
          />
        </div>

        <button class="Video__Play" @click="onPlay" />

        <button
          v-if="index < activeIndex"
          class="Video__Previous"
          @mouseenter="emit('mouseenterPrevious')"
          @mouseleave="emit('mouseleavePrevious')"
          @click="emit('clickPrevious')"
        />
        <button
          v-if="index > activeIndex"
          class="Video__Next"
          @mouseenter="emit('mouseenterNext')"
          @mouseleave="emit('mouseleaveNext')"
          @click="emit('clickNext')"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, inject, onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import sanitizeHtml from "@/utils/sanitizeHtml";

import AboutVideoSplitText from "./AboutVideoSplitText.vue";
import eventBus from "@/utils/EventBus";
import Viewport from "@/store/modules/Viewport";
import { VideoSliderState } from "./AboutVideoSlider.vue";

gsap.registerPlugin(ScrollTrigger);

const { t } = useI18n();

const props = defineProps({
  index: {
    type: Number,
    default: 0,
  },
  active: {
    type: Boolean,
    default: false,
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits([
  "load",
  "mouseenterNext",
  "mouseleaveNext",
  "clickNext",
  "mouseenterPrevious",
  "mouseleavePrevious",
  "clickPrevious",
]);

const state: VideoSliderState = inject("state");

const wrapper = ref<HTMLElement>();
const video = ref<HTMLElement>();

// const source = computed(() => {
//   const key = `about.video-${props.index}.source`;
//   const src = t(key);
//   if (key === src) return null;
//   return src;
// });
const imageSrc = computed(() => {
  const key = `about.video-${props.index}.cover`;
  const src = t(key);
  if (key === src) return "default.jpg";
  return src;
});

const isHover = ref<boolean>(false);
const onPlay = () => {
  state.activeVideoIndex = props.index;
};
const isPlaying = computed(() => state.activeVideoIndex === props.index);

let scrollTimeline: gsap.core.Timeline | null = null;
onMounted(() => {
  gsap.set(video.value, { opacity: 0 });
  eventBus.pageEnterCompleted.once(() => {
    if (!video.value || !wrapper.value) return;

    let isAnimated = true;
    if (Viewport.isDesktop) {
      const { left } = wrapper.value.getBoundingClientRect();
      isAnimated = left < Viewport.windowWidth;
    }

    if (isAnimated) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapper.value,
          start: "top bottom",
          end: "bottom top",
        },
      });

      tl.addLabel("start", props.index * 0.1);

      tl.fromTo(
        video.value,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: "quart.in",
          stagger: {
            amount: 0.3,
          },
        },
        "start",
      );
      tl.fromTo(
        video.value,
        {
          y: 60,
        },
        {
          y: 0,
          duration: 1,
          ease: "quad.inOut",
          stagger: {
            amount: 0.3,
          },
        },
        "start",
      );

      scrollTimeline = tl;
    } else {
      gsap.set(video.value, { opacity: 1 });
    }
  });
});
onUnmounted(() => {
  if (scrollTimeline) {
    scrollTimeline.scrollTrigger.kill(false);
    scrollTimeline.kill();
  }
});
</script>
<style lang="stylus" scoped>
.Video
  position relative
  // aspect-ratio 683/425
  aspect-ratio 400/225
  display flex
  background rgba($black, 1)
  color rgba($white, 1)
  justify-content center
  align-items center

  &__Pause
    position absolute
    top 0
    left 0
    full()
    z-index 0

  &__Inner
    position absolute
    top 0
    left 0
    full()
    z-index 1
    display flex
    flex-direction column
    justify-content center
    align-items center
    text-align center
    gap rem(36)

    transition opacity 1s $easeQuartOut
    &.isPlaying
      opacity 0


  &__Title
    position relative
    font-size rem(24)
    line-height 0.8
    text-transform uppercase

    +desktop()
      font-size rem(40)

    :deep(.Char)
      padding 0.0825em 0 0 0
      line-height 0.825
    :deep(.Word)
      line-height 0.825

  &__Subtitle
    opacity 0.8
    font-family var(--secondary-font)
    font-weight 400
    line-height 1.2

  &__Header
    display flex
    flex-direction column
    gap rem(5)
    +desktop()
      gap rem(12)

    opacity 1
    transition opacity 1s $easeQuartOut
    &.hover
      opacity 0.1


  &__Cover
    position absolute
    top 0
    left 0
    full()

    &__Picture
      position absolute
      top 0
      left 0
      full()
      overflow hidden

      :deep(img)
        position absolute
        top 0
        left 0
        full()
        object-fit cover
        +desktop()
          transform scale(1.1)

      &::after
        content ''
        position absolute
        top 0
        left 0
        full()
        background rgba($black, 0.6)
        opacity 1
        transition opacity 0.7s $easeQuartOut

      &.hover::after
        opacity 0

  &__Previous, &__Next
    position absolute
    top 0
    left 0
    full()
    z-index 1
    background transparent
    display none
    +desktop()
      display flex

  &__Play
    position absolute
    top 0
    left 0
    full()
    z-index 1
    background transparent
    +desktop()
      display none

.PlayButton
  display none
  z-index 1
  +desktop()
    display inline-block
</style>
