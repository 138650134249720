export function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function distance(x1: number, y1: number, x2: number, y2: number) {
  const dx = x2 - x1
  const dy = y2 - y1
  return Math.sqrt(dx * dx + dy * dy)
}

export function pointRadial(x: number, y: number) {
  return [(y = +y) * Math.cos((x -= Math.PI / 2)), y * Math.sin(x)]
}

export function mod(a: number, b: number) {
  return ((a % b) + b) % b
}

export function linearParabola(val: number) {
  return 1 - Math.abs(val * 2.0 - 1.0)
}
