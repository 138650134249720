<template>
  <div class="Layout">
    <div class="CursorBlocker" v-if="pageTransition.isInTransition" />
    <Transition name="fade">
      <Header v-if="showHeader" />
    </Transition>
    <div class="Layout__inner">
      <router-view v-slot="{ Component }">
        <Transition @enter="onEnter" @leave="onLeave" mode="in-out" name="page" appear>
          <component :is="Component" />
        </Transition>
      </router-view>
      <!-- <Transition name="slide-y" appear>
        <CookiesBanner v-if="hud.showCookies" />
      </Transition> -->
    </div>
    <Transition name="slide-y">
      <div>
        <Transition name="fade">
          <Footer v-if="!hud.showLoader && hud.showFooter" />
        </Transition>
      </div>
    </Transition>

    <RotateScreen />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted } from "vue";
import gsap from "gsap";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";

import eventBus from "@/utils/EventBus";

import AudiSettings from "@/store/modules/Audio";
import hud from "@/store/modules/Hud";
import pageTransition from "@/store/modules/PageTransition";

import Header from "@/components/blocks/Layout/Header.vue";
import Footer from "@/components/blocks/Layout/Footer.vue";
// import CookiesBanner from "@/components/blocks/Layout/CookiesBanner.vue";
import RotateScreen from "@/components/blocks/Rotate/RotateScreen.vue";

gsap.registerPlugin(ScrollToPlugin);

let enterEl: Element | null = null;
const onEnter = (el: Element, done: () => void) => {
  enterEl = el;
  document.fonts.ready.then(() => {
    const timeline = gsap.timeline({
      onStart: () => {
        pageTransition.isInTransition = true;
      },
      onComplete: () => {
        eventBus.pageUpdateHUD.emit();
        pageTransition.isFirst = false;
        pageTransition.isInTransition = false;
        done();
        eventBus.pageEnterCompleted.emit({ el: el as HTMLElement });
        // console.log("onEnter Completed", { el });
      },
    });

    if (!pageTransition.isFirst) {
      // IMMEDIATE RENDER FOR PAGEENTER ANIMATION
      gsap.set(el, {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
      });
      gsap.set(document.documentElement, { overflow: "hidden", height: "100%" });
    }

    eventBus.pageEnter.emit({ el: el as HTMLElement, timeline });
  });
};
const onLeave = (el: Element, done: () => void) => {
  // console.log("onLeave", { el, done });
  const timeline = gsap.timeline({
    onComplete: () => {
      done();
      if (enterEl) {
        gsap.set(document.documentElement, { clearProps: "overflow,height" });
        gsap.set(enterEl, { clearProps: "position,top,left,width" });
      }
      eventBus.pageLeaveCompleted.emit({ el: el as HTMLElement });
      // console.log('onLeave Completed', { el })
    },
  });
  eventBus.pageLeave.emit({ el: el as HTMLElement, timeline });
};

const showHeader = computed(() => {
  return !hud.showLoader && (!hud.showHomepage || hud.showHeader);
});

onMounted(() => {
  window.addEventListener("click", startAudio);
});
onUnmounted(() => {
  window.removeEventListener("click", startAudio);
});

const startAudio = () => {
  AudiSettings.canAudioStart = true;
};
</script>

<style lang="stylus" scoped>
.Layout
  width 100%
  min-height 100vh
.CursorBlocker
  position fixed
  top 0
  left 0
  full()
  // background rgba($red, 0.1)
  z-index $z-index-veil
</style>
