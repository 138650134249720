import { reactive } from "vue";

const AudioSettings = reactive({
  isMuted: false,
  isVideoPlaying: false,
  canAudioStart: false,
  hasAmbientStarted: false,
});

export default AudioSettings;
