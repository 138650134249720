<template>
  <div ref="root" class="root-gl" />
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import AppService from "@/services/AppService";
import { useAppState } from "@/services/Composition";

const { state } = useAppState();

const isRunning = computed(() => state.value.matches("xp.playground"));
watch(isRunning, (running) => {
  if (running) {
    AppService.glapp.glview.play();
  } else {
    AppService.glapp.glview.stop();
  }
});

const root = ref(null);

onMounted(() => {
  root.value.appendChild(AppService.glapp.glview.canvas);
  AppService.glapp.glview.scene.isPlayground = true;
  AppService.glapp.glview.scene.setup();
});
onBeforeUnmount(() => {
  root.value.removeChild(AppService.glapp.glview.canvas);
});
</script>

<style lang="stylus">

.root-gl

.glview, .root-gl
  position absolute
  top 0
  left 0
  touch-action none
  box-sizing border-box
  width 100%
  height 100vh
  height: calc(var(--vh, 1vh) * 100);


  canvas
    width 100%
    height 100vh
    height: calc(var(--vh, 1vh) * 100);
    position absolute
    top 0
    left 0

  /**
   * windowed - test canvas not fullscreen
   */
  &.windowed
    margin 200px 0
    padding 40px
    width 100%
    height 100%

    canvas
      position initial
      width 100%
      height 100%
</style>
