import "@/styles/index.styl";
import i18n from "@/core/i18n";
import GlobalComponents from "@/components/_index";
import { createApp } from "vue";
import App from "./App.vue";
import router from "@/core/Router";

const app = createApp(App);

app.use(i18n);
GlobalComponents.register(app);

app.use(router);

app.mount("#app");
