/* eslint-disable no-param-reassign */

export default function PublicPath(path: string): string {
  if (path.startsWith("/")) {
    path = path.slice(1);
  }
  return `/${path}`;
}

export function AssetsPath(path: string): string {
  return PublicPath(`assets/${path}`);
}
