import Viewport from "@/store/modules/Viewport";
import { vec3 } from "gl-matrix";
import Gltf from "nanogl-gltf/lib/Gltf";
import Node from "nanogl-node";
import { GLContext } from "nanogl/types";

const V3_A = vec3.create()
const V3_B = vec3.create()
export default class Bird {

  public root: Node

  nextPosition = vec3.create()
  direction = vec3.create()
  gotodirection = vec3.create()
  diff = vec3.create()
  diffMouse = vec3.create()
  vel = vec3.create()
  velAdd = vec3.create()
  offsetDestination = vec3.create()
  destinationLerp = vec3.create()

  directionChange: number
  closestToDestination: number
  avoidLimit: number

  flap: number
  flapSpeed: number

  isMobile: boolean

  constructor(private gl: GLContext, private birdGltf: Gltf, private index: number, private flockDestination: vec3) {

    this.root = new Node()

    this.root.setScale((0.5 + Math.random() * 0.4) * 0.0065)

    this.isMobile = Viewport.isMobile

    vec3.set(
      this.direction,
      0,
      0,
      0
    )

    vec3.set(
      this.offsetDestination,
      -1 + Math.random() * 2,
      -1 + Math.random() * 2,
      -1 + Math.random() * 2
    )

    vec3.scale(this.offsetDestination, this.offsetDestination, 0.005)

    vec3.set(
      this.velAdd,
      0.4 + Math.random() * 0.3,
      0.4 + Math.random() * 0.3,
      0.4 + Math.random() * 0.3
    )

    vec3.scale(this.velAdd, this.velAdd, (0.025 + Math.random() * 0.005) * 5)

    this.directionChange = (0.003 + Math.random() * 0.005) * 1.2
    this.closestToDestination = 0.8 + Math.random() * 0.3
    this.avoidLimit = 0.1 + Math.random() * 0.2

    this.flap = Math.PI * Math.random()
    this.flapSpeed = (0.055 + Math.random() * 0.04) * 3
  }

  startPosition(flockDestination: vec3) {
    vec3.copy(this.root.position, flockDestination)
    vec3.copy(this.nextPosition, flockDestination)
    vec3.copy(this.destinationLerp, flockDestination)
    this.root.invalidate()
    this.root.updateWorldMatrix()
  }

  preRender(destination: vec3, mousePos: vec3, finaldestination: vec3) {
    vec3.lerp(this.destinationLerp, this.destinationLerp, destination, 0.075 * this.avoidLimit)
    vec3.add(this.gotodirection, this.offsetDestination, this.destinationLerp)
    vec3.subtract(this.gotodirection, this.gotodirection, this.root.position)
    vec3.normalize(this.gotodirection, this.gotodirection)

    // Get the direction to the destination
    vec3.lerp(this.direction, this.direction, this.gotodirection, this.directionChange)

    // Velocity = direction * speed
    vec3.multiply(this.vel, this.velAdd, this.direction)

    //but avoid center when too close
    vec3.add(this.gotodirection, this.offsetDestination, this.destinationLerp)
    const d = vec3.distance(this.root.position, this.gotodirection)
    const dmult =
      1 - Math.min(d, this.closestToDestination) / this.closestToDestination
    vec3.subtract(this.diff, this.root.position, this.gotodirection)
    vec3.normalize(this.diff, this.diff)
    vec3.scale(this.diff, this.diff, dmult * this.avoidLimit * 0.1)
    vec3.add(this.vel, this.vel, this.diff)

    if (!this.isMobile) {
      const dMouse = Math.min(vec3.distance(this.root.position, mousePos), 3)
      const dMouseScale = (1 - dMouse / 3)
      vec3.subtract(V3_A, this.root.position, mousePos)
      vec3.normalize(V3_A, V3_A)
      vec3.scale(V3_A, V3_A, dMouseScale)
      vec3.lerp(this.diffMouse, this.diffMouse, V3_A, 0.01)
      vec3.add(this.vel, this.vel, this.diffMouse)

    }


    //Final velocity is added to the position
    vec3.add(this.nextPosition, this.root.position, this.vel)

    this.root.lookAt(this.nextPosition)

    vec3.copy(this.root.position, this.nextPosition)
    this.root.updateWorldMatrix()

    vec3.normalize(V3_A, this.root.position)
    vec3.normalize(V3_B, destination)

    if (vec3.dot(V3_A, V3_B) < 0.5) {
      this.startPosition(finaldestination)
    }

    this.flap += this.flapSpeed
  }
}