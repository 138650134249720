import Bounds from "nanogl-pbr/Bounds";
import SpotLight from "nanogl-pbr/lighting/SpotLight";

export default class GallerySpot extends SpotLight {

  projectionFromBounds(bounds: Bounds) {
    const lens = this._camera.lens;
    lens.near = 0.1;
    lens.far = 25.0;
    lens.fov = 0.78;
  }

}