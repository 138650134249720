// import Delay from "@/core/Delay";
// import gui from "@/webgl/dev/gui";
import RoomLighting from "@webgl/gallery/GalleryLighting";
import Scene from "@webgl/scene";
import Node from "nanogl-node";
import GltfNode from "nanogl-gltf/lib/elements/Node";
import SpotLight from "nanogl-pbr/lighting/SpotLight";
import GallerySpot from "./GallerySpot";
import gui from "@webgl/dev/gui";
import { quat, vec3 } from "gl-matrix";
import gsap, { Quart } from "gsap";

const MAX_POWER = 15

const V3A = vec3.create()
const V3B = vec3.create()

type powerType = { value: number }

export default class GalleryLights {

  tw: gsap.core.Tween
  tw1: gsap.core.Tween

  node: Node;

  spot: GallerySpot;
  spot1: GallerySpot;

  lighting: RoomLighting;

  spotFactor = 0.0;
  pointFactor = 1.0;
  lighten = 1.0;

  baseLMExpo = 0.0;
  baseLMCutOff = 0.0;
  baseLMOffset = 0.0;

  curr = 0
  power: powerType = { value: 0 }
  power1: powerType = { value: 0 }

  inTransition = false
  isSpot1 = false

  startPositions: vec3[] = []

  constructor(
    readonly scene: Scene
  ) {

    this.node = new Node();

    this.spot = new GallerySpot();

    this.spot.radius = 20;
    this.spot.outerAngle = 0.41;
    this.spot.innerAngle = 0.14;
    this.spot._color.set([0, 0, 0]);
    this.spot.castShadows = true;
    this.spot.shadowmapSize = 1024;

    this.spot1 = new GallerySpot();

    this.spot1.radius = 20;
    this.spot1.outerAngle = 0.41;
    this.spot1.innerAngle = 0.14;
    this.spot1._color.set([0, 0, 0]);
    this.spot1.castShadows = true;
    this.spot1.shadowmapSize = 1024;




/////////////////

/////////////////////////////////////

//////////////////////////////////////
//////////////////////////////
/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
/////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
//////////////////////////////////////
//////

//////////////

  }

  onLoaded() {
    this.startPositions = this.scene.spotList.map(s => vec3.clone(s.position))
    this.scene.root.add(this.spot)
    this.scene.root.add(this.spot1)
    this.isSpot1 = false
    this.alignSpot(this.scene.spotList[0])
    this.tw = gsap.to(this.power1, {
      value: MAX_POWER,
      duration: 0.5,
      ease: Quart.easeIn,
      delay: 0.5,
      onUpdate: () => this.updateSpotColor(this.spot1, this.power1)
    })

    this.isSpot1 = true
  }

  preRender() {
    if (this.inTransition || !this.spot?.getCamera()?._wmatrix) return

    this.moveMouseLight(this.spot)
    this.moveMouseLight(this.spot1)

  }

  moveMouseLight(currSpot: GallerySpot) {
    const mouse = this.scene.inputs.nmouseCoords
    vec3.set(V3A, -mouse[1], mouse[0], 1)
    vec3.normalize(V3A, V3A)
    V3A[0] *= 3
    vec3.copy(V3B, this.startPositions[Math.max(0, Math.min(this.scene.spotList.length - 1, this.curr))])
    // vec3.transformMat4(V3A, V3A, this.spot.getCamera()._wmatrix)
    vec3.transformQuat(V3A, V3A, currSpot.rotation)
    vec3.normalize(V3A, V3A)
    vec3.scale(V3A, V3A, 0.1)
    vec3.add(V3B, V3B, V3A)
    vec3.lerp(currSpot.position, currSpot.position, V3B, 0.1)
    currSpot.invalidate()
    currSpot.updateWorldMatrix()
  }

  move(lerpCam: number) {
    if (Math.round(lerpCam) !== this.curr) {
      this.curr = Math.round(lerpCam)
      if (this.tw) {
        this.tw.kill()
        this.tw = null
      }
      if (this.tw1) {
        this.tw1.kill()
        this.tw1 = null
      }
      this.inTransition = true

      const spot = this.scene.spotList[Math.max(0, Math.min(this.scene.spotList.length - 1, this.curr))]
      this.alignSpot(spot)
      const currSpot = this.isSpot1 ? this.spot1 : this.spot
      const otherSpot = this.isSpot1 ? this.spot : this.spot1
      const currPower = this.isSpot1 ? this.power1 : this.power
      const otherPower = this.isSpot1 ? this.power : this.power1
      this.tw = gsap.to(otherPower, {
        value: MAX_POWER,
        duration: 0.5,
        ease: Quart.easeIn,
        onUpdate: () => this.updateSpotColor(otherSpot, otherPower),
        onComplete: () => this.inTransition = false
      })

      this.tw1 = gsap.to(currPower, {
        value: 0,
        duration: 0.5,
        ease: Quart.easeOut,
        delay: 0.25,
        onUpdate: () => this.updateSpotColor(currSpot, currPower)
      })
      this.isSpot1 = !this.isSpot1
    }
  }

  updateSpotColor = (currSpot: GallerySpot, currPower: powerType) => {
    currSpot._color.set([currPower.value, currPower.value * 0.95, currPower.value * 0.95])
  }

  alignSpot(spot: GltfNode) {
    const currSpot = this.isSpot1 ? this.spot : this.spot1
    const l = spot._children[0] as SpotLight
    vec3.copy(currSpot.position, spot.position)
    quat.copy(currSpot.rotation, spot.rotation)
    if (currSpot.getCamera().lens) {
      currSpot.innerAngle = 0.1
      currSpot.outerAngle = l.outerAngle
      currSpot._camera.lens.fov = 0.78
    }
    currSpot.invalidate()
    currSpot.updateWorldMatrix()
    currSpot.getCamera().updateWorldMatrix()
  }

  setup(lighting: RoomLighting) {
    this.lighting = lighting;
    lighting.lightSetup.add(this.spot);
    lighting.lightSetup.add(this.spot1);

  }
}