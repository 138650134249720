var fn = function( obj ){
var __t,__p='';
__p+='\n#pragma SLOT definitions\n#pragma SLOT precision\n\n\n#if __VERSION__ == 300\n  #define IN in\n  #define OUT out\n#else\n  #define IN attribute\n  #define OUT varying\n#endif\n\n\n#pragma SLOT pv\n\nattribute vec3 aPosition;\nattribute vec3 aNormal;\n\n\nuniform mat4 uMVP;\nuniform mat4 uWorldMatrix;\nuniform mat4 uVP;\n\nOUT vec3 vWorldPos;\n\n\nvoid main(void){\n\n  #pragma SLOT v\n\n  // warp acces\n  vec3 pos = aPosition;// + .1*aNormal;\n  vec3 nrm = vec3(0.0);\n  mat4 worldMatrix = uWorldMatrix;\n  mat4 mvp         = uMVP;\n\n  #pragma SLOT vertex_warp\n\n  vec4 worldPos = worldMatrix * vec4( pos, 1.0 );\n  worldPos.xyz /= worldPos.w;\n  worldPos.w = 1.0;\n\n  #pragma SLOT vertex_warp_world\n\n  vWorldPos = worldPos.xyz;\n  gl_Position     = uVP         * worldPos;\n\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
