export type Layer = {
  name: string;
  speed: number;
  xp: number;
  yp: number;
  y: number;
};

export const LAYERS: Layer[] = [
  {
    name: "sky",
    speed: 0,
    y: -1,
    xp: 0,
    yp: 0,
  },
  {
    name: "clouds",
    speed: 1,
    y: -0.85,
    xp: 0.002,
    yp: 1 / 2,
  },
  {
    name: "mountains",
    speed: 2,
    y: 0.35,
    xp: 0,
    yp: 1 / 4,
  },
  {
    name: "grass",
    speed: 5,
    y: 0.55,
    xp: 0,
    yp: 3 / 4,
  }
];
