import { Howler, Howl } from "howler";
import { AudioId, AudioIds, AudioData } from "@/services/models/AudioModel";

export const DEFAULT_FADE_DURATION = 1500;

class AudioLib {
  _audio: Map<
    AudioId,
    {
      sound: Howl;
      isMuted: boolean;
      isPlaying: boolean;
      currentVolume: number;
      restartAtFadeIn: boolean;
    }
  >;

  _muted = false;
  _init = false;

  static _instance: AudioLib;
  static get instance() {
    if (AudioLib._instance == null) {
      AudioLib._instance = new AudioLib();
    }
    return AudioLib._instance;
  }

  constructor() {
    this._audio = new Map();

    // this.loadAudio();

    document.addEventListener("visibilitychange", this.handleVisibilityChange.bind(this));
  }

  private handleVisibilityChange() {
    this.changeFocus(document.visibilityState !== "visible");
  }

  public loadAudio() {
    if (this._init) return;

    for (const id of AudioIds) {
      this._audio.set(id as AudioId, {
        sound: new Howl({
          src: [`/audio/${id}.mp3`],
          // loop: AudioData[id]?.loop,
          loop: true,
          autoplay: true,
          volume: 0,
        }),
        restartAtFadeIn: AudioData[id]?.restartAtFadeIn ?? true,
        currentVolume: 0,
        isMuted: false,
        isPlaying: false,
      });
    }

    this._init = true;
  }

  public fadeIn(
    id: AudioId,
    opt?: { duration?: number; targetVolume?: number; onComplete?: () => void },
  ) {
    const { duration = DEFAULT_FADE_DURATION, targetVolume, onComplete } = opt || {};

    const soundInfo = this._audio.get(id);
    const audioVolume = targetVolume || AudioData[id]?.volume || 1;
    // console.log(`fade in ${id}`, {
    //   currentVol: soundInfo.currentVolume,
    //   targetVol: audioVolume,
    // });

    if (!soundInfo) return;

    if (soundInfo.restartAtFadeIn && soundInfo.currentVolume === 0) {
      soundInfo.sound.seek(0);
    }

    soundInfo.sound.fade(soundInfo.currentVolume, audioVolume, duration).once("fade", () => {
      onComplete?.();
      soundInfo.currentVolume = audioVolume;
    });

    soundInfo.isMuted = false;
    soundInfo.currentVolume = audioVolume;
    // console.log(`fade in ${id} complete`, { currentVol: soundInfo.currentVolume });
  }

  public fadeOut(
    id: AudioId,
    opt?: { duration?: number; targetVolume?: number; onComplete?: () => void },
  ) {
    const { duration = DEFAULT_FADE_DURATION, targetVolume = 0, onComplete } = opt || {};

    const soundInfo = this._audio.get(id);
    // console.log(`fade out ${id}`, {
    //   currentVol: soundInfo.currentVolume,
    //   targetVol: targetVolume,
    // });
    if (!soundInfo) return;
    soundInfo.sound.fade(soundInfo.currentVolume, targetVolume, duration).once("fade", () => {
      soundInfo.currentVolume = targetVolume;
      // console.log(`fade out ${id} complete`, { currentVol: soundInfo.currentVolume });
      onComplete?.();
    });
  }

  public setMuted(isMuted: boolean) {
    this._muted = isMuted;
    Howler.mute(isMuted);
  }

  private changeFocus(isHidden: boolean) {
    if (!this._muted) {
      Howler.mute(isHidden);
    }
  }
}

export default AudioLib;
