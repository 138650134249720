import Scene from "@/webgl/scene";

import Camera from "nanogl-camera";
import Masks from "@/webgl/gl/masks";
import GLConfig from "nanogl-state/GLConfig";
import { Passes } from "@/webgl/glsl/Passes";
import GallerySection from "./GallerySection";
import GalleryLighting from "./GalleryLighting";
import Lights from "./Lights";
import GLTFResource from "@webgl/assets/GLTFResource";
import Gltf from "nanogl-gltf/lib/Gltf";
import { RenderContext } from "@webgl/core/LightmapRenderer";
import MaterialOverrideExtension from "nanogl-gltf/lib/extensions/MaterialOverrideExtension";
import { addLoaded, addToLoad } from "@/store/modules/WebglLoading";
import Viewport from "@/store/modules/Viewport";
import Birds from "./birds/Birds";
import SlideTexture from "@webgl/glsl/SlideTexture/SlideTexture";
import { getAssetPath } from "./Assets";
import Ray from "@webgl/math/ray";
import { vec3 } from "gl-matrix";
import { StandardPass } from "nanogl-pbr/StandardPass";

/////////////
/////////////////////////////////
//////////

export const NUM_SECTIONS = 3

const SECTIONS: string[] = ["Part01", "Part02", "Part03"]

const V3_A = vec3.create()

export default class Gallery {

  scene: Scene;

  sections: GallerySection[]

  lighting: GalleryLighting;
  lights: Lights

  gltfResource: GLTFResource
  gltflandscape: Gltf

  birdGltfResource: GLTFResource
  birdGltf: Gltf

  slideTex: SlideTexture

  birds: Birds
  birds2: Birds

  ray: Ray

  // blendColor: BlendColor


  loaded = false

  cameraOffset = 0

  constructor(scene: Scene) {

    this.scene = scene;

    this.ray = new Ray()

    this.cameraOffset = Viewport.isMobile ? 3 : 0

    this.slideTex = new SlideTexture(this.scene.gl)



    const overrides = new MaterialOverrideExtension();

    overrides.overridePass('Mat.7', (ctx, material) => {
      material.getPass('color').pass.inputs.add(this.slideTex);
      return null
    })



    this.gltfResource = new GLTFResource(
      `Background${Viewport.isMobile ? "_mobile" : ""}.glb`,
      this.scene,
      {
        extensions: [overrides],
      }
    );

    this.birdGltfResource = new GLTFResource(
      "bird2.glb",
      this.scene
    );


    this.lights = new Lights(this.scene)
    this.lighting = new GalleryLighting(scene, scene.gl, "lighting");

    this.sections = []
    for (let i = 0; i < NUM_SECTIONS; i++) {
      this.sections.push(new GallerySection(this, i + 1, `${SECTIONS[i]}.gltf`))
    }
  }

  load() {
    const all = this.sections.map(s => s.load())
    all.concat(this.lighting.load())
    all.push(this.slideTex.load(getAssetPath(`clouds_8096${Viewport.isMobile ? "_mobile" : ""}.ktx2`), this.scene.gl))


    addToLoad()
    all.concat(this.gltfResource.load().then(() => addLoaded()) as unknown as Promise<void>)
    addToLoad()
    all.concat(this.birdGltfResource.load().then(() => addLoaded()) as unknown as Promise<void>)

    return Promise.all(all)
  }

  onLoaded() {
    this.gltflandscape = this.gltfResource.value

    this.birdGltf = this.birdGltfResource.value

    this.birds = new Birds(this.birdGltf, this.scene)
    this.birds2 = new Birds(this.birdGltf, this.scene)


    this.scene.root.add(this.gltflandscape.root)
    this.gltflandscape.root.invalidate()
    this.gltflandscape.root.updateWorldMatrix()
    this.lighting.onLoaded()
    this.lights.setup(this.lighting)
    for (const section of this.sections) {
      section.onLoaded()
    }
    this.lights.onLoaded()
    this.loaded = true
  }


  preRender(lerpCam: number, dt = 0) {

    if (!this.loaded) return

    this.lighting.preRender(this.scene.camera);
    this.lights.move(lerpCam)
    this.lights.preRender()

    this.slideTex.preRender()

    this.ray.unproject(this.scene.inputs.nmouseCoords, this.scene.camera);


    this.scene.camera.updateWorldMatrix()

    if (!this.scene.force) {
      this.birds?.preRender(this.ray.dir)
      this.birds2?.preRender(this.ray.dir)
    }

    for (const section of this.sections) {
      section.preRender()
    }
  }


  rttPass(lerpCam: number) {
    this.lighting.lightSetup.prepare(this.scene.gl);
    this.lighting.renderLightmaps((ctx: RenderContext) => {
      // this.render(ctx.camera, ctx.mask as unknown as Masks, ctx.pass as unknown as Passes, lerpCam)
      this.render(ctx.camera, Masks.SHADOW_CASTER, Passes.DEPTH, lerpCam)
    })
  }


  render(camera: Camera, mask: Masks, passId: Passes = Passes.DEFAULT, currLerpCam?: number, cfg?: GLConfig, force = false) {

    if (!this.loaded) return

    if (passId !== Passes.REFLECT_DEPTH && passId !== Passes.DEPTH) {
      for (const renderable of this.gltflandscape.renderables) {

        renderable.render(this.scene.gl, camera, mask, passId, cfg)
      }

      this.birds?.render(camera, mask, passId, cfg)
      this.birds2?.render(camera, mask, passId, cfg)
    }

    const blockRender3 = currLerpCam < 15 + this.cameraOffset && currLerpCam > 0
    const blockRender2 = currLerpCam < 8 + this.cameraOffset || currLerpCam > 17 + this.cameraOffset
    const blockRender1 = currLerpCam > 10 + this.cameraOffset && currLerpCam < 24
    for (const [index, section] of this.sections.entries()) {
      if (!force) {
        if (blockRender1 && index === 0) continue
        if (blockRender2 && index === 1) continue
        if (blockRender3 && index === 2) continue
      }
      section.render(camera, mask, passId, cfg)
    }

  }

  postRender() { }
}