<template>
  <div class="Gallery" ref="$root">
    <cursor :label="cursorLabel" :isActive="isHovering" theme="light" />
    <div class="Gallery__overlay">
      <div
        class="Gallery__zone Back"
        :class="{
          Disabled: currentItemIdx === 0,
        }"
        @mouseenter="() => onZoneEnter('back')"
        @mouseleave="() => onZoneLeave()"
        @click="() => (currentItemIdx += 1)"
      />
      <div
        class="Gallery__zone Next"
        :class="{
          Disabled: currentItemIdx === -sources.length + 1,
        }"
        @mouseenter="() => onZoneEnter('next')"
        @mouseleave="() => onZoneLeave()"
        @click="() => (currentItemIdx -= 1)"
      />
      <ul class="Gallery__dots">
        <li
          v-for="(source, index) in sources"
          :key="`dot-${index}`"
          class="Gallery__dot"
          :class="{
            'Gallery__dot--active': index * -1 === currentItemIdx,
          }"
          @click="() => (currentItemIdx = index * -1)"
        />
      </ul>
    </div>
    <div class="Gallery__wrapper" ref="$sliderContainer">
      <image-set
        v-for="(source, index) in sources"
        :key="source"
        :src="`${detailId}/${source}`"
        :alt="sanitizeHtml($t(`detail.${detailId}.title`))"
        :has2x="true"
        :hasWebp="true"
        :class="['Gallery__image', 'prevent-select', `Gallery__image--${index}`]"
        @load="index === 0 && emit('load')"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import gsap from "gsap";
import Draggable from "gsap/dist/Draggable";
import InertiaPlugin from "gsap/dist/InertiaPlugin";
import eventBus from "@/utils/EventBus";
import sanitizeHtml from "@/utils/sanitizeHtml";
import Viewport from "@/store/modules/Viewport";

gsap.registerPlugin(Draggable, InertiaPlugin);
const SNAP_THRESHOLD = 0.15;

const route = useRoute();
const { t } = useI18n();

const draggable = ref<Draggable | null>(null);
const $root = ref<HTMLDivElement | null>(null);
const $sliderContainer = ref<HTMLDivElement | null>(null);
const isHovering = ref<boolean>(false);
const cursorLabel = ref("");
const isDragging = ref(false);
const hasSnapped = ref(false);
// Between 0 and - sources.length
const currentItemIdx = ref(0);

const detailId = computed(() => route.params.detailId);
const hotspotId = computed(() => route.params.hotspotId);

const sources = computed(() =>
  t(`detail.${detailId.value}.${hotspotId.value}.media.source`)
    .split(",")
    .map((slug: string) => slug.trim()),
);

onMounted(() => {
  eventBus.resize.on(handleResize);
  if (!Viewport.isDesktop) createDraggable();
});

const handleResize = () => {
  moveSlider(true);
  if (Viewport.isDesktop) {
    draggable.value?.kill();
    draggable.value = null;
  } else {
    createDraggable();
  }
};

const createDraggable = () => {
  draggable.value = Draggable.create($sliderContainer.value, {
    type: "x",
    bounds: $root.value,
    inertia: true,
    dragResistance: 0.4,
    edgeResistance: 1,
    throwResistance: 2000,
    maxDuration: 0.5,
    zIndexBoost: false,
    onPress: () => (isDragging.value = true),
    onRelease: () => (isDragging.value = true),
    snap: (val) => {
      const interval = $root.value?.offsetWidth;

      const currentSnap = currentItemIdx.value * interval;
      const diff = Math.abs(val) - Math.abs(currentSnap);
      const canSnap = Math.abs(diff) > interval * SNAP_THRESHOLD;
      // console.log(Math.abs(diff), interval * SNAP_THRESHOLD);

      const dir = Math.abs(currentSnap) < Math.abs(val) ? -1 : 1;

      let snap;
      if (canSnap) {
        hasSnapped.value = true;
        snap = currentSnap + interval * dir;
        currentItemIdx.value += dir;
      } else {
        snap = currentSnap;
      }
      return snap;
    },
  })[0];
};

const moveSlider = (preventAnimation?: boolean) => {
  if (!hasSnapped.value)
    gsap.to($sliderContainer.value, {
      x: currentItemIdx.value * $root.value?.offsetWidth,
      duration: preventAnimation ? 0 : 0.5,
    });
  hasSnapped.value = false;
};

watch(currentItemIdx, () => moveSlider());

const onZoneEnter = (zone: "next" | "back") => {
  isHovering.value = true;
  cursorLabel.value = t(`detail.gallery-btn.${zone}`);
};
const onZoneLeave = () => {
  isHovering.value = false;
};

const emit = defineEmits(["load"]);
</script>
<style lang="stylus" scoped>
.Gallery
  display flex
  overflow: hidden
  position relative
  &__wrapper
    display flex
    width fit-content
    height: 100%
  &__image
    width 100vw
    height 100%
    +desktop()
      width 50vw
    :deep(img)
      pointer-events none
      object-fit cover
      full()
  &__overlay
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    display flex
    flex-direction column
    justify-content end
    pointer-events none
    z-index 1
  &__dots
    pointer-events auto
    padding-bottom rem(24)
    margin 0 auto
    height: fit-content
    display flex
    gap rem(8)
  &__dot
    width: rem(12)
    height @width
    border: 2px solid rgba($white, 1)
    border-radius 50%
    cursor pointer
    position relative
    // &::after
    //   position: absolute
    //   content ''
    //   top -1.5px
    //   left -1.5px
    //   width calc(100% + 3px)
    //   height calc(100% + 3px)
    //   border-radius: 50%
    //   transform scale(1)
    //   opacity 0
    //   transition transform 0.5s $easeQuartOut, opacity 0.5s $easeQuartOut
    //   border rem(0.8) solid rgba($white, 1)
    &::before
      position: absolute
      content ''
      top rem(-1)
      left rem(-1)
      right rem(-1)
      bottom rem(-1)
      background rgba($white, 1)
      border-radius: 50%
      opacity 0
      transform scale(0)
      transition opacity 0.5s $easeQuartOut, transform 0.5s $easeQuartOut
    &:not(.Gallery__dot--active):hover
      // &::after
      //   transform scale(1.5)
      //   opacity 0.7
      &::before
        opacity 1
        transform scale(0.5)
    &--active
      // &::after
      //   transform scale(1)
      //   opacity 1
      &::before
        transform scale(1)
        opacity 1
    +desktop()
      width rem(16)
      height @width
  &__zone
    position absolute
    top 0
    bottom 0
    width 25%
    pointer-events auto
    opacity 0
    z-index 2
    transition opacity 0.5s $easeQuartOut
    cursor pointer
    &:hover
      opacity 1
    &.Disabled
      pointer-events none
      opacity 0
    &.Next
      right 0
      background linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent) no-repeat
    &.Back
      left 0
      background linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent) no-repeat
</style>
