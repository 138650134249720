import { mat4, vec3 } from "gl-matrix";
import GLTFResource from "@webgl/assets/GLTFResource";
import Scene from "@webgl/scene";
import Node from "nanogl-node";
import mat4lerp from "@webgl/math/mat4-lerp";
import GltfTypes from "nanogl-gltf/lib/types/GltfTypes";
import GltfNode from "nanogl-gltf/lib/elements/Node";
import Viewport from "@/store/modules/Viewport";
import mat4LookAt from "@webgl/math/mat4-lookat";
import splinePoints from "@webgl/lib/spline";

const V3A = vec3.create()
const V3B = vec3.create()
const M4 = mat4.create()

const POINTS_TO_WATCH = [
  ["cam-01-01", "cam-01-02", "cam-01-03"],
  ["cam-02-01", "cam-02-02", "cam-02-03"],
  ["cam-03-01", "cam-03-02", "cam-03-03"],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
]

export default class CameraPath {

  cameraList: Node[]
  hotSpotList: Node[]

  hotSpotListOrdered: Node[]
  camAttached: number[]

  screenPoints: Node[] = []

  currPos = 0
  lerpMat: mat4 = mat4.create()

  camGltfResource: GLTFResource

  closestCam: number

  pointsToWatchTitle: Node[] = []
  pointsToWatchScorcese: Node[] = []
  pointsToWatchStandingBear: Node[] = []

  timeline: Timeline

  anchors: number[]

  constructor(readonly scene: Scene, readonly dataurl: string) { }


  async loadDatas() {
    try {
      const camGltfResource = new GLTFResource(
        this.dataurl,
        this.scene,
      );
      await camGltfResource.load()
      this.camGltfResource = camGltfResource
    } catch (e) {
      console.error(e)
    }
  }

  onLoaded() {
    this.cameraList = this.camGltfResource.value.getElementByName(GltfTypes.NODE, Viewport.isMobile ? "Cameras-mobile" : "Cameras")._children.filter(n => !!(n as GltfNode).camera).map(n => n)

    this.cameraList[0].updateWorldMatrix()
    this.cameraList[this.cameraList.length - 1].updateWorldMatrix()

    this.generateCameraPath(this.cameraList)

    this.hotSpotList = this.camGltfResource.value.getElementByName(GltfTypes.NODE, "Hotspot")._children
    this.screenPoints = this.camGltfResource.value.getElementByName(GltfTypes.NODE, "ScreenPoints")._children
    // this.scene.root.add(this.camGltfResource.value.getElementByName(GltfTypes.NODE, "Hotspot"))
    this.scene.spotList = this.camGltfResource.value.getElementByName(GltfTypes.NODE, Viewport.isMobile ? "Light-mobile" : "Light")._children as GltfNode[]
    // this.scene.root.add(this.camGltfResource.value.getElementByName(GltfTypes.NODE, "Light"))
    this.scene.root.add(this.camGltfResource.value.root)
    this.scene.spotList.forEach(s => s.updateWorldMatrix())
    this.scene.nbCam = this.cameraList.length

    this.pointsToWatchTitle = POINTS_TO_WATCH[0].map(name =>
      this.camGltfResource.value.getElementByName(GltfTypes.NODE, name))

    this.pointsToWatchScorcese = POINTS_TO_WATCH[1].map(name =>
      this.camGltfResource.value.getElementByName(GltfTypes.NODE, name))

    this.pointsToWatchStandingBear = POINTS_TO_WATCH[2].map(name =>
      this.camGltfResource.value.getElementByName(GltfTypes.NODE, name))
  }

  generateCameraPath(cameras: Node[]) {

    const cams = cameras.slice()
    cams.push(cams[0])
    const spline = splinePoints(cams, {
      fromLookat: true,
      closed: false,
      segmentLength: 0.1
    })

    const coords: number[] = []

    for (let i = 0; i < spline.points.length; i++) {
      const p = spline.points[i]
      const l = spline.lookats[i]

      coords.push(p[0], p[1], p[2], l[0], l[1], l[2])
    }

    this.timeline = new Timeline()
    this.timeline.datas = coords
    this.timeline.numFrames = (coords.length - 1) / 6
    this.anchors = spline.anchors

    this.timeline.getPositionsAt(V3A, V3B, this.timeline.numFrames - 1)
    mat4LookAt(M4, V3A, V3B)
  }

  getPositionAt(perc: number) {
    this.timeline.getPositionsAt(V3A, V3B, Math.max(0, Math.min(perc * this.timeline.numFrames, this.timeline.numFrames - 1)))


    mat4LookAt(this.lerpMat, V3A, V3B);

    return this.lerpMat
  }
}


const SCALE = 1

class Timeline {

  numFrames: number;
  timescale: number;
  datas: number[];

  constructor() {
    this.datas = null;
    this.timescale = 1;
  }


  getPositionsAt(pos: vec3, tgt: vec3, frame: number) {

    frame /= this.timescale;

    const f1 = Math.floor(frame);

    const blend = frame - f1;
    const iblend = 1.0 - blend;

    const i = f1 * 6;
    const dat = this.datas;

    pos[0] = SCALE * (dat[i + 0] * iblend + dat[i + 6] * blend);
    pos[1] = SCALE * (dat[i + 1] * iblend + dat[i + 7] * blend);
    pos[2] = SCALE * (dat[i + 2] * iblend + dat[i + 8] * blend);

    tgt[0] = SCALE * (dat[i + 3] * iblend + dat[i + 9] * blend);
    tgt[1] = SCALE * (dat[i + 4] * iblend + dat[i + 10] * blend);
    tgt[2] = SCALE * (dat[i + 5] * iblend + dat[i + 11] * blend);


  }
}
