<template>
  <button class="Close">
    <svg-icon name="close" class="Close__Icon" />
  </button>
</template>

<style lang="stylus" scoped>
.Close
  width rem(40)
  height rem(40)
  color rgba($white, 1)
  border-radius 50%
  display flex
  justify-content center
  align-items center
  font-size rem(40)

  &::before
    content ""
    position absolute
    top 0
    left 0
    full()
    border-radius 50%
    border 1px solid rgba($white, 1)
    opacity 0.3
    transition transform 0.7s $easeQuartOut, opacity 0.7s $easeQuartOut

  &:hover
    &::before
      transform scale(1.1)
      opacity 1
</style>
