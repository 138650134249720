<template>
  <div class="Content__Wrapper grid padded">
    <div class="Content col-start-1 col-span-10 xl:col-start-5 xl:col-span-4">
      <DetailCursorsPrevNext
        :prev="prev"
        :next="next"
        @prev="emit('prev', prev)"
        @next="emit('next', next)"
      />

      <split-text
        is="h1"
        class="Content__Title h3"
        :state="showTitle || pageTransition.isFirst ? 'active' : 'before'"
        >{{ sanitizeHtml($t(`detail.${detailId}.title`)) }}</split-text
      >
      <p
        :class="['paragraph2', pageTransition.isFirst ? '' : 'appear', showOthers ? '' : 'hidden']"
        v-html="$t(`detail.${detailId}.description`)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch, inject } from "vue";
import gsap from "gsap";

import useDetails, { DetailState } from "@/composables/useDetails";
import eventBus from "@/utils/EventBus";
import sanitizeHtml from "@/utils/sanitizeHtml";
import pageTransition from "@/store/modules/PageTransition";
import DetailCursorsPrevNext from "@/components/blocks/Detail/DetailCursorsPrevNext.vue";
import { HOTSPOTS, getIndexFromId, getIdFromPermalink } from "@/services/states/HotSpots";

const emit = defineEmits(["prev", "next"]);

const { detailId, isHotspotOpen } = useDetails();
const state = inject<DetailState>("state");

const showTitle = ref<boolean>(false);
const showOthers = ref<boolean>(false);

const currIndex = computed(() => getIndexFromId(getIdFromPermalink(detailId.value as string)));
const prev = computed(() =>
  currIndex.value == 0
    ? HOTSPOTS[HOTSPOTS.length - 1].permalink
    : HOTSPOTS[currIndex.value - 1].permalink,
);
const next = computed(() =>
  currIndex.value == HOTSPOTS.length - 1
    ? HOTSPOTS[0].permalink
    : HOTSPOTS[currIndex.value + 1].permalink,
);

const checkTransition = (appearDelay = 0) => {
  if (isHotspotOpen.value || state.isInDetailsTransition) {
    showTitle.value = false;
    showOthers.value = false;
  } else {
    if (pageTransition.isFirst) {
      showTitle.value = true;
      showOthers.value = true;
    } else {
      gsap
        .timeline()
        .addLabel("content", appearDelay)
        .add(() => (showTitle.value = true), "content")
        .add(() => (showOthers.value = true), "content+=0.3");
    }
  }
};

onMounted(() => {
  eventBus.pageEnter.once(() => checkTransition(1.5));
});

watch(
  () => isHotspotOpen.value,
  () => checkTransition(1),
);
watch(
  () => state.isInDetailsTransition,
  () => checkTransition(),
);
</script>

<style lang="stylus" scoped>
.Content
  position relative
  margin-top rem(-36)
  text-align center
  align-self end

  padding 0 0 rem(20)
  +desktop()
    // padding 0 rem(60) rem(52)
    padding 0 0 rem(52)
  +above(1200px)
    padding 0 4.16vw rem(52)

  &__Wrapper
    flex 0 0 auto

  &__Title
    margin 0 0 rem(2)

.Instruction
  margin 0 0 rem(40)
  transition opacity 0.5s $easeQuartOut
  opacity 1

  &--HasSwipe
    opacity 0

  +desktop()
    display none

  &__Icon
    display block
    margin 0 auto
    font-size rem(35)

.appear
  transition opacity 1s $easeQuartOut, transform 0.7s $easeQuartOut
  opacity 1
  &.hidden
    opacity 0
    pointer-events none
    transform translateY(1rem)
</style>
