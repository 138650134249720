var fn = function( obj ){
var __t,__p='';
__p+='\nvarying vec2 vTexCoord0;\n\nuniform sampler2D tTex;\n\nvoid main(void){\n  \n  vec4 tex = texture2D( tTex, vTexCoord0 );\n  gl_FragColor.rgb = tex.rgb;\n  gl_FragColor.a = tex.a;\n  gl_FragColor.rgb = vec3(tex.a);\n  gl_FragColor.a = 1.0;\n\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
