<template>
  <button
    class="SoundBtn"
    :class="[
      { 'SoundBtn--muted': AudioSettings.isMuted },
      `SoundBtn--${hud.uiTheme}`,
      hud.uiThemeLightQuote ? 'SoundBtn--quote' : '',
    ]"
    @click="onSoundClick"
  >
    <svg-icon name="sound" v-if="!AudioSettings.isMuted" />
    <svg-icon name="sound-off" v-else />
  </button>
</template>

<script lang="ts" setup>
import AudioSettings from "@/store/modules/Audio";
import hud from "@/store/modules/Hud";

const onSoundClick = () => {
  AudioSettings.isMuted = !AudioSettings.isMuted;
};
</script>

<style lang="stylus" scoped>
.SoundBtn
  border-radius 50%
  height fit-content
  font-size rem(14)
  cursor pointer
  pointer-events auto
  // padding rem(12.75) rem(14)
  display flex
  align-items center
  justify-content center
  height rem(40)
  width rem(40)
  &--dark
    border 1px solid rgba($black, 0.3)
    svg
      color rgba($black, 1)
  &--light,
  &--quote
    border 1px solid rgba($white, 1)
    svg
      color rgba($white, 1)
  &--muted
    svg
      opacity 0.5
    +desktop()
      opacity 0.5
    :deep(.wave)
      opacity 0
  +desktop()
    font-size rem(12)
    border none
    padding rem(8) rem(10) rem(10)
</style>
