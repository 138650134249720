<template>
  <main v-if="!showPlayground">
    <FontLoader />
    <GLView />
    <Experience />

    <Layout />
  </main>
  <main v-else>
    <GLPlayground />
  </main>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, onMounted, provide, ref, watch } from "vue";

import hud from "@/store/modules/Hud";
import AppService from "./services/AppService";
import { useAppState } from "./services/Composition";

import Layout from "./components/layout/Layout.vue";
import GLView from "./components/GLView.vue";
import Experience from "./components/pages/Experience.vue";
import GLPlayground from "./components/pages/GLPlayground.vue";
import FontLoader from "./components/blocks/Loader/FontLoader.vue";
import { useAudio } from "@/composables/useAudio";

const audio = useAudio();
provide("audioMethods", audio);

const { state } = useAppState();

const isXp = computed(() => state.value.matches("xp"));
const showPlayground = ref<boolean>(false);

onBeforeMount(() => {
  checkFullScreen();
});

onMounted(() => {
  let isPlayground = false,
    isParallax = false;
  /// #if DEBUG
  let params = new URLSearchParams(document.location.search);
  isPlayground = params.has("playground");
  isParallax = params.has("landscaperParallax");
  /// #endif
  AppService.start(isPlayground, isParallax);

  if (isPlayground || isParallax) showPlayground.value = true;
});

const checkFullScreen = () => {
  document.documentElement.classList.toggle("fullscreen", hud.fullscreen);
};

watch(() => hud.fullscreen, checkFullScreen);
</script>

<style lang="stylus">
html
  &.fullscreen
    &, body
      full()
      overflow hidden
    main
      height 100vh
      height calc(var(--vh, 1vh) * 100)

html, body
  // CANNOT BE PUT HERE IT BREAK STICKY ELEMENTS
  // overflow-x hidden
  // scrollbar-gutter stable

main
  width 100%
</style>
