var fn = function( obj ){
var __t,__p='';
__p+='vec2 uvs = baseColor_texCoord();\nvec4 col = vec4(0.);\nuvs.x *= 1.;\nuvs.y *= 1.;\nfloat xp = u_surfaceParallaxY * 0.01;\nfor (int i = 0; i < LAYERS; i++) {\n  vec2 cuvs = uvs;\n    vec4 pVal = parallaxValues[i];\n    cuvs.x += xp * pVal.z + pVal.x * u_timeFactor;\n    float diff = 0.;\n    if(pVal.w >= 0.) {\n      diff = pVal.w;\n    } else {\n      if(i == 0) cuvs.y *= 0.25;\n      else cuvs.y *= 0.75;\n      diff = 1. - abs(pVal.w);\n    }\n    cuvs.y += pVal.y;\n    cuvs.y -= diff;\n    cuvs.y = clamp(cuvs.y, pVal.y + 0.005, pVal.y + 0.005 + 1. / 4.);\n    cuvs.y = clamp(cuvs.y, 0., 1.);\n    cuvs.x = fract(cuvs.x);\n    vec2 suvs = cuvs;\n    vec4 t = texture2D(tex_basecolor, cuvs);\n    col = mix(col, t, t.a);\n  \n}\nfloat _metalness = 1.0;\n#if HAS_metalness\n    _metalness *= metalness();\n#endif\n#if HAS_metalnessFactor\n    _metalness *= metalnessFactor();\n#endif\nsurface.albedo = (col.rgb * vec3(1.0-0.04) ) * (1.0-_metalness);\nsurface.specular = mix( vec3(0.04), col.rgb, _metalness );\nsurface.emission = vec3(0.0);\nsurface.emission += col.rgb;\nsurface.emission  *= emissiveFactor();\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
