var fn = function( obj ){
var __t,__p='';
__p+='precision highp float;\n\n\nvarying vec2 vTexCoord;\n\nuniform sampler2D tInput;\nuniform float uSpread;\nuniform vec3 uKernel[NUM_SAMPLES];\n\n\n\nvoid main(void){\n\n  vec3 color = vec3( 0.0 );\n\n  float alpha = .02 + .98*texture2D( tInput, vTexCoord ).a;\n\n  for(int i=0; i<NUM_SAMPLES; ++i)\n  {\n    vec3 kernel = uKernel[i].xyz;\n    color += texture2D( tInput, vTexCoord + kernel.xy * uSpread * alpha ).xyz * kernel.z;\n  }\n\n\n  gl_FragColor = vec4( color, alpha );\n  \n\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
