
import Sync from 'nanogl-sync'

import MProvider from './materials-provider'
import compileAsync from './material-precompile';
import { TypeScene } from '@/webgl/scene';
import { GLContext } from 'nanogl/types';
import BaseMaterial from 'nanogl-pbr/Material';
import { Passes } from '@/webgl/glsl/Passes';

function noop() {

}


class Materials {

  scene: TypeScene;
  gl: GLContext;

  materials: Record<string, BaseMaterial>;
  matlist: BaseMaterial[];
  defaultMaterial: BaseMaterial;




  constructor(scene: TypeScene) {

    this.scene = scene;
    this.gl = scene.gl;

    this.materials = {};
    this.matlist = [];

    this.defaultMaterial = null;

  }


  dummy() {
    return {
      compile: noop,
      prg: {
        use: noop
      }
    }
  }


  dispose() {
    this.gl = null;
    this.scene = null;
    this.materials = null;
    this.matlist = null;
    this.defaultMaterial = null;
  }

  /**
   * @param {string} uid unique id 
   */
  createProvider(uid: string) {
    return new MProvider(this, uid);
  }



  registerMaterial(mat: BaseMaterial, name: string) {
    // console.log( name )
    if (this.materials[name] === undefined) {
      this.materials[name] = mat;
      mat.name = name;
      this.matlist.push(mat);
    }
  }


  get(name: string) {
    var m = this.materials[name];
    if (m === undefined) {
      console.log('        -> not found', name);
      m = this.defaultMaterial;
    }
    return m;
  }


  compileAllAsync(): Promise<unknown> {
    return compileAsync(this.matlist)
  }

  /**
   * compile all shader using fence sync
   */
  compileAll(): Promise<unknown> {

    this.matlist.forEach((mat: BaseMaterial) => {
      const prg = mat.getPass(Passes.DEFAULT).getProgram();
      prg.use();
    });

    var sync = new Sync(this.gl);
    sync.insert();
    return sync.promise;
  }

}



export default Materials;
