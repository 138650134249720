import { TypeScene } from "@/webgl/scene";
import { ICameraController } from "./ICameraController";



export default class CameraControler {

  scene: TypeScene;
  _current: ICameraController;


  constructor(scene: TypeScene) {
    this.scene = scene;
    this._current = null;
  }


  setControler(ctrl: ICameraController) {
    if (this._current) {
      this._current.stop()
    }
    this._current = ctrl;
    ctrl.start(this.scene.camera);
  }


  preRender() {
    if (this._current) {
      this._current.update(this.scene.dt);
    }
  }


  render() {
    if (this._current && this._current.render) {
      this._current.render(this.scene.dt);
    }
  }

}
