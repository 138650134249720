<template>
  <div class="Experience">
    <ExperienceLoader />
    <TransitionGroup name="fade">
      <ExperienceItemsTitle v-if="isXp || isInit" />
      <ExperienceHotspots v-if="isXp || isInit" />
      <ExperienceQuotes v-if="isXp || isInit" />
      <ExperienceScrollLabel v-if="!hasStartedScrolling" />
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useAppState } from "@/services/Composition";
import ExperienceItemsTitle from "@/components/blocks/Experience/ExperienceItemsTitle.vue";
import ExperienceHotspots from "@/components/blocks/Experience/ExperienceHotspots.vue";
import ExperienceQuotes from "@/components/blocks/Experience/ExperienceQuotes.vue";
import ExperienceLoader from "@/components/blocks/Experience/ExperienceLoader.vue";
import ExperienceScrollLabel from "@/components/blocks/Experience/ExperienceScrollLabel.vue";
import AppService from "@/services/AppService";
import Scene from "@webgl/scene";

const { state } = useAppState();

const isInit = ref(false);
const isXp = computed(() => state.value.matches("xp.running.default"));
watch(isXp, () => {
  if (isXp.value) {
    isInit.value = true;
  }
});

const hasStartedScrolling = computed(
  () =>
    (isInit.value || isInit.value) && (AppService.glapp.scene as Scene).hasStartedScrolling.value,
);
</script>

<style lang="stylus" scoped>
.Experience
  position fixed
  top 0px
  left: 0
  width: 100vw
  height: 100vh
  visibility: hidden
  .loading
    width 100vw
    height 100vh
    height: calc(var(--vh, 1vh) * 100);
    position absolute
    top 0
    left 0
    z-index: 1
    display flex
    justify-content center
    align-items center
    visibility: visible;
    h1
      font-size 2rem
      color black
</style>
