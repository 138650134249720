<template>
  <component
    :is="is"
    :to="to"
    :class="{
      RoundButton: true,
      active: active,
      'RoundButton--HasLabel': label !== '',
      'RoundButton--SmallIcon': smallIcon,
      'RoundButton--Hover': isHover || isHoverForced,
      hover: isHover || isHoverForced,
      'is-touch': Viewport.isTouch,
    }"
    @click="onClick"
  >
    <span
      class="Magnet"
      @mouseenter="onMouseEnter"
      @mousemove="onMouseMove"
      @mouseleave="onMouseLeave"
    />

    <span
      class="HoverBackground"
      :style="{ transform: `translate3d(${current.x}px, ${current.y}px, 0px)` }"
    >
      <span class="HoverBackground__Inner"></span>
    </span>
    <span class="Inner" :style="{ transform: `translate3d(${current.x}px, ${current.y}px, 0px)` }">
      <Transition name="iconTransition">
        <svg-icon
          v-if="iconLabel && label && (isHover || isHoverForced) && !active"
          class="Icon Icon--Label"
          :name="iconLabel"
        />
        <svg-icon v-else class="Icon" :name="icon" />
      </Transition>
      <span v-if="label" class="Label paragraph1"
        ><span class="Line__Wrapper prevent-select">
          <split-text :state="isHoverForced ? 'active' : labelState" :isRewind="labelIsRewind">{{
            label
          }}</split-text>
        </span></span
      >
    </span>
  </component>
</template>

<script lang="ts" setup>
import { computed, defineProps, onMounted, onUnmounted, ref, watch } from "vue";
import gsap from "gsap";
import lerp from "@/utils/Lerp";
import eventBus from "@/utils/EventBus";
import Viewport from "@/store/modules/Viewport";

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  is: {
    type: String,
    default: "router-link",
  },
  to: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  iconLabel: {
    type: String,
    required: false,
  },
  smallIcon: {
    type: Boolean,
    default: false,
  },
  disableMagnet: {
    type: Boolean,
    default: false,
  },
  isClickForced: {
    type: Boolean,
    default: true,
  },
});

const power = 0.5;
const ease = 0.05;
let position = { x: 0, y: 0 };
let target = { x: 0, y: 0 };
let magnetSize = 0;
const current = ref({ x: 0, y: 0 });
const isHover = ref<boolean>(false);
const isHoverForced = ref<boolean>(false);
const labelState = ref("before");
const labelIsRewind = ref<boolean>(false);

onMounted(() => {
  gsap.ticker.add(tick);
});
onUnmounted(() => {
  gsap.ticker.remove(tick);
});

watch(
  () => isHover.value,
  (value) => {
    if (value) {
      labelIsRewind.value = false;
      setTimeout(() => {
        labelState.value = "active";
      }, 200);
    } else {
      labelIsRewind.value = true;
      labelState.value = "before";
    }
  },
);

const tick = () => {
  current.value.x = Math.round(lerp(current.value.x, target.x, ease) * 100) / 100;
  current.value.y = Math.round(lerp(current.value.y, target.y, ease) * 100) / 100;
};

const onMouseEnter = (e: MouseEvent) => {
  if (Viewport.isTouch) return;
  isHover.value = true;
  if (props.disableMagnet) return;
  if (e.target) {
    const { x, y, width } = (e.target as HTMLElement).getBoundingClientRect();
    position = { x, y };
    magnetSize = width;
  }
};
const onMouseMove = (e: MouseEvent) => {
  if (Viewport.isTouch) return;
  if (props.disableMagnet) return;
  target.x = Math.round((e.clientX - position.x - magnetSize / 2) * power * 100) / 100;
  target.y = Math.round((e.clientY - position.y - magnetSize / 2) * power * 100) / 100;
};
const onMouseLeave = () => {
  isHover.value = false;
  if (props.disableMagnet) return;
  target.x = 0;
  target.y = 0;
};

const onClick = () => {
  if (props.isClickForced && !Viewport.isTouch) {
    isHoverForced.value = true;
    eventBus.pageEnterCompleted.once(() => {
      isHoverForced.value = false;
    });
  }
};

const offset = computed(() => `-${props.disableMagnet ? 0 : props.label !== "" ? 64 : 24}px`);
</script>

<style lang="stylus" scoped>
$offset = v-bind(offset)
.iconTransition-enter-active
  transition transform 0.35s $easeQuartOut 0.1s, opacity 0.35s $easeQuartOut 0.1s
.iconTransition-leave-active
  transition transform 0.35s $easeQuartOut, opacity 0.35s $easeQuartOut

.iconTransition-enter-from
  transform translateY(50%)
  opacity 0
.iconTransition-leave-to
  transform translateY(-50%)
  opacity 0

.iconTransition-enter-to,
.iconTransition-leave-from
  transform translateY(0)
  opacity 1

.RoundButton
  position absolute
  top 0
  left 0
  width rem(40)
  height rem(40)
  border-radius 50%
  transform translate(-50%, -50%)

  .Inner
    width 100%
    height 100%
    position absolute
    top 0
    left 0
    border-radius inherit
    background rgba($red, 1)
    display flex
    justify-content center
    align-items center
    color rgba($white, 1)
    font-size rem(24)
    pointer-events none
  &--SmallIcon
    .Inner
      font-size rem(16)

  .Magnet
    display block
    position absolute
    top $offset
    left $offset
    bottom $offset
    right $offset
    border-radius inherit
    // background rgba($red, 0.3)

  .Icon
    position relative
    z-index 1
    font-size rem(40)
    &--Label
      position absolute
      font-size rem(14)

  .Label
    position absolute
    top 0
    left 0
    height 100%
    white-space nowrap
    display flex
    align-items center
    padding 0.18em rem(22) 0 rem(40)
    background rgba($red, 1)
    border-radius 9999px
    pointer-events none

    clip-path inset(0% 100% 0% 0% round rem(20))
    transform scaleY(1.01)
    transition clip-path 0.7s $easeQuartOut 0.2s
  &--Hover .Label
    transition clip-path 0.7s $easeQuartOut


  .Line__Wrapper
    display block
    overflow hidden
    height 48%



  .HoverBackground
    position absolute
    top rem(1)
    left rem(1)
    right rem(1)
    bottom rem(1)
    // background rgba(green, 1)
    pointer-events none
    z-index -1
    &__Inner
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      &::after, &::before
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        content ''
        border-radius 9999px
        transition transform 0.5s $easeQuartOut
      &::after
        background rgba($red, 1)
        border rgba($white, 1) 1px solid
        z-index -1
        transform scale(1)
      &::before
        transform scale(1)
        background rgba($red, 1)
        z-index -2
        transition transform 0.5s $easeQuartOut 0.04s

  .Inner
    &::before,
    &::after
      content ''
      position absolute
      top rem(-4)
      left rem(-4)
      right rem(-4)
      bottom rem(-4)
      border 1px solid rgba($red, 1)
      border-radius inherit
      transition transform 0.5s $easeQuartOut, opacity 0.5s $easeQuartOut
      transform scale(0.8)
      opacity 0
    &::after
      opacity 1

  &--HasLabel .Inner
    &::before,
    &::after
      display none

  &--HasLabel .HoverBackground
    display none

  &:not(.is-touch).hover .HoverBackground__Inner,
  &:not(.is-touch).active .HoverBackground__Inner
    &::after
      transform scale(1.26)
    &::before
      transform scale(1.35)

  &:not(.active):not(.is-touch).hover .Inner
    // &::before
    //   transform scale(1)
    //   opacity 1
    // &::after
    //   transform scale(1.2)
    //   opacity 0
    // .Icon
    //   transform scale(0.9)
    .Label
      clip-path inset(0% 0% 0% 0% round rem(20))

  :deep(.Char)
    padding 0.0825em 0 0 0
    line-height 0.825
  :deep(.Word)
    display flex
    line-height 0.825
</style>
