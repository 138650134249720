<template>
  <picture :alt="props.alt" ref="picture">
    <source
      v-if="hasMobile && !hasMobile2x && hasAvif"
      :srcset="`${getImgSrc(`${baseSrc}_mobile.avif`)} 1x`"
      type="image/avif"
      media="(max-width: 1050px)"
    />
    <source
      v-if="hasMobile && hasMobile2x && hasAvif"
      :srcset="`${getImgSrc(`${baseSrc}_mobile.avif`)} 1x, ${getImgSrc(
        `${baseSrc}_mobile@2x.avif`,
      )} 2x`"
      type="image/avif"
      media="(max-width: 1050px)"
    />
    <source
      v-if="hasMobile && !hasMobile2x && hasWebp"
      :srcset="`${getImgSrc(`${baseSrc}_mobile.webp`)} 1x`"
      type="image/webp"
      media="(max-width: 1050px)"
    />
    <source
      v-if="hasMobile && hasMobile2x && hasWebp"
      :srcset="`${getImgSrc(`${baseSrc}_mobile.webp`)} 1x, ${getImgSrc(
        `${baseSrc}_mobile@2x.webp`,
      )} 2x`"
      type="image/webp"
      media="(max-width: 1050px)"
    />

    <source
      v-if="hasMobile && !hasMobile2x"
      :srcset="`${getImgSrc(`${baseSrc}_mobile.${extension}`)} 1x`"
      media="(max-width: 1050px)"
    />
    <source
      v-if="hasMobile && hasMobile2x"
      :srcset="`${getImgSrc(`${baseSrc}_mobile.${extension}`)} 1x, ${getImgSrc(
        `${baseSrc}_mobile@2x.${extension}`,
      )} 2x`"
      media="(max-width: 1050px)"
    />

    <source
      v-if="has2x && hasAvif"
      :srcset="`${getImgSrc(`${baseSrc}.avif`)} 1x, ${getImgSrc(`${baseSrc}@2x.avif`)} 2x`"
      type="image/avif"
    />
    <source
      v-if="has2x && hasWebp"
      :srcset="`${getImgSrc(`${baseSrc}.webp`)} 1x, ${getImgSrc(`${baseSrc}@2x.webp`)} 2x`"
      type="image/webp"
    />
    <source
      v-if="has2x"
      :srcset="`${getImgSrc(`${baseSrc}.${extension}`)} 1x, ${getImgSrc(
        `${baseSrc}@2x.${extension}`,
      )} 2x`"
    />

    <img :src="getImgSrc(src)" loading="lazy" ref="img" @load="emit('load')" />
  </picture>
</template>

<script lang="ts" setup>
import { computed, defineProps, ref } from "vue";

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: true,
  },
  hasWebp: {
    type: Boolean,
    default: false,
  },
  hasAvif: {
    type: Boolean,
    default: false,
  },
  has2x: {
    type: Boolean,
    default: false,
  },
  hasMobile: {
    type: Boolean,
    default: false,
  },
  hasMobile2x: {
    type: Boolean,
    default: false,
  },
});

const picture = ref<HTMLPictureElement>();
const img = ref<HTMLImageElement>();

const baseSrc = computed(() => {
  const url = props.src.split(".")[0];
  return url;
});
const extension = computed(() => {
  const type = props.src.split(".").pop();
  return type;
});

const getImgSrc = (img: string) => {
  return require(`@/assets/images/${img.toLowerCase()}`);
};

defineExpose({
  picture,
  img,
});
const emit = defineEmits(["load"]);
</script>
