<template>
  <div class="RotateScreen" v-if="!Viewport.isDesktop && Viewport.isLandscape">
    <RotateTitle />

    <div class="RotateScreen__Center" :class="{ init }">
      <h3 class="Title h4">{{ sanitizeHtml($t(`rotate.part1`)) }}</h3>
      <svg
        class="RotateScreen__Icon"
        viewBox="0 0 159 99"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.6836 56L30.6836 39.884"
          stroke="currentColor"
          stroke-width="3.22319"
          stroke-linecap="round"
        />
        <path
          d="M22.625 25.8174L22.625 72.5537C22.625 76.1139 25.5111 79.0001 29.0714 79.0001L128.99 79.0001C132.551 79.0001 135.437 76.1139 135.437 72.5537L135.437 25.8174C135.437 22.2571 132.551 19.371 128.99 19.371L29.0714 19.371C25.5111 19.371 22.625 22.2571 22.625 25.8174Z"
          stroke="currentColor"
          stroke-width="3.22319"
        />
        <path
          d="M151.553 18.116C151.553 9.21536 144.338 2 135.438 2"
          stroke="currentColor"
          stroke-width="3.22319"
        />
        <path
          d="M156.82 11.6696L151.749 17.9319L144.318 14.8174"
          stroke="currentColor"
          stroke-width="3.22319"
        />
        <path
          d="M6.50903 80.7464C6.50903 89.647 13.7244 96.8624 22.625 96.8624"
          stroke="currentColor"
          stroke-width="3.22319"
        />
        <path
          d="M1.24219 87.1928L6.31304 80.9304L13.7448 84.045"
          stroke="currentColor"
          stroke-width="3.22319"
        />
      </svg>
      <h3 class="Title h4">{{ sanitizeHtml($t(`rotate.part2`)) }}</h3>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import gsap from "gsap";

import sanitizeHtml from "@/utils/sanitizeHtml";
import Viewport from "@/store/modules/Viewport";

import RotateTitle from "./RotateTitle";

const init = ref<boolean>(false);
onMounted(() => {
  checkOrientation();
});

let tl: gsap.core.Timeline = null;
const checkOrientation = () => {
  if (tl) {
    tl.kill();
  }
  if (!Viewport.isDesktop && Viewport.isLandscape) {
    tl = gsap.timeline().add(() => (init.value = true), 1.3);
  } else {
    init.value = false;
  }
};

watch(
  () => [Viewport.isDesktop, Viewport.isLandscape],
  () => {
    checkOrientation();
  },
);
</script>

<style lang="stylus" scoped>
.RotateScreen
  position fixed
  z-index 10000
  top 0
  left 0
  width 100%
  height 100%
  background rgba($red, 1)
  color rgba($white, 1)

  display flex
  align-items center
  justify-content center
  text-align center

  &__Icon
    width rem(158)
    height rem(95)

    margin rem(12) 0 rem(20)

  &__Center
    opacity 0
    transition opacity 0.7s $easeQuartOut
    &.init
      opacity 1

.Title
  text-transform uppercase
</style>
